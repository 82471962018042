const LANGS_MASTER_DATA_MENU = [
   {
      id: 1,
      title: 'menu_elementary',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "number",
            cn: "数字",
            cn_pronunciation: "[shùzì]",
            jp: "数",
            jp_pronunciation: "[かず]",
            ko: "숫자"
         },
         {
            id: 2,
            en: "time",
            cn: "时间",
            cn_pronunciation: "[shíjiān]",
            jp: "時間",
            jp_pronunciation: "[じかん]",
            ko: "시간"
         },
         {
            id: 3,
            en: "stationaries",
            cn: "文具",
            cn_pronunciation: "[wénjù]",
            jp: "文房具",
            jp_pronunciation: "[ぶんぼうぐ]",
            ko: "문구"
         },
         {
            id: 4,
            en: "animals",
            cn: "动物",
            cn_pronunciation: "[dòngwù]",
            jp: "動物",
            jp_pronunciation: "[どうぶつ]",
            ko: "동물"
         },
         {
            id: 5,
            en: "food",
            cn: "食物",
            cn_pronunciation: "[shíwù]",
            jp: "食べ物",
            jp_pronunciation: "[たべもの]",
            ko: "음식"
         },
         {
            id: 6,
            en: "fruits",
            cn: "水果",
            cn_pronunciation: "[shuǐguǒ]",
            jp: "果物",
            jp_pronunciation: "[くだもの]",
            ko: "과일"
         },
         {
            id: 7,
            en: "vegetable",
            cn: "蔬菜",
            cn_pronunciation: "[shūcài]",
            jp: "野菜",
            jp_pronunciation: "[やさい]",
            ko: "채소, 야채"
         },
         {
            id: 8,
            en: "country",
            cn: "国家",
            cn_pronunciation: "[guójiā]",
            jp: "国",
            jp_pronunciation: "[くに]",
            ko: "국가"
         },
         {
            id: 9,
            en: "appellation",
            cn: "称呼",
            cn_pronunciation: "[chēnghu]",
            jp: "称呼",
            jp_pronunciation: "[しょうこ]",
            ko: "호칭"
         },
         {
            id: 10,
            en: "music",
            cn: "音乐",
            cn_pronunciation: "[yīnyuè]",
            jp: "音楽",
            jp_pronunciation: "[おんがく]",
            ko: "음악"
         },
         {
            id: 11,
            en: "furniture",
            cn: "家具",
            cn_pronunciation: "[jiāju]",
            jp: "家具",
            jp_pronunciation: "[かぐ]",
            ko: "가구"
         },
         {
            id: 12,
            en: "feeling",
            cn: "感觉",
            cn_pronunciation: "[gǎnjué]",
            jp: "感じ",
            jp_pronunciation: "[かんじ]",
            ko: "느낌"
         },
         {
            id: 13,
            en: "home appliances",
            cn: "电器",
            cn_pronunciation: "[diànqì]",
            jp: "家電製品",
            jp_pronunciation: "[かでんせいひん]",
            ko: "가전제품"
         },
         {
            id: 14,
            en: "entertainment",
            cn: "娱乐",
            cn_pronunciation: "[yúlè]",
            jp: "エンターテイメント",
            jp_pronunciation: "[エンターテイメント]",
            ko: "엔터테인먼트"
         },
         {
            id: 15,
            en: "tourist attraction",
            cn: "景点",
            cn_pronunciation: "[jǐngdiǎn]",
            jp: "観光地",
            jp_pronunciation: "[かんこうち]",
            ko: "관광지"
         },
         {
            id: 16,
            en: "regards",
            cn: "问候",
            cn_pronunciation: "[wènhòu]",
            jp: "安否",
            jp_pronunciation: "[あんぴ]",
            ko: "안부"
         },
         {
            id: 17,
            en: "demonstrative pronoun",
            cn: "指示代词",
            cn_pronunciation: "[zhǐshìdàicí]",
            jp: "指示代名詞",
            jp_pronunciation: "[しじだいめいし]",
            ko: "지시대명사"
         },
         {
            id: 18,
            en: "adjective",
            cn: "形容词",
            cn_pronunciation: "[xíngróngcí]",
            jp: "形容詞",
            jp_pronunciation: "[けいようし]",
            ko: "형용사"
         },
         {
            id: 19,
            en: "public holiday",
            cn: "节日",
            cn_pronunciation: "[jiérì]",
            jp: "祝日",
            jp_pronunciation: "[しゅくじつ]",
            ko: "공휴일"
         },
         {
            id: 20,
            en: "body",
            cn: "身体",
            cn_pronunciation: "[shēntǐ]",
            jp: "身体",
            jp_pronunciation: "[しんたい]",
            ko: "신체"
         },
         {
            id: 21,
            en: "sports",
            cn: "运动",
            cn_pronunciation: "[yùndòng]",
            jp: "運動",
            jp_pronunciation: "[うんどう]",
            ko: "운동"
         },
         {
            id: 22,
            en: "means of transportation",
            cn: "交通工具",
            cn_pronunciation: "[[jiāotōnggōngjù]]",
            jp: "交通手段",
            jp_pronunciation: "[[こうつうしゅだん]]",
            ko: "교통수단"
         },
         {
            id: 23,
            en: "clothes & accessories",
            cn: "服饰",
            cn_pronunciation: "[[fúshì]]",
            jp: "服飾",
            jp_pronunciation: "[[ふくしょく]]",
            ko: "의복"
         },
         {
            id: 24,
            en: "family",
            cn: "家",
            cn_pronunciation: "[[jiā]]",
            jp: "家族",
            jp_pronunciation: "[[かぞく]]",
            ko: "가족"
         },
         {
            id: 25,
            en: "occupation",
            cn: "职业",
            cn_pronunciation: "[[zhíyè]]",
            jp: "職業",
            jp_pronunciation: "[[しょくぎょう]]",
            ko: "직업"
         },
         {
            id: 26,
            en: "computer",
            cn: "电脑",
            cn_pronunciation: "[[diànnǎo]]",
            jp: "コンピューター",
            jp_pronunciation: "[[コンピューター]]",
            ko: "컴퓨터"
         },
         {
            id: 27,
            en: "nature",
            cn: "自然",
            cn_pronunciation: "[[zìrán]]",
            jp: "自然",
            jp_pronunciation: "[[しぜん]]",
            ko: "자연"
         },
         {
            id: 28,
            en: "weather",
            cn: "天气",
            cn_pronunciation: "[[tiānqì]]",
            jp: "天気",
            jp_pronunciation: "[[てんき]]",
            ko: "날씨"
         },
         {
            id: 29,
            en: "place",
            cn: "地点",
            cn_pronunciation: "[[dìdiǎn]]",
            jp: "場所",
            jp_pronunciation: "[[ばしょ]]",
            ko: "장소"
         },
         {
            id: 30,
            en: "person",
            cn: "人称",
            cn_pronunciation: "[[rénchēng]]",
            jp: "人称",
            jp_pronunciation: "[[にんしょう]]",
            ko: "인칭"
         },
         {
            id: 31,
            en: "color",
            cn: "颜色",
            cn_pronunciation: "[[yánsè]]",
            jp: "色",
            jp_pronunciation: "[[いろ]]",
            ko: "색"
         },
         {
            id: 32,
            en: "toy",
            cn: "玩具",
            cn_pronunciation: "[[wánjù]]",
            jp: "玩具",
            jp_pronunciation: "[[おもちゃ]]",
            ko: "장난감"
         },
         {
            id: 33,
            en: "verb",
            cn: "动词",
            cn_pronunciation: "[[dòngcí]]",
            jp: "動詞",
            jp_pronunciation: "[[どうし]]",
            ko: "동사"
         },
         {
            id: 34,
            en: "subject",
            cn: "学科",
            cn_pronunciation: "[[xuékē]]",
            jp: "教科目",
            jp_pronunciation: "[[きょうかもく]]",
            ko: "교과목"
         },
         {
            id: 35,
            en: "public signs",
            cn: "公共标识",
            cn_pronunciation: "[[gōnggòngbiāoshí]]",
            jp: "公共標識",
            jp_pronunciation: "[[こうきょう]]",
            ko: "공공표지"
         },
         {
            id: 36,
            en: "daily necessities",
            cn: "日用品",
            cn_pronunciation: "[[rìyòngpǐn]]",
            jp: "日用品",
            jp_pronunciation: "[[にちようひん]]",
            ko: "일용품"
         },
         {
            id: 37,
            en: "shapes",
            cn: "形状",
            cn_pronunciation: "[[xíngzhuàng]]",
            jp: "形状",
            jp_pronunciation: "[[けいじょう]]",
            ko: "모양"
         },
         {
            id: 38,
            en: "drinks",
            cn: "饮料",
            cn_pronunciation: "[[yǐnliào]]",
            jp: "飲物",
            jp_pronunciation: "[[のみもの]]",
            ko: "음료"
         },
         {
            id: 39,
            en: "school",
            cn: "学校",
            cn_pronunciation: "[[xuéxiào]]",
            jp: "学校",
            jp_pronunciation: "[[がっこう]]",
            ko: "학교"
         },
         {
            id: 40,
            en: "plants",
            cn: "植物",
            cn_pronunciation: "[[zhíwù]]",
            jp: "植物",
            jp_pronunciation: "[[しょくぶつ]]",
            ko: "식물"
         },
         {
            id: 41,
            en: "measurement",
            cn: "测量",
            cn_pronunciation: "[[cèliàng]]",
            jp: "測量",
            jp_pronunciation: "[[そくりょう]]",
            ko: "측량"
         }
      ]
   }
]

   export default LANGS_MASTER_DATA_MENU; 
