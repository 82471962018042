import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools} from 'redux-devtools-extension'
import rootReducer from './rootReducer'
import logger from 'redux-logger'

let middlewares = [];

if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger);
  middlewares = [...middlewares, thunk, logger];
} else {
  middlewares = [...middlewares, thunk];
}

const store = createStore(
    rootReducer, 
    // composeWithDevTools(applyMiddleware(logger, thunk))
    // composeWithDevTools(applyMiddleware(...middlewares))
    compose(applyMiddleware(...middlewares))
)

export default store