import { SELECT_EN_VOICECATEGORY } from './en_voiceTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

const initialState = {
    en_voicecategory: {value: 0, label: 'default'}
}

const en_voiceReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_EN_VOICECATEGORY:
            return {
             ...state,
             en_voicecategory: action.payload
            }
        // case SELECT_VIEWSTYLE:
        //     return {
        //     ...state,
        //     viewstsyle: action.payload
        //     }        
                
        default: return state
    }
}

export default en_voiceReducer