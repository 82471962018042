import { SELECT_STORY_MENUCATEGORY } from './story_menuTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectStory_Menucategory = (string) => {
    return {
        type: SELECT_STORY_MENUCATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }