import { SELECT_MENUCATEGORY } from './menuTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectMenucategory = (string) => {
    return {
        type: SELECT_MENUCATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }