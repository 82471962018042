import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function StandardSmallHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/section-header-3.jpg") + ")"
        }}
      >
        <div className="filter filter-danger" />
        <div className="content-center">
          <Container>
            <h1>
              3개국어 사전 <br />
              
            </h1>
            <h3>검색.</h3>
          </Container>
        </div>
      </div>
    </>
  );
}

export default StandardSmallHeader;
