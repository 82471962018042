/*!

=========================================================
* Paper Kit PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

//redux
import { Provider } from "react-redux";
// import { store } from "./redux/store";
import store from "./redux/store";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Index from "views/Index.js";
// import Header from "views/3langschool/Header.js";
import Elementary_word from "views/3langschool/Elementary_word.js";
import Elementary_story from "views/3langschool/Elementary_story.js";
import General_word from "views/3langschool/General_word.js";
// import Menu from "views/3langschool/Menu.js";
// import Storeinfo from "views/3langschool/Storeinfo.js";
import Franchise from "views/3langschool/Franchise.js";
import StartupInquiry from "views/3langschool/StartupInquiry.js";
// import FAQ from "views/3langschool/FAQ.js";
// import ETC from "views/3langschool/ETC.js";

// spectrum
// import Display from "views/spectrum/Display.js";

// others

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/index" render={props => <Index {...props} />} />

        {/* <Route path="/elementary" render={props => <Elementary category="elementary" {...props} />} /> */}
        <Route path="/elementary_word" render={props => <Elementary_word {...props} />} />
        <Route path="/elementary_story" render={props => <Elementary_story {...props} />} />
        <Route path="/general_word" render={props => <General_word {...props} />} />
        {/* <Route path="/menu" render={props => <Menu {...props} />} />
        <Route path="/storeinfo" render={props => <Storeinfo {...props} />} />
        <Route path="/franchise" render={props => <Franchise {...props} />} />
        <Route path="/cost" render={props => <StartupInquiry Link='cost' {...props} />} />
        <Route path="/inquiry" render={props => <StartupInquiry Link='inquiry'{...props} />} />
        <Route path="/faq" render={props => <FAQ {...props} />} />
        <Route path="/etc" render={props => <ETC {...props} />} />
        <Route path="/spectrum" render={props => <Display {...props} />} /> */}
        <Route path="/education-consulting" render={props => <Franchise {...props} />} />
        <Route path="/inquiry" render={props => <StartupInquiry {...props} />} />
        <Redirect to="/index" />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
