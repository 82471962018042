import React from "react";

// reactstrap components
import {  
  Card,
  CardBody,  
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";



function StandardFranchise00() {
  return (
    <>
      <div className="main">
        <div className="section">
          <Container>
          <br/>
            <h3 className="title-uppercase">외국어 학습은 이렇게!</h3>
            <br/>
            <p>
              아무리 공부해도 외국어(영어, 중국어, 일본어)가 늘지 않는 분들한테 눈높이 교육을 해 드립니다.
            </p>            
            <p>
              또한, 장기적인 계획을 갖고 관리를 해 드립니다.
            </p>
            <br/>
            <h3 className="title-uppercase">
              지금
              <i className="fa fa-heart heart mr-3 ml-1" />
              바로 시작하세요
            </h3>
            <br/>
            <p>
              본인의 외국어 실력이 성장하는 모습을 보고 만족하실 겁니다.
            </p>

            <h3 className="more-info">컨설팅 제공혜택</h3>
            <br/>
            <Row className="coloured-cards">
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="blue"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">혜택 1</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          원어민 선생님
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        원어민 선생님을 선정해서 원하는 시간에 배정을 해 드립니다.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="green"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">혜택 2</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          사전인터뷰
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        사전에 상담을 통해서 외국어 실력 체크를 합니다.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="yellow"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">혜택 3</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          한국인 선생님
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        수업을 원활히 진행을 하기 위해서 보조교사로서 한국인 선생님을
                        배정해드립니다.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
            </Row>
            <Row className="coloured-cards">
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="brown"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">혜택 4</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          주기적인 평가
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        주기적인 평가를 통해서 부족한 부분을 파악하는데 도움을 드립니다.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="purple"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">혜택 5</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          학습로드맵 제공
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        본인이 원하는 레벨에 도달하기 위한 로드맵을 제공해드립니다.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="orange"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">혜택 6</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Q&A 센터운영
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        학습전반에 관한 문의사항을 지원하기 위한 Q&A 센터를 운영합니다.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
            </Row>            
          </Container>
        </div>
      </div>      
    </>
  );
}

export default StandardFranchise00;
