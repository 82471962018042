import { SELECT_GENERAL_SUBCATEGORY } from './generalTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

const initialState = {
    general_subcategory: 'country'
    // viewstyle: 'card'
}

const generalReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_GENERAL_SUBCATEGORY:
            return {
             ...state,
             general_subcategory: action.payload
            }
        // case SELECT_VIEWSTYLE:
        //     return {
        //     ...state,
        //     viewstsyle: action.payload
        //     }        
                
        default: return state
    }
}

export default generalReducer