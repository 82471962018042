import React from "react";

// core components
// import ColorNavbarNotTransparent from "components/Navbars/ColorNavbarNotTransparent.js";
import StandardNavbar01NotTransparent from "components/3langschool/Navbar/StandardNavbar01NotTransparent.js";

// james added
// import Underconstruction from "components/3langschool/Display/Underconstruction.js";
// cost
// import StandardCost01 from "components/3langschool/Cost/StandardCost01.js";
// import StandardCost02 from "components/3langschool/Cost/StandardCost02.js";
// import StandardCost03 from "components/3langschool/Cost/StandardCost03.js";
// join franchise
import StandardJoinFranchise01 from "components/3langschool/JoinFranchise/StandardJoinFranchise01.js";
// contact
import StandardContactUs03 from "components/3langschool/ContactUs/StandardContactUs03.js";
// import StandardContactUs02 from "components/3langschool/ContactUs/StandardContactUs02";
import StandardFooter01 from "components/3langschool/Footer/StandardFooter01.js";



function StartupInquiry() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("start");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("start");
    };
  });  
  return (
    <>
    {/* <ColorNavbarNotTransparent />     */}
    <StandardNavbar01NotTransparent />    
    {/* <Underconstruction /> */}
    <StandardJoinFranchise01 />
    <StandardContactUs03 />    
    {/* <StandardCost01 />
    <StandardCost02 />
    <StandardCost03 /> */}
    {/* <StandardContactUs03 /> */}
    {/* <StandardContactUs02 /> */}
    <StandardFooter01 />
    </>
  );
}

export default StartupInquiry;
