import { SELECT_KO_VOICECATEGORY } from './ko_voiceTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectKO_Voicecategory = (string) => {
    return {
        type: SELECT_KO_VOICECATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }