const LANGS_MASTER_DATA_MATERIAL_MENU = [
   {
      id: 1,
      title: 'menu_general',
      routeName: 'general',
      items: [
         {
            id: 1,
            en: "country",
            cn: "国家",
            cn_pronunciation: "[guójiā]",
            jp: "国家",
            jp_pronunciation: "[こっか]",
            ko: "국가"
         }
      ]
   }
]

   export default LANGS_MASTER_DATA_MATERIAL_MENU; 
