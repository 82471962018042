import React from "react";

// core components
// import ColorNavbarNotTransparent from "components/Navbars/ColorNavbarNotTransparent.js";
import StandardNavbar01NotTransparent from "components/3langschool/Navbar/StandardNavbar01NotTransparent.js";

// james added
// import Underconstruction from "components/3langschool/Display/Underconstruction.js";
// franchise
import StandardFranchise00 from "components/3langschool/Franchise/StandardFranchise00.js";
import StandardFranchise01 from "components/3langschool/Franchise/StandardFranchise01.js";
// import StandardFranchise02 from "components/3langschool/Franchise/StandardFranchise02.js";
// import StandardFranchise03 from "components/3langschool/Franchise/StandardFranchise03.js";
// import StandardFranchise04 from "components/3langschool/Franchise/StandardFranchise04.js";
// import StandardFranchise05 from "components/3langschool/Franchise/StandardFranchise05.js";
// footer
import StandardFooter01 from "components/3langschool/Footer/StandardFooter01.js";



// testimonial
// import StandardTestimonial01 from "components/3langschool/Testimonial/StandardTestimonial01";
// import StandardTestimonial02 from "components/3langschool/Testimonial/StandardTestimonial02";
// import StandardTestimonial03 from "components/3langschool/Testimonial/StandardTestimonial03";

function Franchise() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("franchise");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("franchise");
    };
  });  
  return (
    <>    
    <StandardNavbar01NotTransparent />
    {/* <Underconstruction /> */}    
    <StandardFranchise00 />
    <StandardFranchise01 />
    {/* <StandardFranchise02 />    
    <StandardFranchise03 />
    <StandardFranchise04 />
    <StandardFranchise05 />    
    <StandardTestimonial01 />
    <StandardTestimonial02 />
    <StandardTestimonial03 /> */}
    

    <StandardFooter01 />
    </>
  );
}

export default Franchise;
