import { SELECT_KO_VOICECATEGORY } from './ko_voiceTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

const initialState = {
    ko_voicecategory: {value: 0, label: 'default'}
}

const ko_voiceReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_KO_VOICECATEGORY:
            return {
             ...state,
             ko_voicecategory: action.payload
            }
        // case SELECT_VIEWSTYLE:
        //     return {
        //     ...state,
        //     viewstsyle: action.payload
        //     }        
                
        default: return state
    }
}

export default ko_voiceReducer