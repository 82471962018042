import React from "react";
// import { useState } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';

// reactstrap components
import {
  Button,
  // Label,
  // FormGroup,
  // Input,
  Table,
  Container,
  Row,
  Col  
} from "reactstrap";

// core components

// react-redux
import { connect } from 'react-redux'
import { selectSubcategory } from '../../../redux'
// import { selectViewstyle } from '../../../redux'
import { selectEN_Voicecategory } from '../../../redux'
import { selectCN_Voicecategory } from '../../../redux'
import { selectJP_Voicecategory } from '../../../redux'
import { selectKO_Voicecategory } from '../../../redux'

// james added
import LANGS_MASTER_DATA from './langs_master.data';

const collections = LANGS_MASTER_DATA
// const myfilter = collections.filter(collection => collection.routeName === 'sharedkitchen');    

function Display04_sub_01(props) {  
  // console.log(`${category}`) 
  // console.log(`${subcategory}`) 
  const {subcategory} = props  
  // const {viewstyle} = props    
  const {en_voicecategory} = props  
  const {cn_voicecategory} = props  
  const {jp_voicecategory} = props  
  const {ko_voicecategory} = props  
  
  // const onEnd = () => {console.log("onEnd event occurred")}

  // const onError = (event) => {
  //   console.log(event);
  // };


  const myfilter = collections.filter(collection => collection.title === `${subcategory}`);      

  const { speak, voices } = useSpeechSynthesis();
  // const { speak, voices, supported } = useSpeechSynthesis(onEnd, onError);
  const enVoices = voices.filter(collection => collection.lang.includes('en'));
  const cnVoices = voices.filter(collection => collection.lang.includes('zh'));
  const jpVoices = voices.filter(collection => collection.lang.includes('ja'));
  const koVoices = voices.filter(collection => collection.lang.includes('ko'));

  console.log('en_voicecategory.value is ' + `${en_voicecategory.value}`) 

  return (
    <>
      {/* <div className="section section-gray"> */}
      {/* <div className="features-1"> */}
      {/* cost 1 - simple table */}
        {/* <Container className="tim-container"> */}
        <Container>

        <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">초등학교 단어</h2>
              <h5 className="description">
              {myfilter[0].title}
              </h5>
            </Col>
        </Row>
        {/* <h5>supported is {supported}</h5> */}
        
          {/* <div className="title">
            <h3>초등학교 단어</h3>
          </div> */}
          <Row>
          {/* <Col className="ml-auto mr-auto" md="12"> */}
                {/* <h4 className="title">
                <small>{myfilter[0].title}</small>
                </h4> */}
          <Table responsive>
          <thead>
                  <tr>
                    {/* <th className="text-center">#</th> */}
                    <th colspan="2" className="text-right">영어</th>
                    <th colspan="2" className="text-right">중국어</th>
                    <th colspan="2" className="text-right">일본어</th>
                    <th colspan="2" className="text-right">한국어</th>
                  </tr>
          </thead>
          </Table>
          <Table responsive>
          {            
            myfilter[0].items.map(
              item => (
                <tbody>
                  <tr>
                    {/* <td className="text-center">{item.extra1}</td> */}
                    
                    <td colspan="2" className="text-right">{item.en}
                    <Button
                      className="btn-link"
                      color="danger"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.en}`, voice: enVoices[ `${en_voicecategory.value}`]})}
                      // onClick={() => speak({ text:`${item.en}`, voice: voices[7]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button>   
                    </td>
                    <td colspan="2" className="text-right">{item.cn}&nbsp;{item.cn_pronunciation}
                    <Button
                      className="btn-link"
                      color="danger"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.cn}`, voice: cnVoices[ `${cn_voicecategory.value}`]})}
                      // onClick={() => speak({ text:`${item.cn}`, voice: voices[21]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button> 
                    </td>
                    <td colspan="2" className="text-right">{item.jp}&nbsp;{item.jp_pronunciation}
                    <Button
                      className="btn-link"
                      color="danger"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.jp}`, voice: jpVoices[ `${jp_voicecategory.value}`]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button>                      
                    </td>
                    <td colspan="2" className="text-right">{item.ko}
                    <Button
                      className="btn-link"
                      color="danger"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.ko}`, voice: koVoices[ `${ko_voicecategory.value}`]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button>                     
                    </td>

                    {/* <td colspan="2" className="text-right"><h4>{item.en}</h4></td>
                    <td colspan="2" className="text-right"><h4>{item.cn}</h4><br/><h4>{item.cn_pronunciation}</h4></td>
                    <td colspan="2" className="text-right"><h4>{item.jp}</h4><br/><h4>{item.jp_pronunciation}</h4></td>
                    <td colspan="2" className="text-right"><h4>{item.ko}</h4></td>                                      */}
                  </tr>                                                                                     
                </tbody>

              )
            )
          }

          </Table>      
                {/* </Col> */}

          </Row>
        </Container>
        {/* </div> */}
      {/* </div> */}
    </>
  );
}

// export default Display04_sub;


const mapStateToProps = state => {
  return {
      subcategory: state.elementary.subcategory,
      // viewstyle: state.style.viewstyle,
      en_voicecategory : state.en_voice.en_voicecategory,
      cn_voicecategory : state.cn_voice.cn_voicecategory,
      jp_voicecategory : state.jp_voice.jp_voicecategory,
      ko_voicecategory : state.ko_voice.ko_voicecategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  selectSubcategory: (string) => dispatch(selectSubcategory(string)),
  // selectViewstyle: (string) => dispatch(selectViewstyle(string)),
  selectEN_Voicecategory: (string) => dispatch(selectEN_Voicecategory(string)),
  selectCN_Voicecategory: (string) => dispatch(selectCN_Voicecategory(string)),
  selectJP_Voicecategory: (string) => dispatch(selectJP_Voicecategory(string)),
  selectKO_Voicecategory: (string) => dispatch(selectKO_Voicecategory(string)),
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Display04_sub_01)