import { SELECT_EN_VOICECATEGORY } from './en_voiceTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectEN_Voicecategory = (string) => {
    return {
        type: SELECT_EN_VOICECATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }