import React from "react";

// reactstrap components
import {
  // Label,
  // FormGroup,
  // Input,
  Table,
  Container,
  Row,
  Col  
} from "reactstrap";

// core components

// react-redux
import { connect } from 'react-redux'
import { selectStory_Subcategory } from '../../../redux'
import { selectViewstyle } from '../../../redux'

// james added
import LANGS_MASTER_DATA_STORY from './langs_master_story.data';

const collections = LANGS_MASTER_DATA_STORY
// const myfilter = collections.filter(collection => collection.routeName === 'sharedkitchen');    

function Display05_sub_01(props) {  
  // console.log(`${category}`) 
  // console.log(`${subcategory}`) 
  const {story_subcategory} = props  
  const myfilter = collections.filter(collection => collection.title === `${story_subcategory}`);      

  return (
    <>
      {/* <div className="section section-gray"> */}
      {/* <div className="features-1"> */}
      {/* cost 1 - simple table */}
        {/* <Container className="tim-container"> */}
        <Container>

        <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">초등학교 동화</h2>
              <h5 className="description">
              {myfilter[0].title}
              </h5>
            </Col>
        </Row>
        
          {/* <div className="title">
            <h3>초등학교 단어</h3>
          </div> */}
          <Row>
          {/* <Col className="ml-auto mr-auto" md="12"> */}
                {/* <h4 className="title">
                <small>{myfilter[0].title}</small>
                </h4> */}
          <Table responsive>
          <thead>
                  <tr>
                    {/* <th className="text-center">#</th> */}
                    <th colspan="2" className="text-right">영어</th>
                    <th colspan="2" className="text-right">중국어</th>
                    <th colspan="2" className="text-right">일본어</th>
                    <th colspan="2" className="text-right">한국어</th>
                  </tr>
          </thead>
          </Table>
          <Table responsive>
          {            
            myfilter[0].items.map(
              item => (
                <tbody>
                  <tr>
                    {/* <td className="text-center">{item.extra1}</td> */}
                    
                    <td colspan="2" className="text-right">{item.en}</td>
                    <td colspan="2" className="text-right">{item.cn}<br/>{item.cn_pronunciation}</td>
                    <td colspan="2" className="text-right">{item.jp}<br/>{item.jp_pronunciation}</td>
                    <td colspan="2" className="text-right">{item.ko}</td>

                    {/* <td colspan="2" className="text-right"><h4>{item.en}</h4></td>
                    <td colspan="2" className="text-right"><h4>{item.cn}</h4><br/><h4>{item.cn_pronunciation}</h4></td>
                    <td colspan="2" className="text-right"><h4>{item.jp}</h4><br/><h4>{item.jp_pronunciation}</h4></td>
                    <td colspan="2" className="text-right"><h4>{item.ko}</h4></td>                                      */}
                  </tr>                                                                                     
                </tbody>

              )
            )
          }

          </Table>      
                {/* </Col> */}

          </Row>
        </Container>
        {/* </div> */}
      {/* </div> */}
    </>
  );
}

// export default Display04_sub;


const mapStateToProps = state => {
  return {
      story_subcategory: state.story.story_subcategory,
      viewstyle: state.style.viewstyle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectStory_Subcategory: (string) => dispatch(selectStory_Subcategory(string)),
  selectViewstyle: (string) => dispatch(selectViewstyle(string))
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Display05_sub_01)