import React from "react";

// reactstrap components
import {
  Button,
  Card,  
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

const post_url = "https://us-central1-langschool-8bb8a.cloudfunctions.net/router/api/contact"

class StandardContactUs03 extends React.Component {

  constructor(props) {
    super(props);

    this.state = {


      name: '',           // 1.이름
      phone: '',          // 2.핸드폰 번호      ex) 010-1234-0901
      email: '',          // 3.이메일           ex) 000@kakao.com
      message: ''         // 4.문의사항

    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
/////////////// send inquiry to the server
    try {
        
        await fetch(post_url, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
            },
                        
            body: JSON.stringify(this.state)            
            
        })
        .then(res => {
            if (res.status === 200) {
                
                this.setState({
                    // interest: '',                            
                    name: '',
                    phone: '',
                    email: '',
                    message: ''
                })                
                alert("메일이 성공적으로 전송되었습니다!");
                
            }
                
            else
                alert("메일을 전송하는 중 문제가 발생되었습니다. 다시 전송해주세요!");
        })

        
    } catch(e) {
        throw e;
        }
/////////////// 
}

// for all the other elements except 'select option'
handleChange = event => {
  const { value, name } = event.target;
  this.setState({ [name]: value })
}

  render() {
    return (
      <>
        <div className="section-contactus cd-section" id="contact-us">
          {/* ********* CONTACT US 1 ********* */}
          <div
            className="contactus-1 section-image"
            style={{
              backgroundImage:
                "url(" + require("assets/img/sections/soroush-karimi.jpg") + ")"
            }}
          >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <Card className="card-contact no-transition">
                    <CardTitle className="text-center" tag="h3">
                      문의
                    </CardTitle>
                    <Row>
                      <Col className="ml-auto" md="5">
                        <CardBody>
                          <div className="info info-horizontal">
                            <div className="icon icon-info">
                              <i className="nc-icon nc-pin-3" />
                            </div>
                            <div className="description">
                              <h4 className="info-title">
                                회사주소
                              </h4>
                              <p>
                                서울 성북구 종암로 24가길 <br />
                                삼랑(3LANGSCHOOL)
                                
                              </p>
                            </div>
                          </div>
                          <div className="info info-horizontal">
                            <div className="icon icon-danger">
                              <i className="nc-icon nc-badge" />
                            </div>
                            <div className="description">
                              <h4 className="info-title">전화번호</h4>
                              <p>
                                제임스 허 <br />                              
                                월 - 금, 10:00-18:00
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Col>
                      <Col className="mr-auto" md="5">
                        <Form id="contact-form" method="post" role="form" onSubmit={this.handleSubmit}>
                          <CardBody>
                            <Row>
                            <Col md="6">
                                <FormGroup className="label-floating">
                                  <label className="control-label">
                                    이름
                                  </label>
                                  <Input
                                    name="name"
                                    placeholder="이름"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={this.state.name}
                                    required
                                  />
                                </FormGroup>
                              </Col>                          
                              <Col md="6">
                                <FormGroup className="label-floating">
                                  <label className="control-label">
                                    핸드폰 번호
                                  </label>
                                  <Input
                                    name="phone"
                                    placeholder="핸드폰 번호"
                                    type="text"
                                    onChange={this.handleChange}
                                    value={this.state.phone}
                                    required
                                  />
                                </FormGroup>
                              </Col>
  
                            </Row>
                            <FormGroup className="label-floating">
                              <label className="control-label">
                                이메일주소
                              </label>
                              <Input
                                name="email"
                                placeholder="이메일"
                                type="email"
                                onChange={this.handleChange}
                                value={this.state.email}
                                required
                              />
                            </FormGroup>
                            <FormGroup className="label-floating">
                              <label className="control-label">
                                문의내용
                              </label>
                              <Input
                                id="message"
                                name="message"
                                placeholder="문의내용..."
                                type="textarea"
                                onChange={this.handleChange}
                                value = {this.state.message}
                                rows="6"
                              />
                            </FormGroup>
                            <Row>
                              <Col md="6">
                                <FormGroup check>
                                  <Label check>
                                    <Input defaultValue="" type="checkbox" />
                                    체크해주세요 !{" "}
                                    <span className="form-check-sign" />
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <Button
                                  className="pull-right"
                                  color="primary"
                                  type="submit"
                                >
                                  전송
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Form>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END CONTACT US 1 ********* */}
  
        </div>
      </>
      );
  }

  
}

export default StandardContactUs03;
