import React from "react";

// core components
// import ColorNavbarNotTransparent from "components/Navbars/ColorNavbarNotTransparent.js";
import StandardNavbar01NotTransparent from "components/3langschool/Navbar/StandardNavbar01NotTransparent.js";

// james added
// header
// small header
import StandardSmallHeader from "components/3langschool/Header/StandardSmallHeader.js";
// import StandardSmallHeader2 from "components/3langschool/Header/StandardSmallHeader2.js";
// import StandardSmallHeader3 from "components/3langschool/Header/StandardSmallHeader3.js";

// Display
// import Display01 from "components/3langschool/Display/Display01.js";
// import Display02 from "components/3langschool/Display/Display02.js";
// import Display03 from "components/3langschool/Display/Display03.js";
// import Display04_main from "components/3langschool/Display/Display04_main.js";
// footer
import StandardFooter01 from "components/3langschool/Footer/StandardFooter01.js";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("menu");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("menu");
    };
  });  
  return (
    <>
    {/* <ColorNavbarNotTransparent />     */}
    <StandardNavbar01NotTransparent />  
    

    {/* <StandardSmallHeader3 />       */}

    {/* <Display03 /> */}
    {/* <Display04_main /> */}
    {/* <StandardSmallHeader2 /> */}
    <StandardSmallHeader />
    
    {/* <Display01 /> */}
    {/* <Display02 /> */}
    
    <StandardFooter01 />
    </>
  );
}

export default Index;
