import React from "react";

// reactstrap components
import {
  Button,  
  // Label,
  // FormGroup,
  // Input,
  // Table,
  Container,
  Row,
  Col  
} from "reactstrap";

// react-redux
import { connect } from 'react-redux'
import { selectStory_Subcategory } from '../../../redux'
import { selectViewstyle } from '../../../redux'

// core components

// Display
import Display05_sub_01 from "components/3langschool/Display/Display05_sub_01.js";
import Display05_sub_02 from "components/3langschool/Display/Display05_sub_02.js";
import Display05_sub_03 from "components/3langschool/Display/Display05_sub_03.js";
import Display05_sub_04 from "components/3langschool/Display/Display05_sub_04.js";

// james added
import LANGS_MASTER_DATA_STORY from './langs_master_story.data';

const collections = LANGS_MASTER_DATA_STORY
// const myfilter = collections.filter(collection => collection.routeName === 'sharedkitchen');    

function Display05_main(props) {
 
  const {subcategory, viewstyle} = props  
  // let { title} = useParams(); 
  // console.log('props subcategory ' +  `${subcategory}`) 
  // console.log('props viewstyle ' +  `${viewstyle}`) 
  // console.log('category is ' +  `${category}`) 
  // console.log('subcategory is ' + `${subcategory}`) 
  // const myfilter = collections.filter(collection => collection.routeName === `${category}`);        
  // const myfilter = collections.filter(collection => collection.title === `$number1`);        
  
  
  

  return (
    
    <>    
    {/* <div className="section section-buttons section-gray"> */}
    <div className="section section-buttons section-gray">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
          
            <div className="title">
                    <h2>View Style</h2>
            </div>
              <Button color="info" type="button" className="mr-1"
              onClick={() => props.selectViewstyle('table')}
              >
                Table
              </Button>
              <Button color="info" type="button" className="mr-1"
              onClick={() => props.selectViewstyle('no border')}
              >
                No border
              </Button>
              <Button color="info" type="button" className="mr-1"
              onClick={() => props.selectViewstyle('card')}
              >
                Card
              </Button>  
          </Col>
        </Row>
      </Container>
    </div>

    
          
    
    
    
    {/* {console.log('before switch case ' +  `${viewstyle}`) }             */}
        {(() => {
  
        switch (`${viewstyle}`) {
          case 'table':
            
              return (                
                <Display05_sub_01 />
              )
          case 'no border':            
              return (
                <Display05_sub_02 />
              )
          case 'card':            
                return (
                  <Display05_sub_04 />
                
                )             
          default:
              return (                
                <Display05_sub_04 />
                
              )
              
        }

      })()}
      {/* {console.log('after switch case ' +  `${viewstyle}`) }             */}
    </>


 
    
    // <Display04_sub subcategory={subcategory}/>  
    // <Display05_sub subcategory={subcategory}/>  
    // <Display06_sub subcategory={subcategory}/>  
    // <Display04_sub subcategory={'number1'}/>  
    
    // <Display04_sub category={'number'}/>  
    // <Display04_sub category={'number'}/>  
    
    
     
  );
}

// export default Display04_main;


const mapStateToProps = state => {
  return {
      story_subcategory: state.story.story_subcategory,
      viewstyle: state.style.viewstyle
  }
}

const mapDispatchToProps = dispatch => {
  return {
  selectStory_Subcategory: (string) => dispatch(selectStory_Subcategory(string)),
  // selectSubcategory: (string) => dispatch(selectSubcategory(string)),
  selectViewstyle: (string) => dispatch(selectViewstyle(string))
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Display05_main)

