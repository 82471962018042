import { SELECT_STORY_SUBCATEGORY } from './elementary_storyTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

const initialState = {
    story_subcategory: 'Lion And The Mouse'
    // viewstyle: 'card'
}

const elementary_storyReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_STORY_SUBCATEGORY:
            return {
             ...state,
             story_subcategory: action.payload
            }
        // case SELECT_VIEWSTYLE:
        //     return {
        //     ...state,
        //     viewstsyle: action.payload
        //     }        
                
        default: return state
    }
}

export default elementary_storyReducer