import { SELECT_SUBCATEGORY } from './elementaryTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectSubcategory = (string) => {
    return {
        type: SELECT_SUBCATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }