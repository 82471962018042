import { SELECT_JP_VOICECATEGORY } from './jp_voiceTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectJP_Voicecategory = (string) => {
    return {
        type: SELECT_JP_VOICECATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }