const LANGS_MASTER_DATA_STORY_MENU = [
   {
      id: 1,
      title: 'menu_elementary_story',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Lion And The Mouse",
            cn: "狮子与老鼠",
            cn_pronunciation: "[shīziyǔlǎoshǔ]",
            jp: "ライオンとねずみ",
            jp_pronunciation: "[ライオンとねずみ]",
            ko: "사자와 쥐"
         },
         {
            id: 2,
            en: "Red Shoes",
            cn: "红鞋子",
            cn_pronunciation: "[hóngxiézi]",
            jp: "赤い靴",
            jp_pronunciation: "[あかいくつ]",
            ko: "빨간 구두"
         },
         {
            id: 3,
            en: "Little Mermaid",
            cn: "小美人鱼",
            cn_pronunciation: "[xiǎoměirényú]",
            jp: "リトルマーメイド",
            jp_pronunciation: "[リトルマーメイド]",
            ko: "인어공주"
         },
         {
            id: 4,
            en: "Golden Bird",
            cn: "金鸟",
            cn_pronunciation: "[jīnniăo]",
            jp: "黄金の鳥",
            jp_pronunciation: "[おうごんのとり]",
            ko: "황금새"
         },
         {
            id: 5,
            en: "Snow Queen",
            cn: "冰雪女王",
            cn_pronunciation: "[bīngxuĕnǚwáng]",
            jp: "雪の女王",
            jp_pronunciation: "[ゆきのじょうおう]",
            ko: "눈 여왕"
         },
         {
            id: 6,
            en: "Elves And The Shoe Maker",
            cn: "鞋匠与小矮人",
            cn_pronunciation: "[xiéjiàngyǔxiǎoǎirén]",
            jp: "エルフと靴屋さん",
            jp_pronunciation: "[エルフとくつやさん]",
            ko: "요정과 구두장이"
         },
         {
            id: 7,
            en: "Rumpelstiltskin",
            cn: "侏儒怪",
            cn_pronunciation: "[zhūrúguài]",
            jp: "ルンペルシュティルツキン",
            jp_pronunciation: "[ルンペルシュティルツキン]",
            ko: "럼펠스틸트스킨"
         },
         {
            id: 8,
            en: "Little Match Girl",
            cn: "卖火柴的小女孩",
            cn_pronunciation: "[màihuŏcháidexiăonǚhái]",
            jp: "マッチ売りの少女",
            jp_pronunciation: "[マッチうりのしょうじょ]",
            ko: "성냥팔이 소녀"
         },
         {
            id: 9,
            en: "Cinderella",
            cn: "灰姑娘",
            cn_pronunciation: "[huīgūniáng]",
            jp: "シンデレラ",
            jp_pronunciation: "[シンデレラ]",
            ko: "신데렐라"
         },
         {
            id: 10,
            en: "Three Little Pigs",
            cn: "三只小猪",
            cn_pronunciation: "[sānzhĭxiăozhū]",
            jp: "三匹の子豚",
            jp_pronunciation: "[さんびきのこぶた]",
            ko: "아기돼지 삼형제"
         },
         {
            id: 11,
            en: "Alibaba And 40 Thieves",
            cn: "阿里巴巴与四十大盗",
            cn_pronunciation: "[ālĭbābāyŭsìshídàdào]",
            jp: "アリババと40人の盗賊",
            jp_pronunciation: "[アリババとよんじゅうにんのとうぞく]",
            ko: "알리바바와 40인의 도둑"
         },
         {
            id: 12,
            en: "Gulliver-s Travels",
            cn: "格列佛游记",
            cn_pronunciation: "[gélièfóyóujì]",
            jp: "ガリバー トラベル",
            jp_pronunciation: "[ガリバー トラベル]",
            ko: "걸리버 여행기"
         },
         {
            id: 13,
            en: "Frog Prince in English",
            cn: "青蛙王子",
            cn_pronunciation: "[qīngwāwángzi]",
            jp: "カエルの王子様",
            jp_pronunciation: "[かえるのおうじさま]",
            ko: "개구리 왕자"
         },
         {
            id: 14,
            en: "Aladdin and the Magic Lamp",
            cn: "阿拉丁神灯",
            cn_pronunciation: "[ālādīngshéndēng]",
            jp: "アラジンと魔法のランプ",
            jp_pronunciation: "[アラジンとまほうのランプ]",
            ko: "알라딘과 요술 램프"
         },
         {
            id: 15,
            en: "Lazy Donkey",
            cn: "懒惰的驴子",
            cn_pronunciation: "[lǎnduòdelǘzi]",
            jp: "レイジー・ロバ",
            jp_pronunciation: "[レイジー・ロバ]",
            ko: "게으른 당나귀"
         },
         {
            id: 16,
            en: "Alice in Wonderland",
            cn: "爱丽丝梦游仙境",
            cn_pronunciation: "[àilìsīmèngyóuxiānjìng]",
            jp: "不思議の国のアリス",
            jp_pronunciation: "[ふしぎのくにのアリス]",
            ko: "이상한 나라의 앨리스"
         },
         {
            id: 17,
            en: "Four Brahmins",
            cn: "四个婆罗门教徒",
            cn_pronunciation: "[sìgèpóluóménjiàotú]",
            jp: "４人のブラーミン",
            jp_pronunciation: "[よにんのブラーミン]",
            ko: "네 명의 브라만"
         },
         {
            id: 18,
            en: "King of Golden Mountain",
            cn: "金山国王",
            cn_pronunciation: "[jīnshānguówáng]",
            jp: "ゴールデンマウンテンの王",
            jp_pronunciation: "[ゴールデンマウンテンのおう]",
            ko: "황금산의 왕"
         },
         {
            id: 19,
            en: "Ant And The Grasshopper",
            cn: "蚂蚁和蚂蚱",
            cn_pronunciation: "[mǎyǐhémàzhà]",
            jp: "アリとキリギリス",
            jp_pronunciation: "[アリとキリギリス]",
            ko: "개미와 베짱이"
         },
         {
            id: 20,
            en: "Puss In Boots",
            cn: "穿靴子的猫",
            cn_pronunciation: "[chuānxuēzidemāo]",
            jp: "長ぐつをはいたネコ",
            jp_pronunciation: "[ながぐつをはいたネコ]",
            ko: "장화 신은 고양이"
         },
         {
            id: 21,
            en: "12 Dancing Princess",
            cn: "十二个跳舞的公主",
            cn_pronunciation: "[shíèrgètiàowŭdegōngzhŭ]",
            jp: "12人の踊るプリンセス",
            jp_pronunciation: "[じゅうににんのおどるプリンセス]",
            ko: "춤 추는 열 두 명의 공주"
         },
         {
            id: 22,
            en: "Peter Pan",
            cn: "彼得潘",
            cn_pronunciation: "[bĭdépān]",
            jp: "ピーターパン",
            jp_pronunciation: "[ピーターパン]",
            ko: "피터팬"
         },
         {
            id: 23,
            en: "Pinocchio",
            cn: "木偶奇遇记",
            cn_pronunciation: "[mùǒuqíyùjì]",
            jp: "ピノキオ",
            jp_pronunciation: "[ピノキオ]",
            ko: "피노키오"
         },
         {
            id: 24,
            en: "Pied Piper of Hamlin",
            cn: "花衣风笛手",
            cn_pronunciation: "[huāyīfēngdíshǒu]",
            jp: "ハーメルンの笛吹き男",
            jp_pronunciation: "[ハーメルンのふえふきおとこ]",
            ko: "하멜른의 피리 부는 사나이"
         },
         {
            id: 25,
            en: "Jungle Book",
            cn: "森林王子",
            cn_pronunciation: "[sēnlín wángzǐ]",
            jp: "ジャングルブック",
            jp_pronunciation: "[ジャングルブック]",
            ko: "정글북"
         },
         {
            id: 26,
            en: "Flying Trunk",
            cn: "会飞的箱子",
            cn_pronunciation: "[huìfēidexiāngzi]",
            jp: "飛ぶトランク",
            jp_pronunciation: "[とぶトランク]",
            ko: "하늘을 나는 트렁크"
         },
         {
            id: 27,
            en: "Selfish Giant",
            cn: "自私的巨人",
            cn_pronunciation: "[zìsīdejùrén]",
            jp: "自分勝手な巨人",
            jp_pronunciation: "[じぶんかってなきょじん]",
            ko: "욕심쟁이 거인"
         },
         {
            id: 28,
            en: "Salty Sea",
            cn: "咸海",
            cn_pronunciation: "[xiánhăi]",
            jp: "塩辛い海の物語",
            jp_pronunciation: "[しおからいうみのものがたり]",
            ko: "소금 만드는 맷돌"
         },
         {
            id: 29,
            en: "Fisherman and His Wife",
            cn: "渔夫和他的妻子",
            cn_pronunciation: "[yúfūhétādeqīzi]",
            jp: "漁師とその妻",
            jp_pronunciation: "[りょうしとそのつま]",
            ko: "어부와 아내"
         },
         {
            id: 30,
            en: "Goose Girl",
            cn: "牧鹅姑娘",
            cn_pronunciation: "[mùégūniáng]",
            jp: "ガチョウ少女の物語",
            jp_pronunciation: "[ガチョウしょうじょのものがたり]",
            ko: "거위 치는 소녀"
         },
         {
            id: 31,
            en: "Sindbad the Sailor (Part 1)",
            cn: "辛巴达历险记",
            cn_pronunciation: "[xīnbādálìxiănjì]",
            jp: "船員のシンドバッドだ",
            jp_pronunciation: "[せんいんのシンドバッドだ]",
            ko: "신밧드의 모험- 파트1"
         },
         {
            id: 32,
            en: "King Grisly Beard",
            cn: "国王贵斯里·比尔德",
            cn_pronunciation: "[guówángguìsīlǐ · bǐěrdé]",
            jp: "ひどい王様",
            jp_pronunciation: "[ひどいおうさま]",
            ko: "수염 난 그리즐리 왕"
         },
         {
            id: 33,
            en: "The Lazy Brahmin Story",
            cn: "懒惰的婆罗门",
            cn_pronunciation: "[lǎnduòdepóluómén]",
            jp: "ナマケモノのバラモン",
            jp_pronunciation: "[ナマケモノのバラモン]",
            ko: "게으른 부자"
         }
      ]
   }
]

   export default LANGS_MASTER_DATA_STORY_MENU; 
