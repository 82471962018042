import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {  
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,  
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";
// core components

function StandardNavbar01() {
  const [navbarColor, setNavbarColor] = React.useState("");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } 
      // else if (
      //   document.documentElement.scrollTop < 500 ||
      //   document.body.scrollTop < 500
      // ) {
      //   setNavbarColor("navbar-transparent");
      // }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            {/* <NavbarBrand id="navbar-brand" href="http://pf.kakao.com/_VdTfxb" target="_blank"> */}
            <NavbarBrand id="navbar-brand" to="/index" tag={Link}>
            
            <img
                alt="..."
                className="creative-tim-logo"                
                // src={require("../../assets/img/creative-tim-white-slim2.png")}
                // src={require("assets/img/logo.jpg")}
                // src={require("assets/img/gallery.jpg")}
                src={require("assets/img/3lang.jpg")}
              />              
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
            3LANG
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />              
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
          {/* <Nav className="ml-auto" navbar> */}
            <Nav className="ml-auto mr-auto" navbar>
            <UncontrolledDropdown nav inNavbar>                
                <DropdownToggle className="navbar-brand dropdown-item" color="default" caret nav>
                  초등학교
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/elementary_word" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    초등학교 단어
                  </DropdownItem>
                  <DropdownItem to="/elementary_story" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    초등학교 동화
                  </DropdownItem>                                    
                </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle color="default" caret nav>
                  일반
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/general_word" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    일반 단어
                  </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>                            
            <UncontrolledDropdown nav inNavbar>                
                <DropdownToggle className="navbar-brand dropdown-item" color="default" caret nav>
                교육컨설팅
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/education-consulting" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                  교육컨설팅
                  </DropdownItem>                                  
                </DropdownMenu>
            </UncontrolledDropdown>    
            <UncontrolledDropdown nav inNavbar>                
                <DropdownToggle className="navbar-brand dropdown-item" color="default" caret nav>
                문의
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/inquiry" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                  문의
                  </DropdownItem>                                  
                </DropdownMenu>
            </UncontrolledDropdown>                     

              {/* <UncontrolledDropdown nav inNavbar>                
                <DropdownToggle className="navbar-brand dropdown-item" color="default" caret nav>
                  중학교
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/menu" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    중학교
                  </DropdownItem>                  
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  고등학교
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/storeinfo" tag={Link}>
                    <i className="nc-icon nc-bullet-list-67" />
                    고등학교
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle color="default" caret nav>
                  대학교
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/franchise" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    대학교
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  비즈니스                  
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/cost" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    비즈니스1
                  </DropdownItem>
                  <DropdownItem to="/inquiry" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    비즈니스2
                  </DropdownItem>                  
                </DropdownMenu>                
	            </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle color="default" caret nav>
                  기타
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/faq" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    세계지도
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle color="default" caret nav>
                  공지
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/etc" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    공지
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>   
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle color="default" caret nav>
                  멤버십
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/spectrum" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    멤버십
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> 
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle color="default" caret nav>
                  멤버십
                </DropdownToggle> */}               
              <UncontrolledDropdown nav inNavbar>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/education-consulting" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    교육컨설팅
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/inquiry" tag={Link}>
                  <i className="nc-icon nc-bullet-list-67" />
                    문의
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle color="default" nav>
              카카오
              </DropdownToggle>
              <DropdownMenu className="dropdown-danger" right>
              <DropdownItem href="http://pf.kakao.com/_VdTfxb" target="_blank">                
                <img
                alt="..."
                className="creative-tim-logo"                                
                src={require("assets/img/logo.jpg")}
                width="120"/>
                </DropdownItem>                            
              </DropdownMenu>
              </UncontrolledDropdown>                  */}
                       
              <NavItem>
                {/* <Button
                  className="btn-round"
                  color="danger"
                  href="http://pf.kakao.com/_VdTfxb"
                  target="_blank"
                >
                  <i className="nc-icon nc-cart-simple" /> Buy Now
                </Button>        */}
                {/* <Button
                  className="btn-round"
                  color="danger"
                  href="http://pf.kakao.com/_VdTfxb"
                  target="_blank"                  
                  size="sm"
                >
                  <img
                    alt="..."
                    className="creative-tim-logo"                                
                    src={require("assets/img/logo.jpg")}
                  />  
                </Button>                 */}
                {/* <a href="http://pf.kakao.com/_VdTfxb"
                  target="_blank">
                <img
                    alt="..."
                    className="creative-tim-logo"                                
                    src={require("assets/img/logo.jpg")}
                  />                        
                  </a> */}
              </NavItem>
  
            {/* <NavbarBrand id="navbar-brand" href="#" target="_blank">
            <NavbarBrand id="navbar-brand" href="#pable" target="_blank">
            <img
                alt="..."
                // className="creative-tim-logo"                                
                src={require("assets/img/kakao_logo.png")}
                width="110"                
              />                            
            </NavbarBrand>                                   */}
            {/* <NavbarBrand id="navbar-brand" href="#" target="_blank">         */}
            <NavbarBrand id="navbar-brand" href="https://open.kakao.com/o/sazDLIRc" target="_blank">        
            <img
                alt="..."
                // className="creative-tim-logo"                                
                src={require("assets/img/kakaotalk_logo.png")}
                width="42"
              />

            </NavbarBrand>            
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default StandardNavbar01;
