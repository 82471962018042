import React from "react";


// reactstrap components
import {
  Container,
  Row,
  Col  
} from "reactstrap";


function Display04_sub_default(props) {  

  return (
    <>
      <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">View Style을 선택해주세요</h2>
              <h5 className="description">
              
              </h5>
            </Col>
          </Row>      
        </Container>
    </>
  );
}

// export default StandardSmallHeader3;
export default Display04_sub_default