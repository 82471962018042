import React from "react";

// reactstrap components
import {
  // Badge,
  // Button,
  Card,
  CardBody,
  // CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  // UncontrolledTooltip
} from "reactstrap";

// core components

function StandardJoinFranchise01() {
  return (
    <>
      <div className="section section-gray" id="cards">
        <Container className="tim-container">
          <div className="title text-center">
            <h2>외국어교육컨설팅</h2>
          </div>
          {/* <h4 className="title text-center">가맹절차</h4> */}
          <Row>
            {/* 01번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">01</CardTitle>                  
                  <hr />
                  <h6 className="card-category">사전상담</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>
            {/* 02번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">02</CardTitle>                  
                  <hr />
                  <h6 className="card-category">실력평가</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>            
            {/* 03번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">03</CardTitle>                  
                  <hr />
                  <h6 className="card-category">외국어 선생님 배정</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>
            {/* 04번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">04</CardTitle>                  
                  <hr />
                  <h6 className="card-category">수업후 보조교사와 체크</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>    
            {/* 05번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">05</CardTitle>                  
                  <hr />
                  <h6 className="card-category">단기로드맵 확인</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>
            {/* 06번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">06</CardTitle>                  
                  <hr />
                  <h6 className="card-category">주간평가</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>            
            {/* 07번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">07</CardTitle>                  
                  <hr />
                  <h6 className="card-category">월말평가</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>
            {/* 08번*/}
            <Col md="3" sm="6">
              <Card className="card-profile">
                <CardBody>
                  <CardTitle tag="h1">08</CardTitle>                  
                  <hr />
                  <h6 className="card-category">레벨평가 진행</h6>
                </CardBody>
              </Card>
              {/* end card */}
            </Col>                        
          </Row>
        </Container>
      </div>
    </>
  );
}

export default StandardJoinFranchise01;
