import { SELECT_CN_VOICECATEGORY } from './cn_voiceTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectCN_Voicecategory = (string) => {
    return {
        type: SELECT_CN_VOICECATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }