import { SELECT_SUBCATEGORY } from './elementaryTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

const initialState = {
    subcategory: 'number'
    // viewstyle: 'card'
}

const elementaryReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_SUBCATEGORY:
            return {
             ...state,
             subcategory: action.payload
            }
        // case SELECT_VIEWSTYLE:
        //     return {
        //     ...state,
        //     viewstsyle: action.payload
        //     }        
                
        default: return state
    }
}

export default elementaryReducer