import React from "react";

// core components
// import ColorNavbarNotTransparent from "components/Navbars/ColorNavbarNotTransparent.js";
import StandardNavbar01NotTransparent from "components/3langschool/Navbar/StandardNavbar01NotTransparent.js";

// james added
// header
// import StandardHeader00 from "components/3langschool/Header/StandardHeader00.js";
// import StandardHeader01 from "components/3langschool/Header/StandardHeader01.js";
// import StandardHeader02 from "components/3langschool/Header/StandardHeader02.js";
// import StandardHeader03 from "components/3langschool/Header/StandardHeader03.js";
// import StandardHeader04 from "components/3langschool/Header/StandardHeader04.js";
// import StandardSmallHeader3 from "components/3langschool/Header/StandardSmallHeader3.js";
// import StandardSmallHeader4 from "components/3langschool/Header/StandardSmallHeader4.js";
// import StandardSmallHeader4 from "components/3langschool/Header/StandardSmallHeader4.js";
import StandardSmallHeader6 from "components/3langschool/Header/StandardSmallHeader6.js";

// Display
// import Display04 from "components/3langschool/Display/Display04.js";
// import Display04_main from "components/3langschool/Display/Display04_main.js";
import Display06_main from "components/3langschool/Display/Display06_main.js";
// import Display04_main_test from "components/3langschool/Display/Display04_main_test.js";
// import Display05 from "components/3langschool/Display/Display05.js";

// footer
import StandardFooter01 from "components/3langschool/Footer/StandardFooter01.js";
// function Elementary({category}) {
function General_word() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("header");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("header");
    };
  });  
  return (
    <>
    {/* <ColorNavbarNotTransparent />     */}
    <StandardNavbar01NotTransparent />       
    {/* <StandardSmallHeader3 />        */}
    {/* <StandardSmallHeader4 />        */}
    <StandardSmallHeader6 />       
    {/* <Display04 category={category}/> */}
    {/* <Display04_main /> */}
    <Display06_main />
    {/* <Display04_main category={category}/> */}
    {/* <Display05 category={category}/> */}

    {/* <StandardHeader00 /> */}

    {/* <StandardHeader01 />
    <StandardHeader02 />
    <StandardHeader03 />
    <StandardHeader04 /> */}

    <StandardFooter01 />
    </>
  );
}

export default General_word;

