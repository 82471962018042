import React from "react";

// reactstrap components
import {
  Button,
  // Card,
  // CardBody,
  // CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";


// react-redux
import { connect } from 'react-redux'
import { selectGeneral_Subcategory } from '../../../redux'
import { selectGeneral_Menucategory } from '../../../redux'


// core components
// import Display04_main from "components/3langschool/Display/Display04_main.js";

// james added
import LANGS_MASTER_DATA_MATERIAL_MENU from './langs_master_material_menu.data';

const collections = LANGS_MASTER_DATA_MATERIAL_MENU


function StandardSmallHeader6(props) {
  const {general_menucategory} = props
  const myfilter = collections.filter(collection => collection.title === `${general_menucategory}`);
  // const myfilter = collections.filter(collection => collection.title === 'menu_elementary');
  
  console.log('general_menucategory subcategory ' +  `${general_menucategory}`) 
  // console.log('StandardSmallHeader3 props ' +  `${props}`) 
  return (
    <>
      <div className="section section-feature cd-section" id="features">
        {/* ********* FEATURES 1 ********* */}
        <div className="features-1">
          <Container>
            <Row>
            {
              myfilter[0].items.map(
                item => (                

                  <Col xs="2">
                  <div>
                  {/* <div className="info"> */}
                    {/* <div className="icon icon-danger">
                      <i className="nc-icon nc-palette" />
                    </div> */}
                    <div className="description">
                    {/* <div className="description"> */}
                      {/* <h6 className="info-title"><a href="#pablo">{item.ko}</a></h6>                       */}
                      <Button
                        className="btn-link"
                        color="danger"
                        // href="#pablo"
                        onClick={() => props.selectGeneral_Subcategory(`${item.en}`)}
                      >
                        {/* See more */}
                        {item.ko}
                      </Button>
                    </div>
                  </div>
                </Col>
                )
              )              
            }                    
            </Row>
          </Container>      
        </div>
        {/* ********* END FEATURES 1 ********* */}

      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
      // subcategory: state.elementary.subcategory,
      general_menucategory: state.general_menu.general_menucategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  selectGeneral_Subcategory: (string) => dispatch(selectGeneral_Subcategory(string)),
  selectGeneral_Menucategory: (string) => dispatch(selectGeneral_Menucategory(string))  
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StandardSmallHeader6)