import React from "react";

// reactstrap components
import {
  // Label,
  // FormGroup,
  // Input,
  Table,
  Container,
  Row,
  Col  
} from "reactstrap";

// core components

// react-redux
import { connect } from 'react-redux'
import { selectStory_Subcategory } from '../../../redux'
import { selectViewstyle } from '../../../redux'

// james added
import LANGS_MASTER_DATA_STORY from './langs_master_story.data';

const collections = LANGS_MASTER_DATA_STORY
// const myfilter = collections.filter(collection => collection.routeName === 'sharedkitchen');    



function Display05_sub_02(props) {  
  // console.log(`${category}`) 
  // console.log(`${subcategory}`) 
  const {story_subcategory} = props  
  const myfilter = collections.filter(collection => collection.title === `${story_subcategory}`);      

  return (
    <>
      {/* <div className="section section-feature cd-section" id="features"> */}
      {/* cost 1 - simple table */}
      {/* <div className="features-1"> */}

      <Container>

          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">초등학교 동화</h2>
              <h5 className="description">
              {myfilter[0].title}
              </h5>
            </Col>
          </Row>

          <Row>  
          <Table responsive>
          <thead>
                  <tr>
                    {/* <th className="text-center">#</th> */}
                    <th colspan="2" className="text-right">영어</th>
                    <th colspan="2" className="text-right">중국어</th>
                    <th colspan="2" className="text-right">일본어</th>
                    <th colspan="2" className="text-right">한국어</th>
                  </tr>
          </thead>
          </Table>      
          {
            
            myfilter[0].items.map(
              item => (                

                <>                
                  <Col md="3">
                  <div className="info" >

                    <div className="description">
                    {/* <div className="description" style={{"border-bottom": 10 + 'px solid black'}}></div> */}
                      <h4 className="info-title">{item.en}</h4>



                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">

                    <div className="description">
                      <h4 className="info-title">{item.cn}<br/>{item.cn_pronunciation}</h4>


                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">

                    <div className="description">
                      <h4 className="info-title">{item.jp}<br/>{item.jp_pronunciation}</h4>


                    </div>
                  </div>
                </Col>
                <Col md="3">
                  <div className="info">

                    <div className="description">
                      <h4 className="info-title">{item.ko}</h4>


                    </div>
                  </div>              
                </Col>                                    
              </>  

              )
            )
          }
          </Row>
        </Container>
                {/* </div> */}
        {/* ********* END FEATURES 1 ********* */}
      {/* </div> */}
    </>
  );
}

// export default Display05_sub;

const mapStateToProps = state => {
  return {
      story_subcategory: state.story.story_subcategory,
      viewstyle: state.style.viewstyle
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectStory_Subcategory: (string) => dispatch(selectStory_Subcategory(string)),
  selectViewstyle: (string) => dispatch(selectViewstyle(string))
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Display05_sub_02)