const LANGS_MASTER_DATA_MATERIAL = [
   {
      id: 1,
      title: 'country',
      routeName: 'new',
      items: [
         {
            id: 1,
            en: "Afghanistan",
            googleMapUrl: "https://www.google.com/maps/place/Afghanistan",
            en2: "Kabul",
            cn: "阿富汗",
            cn_pronunciation: "[āfùhàn]",
            cn2: "喀布尔",
            cn2_pronunciation: "[kābùěr]",
            jp: "アフガニスタン",
            jp2: "カブール",
            ko: "아프가니스탄",
            ko2: "카불",
            flagname: "1.Afghanistan.png",
         },
         {
            id: 2,
            en: "Albania",
            googleMapUrl: "https://www.google.com/maps/place/Albania",
            en2: "Tirana",
            cn: "阿尔巴尼亚",
            cn_pronunciation: "[āěrbāníyà]",
            cn2: "地拉那",
            cn2_pronunciation: "[dìlānà]",
            jp: "アルバニア",
            jp2: "ティラナ",
            ko: "알바니아",
            ko2: "티라나",
            flagname: "2.Albania.png",
         },
         {
            id: 3,
            en: "Algeria",
            googleMapUrl: "https://www.google.com/maps/place/Algeria",
            en2: "Algiers",
            cn: "阿尔及利亚",
            cn_pronunciation: "[āěrjílìyà]",
            cn2: "阿尔及尔",
            cn2_pronunciation: "[āěrjíěr]",
            jp: "アルジェリア",
            jp2: "アルジェー",
            ko: "알제리",
            ko2: "알제",
            flagname: "3.Algeria.png",
         },
         {
            id: 4,
            en: "Andorra",
            googleMapUrl: "https://www.google.com/maps/place/Andorra",
            en2: "Andorra la Vella",
            cn: "安道尔",
            cn_pronunciation: "[āndàoěr]",
            cn2: "安道尔城",
            cn2_pronunciation: "[āndàoěrchéng]",
            jp: "アンドラ",
            jp2: "アンドラ·ラ·ベリャ",
            ko: "안도라",
            ko2: "안도라라베야",
            flagname: "4.Andorra.png",
         },
         {
            id: 5,
            en: "Angola",
            googleMapUrl: "https://www.google.com/maps/place/Angola",
            en2: "Luanda",
            cn: "安哥拉",
            cn_pronunciation: "[āngēlā]",
            cn2: "罗安达",
            cn2_pronunciation: "[luóāndá]",
            jp: "アンゴラ",
            jp2: "ルアンダ",
            ko: "앙골라",
            ko2: "루안다",
            flagname: "5.Angola.png",
         },
         {
            id: 6,
            en: "Antigua and Barbuda",
            googleMapUrl: "https://www.google.com/maps/place/Antigua and Barbuda",
            en2: "St. John's",
            cn: "安提瓜和巴布达",
            cn_pronunciation: "[āntíguāhébābùdá]",
            cn2: "圣约翰",
            cn2_pronunciation: "[shèngyuēhàn]",
            jp: "アンティグア・バーブーダ",
            jp2: "セントジョンズ",
            ko: "앤티가바부다",
            ko2: "세인트존스",
            flagname: "6.Antigua and Barbuda.png",
         },
         {
            id: 7,
            en: "Argentina",
            googleMapUrl: "https://www.google.com/maps/place/Argentina",
            en2: "Buenos Aires",
            cn: "阿根廷",
            cn_pronunciation: "[āgēntíng]",
            cn2: "布宜诺斯艾利斯",
            cn2_pronunciation: "[bùyínuòsīàilìsī]",
            jp: "アルゼンチン",
            jp2: "ブエノスアイレス",
            ko: "아르헨티나",
            ko2: "부에노스아이레스",
            flagname: "7.Argentina.png",
         },
         {
            id: 8,
            en: "Armenia",
            googleMapUrl: "https://www.google.com/maps/place/Armenia",
            en2: "Yerevan",
            cn: "亞美尼亞",
            cn_pronunciation: "[yàměiníyà]",
            cn2: "埃里温",
            cn2_pronunciation: "[āilǐwēn]",
            jp: "アルメニア",
            jp2: "エレバン",
            ko: "아르메니아",
            ko2: "예레반",
            flagname: "8.Armenia.png",
         },
         {
            id: 9,
            en: "Australia",
            googleMapUrl: "https://www.google.com/maps/place/Australia",
            en2: "Canberra",
            cn: "澳大利亚",
            cn_pronunciation: "[àodàlìyà]",
            cn2: "堪培拉",
            cn2_pronunciation: "[kānpéilā]",
            jp: "オーストラリア",
            jp2: "キャンベラ",
            ko: "호주",
            ko2: "캔버라",
            flagname: "9.Australia.png",
         },
         {
            id: 10,
            en: "Austria",
            googleMapUrl: "https://www.google.com/maps/place/Austria",
            en2: "Vienna",
            cn: "奥地利",
            cn_pronunciation: "[àodìlì]",
            cn2: "维也纳",
            cn2_pronunciation: "[wéiyěnà]",
            jp: "オーストリア",
            jp2: "ウイーン",
            ko: "오스트리아",
            ko2: "비엔나",
            flagname: "10.Austria.png",
         },
         {
            id: 11,
            en: "Azerbaijan",
            googleMapUrl: "https://www.google.com/maps/place/Azerbaijan",
            en2: "Baku",
            cn: "阿塞拜疆",
            cn_pronunciation: "[āsāibàijiāng]",
            cn2: "巴库",
            cn2_pronunciation: "[bākù]",
            jp: "アゼルバイジャン",
            jp2: "バクー",
            ko: "아제르바이잔",
            ko2: "바쿠",
            flagname: "11.Azerbaijan.png",
         },
         {
            id: 12,
            en: "The Bahamas",
            googleMapUrl: "https://www.google.com/maps/place/The Bahamas",
            en2: "Nassau",
            cn: "巴哈马",
            cn_pronunciation: "[bāhāmǎ]",
            cn2: "拿骚",
            cn2_pronunciation: "[násāo]",
            jp: "バハマ",
            jp2: "ナッソー",
            ko: "바하마",
            ko2: "나사우",
            flagname: "12.The Bahamas.png",
         },
         {
            id: 13,
            en: "Bahrain",
            googleMapUrl: "https://www.google.com/maps/place/Bahrain",
            en2: "Manama",
            cn: "巴林",
            cn_pronunciation: "[bālín]",
            cn2: "麦纳麦",
            cn2_pronunciation: "[màinàmài]",
            jp: "バーレーン",
            jp2: "マナーマ",
            ko: "바레인",
            ko2: "마나마",
            flagname: "13.Bahrain.png",
         },
         {
            id: 14,
            en: "Bangladesh",
            googleMapUrl: "https://www.google.com/maps/place/Bangladesh",
            en2: "Dhaka",
            cn: "孟加拉国",
            cn_pronunciation: "[mèngjiālāguó]",
            cn2: "达卡",
            cn2_pronunciation: "[dákǎ]",
            jp: "バングラデシュ",
            jp2: "ダッカ",
            ko: "방글라데시",
            ko2: "다카",
            flagname: "14.Bangladesh.png",
         },
         {
            id: 15,
            en: "Barbados",
            googleMapUrl: "https://www.google.com/maps/place/Barbados",
            en2: "Bridgetown",
            cn: "巴巴多斯",
            cn_pronunciation: "[bābāduōsī]",
            cn2: "布里奇敦",
            cn2_pronunciation: "[bùlǐqídūn]",
            jp: "バルバドス",
            jp2: "ブリッジタウン",
            ko: "바베이도스",
            ko2: "브리지 타운",
            flagname: "15.Barbados.png",
         },
         {
            id: 16,
            en: "Belarus",
            googleMapUrl: "https://www.google.com/maps/place/Belarus",
            en2: "Minsk",
            cn: "白俄罗斯",
            cn_pronunciation: "[báiéluósī]",
            cn2: "明斯克",
            cn2_pronunciation: "[míngsīkè]",
            jp: "ベラルーシ",
            jp2: "ミンスク",
            ko: "벨라루스",
            ko2: "민스크",
            flagname: "16.Belarus.png",
         },
         {
            id: 17,
            en: "Belgium",
            googleMapUrl: "https://www.google.com/maps/place/Belgium",
            en2: "Brussels",
            cn: "比利时",
            cn_pronunciation: "[bǐlìshí]",
            cn2: "布鲁塞尔",
            cn2_pronunciation: "[bùlǔsāiěr]",
            jp: "ベルギー",
            jp2: "ブリュッセル",
            ko: "벨기에",
            ko2: "브뤼셀",
            flagname: "17.Belgium.png",
         },
         {
            id: 18,
            en: "Belize",
            googleMapUrl: "https://www.google.com/maps/place/Belize",
            en2: "Belmopan",
            cn: "伯利兹",
            cn_pronunciation: "[bólìzī]",
            cn2: "贝尔墨邦",
            cn2_pronunciation: "[bèiěrmòbāng]",
            jp: "ベリーズ",
            jp2: "ベルモパン",
            ko: "벨리즈",
            ko2: "벨모팬",
            flagname: "18.Belize.png",
         },
         {
            id: 19,
            en: "Benin",
            googleMapUrl: "https://www.google.com/maps/place/Benin",
            en2: "Porto-Novo",
            cn: "贝宁",
            cn_pronunciation: "[bèiníng]",
            cn2: "波多诺伏",
            cn2_pronunciation: "[bōduōnuòfú]",
            jp: "ベナン",
            jp2: "ポルトノボ",
            ko: "베냉",
            ko2: "포르토노보",
            flagname: "19.Benin.png",
         },
         {
            id: 20,
            en: "Bhutan",
            googleMapUrl: "https://www.google.com/maps/place/Bhutan",
            en2: "Thimphu",
            cn: "不丹",
            cn_pronunciation: "[búdān]",
            cn2: "廷布",
            cn2_pronunciation: "[tíngbù]",
            jp: "ブータン",
            jp2: "ティンプー",
            ko: "부탄",
            ko2: "팀부",
            flagname: "20.Bhutan.png",
         },
         {
            id: 21,
            en: "Bolivia",
            googleMapUrl: "https://www.google.com/maps/place/Bolivia",
            en2: "Sucre",
            cn: "玻利維亞",
            cn_pronunciation: "[bōlìwéiyà]",
            cn2: "苏克雷",
            cn2_pronunciation: "[sūkèléi]",
            jp: "ボリビア",
            jp2: "スクレ",
            ko: "볼리비아",
            ko2: "수크레",
            flagname: "21.Bolivia.png",
         },
         {
            id: 22,
            en: "Bosnia and Herzegovina",
            googleMapUrl: "https://www.google.com/maps/place/Bosnia and Herzegovina",
            en2: "Sarajevo",
            cn: "波斯尼亚和黑塞哥维那",
            cn_pronunciation: "[bōsīníyàhéhēisāigēwéinà]",
            cn2: "萨拉热窝",
            cn2_pronunciation: "[sàlārèwō]",
            jp: "ボスニア・ヘルツェゴビナ",
            jp2: "サラエボ",
            ko: "보스니아헤르체고비나",
            ko2: "사라예보",
            flagname: "22.Bosnia and Herzegovina.png",
         },
         {
            id: 23,
            en: "Botswana",
            googleMapUrl: "https://www.google.com/maps/place/Botswana",
            en2: "Gaborone",
            cn: "波札那",
            cn_pronunciation: "[bōzhánà]",
            cn2: "哈博罗内",
            cn2_pronunciation: "[hābóluónèi]",
            jp: "ボツワナ",
            jp2: "ガボローネ",
            ko: "보츠와나",
            ko2: "가보로네",
            flagname: "23.Botswana.png",
         },
         {
            id: 24,
            en: "Brazil",
            googleMapUrl: "https://www.google.com/maps/place/Brazil",
            en2: "Brasília",
            cn: "巴西",
            cn_pronunciation: "[bāxī]",
            cn2: "巴西利亚",
            cn2_pronunciation: "[bāxīlìyà]",
            jp: "ブラジル",
            jp2: "ブラジリア",
            ko: "브라질",
            ko2: "브라질리아",
            flagname: "24.Brazil.png",
         },
         {
            id: 25,
            en: "Brunei",
            googleMapUrl: "https://www.google.com/maps/place/Brunei",
            en2: "Bandar Seri Begawan",
            cn: "文莱",
            cn_pronunciation: "[wénlái]",
            cn2: "斯里巴加湾市",
            cn2_pronunciation: "[sīlǐbājiāwānshì]",
            jp: "ブルネイ",
            jp2: "バンダルスリブガワン",
            ko: "브루나이",
            ko2: "반다르세리베가완",
            flagname: "25.Brunei.png",
         },
         {
            id: 26,
            en: "Bulgaria",
            googleMapUrl: "https://www.google.com/maps/place/Bulgaria",
            en2: "Sofia",
            cn: "保加利亚",
            cn_pronunciation: "[bǎojiālìyà]",
            cn2: "索菲亚",
            cn2_pronunciation: "[suǒfēiyà]",
            jp: "ブルガリア",
            jp2: "ソフィア",
            ko: "불가리아",
            ko2: "소피아",
            flagname: "26.Bulgaria.png",
         },
         {
            id: 27,
            en: "Burkina Faso",
            googleMapUrl: "https://www.google.com/maps/place/Burkina Faso",
            en2: "Ouagadougou",
            cn: "布吉納法索",
            cn_pronunciation: "[bùjínàfǎsuǒ]",
            cn2: "瓦加杜古",
            cn2_pronunciation: "[wǎjiādùgǔ]",
            jp: "ブルキナファソ",
            jp2: "ワガドゥグー",
            ko: "부르키나파소",
            ko2: "와가두구",
            flagname: "27.Burkina Faso.png",
         },
         {
            id: 28,
            en: "Burundi",
            googleMapUrl: "https://www.google.com/maps/place/Burundi",
            en2: "Bujumbura",
            cn: "蒲隆地",
            cn_pronunciation: "[púlóngdì]",
            cn2: "布琼布拉",
            cn2_pronunciation: "[bùqióngbùlā]",
            jp: "ブルンジ",
            jp2: "ブジュンブラ",
            ko: "부룬디",
            ko2: "부줌부라",
            flagname: "28.Burundi.png",
         },
         {
            id: 29,
            en: "Cambodia",
            googleMapUrl: "https://www.google.com/maps/place/Cambodia",
            en2: "Phnom Penh",
            cn: "柬埔寨",
            cn_pronunciation: "[jiǎnpǔzhài]",
            cn2: "金边",
            cn2_pronunciation: "[jīnbiān]",
            jp: "カンボジア",
            jp2: "プノンペン",
            ko: "캄보디아",
            ko2: "프놈펜",
            flagname: "29.Cambodia.png",
         },
         {
            id: 30,
            en: "Cameroon",
            googleMapUrl: "https://www.google.com/maps/place/Cameroon",
            en2: "Yaoundé",
            cn: "喀麦隆",
            cn_pronunciation: "[kāmàilóng]",
            cn2: "雅温得",
            cn2_pronunciation: "[yǎwēndé]",
            jp: "カメルーン",
            jp2: "ヤウンデ",
            ko: "카메룬",
            ko2: "야운데",
            flagname: "30.Cameroon.png",
         },
         {
            id: 31,
            en: "Canada",
            googleMapUrl: "https://www.google.com/maps/place/Canada",
            en2: "Ottawa",
            cn: "加拿大",
            cn_pronunciation: "[jiānádà]",
            cn2: "渥太华",
            cn2_pronunciation: "[wòtàihuá]",
            jp: "カナダ",
            jp2: "オタワ",
            ko: "캐나다",
            ko2: "오타와",
            flagname: "31.Canada.png",
         },
         {
            id: 32,
            en: "Cape Verde",
            googleMapUrl: "https://www.google.com/maps/place/Cape Verde",
            en2: "Praia",
            cn: "佛得角",
            cn_pronunciation: "[fódéjiǎo]",
            cn2: "普拉亚",
            cn2_pronunciation: "[pǔlāyà]",
            jp: "カーボベルデ",
            jp2: "プライア",
            ko: "카보베르데",
            ko2: "프라이어",
            flagname: "32.Cape Verde.png",
         },
         {
            id: 33,
            en: "The Central African Republic",
            googleMapUrl: "https://www.google.com/maps/place/The Central African Republic",
            en2: "Bangui",
            cn: "中非共和國",
            cn_pronunciation: "[zhōngfēigònghéguó]",
            cn2: "班吉",
            cn2_pronunciation: "[bānjí]",
            jp: "中央アフリカ共和国",
            jp2: "バンギ",
            ko: "중앙 아프리카 공화국",
            ko2: "방기",
            flagname: "33.Central African Republic(The Central African Republic).png",
         },
         {
            id: 34,
            en: "Chad",
            googleMapUrl: "https://www.google.com/maps/place/Chad",
            en2: "N'Djamena",
            cn: "乍得",
            cn_pronunciation: "[zhàdé]",
            cn2: "恩贾梅纳",
            cn2_pronunciation: "[ēnjiǎméinà]",
            jp: "チャド",
            jp2: "ンジャメナ",
            ko: "차드",
            ko2: "은자메나",
            flagname: "34.Chad.png",
         },
         {
            id: 35,
            en: "Chile",
            googleMapUrl: "https://www.google.com/maps/place/Chile",
            en2: "Santiago",
            cn: "智利",
            cn_pronunciation: "[zhìlì]",
            cn2: "圣地亚哥",
            cn2_pronunciation: "[shèngdìyàgē]",
            jp: "チリ",
            jp2: "サンティアゴ",
            ko: "칠레",
            ko2: "산티아고",
            flagname: "35.Chile.png",
         },
         {
            id: 36,
            en: "Colombia",
            googleMapUrl: "https://www.google.com/maps/place/Colombia",
            en2: "Bogotá",
            cn: "哥伦比亚",
            cn_pronunciation: "[gēlúnbǐyà]",
            cn2: "波哥大",
            cn2_pronunciation: "[bōgēdà]",
            jp: "コロンビア",
            jp2: "ボゴタ",
            ko: "콜롬비아",
            ko2: "보고타",
            flagname: "36.Colombia.png",
         },
         {
            id: 37,
            en: "The Comoros",
            googleMapUrl: "https://www.google.com/maps/place/The Comoros",
            en2: "Moroni",
            cn: "葛摩",
            cn_pronunciation: "[gěmó]",
            cn2: "莫罗尼",
            cn2_pronunciation: "[mòluóní]",
            jp: "コモロ",
            jp2: "モロニ",
            ko: "코모로",
            ko2: "모로니",
            flagname: "37.Comoros(The Comoros).png",
         },
         {
            id: 38,
            en: "Cook Islands",
            googleMapUrl: "https://www.google.com/maps/place/Cook Islands",
            en2: "Avarua",
            cn: "库克群岛",
            cn_pronunciation: "[kùkèqúndǎo]",
            cn2: "阿瓦鲁阿",
            cn2_pronunciation: "[āwǎlǔā]",
            jp: "クック諸島",
            jp2: "アバルア",
            ko: "쿡 제도",
            ko2: "아바루아",
            flagname: "38.Cook Islands.png",
         },
         {
            id: 39,
            en: "Costa Rica",
            googleMapUrl: "https://www.google.com/maps/place/Costa Rica",
            en2: "San José",
            cn: "哥斯达黎加",
            cn_pronunciation: "[gēsīdálíjiā]",
            cn2: "圣何塞",
            cn2_pronunciation: "[shènghésāi]",
            jp: "コスタリカ",
            jp2: "サンノゼ",
            ko: "코스타리카",
            ko2: "산 호세",
            flagname: "39.Costa Rica.png",
         },
         {
            id: 40,
            en: "Cote d'Ivoire",
            googleMapUrl: "https://www.google.com/maps/place/Cote d'Ivoire",
            en2: "Yamoussoukro",
            cn: "科特迪瓦",
            cn_pronunciation: "[kētèdíwǎ]",
            cn2: "亚穆苏克罗",
            cn2_pronunciation: "[yàmùsūkèluó]",
            jp: "コートジボワール",
            jp2: "ヤムスクロ",
            ko: "코트디부아르",
            ko2: "야무스쿠로",
            flagname: "40.Cote d'Ivoire.png",
         },
         {
            id: 41,
            en: "Croatia",
            googleMapUrl: "https://www.google.com/maps/place/Croatia",
            en2: "Zagreb",
            cn: "克罗地亚",
            cn_pronunciation: "[kèluódìyà]",
            cn2: "萨格勒布",
            cn2_pronunciation: "[sàgélèbù]",
            jp: "クロアチア",
            jp2: "ザグレブ",
            ko: "크로아티아",
            ko2: "자그레브",
            flagname: "41.Croatia.png",
         },
         {
            id: 42,
            en: "Cuba",
            googleMapUrl: "https://www.google.com/maps/place/Cuba",
            en2: "Havana",
            cn: "古巴",
            cn_pronunciation: "[gǔbā]",
            cn2: "哈瓦那",
            cn2_pronunciation: "[hāwǎnà]",
            jp: "キューバ",
            jp2: "ハバナ",
            ko: "쿠바",
            ko2: "아바나",
            flagname: "42.Cuba.png",
         },
         {
            id: 43,
            en: "Cyprus",
            googleMapUrl: "https://www.google.com/maps/place/Cyprus",
            en2: "Nicosia",
            cn: "賽普勒斯",
            cn_pronunciation: "[sàipǔlèsī]",
            cn2: "尼科西亚",
            cn2_pronunciation: "[níkēxīyà]",
            jp: "キプロス",
            jp2: "ニコシア",
            ko: "사이프러스",
            ko2: "니코시아",
            flagname: "43.Cyprus.png",
         },
         {
            id: 44,
            en: "The Czech Republic",
            googleMapUrl: "https://www.google.com/maps/place/The Czech Republic",
            en2: "Prague",
            cn: "捷克",
            cn_pronunciation: "[jiékè]",
            cn2: "布拉格",
            cn2_pronunciation: "[bùlāgé]",
            jp: "チェコ",
            jp2: "プラハ",
            ko: "체코",
            ko2: "프라하",
            flagname: "44.The Czech Republic.png",
         },
         {
            id: 45,
            en: "The Democratic Republic of the Congo",
            googleMapUrl: "https://www.google.com/maps/place/The Democratic Republic of the Congo",
            en2: "Kinshasa",
            cn: "刚果民主共和国",
            cn_pronunciation: "[gāngguǒmínzhǔgònghéguó]",
            cn2: "金沙萨",
            cn2_pronunciation: "[jīnshāsà]",
            jp: "コンゴ民主共和国",
            jp2: "キンシャサ",
            ko: "콩고",
            ko2: "킨샤사",
            flagname: "45.The Democratic Republic of the Congo.png",
         },
         {
            id: 46,
            en: "Denmark",
            googleMapUrl: "https://www.google.com/maps/place/Denmark",
            en2: "Copenhagen",
            cn: "丹麦",
            cn_pronunciation: "[dānmài]",
            cn2: "哥本哈根",
            cn2_pronunciation: "[gēběnhāgēn]",
            jp: "デンマーク",
            jp2: "コペンハーゲン",
            ko: "덴마크",
            ko2: "코펜하겐",
            flagname: "46.Denmark.png",
         },
         {
            id: 47,
            en: "Djibouti",
            googleMapUrl: "https://www.google.com/maps/place/Djibouti",
            en2: "Djibouti",
            cn: "吉布提",
            cn_pronunciation: "[jíbùtí]",
            cn2: "吉布提",
            cn2_pronunciation: "[jíbùtí]",
            jp: "ジブチ",
            jp2: "ジブチ",
            ko: "지부티",
            ko2: "지부티",
            flagname: "47.Djibouti.png",
         },
         {
            id: 48,
            en: "Dominica",
            googleMapUrl: "https://www.google.com/maps/place/Dominica",
            en2: "Roseau",
            cn: "多米尼克",
            cn_pronunciation: "[duōmǐníkè]",
            cn2: "罗索",
            cn2_pronunciation: "[luósuǒ]",
            jp: "ドミニカ国",
            jp2: "ロゾー",
            ko: "도미니카",
            ko2: "로조",
            flagname: "48.Dominica.png",
         },
         {
            id: 49,
            en: "The Dominican Republic",
            googleMapUrl: "https://www.google.com/maps/place/The Dominican Republic",
            en2: "Santo Domingo",
            cn: "多明尼加共和國",
            cn_pronunciation: "[duōmíngníjiāgònghéguó]",
            cn2: "圣多明各",
            cn2_pronunciation: "[shèngduōmínggè]",
            jp: "ドミニカ共和国",
            jp2: "サントドミンゴ",
            ko: "도미니카 공화국",
            ko2: "산토도밍고",
            flagname: "49.The Dominican Republic.png",
         },
         {
            id: 50,
            en: "East Timor",
            googleMapUrl: "https://www.google.com/maps/place/East Timor",
            en2: "Dili",
            cn: "东帝汶",
            cn_pronunciation: "[dōngdìwèn]",
            cn2: "帝",
            cn2_pronunciation: "[dì]",
            jp: "東ティモール",
            jp2: "ディリ",
            ko: "동티모르",
            ko2: "딜리",
            flagname: "50.East Timor.png",
         },
         {
            id: 51,
            en: "Ecuador",
            googleMapUrl: "https://www.google.com/maps/place/Ecuador",
            en2: "Quito",
            cn: "厄瓜多尔",
            cn_pronunciation: "[èguāduōěr]",
            cn2: "基多",
            cn2_pronunciation: "[jīduō]",
            jp: "エクアドル",
            jp2: "キト",
            ko: "에콰도르",
            ko2: "키토",
            flagname: "51.Ecuador.png",
         },
         {
            id: 52,
            en: "Egypt",
            googleMapUrl: "https://www.google.com/maps/place/Egypt",
            en2: "Cairo",
            cn: "埃及",
            cn_pronunciation: "[āijí]",
            cn2: "开罗",
            cn2_pronunciation: "[kāiluó]",
            jp: "エジプト",
            jp2: "カイロ",
            ko: "이집트",
            ko2: "카이로",
            flagname: "52.Egypt.png",
         },
         {
            id: 53,
            en: "El Salvador",
            googleMapUrl: "https://www.google.com/maps/place/El Salvador",
            en2: "San Salvador",
            cn: "萨尔瓦多",
            cn_pronunciation: "[sàěrwǎduō]",
            cn2: "圣萨尔瓦多",
            cn2_pronunciation: "[shèngsàěrwǎduō]",
            jp: "エルサルバドル",
            jp2: "サンサルバドル",
            ko: "엘살바도르",
            ko2: "산살바도르",
            flagname: "53.El Salvador.png",
         },
         {
            id: 54,
            en: "Equatorial Guinea",
            googleMapUrl: "https://www.google.com/maps/place/Equatorial Guinea",
            en2: "Malabo",
            cn: "赤道几内亚",
            cn_pronunciation: "[chìdàojǐnèiyà]",
            cn2: "马拉博",
            cn2_pronunciation: "[mǎlābó]",
            jp: "せきどうギニア",
            jp2: "マラボ",
            ko: "적도 기니",
            ko2: "말라보",
            flagname: "54.Equatorial Guinea.png",
         },
         {
            id: 55,
            en: "Eritrea",
            googleMapUrl: "https://www.google.com/maps/place/Eritrea",
            en2: "Asmara",
            cn: "厄立特里亚",
            cn_pronunciation: "[èlìtèlǐyà]",
            cn2: "阿斯马拉",
            cn2_pronunciation: "[āsīmǎlā]",
            jp: "エリトリア",
            jp2: "アスマラ",
            ko: "에리트레아",
            ko2: "아스마라",
            flagname: "55.Eritrea.png",
         },
         {
            id: 56,
            en: "Estonia",
            googleMapUrl: "https://www.google.com/maps/place/Estonia",
            en2: "Tallinn",
            cn: "爱沙尼亚",
            cn_pronunciation: "[àishāníyà]",
            cn2: "塔林",
            cn2_pronunciation: "[tǎlín]",
            jp: "エストニア",
            jp2: "タリン",
            ko: "에스토니아",
            ko2: "탈린",
            flagname: "56.Estonia.png",
         },
         {
            id: 57,
            en: "Ethiopia",
            googleMapUrl: "https://www.google.com/maps/place/Ethiopia",
            en2: "Addis Ababa",
            cn: "埃塞俄比亚",
            cn_pronunciation: "[āisāiébǐyà]",
            cn2: "亚的斯亚贝巴",
            cn2_pronunciation: "[yàdesīyàbèibā]",
            jp: "エチオピア",
            jp2: "アジスアベバ",
            ko: "에티오피아",
            ko2: "아디스아바바",
            flagname: "57.Ethiopia.png",
         },
         {
            id: 58,
            en: "Fiji",
            googleMapUrl: "https://www.google.com/maps/place/Fiji",
            en2: "Suva",
            cn: "斐濟",
            cn_pronunciation: "[fěijì]",
            cn2: "苏瓦",
            cn2_pronunciation: "[sūwǎ]",
            jp: "フィジー",
            jp2: "スバ",
            ko: "피지",
            ko2: "수바",
            flagname: "58.Fiji.png",
         },
         {
            id: 59,
            en: "Finland",
            googleMapUrl: "https://www.google.com/maps/place/Finland",
            en2: "Helsinki",
            cn: "芬兰",
            cn_pronunciation: "[fēnlán]",
            cn2: "赫尔辛基",
            cn2_pronunciation: "[hèěrxīnjī]",
            jp: "フィンランド",
            jp2: "ヘルシンキ",
            ko: "핀란드",
            ko2: "헬싱키",
            flagname: "59.Finland.png",
         },
         {
            id: 60,
            en: "France",
            googleMapUrl: "https://www.google.com/maps/place/France",
            en2: "Paris",
            cn: "法国",
            cn_pronunciation: "[fǎguó]",
            cn2: "巴黎",
            cn2_pronunciation: "[bālí]",
            jp: "フランス",
            jp2: "パリ",
            ko: "프랑스",
            ko2: "파리",
            flagname: "60.France.png",
         },
         {
            id: 61,
            en: "Gabon",
            googleMapUrl: "https://www.google.com/maps/place/Gabon",
            en2: "Libreville",
            cn: "加蓬",
            cn_pronunciation: "[jiāpéng]",
            cn2: "利伯维尔",
            cn2_pronunciation: "[lìbówéiěr]",
            jp: "ガボン",
            jp2: "リーブルビル",
            ko: "가봉",
            ko2: "리브르빌",
            flagname: "61.Gabon.png",
         },
         {
            id: 62,
            en: "The Gambia",
            googleMapUrl: "https://www.google.com/maps/place/The Gambia",
            en2: "Banjul",
            cn: "冈比亚",
            cn_pronunciation: "[gāngbǐyà]",
            cn2: "班珠尔",
            cn2_pronunciation: "[bānzhūěr]",
            jp: "ガンビア",
            jp2: "バンジュル",
            ko: "감비아",
            ko2: "반줄",
            flagname: "62.The Gambia.png",
         },
         {
            id: 63,
            en: "Georgia",
            googleMapUrl: "https://www.google.com/maps/place/Georgia",
            en2: "Tbilisi",
            cn: "喬治亞",
            cn_pronunciation: "[qiáozhìyà]",
            cn2: "第比利斯",
            cn2_pronunciation: "[dìbǐlìsī]",
            jp: "ジョージア",
            jp2: "トビリシ",
            ko: "그루지야",
            ko2: "트빌리시",
            flagname: "63.Georgia.png",
         },
         {
            id: 64,
            en: "Germany",
            googleMapUrl: "https://www.google.com/maps/place/Germany",
            en2: "Berlin",
            cn: "德国",
            cn_pronunciation: "[déguó]",
            cn2: "柏林",
            cn2_pronunciation: "[bǎilín]",
            jp: "ドイツ",
            jp2: "ベルリン",
            ko: "독일",
            ko2: "베를린",
            flagname: "64.Germany.png",
         },
         {
            id: 65,
            en: "Ghana",
            googleMapUrl: "https://www.google.com/maps/place/Ghana",
            en2: "Accra",
            cn: "加纳",
            cn_pronunciation: "[jiānà]",
            cn2: "阿克拉",
            cn2_pronunciation: "[ākèlā]",
            jp: "ガーナ",
            jp2: "アクラ",
            ko: "가나",
            ko2: "아크라",
            flagname: "65.Ghana.png",
         },
         {
            id: 66,
            en: "Greece",
            googleMapUrl: "https://www.google.com/maps/place/Greece",
            en2: "Athens",
            cn: "希腊",
            cn_pronunciation: "[xīlà]",
            cn2: "雅典",
            cn2_pronunciation: "[yǎdiǎn]",
            jp: "ギリシャ",
            jp2: "アテネ",
            ko: "그리스",
            ko2: "아테네",
            flagname: "66.Greece.png",
         },
         {
            id: 67,
            en: "Grenada",
            googleMapUrl: "https://www.google.com/maps/place/Grenada",
            en2: "St. George's",
            cn: "格林纳达",
            cn_pronunciation: "[gélínnàdá]",
            cn2: "圣乔治",
            cn2_pronunciation: "[shèngqiáozhì]",
            jp: "グレナダ",
            jp2: "セントジョージ",
            ko: "그레나다",
            ko2: "세인트조지스",
            flagname: "67.Grenada.png",
         },
         {
            id: 68,
            en: "Guatemala",
            googleMapUrl: "https://www.google.com/maps/place/Guatemala",
            en2: "Guatemala City",
            cn: "危地马拉",
            cn_pronunciation: "[wēidìmǎlā]",
            cn2: "危地马拉城",
            cn2_pronunciation: "[wēidìmǎlāchéng]",
            jp: "グアテマラ",
            jp2: "グアテマラシティー",
            ko: "과테말라",
            ko2: "과테말라시티",
            flagname: "68.Guatemala.png",
         },
         {
            id: 69,
            en: "Guinea",
            googleMapUrl: "https://www.google.com/maps/place/Guinea",
            en2: "Conakry",
            cn: "几内亚",
            cn_pronunciation: "[jǐnèiyà]",
            cn2: "科纳克里",
            cn2_pronunciation: "[kēnàkèlǐ]",
            jp: "ギニア",
            jp2: "コナクリ",
            ko: "기니",
            ko2: "코나크리",
            flagname: "69.Guinea.png",
         },
         {
            id: 70,
            en: "Guinea-Bissau",
            googleMapUrl: "https://www.google.com/maps/place/Guinea-Bissau",
            en2: "Bissau",
            cn: "幾內亞比索",
            cn_pronunciation: "[jǐnàyàbǐsuǒ]",
            cn2: "比绍",
            cn2_pronunciation: "[bǐshào]",
            jp: "ギニアビサウ",
            jp2: "ビサウ",
            ko: "기니비사우",
            ko2: "비사우",
            flagname: "70.Guinea-Bissau.png",
         },
         {
            id: 71,
            en: "Guyana",
            googleMapUrl: "https://www.google.com/maps/place/Guyana",
            en2: "Georgetown",
            cn: "圭亚那",
            cn_pronunciation: "[guīyànà]",
            cn2: "乔治敦",
            cn2_pronunciation: "[qiáozhìdūn]",
            jp: "ガイアナ",
            jp2: "ジョージタウン",
            ko: "가이아나",
            ko2: "조지타운",
            flagname: "71.Guyana.png",
         },
         {
            id: 72,
            en: "Haiti",
            googleMapUrl: "https://www.google.com/maps/place/Haiti",
            en2: "Port-au-Prince",
            cn: "海地",
            cn_pronunciation: "[hǎidì]",
            cn2: "太子港",
            cn2_pronunciation: "[tàizǐgǎng]",
            jp: "ハイチ",
            jp2: "ポルトープランス",
            ko: "아이티",
            ko2: "포르토프랭스",
            flagname: "72.Haiti.png",
         },
         {
            id: 73,
            en: "Honduras",
            googleMapUrl: "https://www.google.com/maps/place/Honduras",
            en2: "Tegucigalpa",
            cn: "洪都拉斯",
            cn_pronunciation: "[hóngdōulāsī]",
            cn2: "特古西加尔巴",
            cn2_pronunciation: "[tègǔxījiāěrbā]",
            jp: "ホンジュラス",
            jp2: "テグシガルパ",
            ko: "온두라스",
            ko2: "테구시갈파",
            flagname: "73.Honduras.png",
         },
         {
            id: 74,
            en: "Hungary",
            googleMapUrl: "https://www.google.com/maps/place/Hungary",
            en2: "Budapest",
            cn: "匈牙利",
            cn_pronunciation: "[xiōngyálì]",
            cn2: "布达佩斯",
            cn2_pronunciation: "[bùdápèisī]",
            jp: "ハンガリー",
            jp2: "ブダペスト",
            ko: "헝가리",
            ko2: "부다페스트",
            flagname: "74.Hungary.png",
         },
         {
            id: 75,
            en: "Iceland",
            googleMapUrl: "https://www.google.com/maps/place/Iceland",
            en2: "Reykjavík",
            cn: "冰岛",
            cn_pronunciation: "[bīngdǎo]",
            cn2: "雷克雅未克",
            cn2_pronunciation: "[léikèyǎwèikè]",
            jp: "アイスランド",
            jp2: "レイキャビク",
            ko: "아이슬란드",
            ko2: "레이캬비크",
            flagname: "75.Iceland.png",
         },
         {
            id: 76,
            en: "India",
            googleMapUrl: "https://www.google.com/maps/place/India",
            en2: "New Delhi",
            cn: "印度",
            cn_pronunciation: "[yìndù]",
            cn2: "新德里",
            cn2_pronunciation: "[xīndélǐ]",
            jp: "インド",
            jp2: "ニューデリー",
            ko: "인도",
            ko2: "뉴델리",
            flagname: "76.India.png",
         },
         {
            id: 77,
            en: "Indonesia",
            googleMapUrl: "https://www.google.com/maps/place/Indonesia",
            en2: "Jakarta",
            cn: "印度尼西亚",
            cn_pronunciation: "[yìndùníxīyà]",
            cn2: "雅加达",
            cn2_pronunciation: "[yǎjiādá]",
            jp: "インドネシア",
            jp2: "ジャカルタ",
            ko: "인도네시아",
            ko2: "자카르타",
            flagname: "77.Indonesia.png",
         },
         {
            id: 78,
            en: "Iran",
            googleMapUrl: "https://www.google.com/maps/place/Iran",
            en2: "Tehran",
            cn: "伊朗",
            cn_pronunciation: "[yīlǎng]",
            cn2: "德黑兰",
            cn2_pronunciation: "[déhēilán]",
            jp: "イラン",
            jp2: "テヘラン",
            ko: "이란",
            ko2: "테헤란",
            flagname: "78.Iran.png",
         },
         {
            id: 79,
            en: "Iraq",
            googleMapUrl: "https://www.google.com/maps/place/Iraq",
            en2: "Baghdad",
            cn: "伊拉克",
            cn_pronunciation: "[yīlākè]",
            cn2: "巴格达",
            cn2_pronunciation: "[bāgédá]",
            jp: "イラク",
            jp2: "バグダッド",
            ko: "이라크",
            ko2: "바그다드",
            flagname: "79.Iraq.png",
         },
         {
            id: 80,
            en: "Ireland",
            googleMapUrl: "https://www.google.com/maps/place/Ireland",
            en2: "Dublin",
            cn: "爱尔兰岛",
            cn_pronunciation: "[àiěrlándǎo]",
            cn2: "都柏林",
            cn2_pronunciation: "[dōubǎilín]",
            jp: "アイルランド島",
            jp2: "ダブリン",
            ko: "아일랜드",
            ko2: "더블린",
            flagname: "80.Ireland.png",
         },
         {
            id: 81,
            en: "Israel",
            googleMapUrl: "https://www.google.com/maps/place/Israel",
            en2: "Jerusalem",
            cn: "以色列",
            cn_pronunciation: "[yǐsèliè]",
            cn2: "耶路撒冷",
            cn2_pronunciation: "[yēlùsālěng]",
            jp: "イスラエル",
            jp2: "エルサレム",
            ko: "이스라엘",
            ko2: "예루살렘",
            flagname: "81.Israel.png",
         },
         {
            id: 82,
            en: "Italy",
            googleMapUrl: "https://www.google.com/maps/place/Italy",
            en2: "Rome",
            cn: "意大利",
            cn_pronunciation: "[yìdàlì]",
            cn2: "罗马",
            cn2_pronunciation: "[luómǎ]",
            jp: "イタリア",
            jp2: "ローマ",
            ko: "이탈리아",
            ko2: "로마",
            flagname: "82.Italy.png",
         },
         {
            id: 83,
            en: "Jamaica",
            googleMapUrl: "https://www.google.com/maps/place/Jamaica",
            en2: "Kingston",
            cn: "牙买加",
            cn_pronunciation: "[yámǎijiā]",
            cn2: "金士顿",
            cn2_pronunciation: "[jīnshìdùn]",
            jp: "ジャマイカ",
            jp2: "キングストン",
            ko: "자메이카",
            ko2: "킹스턴",
            flagname: "83.Jamaica.png",
         },
         {
            id: 84,
            en: "Japan",
            googleMapUrl: "https://www.google.com/maps/place/Japan",
            en2: "Tokyo",
            cn: "日本",
            cn_pronunciation: "[rìběn]",
            cn2: "东京",
            cn2_pronunciation: "[dōngjīng]",
            jp: "日本",
            jp2: "東京",
            ko: "일본",
            ko2: "도쿄",
            flagname: "84.Japan.png",
         },
         {
            id: 85,
            en: "Jordan",
            googleMapUrl: "https://www.google.com/maps/place/Jordan",
            en2: "Amman",
            cn: "约旦",
            cn_pronunciation: "[yuēdàn]",
            cn2: "安曼",
            cn2_pronunciation: "[ānmàn]",
            jp: "ヨルダン",
            jp2: "アンマン",
            ko: "요르단",
            ko2: "암만",
            flagname: "85.Jordan.png",
         },
         {
            id: 86,
            en: "Kazakhstan",
            googleMapUrl: "https://www.google.com/maps/place/Kazakhstan",
            en2: "Astana",
            cn: "哈萨克斯坦",
            cn_pronunciation: "[hāsàkèsītǎn]",
            cn2: "阿斯塔纳",
            cn2_pronunciation: "[āsītǎnà]",
            jp: "カザフスタン",
            jp2: "アスタナ",
            ko: "카자흐스탄",
            ko2: "아스타나",
            flagname: "86.Kazakhstan.png",
         },
         {
            id: 87,
            en: "Kenya",
            googleMapUrl: "https://www.google.com/maps/place/Kenya",
            en2: "Nairobi",
            cn: "肯尼亚",
            cn_pronunciation: "[kěnníyà]",
            cn2: "内罗毕",
            cn2_pronunciation: "[nèiluóbì]",
            jp: "ケニア",
            jp2: "ナイロビ",
            ko: "케냐",
            ko2: "나이로비",
            flagname: "87.Kenya.png",
         },
         {
            id: 88,
            en: "Kiribati",
            googleMapUrl: "https://www.google.com/maps/place/Kiribati",
            en2: "Tarawa",
            cn: "基里巴斯",
            cn_pronunciation: "[jīlǐbāsī]",
            cn2: "塔拉瓦",
            cn2_pronunciation: "[nántǎlāwǎ]",
            jp: "キリバス",
            jp2: "タラワ",
            ko: "키리바시",
            ko2: "타라와",
            flagname: "88.Kiribati.png",
         },
         {
            id: 89,
            en: "Kosovo",
            googleMapUrl: "https://www.google.com/maps/place/Kosovo",
            en2: "Pristina",
            cn: "科索沃",
            cn_pronunciation: "[kēsuǒwò]",
            cn2: "普里什蒂纳",
            cn2_pronunciation: "[pǔlǐshídìnà]",
            jp: "コソボ",
            jp2: "プリシュティナ",
            ko: "코소보",
            ko2: "프리슈티나",
            flagname: "89.Kosovo.png",
         },
         {
            id: 90,
            en: "Kuwait",
            googleMapUrl: "https://www.google.com/maps/place/Kuwait",
            en2: "Kuwait City",
            cn: "科威特",
            cn_pronunciation: "[kēwēitè]",
            cn2: "科威特市",
            cn2_pronunciation: "[kēwēitèshì]",
            jp: "クウェート",
            jp2: "クウェートシティ",
            ko: "쿠웨이트",
            ko2: "쿠웨이트 시티",
            flagname: "90.Kuwait.png",
         },
         {
            id: 91,
            en: "Kyrgyzstan",
            googleMapUrl: "https://www.google.com/maps/place/Kyrgyzstan",
            en2: "Bishkek",
            cn: "吉尔吉斯斯坦",
            cn_pronunciation: "[jíěrjísīsītǎn]",
            cn2: "比什凯克",
            cn2_pronunciation: "[bǐshíkǎikè]",
            jp: "キルギス",
            jp2: "ビシュケク",
            ko: "키르기스스탄",
            ko2: "비슈케크",
            flagname: "91.Kyrgyzstan.png",
         },
         {
            id: 92,
            en: "Laos",
            googleMapUrl: "https://www.google.com/maps/place/Laos",
            en2: "Vientiane",
            cn: "老挝",
            cn_pronunciation: "[lǎowō]",
            cn2: "万象",
            cn2_pronunciation: "[wànxiàng]",
            jp: "ラオス",
            jp2: "ビエンチャン",
            ko: "라오스",
            ko2: "비엔티안",
            flagname: "92.Laos.png",
         },
         {
            id: 93,
            en: "Latvia",
            googleMapUrl: "https://www.google.com/maps/place/Latvia",
            en2: "Riga",
            cn: "拉脫維亞",
            cn_pronunciation: "[lātuōwéiyà]",
            cn2: "里加",
            cn2_pronunciation: "[lǐjiā]",
            jp: "ラトビア",
            jp2: "リガ",
            ko: "라트비아",
            ko2: "리가",
            flagname: "93.Latvia.png",
         },
         {
            id: 94,
            en: "Lebanon",
            googleMapUrl: "https://www.google.com/maps/place/Lebanon",
            en2: "Beirut",
            cn: "黎巴嫩",
            cn_pronunciation: "[líbānèn]",
            cn2: "贝鲁特",
            cn2_pronunciation: "[bèilǔtè]",
            jp: "レバノン",
            jp2: "ベイルート",
            ko: "레바논",
            ko2: "베이루트",
            flagname: "94.Lebanon.png",
         },
         {
            id: 95,
            en: "Lesotho",
            googleMapUrl: "https://www.google.com/maps/place/Lesotho",
            en2: "Maseru",
            cn: "莱索托",
            cn_pronunciation: "[láisuǒtuō]",
            cn2: "马塞卢",
            cn2_pronunciation: "[mǎsāilú]",
            jp: "レソト",
            jp2: "マセル",
            ko: "레소토",
            ko2: "마세루",
            flagname: "95.Lesotho.png",
         },
         {
            id: 96,
            en: "Liberia",
            googleMapUrl: "https://www.google.com/maps/place/Liberia",
            en2: "Monrovia",
            cn: "利比里亚",
            cn_pronunciation: "[lìbǐlǐyà]",
            cn2: "蒙罗维亚",
            cn2_pronunciation: "[méngluówéiyà]",
            jp: "リベリア",
            jp2: "モンロビア",
            ko: "라이베리아",
            ko2: "먼로비아",
            flagname: "96.Liberia.png",
         },
         {
            id: 97,
            en: "Libya",
            googleMapUrl: "https://www.google.com/maps/place/Libya",
            en2: "Tripoli",
            cn: "利比亚",
            cn_pronunciation: "[lìbǐyà]",
            cn2: "的黎波里",
            cn2_pronunciation: "[delíbōlǐ]",
            jp: "リビア",
            jp2: "トリポリ",
            ko: "리비아",
            ko2: "트리폴리",
            flagname: "97.Libya.png",
         },
         {
            id: 98,
            en: "Liechtenstein",
            googleMapUrl: "https://www.google.com/maps/place/Liechtenstein",
            en2: "Vaduz",
            cn: "列支敦斯登",
            cn_pronunciation: "[lièzhīdūnsīdēng]",
            cn2: "瓦杜兹",
            cn2_pronunciation: "[wǎdùzī]",
            jp: "リヒテンシュタイン",
            jp2: "ファドゥーツ",
            ko: "리히텐슈타인",
            ko2: "파두츠",
            flagname: "98.Liechtenstein.png",
         },
         {
            id: 99,
            en: "Lithuania",
            googleMapUrl: "https://www.google.com/maps/place/Lithuania",
            en2: "Vilnius",
            cn: "立陶宛",
            cn_pronunciation: "[lìtáowǎn]",
            cn2: "维尔纽斯",
            cn2_pronunciation: "[wéiěrniǔsī]",
            jp: "リトアニア",
            jp2: "ヴィルニアス",
            ko: "리투아니아",
            ko2: "빌뉴스",
            flagname: "99.Lithuania.png",
         },
         {
            id: 100,
            en: "Luxembourg",
            googleMapUrl: "https://www.google.com/maps/place/Luxembourg",
            en2: "Luxembourg City",
            cn: "卢森堡",
            cn_pronunciation: "[lúsēnbǎo]",
            cn2: "卢森堡市",
            cn2_pronunciation: "[lúsēnbǎoshì]",
            jp: "ルクセンブルク",
            jp2: "ルクセンブルク",
            ko: "룩셈부르크",
            ko2: "룩셈부르크",
            flagname: "100.Luxembourg.png",
         },
         {
            id: 101,
            en: "Macedonia",
            googleMapUrl: "https://www.google.com/maps/place/Macedonia",
            en2: "Skopje",
            cn: "馬其頓",
            cn_pronunciation: "[mǎqídùn]",
            cn2: "斯科普里",
            cn2_pronunciation: "[sīkēpǔlǐ]",
            jp: "マケドニア",
            jp2: "スコピエ",
            ko: "마케도니아",
            ko2: "스코페",
            flagname: "101.Macedonia.png",
         },
         {
            id: 102,
            en: "Madagascar",
            googleMapUrl: "https://www.google.com/maps/place/Madagascar",
            en2: "Antananarivo",
            cn: "马达加斯加",
            cn_pronunciation: "[mǎdájiāsījiā]",
            cn2: "塔那那利佛",
            cn2_pronunciation: "[tǎnànàlìfó]",
            jp: "マダガスカル",
            jp2: "アンタナナリボ",
            ko: "마다가스카르",
            ko2: "안타나나리보",
            flagname: "102.Madagascar.png",
         },
         {
            id: 103,
            en: "Malawi",
            googleMapUrl: "https://www.google.com/maps/place/Malawi",
            en2: "Lilongwe",
            cn: "马拉维",
            cn_pronunciation: "[mǎlāwéi]",
            cn2: "利隆圭",
            cn2_pronunciation: "[lìlóngguī]",
            jp: "マラウイ",
            jp2: "リロングウェ",
            ko: "말라위",
            ko2: "릴롱궤",
            flagname: "103.Malawi.png",
         },
         {
            id: 104,
            en: "Malaysia",
            googleMapUrl: "https://www.google.com/maps/place/Malaysia",
            en2: "Kuala Lumpur",
            cn: "马来西亚",
            cn_pronunciation: "[mǎláixīyà]",
            cn2: "吉隆坡",
            cn2_pronunciation: "[jílóngpō]",
            jp: "マレーシア",
            jp2: "クアラルンプール",
            ko: "말레이시아",
            ko2: "쿠알라룸푸르",
            flagname: "104.Malaysia.png",
         },
         {
            id: 105,
            en: "Maldives",
            googleMapUrl: "https://www.google.com/maps/place/Maldives",
            en2: "Malé",
            cn: "马尔代夫",
            cn_pronunciation: "[mǎěrdàifū]",
            cn2: "马累",
            cn2_pronunciation: "[mǎlèi]",
            jp: "モルディブ",
            jp2: "マレ",
            ko: "몰디브",
            ko2: "말레",
            flagname: "105.Maldives.png",
         },
         {
            id: 106,
            en: "Mali",
            googleMapUrl: "https://www.google.com/maps/place/Mali",
            en2: "Bamako",
            cn: "马里共和国",
            cn_pronunciation: "[mǎlǐgònghéguó]",
            cn2: "巴马科",
            cn2_pronunciation: "[bāmǎkē]",
            jp: "マリ",
            jp2: "バマコ",
            ko: "말리",
            ko2: "바마코",
            flagname: "106.Mali.png",
         },
         {
            id: 107,
            en: "Malta",
            googleMapUrl: "https://www.google.com/maps/place/Malta",
            en2: "Valletta",
            cn: "马耳他",
            cn_pronunciation: "[mǎěrtā]",
            cn2: "瓦莱塔",
            cn2_pronunciation: "[wǎláitǎ]",
            jp: "マルタ",
            jp2: "バレッタ",
            ko: "몰타",
            ko2: "발레타",
            flagname: "107.Malta.png",
         },
         {
            id: 108,
            en: "The Marshall Islands",
            googleMapUrl: "https://www.google.com/maps/place/The Marshall Islands",
            en2: "Majuro",
            cn: "馬紹爾群島",
            cn_pronunciation: "[mǎshàoěrqúndǎo]",
            cn2: "马朱罗",
            cn2_pronunciation: "[mǎzhūluó]",
            jp: "マーシャル諸島",
            jp2: "マジュロ",
            ko: "마셜 제도",
            ko2: "마주로",
            flagname: "108.The Marshall Islands.png",
         },
         {
            id: 109,
            en: "Mauritania",
            googleMapUrl: "https://www.google.com/maps/place/Mauritania",
            en2: "Nouakchott",
            cn: "毛里塔尼亚",
            cn_pronunciation: "[máolǐtǎníyà]",
            cn2: "努瓦克肖特",
            cn2_pronunciation: "[nǔwǎkèxiāotè]",
            jp: "モーリタニア",
            jp2: "ヌアクショット",
            ko: "모리타니아",
            ko2: "뉴악쇼트",
            flagname: "109.Mauritania.png",
         },
         {
            id: 110,
            en: "Mauritius",
            googleMapUrl: "https://www.google.com/maps/place/Mauritius",
            en2: "Port Louis",
            cn: "毛里求斯",
            cn_pronunciation: "[máolǐqiúsī]",
            cn2: "路易港",
            cn2_pronunciation: "[lùyìgǎng]",
            jp: "モーリシャス",
            jp2: "ポートルイス",
            ko: "모리셔스",
            ko2: "포트루이스",
            flagname: "110.Mauritius.png",
         },
         {
            id: 111,
            en: "Mexico",
            googleMapUrl: "https://www.google.com/maps/place/Mexico",
            en2: "Mexico City",
            cn: "墨西哥",
            cn_pronunciation: "[mòxīgē]",
            cn2: "墨西哥城",
            cn2_pronunciation: "[mòxīgēchéng]",
            jp: "メキシコ",
            jp2: "メキシコシティ",
            ko: "멕시코",
            ko2: "멕시코 시티",
            flagname: "111.Mexico.png",
         },
         {
            id: 112,
            en: "Micronesia",
            googleMapUrl: "https://www.google.com/maps/place/Micronesia",
            en2: "Palikir",
            cn: "密克罗尼西亚群岛",
            cn_pronunciation: "[mìkèluóníxīyàqúndǎo]",
            cn2: "帕利基尔",
            cn2_pronunciation: "[pàlìjīěr]",
            jp: "ミクロネシア",
            jp2: "パリキール",
            ko: "미크로네시아",
            ko2: "팔리커",
            flagname: "112.Micronesia.png",
         },
         {
            id: 113,
            en: "Moldova",
            googleMapUrl: "https://www.google.com/maps/place/Moldova",
            en2: "Chisinau",
            cn: "摩尔多瓦",
            cn_pronunciation: "[móěrduōwǎ]",
            cn2: "基希讷乌",
            cn2_pronunciation: "[jīxīnèwū]",
            jp: "モルドバ",
            jp2: "キシナウ",
            ko: "몰도바",
            ko2: "키시나우",
            flagname: "113.Moldova.png",
         },
         {
            id: 114,
            en: "Monaco",
            googleMapUrl: "https://www.google.com/maps/place/Monaco",
            en2: "Monaco",
            cn: "摩纳哥",
            cn_pronunciation: "[mónàgē]",
            cn2: "摩纳哥",
            cn2_pronunciation: "[mónàgē]",
            jp: "モナコ",
            jp2: "モナコ",
            ko: "모나코",
            ko2: "모나코",
            flagname: "114.Monaco.png",
         },
         {
            id: 115,
            en: "Mongolia",
            googleMapUrl: "https://www.google.com/maps/place/Mongolia",
            en2: "Ulaanbaatar",
            cn: "蒙古国",
            cn_pronunciation: "[ménggǔguó]",
            cn2: "乌兰巴托",
            cn2_pronunciation: "[wūlánbātuō]",
            jp: "モンゴル",
            jp2: "ウランバートル",
            ko: "몽골",
            ko2: "울란바토르",
            flagname: "115.Mongolia.png",
         },
         {
            id: 116,
            en: "Montenegro",
            googleMapUrl: "https://www.google.com/maps/place/Montenegro",
            en2: "Podgorica",
            cn: "蒙特內哥羅",
            cn_pronunciation: "[méngtènàgēluó]",
            cn2: "波德戈里察",
            cn2_pronunciation: "[bōdégēlǐchá]",
            jp: "モンテネグロ",
            jp2: "ポドゴリツァ",
            ko: "몬테네그로",
            ko2: "포도고리차",
            flagname: "116.Montenegro.png",
         },
         {
            id: 117,
            en: "Morocco",
            googleMapUrl: "https://www.google.com/maps/place/Morocco",
            en2: "Rabat",
            cn: "摩洛哥",
            cn_pronunciation: "[móluògē]",
            cn2: "拉巴特",
            cn2_pronunciation: "[lābātè]",
            jp: "モロッコ",
            jp2: "ラバト",
            ko: "모로코",
            ko2: "라바트",
            flagname: "117.Morocco.png",
         },
         {
            id: 118,
            en: "Mozambique",
            googleMapUrl: "https://www.google.com/maps/place/Mozambique",
            en2: "Maputo",
            cn: "莫桑比克",
            cn_pronunciation: "[mòsāngbǐkè]",
            cn2: "马普托",
            cn2_pronunciation: "[mǎpǔtuō]",
            jp: "モザンビーク",
            jp2: "マプト",
            ko: "모잠비크",
            ko2: "마푸토",
            flagname: "118.Mozambique.png",
         },
         {
            id: 119,
            en: "Myanmar",
            googleMapUrl: "https://www.google.com/maps/place/Myanmar",
            en2: "Naypyidaw",
            cn: "缅甸",
            cn_pronunciation: "[miǎndiàn]",
            cn2: "内比都",
            cn2_pronunciation: "[nèibǐdōu]",
            jp: "ミャンマー",
            jp2: "ネピドー",
            ko: "미얀마",
            ko2: "네피도",
            flagname: "119.Myanmar.png",
         },
         {
            id: 120,
            en: "Namibia",
            googleMapUrl: "https://www.google.com/maps/place/Namibia",
            en2: "Windhoek",
            cn: "纳米比亚",
            cn_pronunciation: "[nàmǐbǐyà]",
            cn2: "温得和克",
            cn2_pronunciation: "[wēndéhékè]",
            jp: "ナミビア",
            jp2: "ウィントフック",
            ko: "나미비아",
            ko2: "빈트후크",
            flagname: "120.Namibia.png",
         },
         {
            id: 121,
            en: "Nauru",
            googleMapUrl: "https://www.google.com/maps/place/Nauru",
            en2: "Yaren District",
            cn: "諾魯",
            cn_pronunciation: "[nuòlǔ]",
            cn2: "亚伦",
            cn2_pronunciation: "[yàlún]",
            jp: "ナウル",
            jp2: "ヤレン",
            ko: "나우루",
            ko2: "야렌",
            flagname: "121.Nauru.png",
         },
         {
            id: 122,
            en: "Nepal",
            googleMapUrl: "https://www.google.com/maps/place/Nepal",
            en2: "Kathmandu",
            cn: "尼泊尔",
            cn_pronunciation: "[níbóěr]",
            cn2: "加德满都",
            cn2_pronunciation: "[jiādémǎndōu]",
            jp: "ネパール",
            jp2: "カトマンズ",
            ko: "네팔",
            ko2: "카트만두",
            flagname: "122.Nepal.png",
         },
         {
            id: 123,
            en: "The Netherlands",
            googleMapUrl: "https://www.google.com/maps/place/The Netherlands",
            en2: "Amsterdam",
            cn: "荷兰",
            cn_pronunciation: "[hélán]",
            cn2: "阿姆斯特丹",
            cn2_pronunciation: "[āmǔsītèdān]",
            jp: "オランダ",
            jp2: "アムステルダム",
            ko: "네덜란드",
            ko2: "암스테르담",
            flagname: "123.The Netherlands.png",
         },
         {
            id: 124,
            en: "New Zealand",
            googleMapUrl: "https://www.google.com/maps/place/New Zealand",
            en2: "Wellington",
            cn: "新西兰",
            cn_pronunciation: "[xīnxīlán]",
            cn2: "惠灵顿",
            cn2_pronunciation: "[huìlíngdùn]",
            jp: "ニュージーランド",
            jp2: "ウェリントン",
            ko: "뉴질랜드",
            ko2: "웰링턴",
            flagname: "124.New Zealand.png",
         },
         {
            id: 125,
            en: "Nicaragua",
            googleMapUrl: "https://www.google.com/maps/place/Nicaragua",
            en2: "Managua",
            cn: "尼加拉瓜",
            cn_pronunciation: "[níjiālāguā]",
            cn2: "马那瓜",
            cn2_pronunciation: "[mǎnàguā]",
            jp: "ニカラグア",
            jp2: "マナグア",
            ko: "니카라과",
            ko2: "마나과",
            flagname: "125.Nicaragua.png",
         },
         {
            id: 126,
            en: "Niger",
            googleMapUrl: "https://www.google.com/maps/place/Niger",
            en2: "Niamey",
            cn: "尼日尔",
            cn_pronunciation: "[nírìěr]",
            cn2: "尼亚美",
            cn2_pronunciation: "[níyàměi]",
            jp: "ニジェール",
            jp2: "ニアメー",
            ko: "니제르",
            ko2: "니아메",
            flagname: "126.Niger.png",
         },
         {
            id: 127,
            en: "Nigeria",
            googleMapUrl: "https://www.google.com/maps/place/Nigeria",
            en2: "Abuja",
            cn: "奈及利亞",
            cn_pronunciation: "[nàijílìyà]",
            cn2: "阿布贾",
            cn2_pronunciation: "[ābùjiǎ]",
            jp: "ナイジェリア",
            jp2: "アブジャ",
            ko: "나이지리아",
            ko2: "아부자",
            flagname: "127.Nigeria.png",
         },
         {
            id: 128,
            en: "Niue",
            googleMapUrl: "https://www.google.com/maps/place/Niue",
            en2: "Alofi",
            cn: "紐埃",
            cn_pronunciation: "[niǔāi]",
            cn2: "阿洛菲",
            cn2_pronunciation: "[āluòfēi]",
            jp: "ニウエ",
            jp2: "アロフィ",
            ko: "니우에",
            ko2: "알로피",
            flagname: "128.Niue.png",
         },
         {
            id: 129,
            en: "North Korea",
            googleMapUrl: "https://www.google.com/maps/place/North Korea",
            en2: "Pyongyang",
            cn: "朝鲜民主主义人民共和国",
            cn_pronunciation: "[cháoxiānmínzhǔzhǔyìrénmíngònghéguó]",
            cn2: "平壤（平壤）",
            cn2_pronunciation: "[píngrǎng（píngrǎng）]",
            jp: "北朝鮮",
            jp2: "平壌（ピョンヤン）",
            ko: "북한",
            ko2: "평양",
            flagname: "129.North Korea.png",
         },
         {
            id: 130,
            en: "Norway",
            googleMapUrl: "https://www.google.com/maps/place/Norway",
            en2: "Oslo",
            cn: "挪威",
            cn_pronunciation: "[nuówēi]",
            cn2: "奥斯陆",
            cn2_pronunciation: "[àosīlù]",
            jp: "ノルウェー",
            jp2: "オスロ",
            ko: "노르웨이",
            ko2: "오슬로",
            flagname: "130.Norway.png",
         },
         {
            id: 131,
            en: "Oman",
            googleMapUrl: "https://www.google.com/maps/place/Oman",
            en2: "Muscat",
            cn: "阿曼",
            cn_pronunciation: "[āmàn]",
            cn2: "马斯喀特",
            cn2_pronunciation: "[mǎsīkātè]",
            jp: "オマーン",
            jp2: "マスカット",
            ko: "오만",
            ko2: "무스카트",
            flagname: "131.Oman.png",
         },
         {
            id: 132,
            en: "Pakistan",
            googleMapUrl: "https://www.google.com/maps/place/Pakistan",
            en2: "Islamabad",
            cn: "巴基斯坦",
            cn_pronunciation: "[bājīsītǎn]",
            cn2: "伊斯兰堡",
            cn2_pronunciation: "[yīsīlánbǎo]",
            jp: "パキスタン",
            jp2: "イスラマバード",
            ko: "파키스탄",
            ko2: "이슬라마바드",
            flagname: "132.Pakistan.png",
         },
         {
            id: 133,
            en: "Palau",
            googleMapUrl: "https://www.google.com/maps/place/Palau",
            en2: "Ngerulmud",
            cn: "帛琉",
            cn_pronunciation: "[bóliú]",
            cn2: "Ngerulmud",
            cn2_pronunciation: "[Ngerulmud]",
            jp: "パラオ",
            jp2: "ンゲルルムッド",
            ko: "팔라우",
            ko2: "응게룰무드",
            flagname: "133.Palau.png",
         },
         {
            id: 134,
            en: "Panama",
            googleMapUrl: "https://www.google.com/maps/place/Panama",
            en2: "Panama City",
            cn: "巴拿马",
            cn_pronunciation: "[bānámǎ]",
            cn2: "巴拿马城",
            cn2_pronunciation: "[bānámǎchéng]",
            jp: "パナマ",
            jp2: "パナマシティー",
            ko: "파나마",
            ko2: "파나마시티",
            flagname: "134.Panama.png",
         },
         {
            id: 135,
            en: "Papua New Guinea",
            googleMapUrl: "https://www.google.com/maps/place/Papua New Guinea",
            en2: "Port Moresby",
            cn: "巴布亚新几内亚",
            cn_pronunciation: "[bābùyàxīnjǐnèiyà]",
            cn2: "莫尔兹比港",
            cn2_pronunciation: "[mòěrzībǐgǎng]",
            jp: "パプアニューギニア",
            jp2: "ポートモレスビー",
            ko: "파푸아뉴기니",
            ko2: "포트모르즈비",
            flagname: "135.Papua New Guinea.png",
         },
         {
            id: 136,
            en: "Paraguay",
            googleMapUrl: "https://www.google.com/maps/place/Paraguay",
            en2: "Asunción",
            cn: "巴拉圭",
            cn_pronunciation: "[bālāguī]",
            cn2: "亚松森",
            cn2_pronunciation: "[yàsōngsēn]",
            jp: "パラグアイ",
            jp2: "アスンシオン",
            ko: "파라과이",
            ko2: "아순시온",
            flagname: "136.Paraguay.png",
         },
         {
            id: 137,
            en: "The People's Republic of China",
            googleMapUrl: "https://www.google.com/maps/place/The People's Republic of China",
            en2: "Beijing",
            cn: "中华人民共和国",
            cn_pronunciation: "[zhōnghuárénmíngònghéguó]",
            cn2: "北京",
            cn2_pronunciation: "[běijīng]",
            jp: "中華人民共和国",
            jp2: "北京",
            ko: "중국",
            ko2: "북경,베이징",
            flagname: "137.The People's Republic of China.png",
         },
         {
            id: 138,
            en: "Peru",
            googleMapUrl: "https://www.google.com/maps/place/Peru",
            en2: "Lima",
            cn: "秘鲁",
            cn_pronunciation: "[mìlǔ]",
            cn2: "利马",
            cn2_pronunciation: "[lìmǎ]",
            jp: "ペルー",
            jp2: "リマ",
            ko: "페루",
            ko2: "리마",
            flagname: "138.Peru.png",
         },
         {
            id: 139,
            en: "The Philippines",
            googleMapUrl: "https://www.google.com/maps/place/The Philippines",
            en2: "Manila",
            cn: "菲律宾",
            cn_pronunciation: "[fēilǜbīn]",
            cn2: "马尼拉",
            cn2_pronunciation: "[mǎnílā]",
            jp: "フィリピン",
            jp2: "マニラ",
            ko: "필리핀",
            ko2: "마닐라",
            flagname: "139.The Philippines.png",
         },
         {
            id: 140,
            en: "Poland",
            googleMapUrl: "https://www.google.com/maps/place/Poland",
            en2: "Warsaw",
            cn: "波兰",
            cn_pronunciation: "[bōlán]",
            cn2: "华沙",
            cn2_pronunciation: "[huáshā]",
            jp: "ポーランド",
            jp2: "ワルシャワ",
            ko: "폴란드",
            ko2: "바르샤바",
            flagname: "140.Poland.png",
         },
         {
            id: 141,
            en: "Portugal",
            googleMapUrl: "https://www.google.com/maps/place/Portugal",
            en2: "Lisbon",
            cn: "葡萄牙",
            cn_pronunciation: "[pútáoyá]",
            cn2: "里斯本",
            cn2_pronunciation: "[lǐsīběn]",
            jp: "ポルトガル",
            jp2: "リスボン",
            ko: "포르투갈",
            ko2: "리스본",
            flagname: "141.Portugal.png",
         },
         {
            id: 142,
            en: "Qatar",
            googleMapUrl: "https://www.google.com/maps/place/Qatar",
            en2: "Doha",
            cn: "卡塔尔",
            cn_pronunciation: "[kǎtǎěr]",
            cn2: "多哈",
            cn2_pronunciation: "[duōhā]",
            jp: "カタール",
            jp2: "ドーハ",
            ko: "카타르",
            ko2: "도하",
            flagname: "142.Qatar.png",
         },
         {
            id: 143,
            en: "The Republic of China",
            googleMapUrl: "https://www.google.com/maps/place/The Republic of China",
            en2: "Taipei",
            cn: "中華民國",
            cn_pronunciation: "[zhōnghuámínguó]",
            cn2: "台北",
            cn2_pronunciation: "[táiběi]",
            jp: "中華民国",
            jp2: "台北",
            ko: "대만,타이완",
            ko2: "타이베이",
            flagname: "143.The Republic of China.png",
         },
         {
            id: 144,
            en: "The Republic of the Congo",
            googleMapUrl: "https://www.google.com/maps/place/The Republic of the Congo",
            en2: "Brazzaville",
            cn: "刚果共和国",
            cn_pronunciation: "[gāngguǒgònghéguó]",
            cn2: "布拉柴维尔",
            cn2_pronunciation: "[bùlācháiwéiěr]",
            jp: "コンゴ共和国",
            jp2: "ブラザビル",
            ko: "콩고",
            ko2: "브라자빌",
            flagname: "144.The Republic of the Congo.png",
         },
         {
            id: 145,
            en: "Romania",
            googleMapUrl: "https://www.google.com/maps/place/Romania",
            en2: "Bucharest",
            cn: "羅馬尼亞",
            cn_pronunciation: "[luómǎníyà]",
            cn2: "布加勒斯特",
            cn2_pronunciation: "[bùjiālèsītè]",
            jp: "ルーマニア",
            jp2: "ブカレスト",
            ko: "루마니아",
            ko2: "부카레스트",
            flagname: "145.Romania.png",
         },
         {
            id: 146,
            en: "Russia",
            googleMapUrl: "https://www.google.com/maps/place/Russia",
            en2: "Moscow",
            cn: "俄罗斯",
            cn_pronunciation: "[éluósī]",
            cn2: "莫斯科",
            cn2_pronunciation: "[mòsīkē]",
            jp: "ロシア",
            jp2: "モスクワ",
            ko: "러시아",
            ko2: "모스크바",
            flagname: "146.Russia.png",
         },
         {
            id: 147,
            en: "Rwanda",
            googleMapUrl: "https://www.google.com/maps/place/Rwanda",
            en2: "Kigali",
            cn: "卢旺达",
            cn_pronunciation: "[lúwàngdá]",
            cn2: "基加利",
            cn2_pronunciation: "[jījiālì]",
            jp: "ルワンダ",
            jp2: "キガリ",
            ko: "르완다",
            ko2: "키갈리",
            flagname: "147.Rwanda.png",
         },
         {
            id: 148,
            en: "Saint Kitts and Nevis",
            googleMapUrl: "https://www.google.com/maps/place/Saint Kitts and Nevis",
            en2: "Basseterre",
            cn: "聖克里斯多福與尼維斯",
            cn_pronunciation: "[shèngkèlǐsīduōfúyǔníwéisī]",
            cn2: "巴斯特尔",
            cn2_pronunciation: "[bāsītèěr]",
            jp: "セントクリストファー・ネイビス",
            jp2: "バステール",
            ko: "세인트키츠네비스",
            ko2: "바스테르",
            flagname: "148.Saint Kitts and Nevis.png",
         },
         {
            id: 149,
            en: "Saint Lucia",
            googleMapUrl: "https://www.google.com/maps/place/Saint Lucia",
            en2: "Castries",
            cn: "圣卢西亚",
            cn_pronunciation: "[shènglúxīyà]",
            cn2: "卡斯特里",
            cn2_pronunciation: "[kǎsītèlǐ]",
            jp: "セントルシア",
            jp2: "カストリーズ",
            ko: "세인트루시아",
            ko2: "캐스트리스",
            flagname: "149.Saint Lucia.png",
         },
         {
            id: 150,
            en: "Saint Vincent and the Grenadines",
            googleMapUrl: "https://www.google.com/maps/place/Saint Vincent and the Grenadines",
            en2: "Kingstown",
            cn: "圣文森特和格林纳丁斯",
            cn_pronunciation: "[shèngwénsēntèhégélínnàdīngsī]",
            cn2: "金斯敦",
            cn2_pronunciation: "[jīnsīdūn]",
            jp: "セントビンセント・グレナディーン",
            jp2: "キングスタウン",
            ko: "세인트빈센트 그레나딘",
            ko2: "킹스타운",
            flagname: "150.Saint Vincent and the Grenadines.png",
         },
         {
            id: 151,
            en: "Samoa",
            googleMapUrl: "https://www.google.com/maps/place/Samoa",
            en2: "Apia",
            cn: "萨摩亚",
            cn_pronunciation: "[sàmóyà]",
            cn2: "阿皮亚",
            cn2_pronunciation: "[āpíyà]",
            jp: "サモア",
            jp2: "アピーア",
            ko: "사모아",
            ko2: "아피아",
            flagname: "151.Samoa.png",
         },
         {
            id: 152,
            en: "San Marino",
            googleMapUrl: "https://www.google.com/maps/place/San Marino",
            en2: "San Marino",
            cn: "圣马力诺",
            cn_pronunciation: "[shèngmǎlìnuò]",
            cn2: "圣马力诺",
            cn2_pronunciation: "[shèngmǎlìnuò]",
            jp: "サンマリノ",
            jp2: "サン·マリノ",
            ko: "산마리노",
            ko2: "산마리노",
            flagname: "152.San Marino.png",
         },
         {
            id: 153,
            en: "Sao Tome and Principe",
            googleMapUrl: "https://www.google.com/maps/place/Sao Tome and Principe",
            en2: "São Tomé",
            cn: "圣多美和普林西比",
            cn_pronunciation: "[shèngduōměihépǔlínxībǐ]",
            cn2: "圣多美",
            cn2_pronunciation: "[shèngduōměi]",
            jp: "サントメ・プリンシペ",
            jp2: "サントメ",
            ko: "상투메프린시페",
            ko2: "상투메",
            flagname: "153.Sao Tome and Principe.png",
         },
         {
            id: 154,
            en: "Saudi Arabia",
            googleMapUrl: "https://www.google.com/maps/place/Saudi Arabia",
            en2: "Riyadh",
            cn: "沙特阿拉伯",
            cn_pronunciation: "[shātèālābó]",
            cn2: "利雅得",
            cn2_pronunciation: "[lìyǎdé]",
            jp: "サウジアラビア",
            jp2: "リヤド",
            ko: "사우디아라비아",
            ko2: "리야드",
            flagname: "154.Saudi Arabia.png",
         },
         {
            id: 155,
            en: "Senegal",
            googleMapUrl: "https://www.google.com/maps/place/Senegal",
            en2: "Dakar",
            cn: "塞内加尔",
            cn_pronunciation: "[sāinèijiāěr]",
            cn2: "达喀尔",
            cn2_pronunciation: "[dákāěr]",
            jp: "セネガル",
            jp2: "ダカール",
            ko: "세네갈",
            ko2: "다카르",
            flagname: "155.Senegal.png",
         },
         {
            id: 156,
            en: "Serbia",
            googleMapUrl: "https://www.google.com/maps/place/Serbia",
            en2: "Belgrade",
            cn: "塞尔维亚",
            cn_pronunciation: "[sāiěrwéiyà]",
            cn2: "贝尔格莱德",
            cn2_pronunciation: "[bèiěrgéláidé]",
            jp: "セルビア",
            jp2: "ベオグラード",
            ko: "세르비아",
            ko2: "벨그라드",
            flagname: "156.Serbia.png",
         },
         {
            id: 157,
            en: "The Seychelles",
            googleMapUrl: "https://www.google.com/maps/place/The Seychelles",
            en2: "Victoria",
            cn: "塞舌尔",
            cn_pronunciation: "[sāishéěr]",
            cn2: "维多利亚",
            cn2_pronunciation: "[wéiduōlìyà]",
            jp: "セーシェル",
            jp2: "ビクトリア",
            ko: "세이셸",
            ko2: "빅토리아",
            flagname: "157.The Seychelles.png",
         },
         {
            id: 158,
            en: "Sierra Leone",
            googleMapUrl: "https://www.google.com/maps/place/Sierra Leone",
            en2: "Freetown",
            cn: "塞拉利昂",
            cn_pronunciation: "[sāilālìáng]",
            cn2: "弗里敦",
            cn2_pronunciation: "[fúlǐdūn]",
            jp: "シエラレオネ",
            jp2: "フリータウン",
            ko: "시에라리온",
            ko2: "프리타운",
            flagname: "158.Sierra Leone.png",
         },
         {
            id: 159,
            en: "Singapore",
            googleMapUrl: "https://www.google.com/maps/place/Singapore",
            en2: "Singapore",
            cn: "新加坡",
            cn_pronunciation: "[xīnjiāpō]",
            cn2: "新加坡",
            cn2_pronunciation: "[xīnjiāpō]",
            jp: "シンガポール",
            jp2: "シンガポール",
            ko: "싱가포르",
            ko2: "싱가포르",
            flagname: "159.Singapore.png",
         },
         {
            id: 160,
            en: "Slovakia",
            googleMapUrl: "https://www.google.com/maps/place/Slovakia",
            en2: "Bratislava",
            cn: "斯洛伐克",
            cn_pronunciation: "[sīluòfákè]",
            cn2: "布拉迪斯拉发",
            cn2_pronunciation: "[bùlādísīlāfā]",
            jp: "スロバキア",
            jp2: "ブラチスラバ",
            ko: "슬로바키아",
            ko2: "브라티슬라바",
            flagname: "160.Slovakia.png",
         },
         {
            id: 161,
            en: "Slovenia",
            googleMapUrl: "https://www.google.com/maps/place/Slovenia",
            en2: "Ljubljana",
            cn: "斯洛文尼亚",
            cn_pronunciation: "[sīluòwénníyà]",
            cn2: "卢布尔雅那",
            cn2_pronunciation: "[lúbùěryǎnà]",
            jp: "スロベニア",
            jp2: "リュブリャナ",
            ko: "슬로베니아",
            ko2: "류블랴나",
            flagname: "161.Slovenia.png",
         },
         {
            id: 162,
            en: "The Solomon Islands",
            googleMapUrl: "https://www.google.com/maps/place/The Solomon Islands",
            en2: "Honiara",
            cn: "所罗门群岛",
            cn_pronunciation: "[suǒluóménqúndǎo]",
            cn2: "霍尼亚拉",
            cn2_pronunciation: "[huòníyàlā]",
            jp: "ソロモン諸島",
            jp2: "ホニアラ",
            ko: "솔로몬",
            ko2: "호니아라",
            flagname: "162.The Solomon Islands.png",
         },
         {
            id: 163,
            en: "Somalia",
            googleMapUrl: "https://www.google.com/maps/place/Somalia",
            en2: "Mogadishu",
            cn: "索马里",
            cn_pronunciation: "[suǒmǎlǐ]",
            cn2: "摩加迪沙",
            cn2_pronunciation: "[mójiādíshā]",
            jp: "ソマリア",
            jp2: "モガディシュ",
            ko: "소말리아",
            ko2: "모가디슈",
            flagname: "163.Somalia.png",
         },
         {
            id: 164,
            en: "South Africa",
            googleMapUrl: "https://www.google.com/maps/place/South Africa",
            en2: "Pretoria",
            cn: "南非",
            cn_pronunciation: "[nánfēi]",
            cn2: "比勒陀利亚",
            cn2_pronunciation: "[bǐlètuólìyà]",
            jp: "南アフリカ共和国",
            jp2: "プレトリア",
            ko: "남아프리카 공화국",
            ko2: "프리토리아",
            flagname: "164.South Africa.png",
         },
         {
            id: 165,
            en: "South Korea",
            googleMapUrl: "https://www.google.com/maps/place/South Korea",
            en2: "Seoul",
            cn: "大韩民国",
            cn_pronunciation: "[dàhánmínguó]",
            cn2: "首尔",
            cn2_pronunciation: "[Shǒu’ěr]",
            jp: "大韓民国",
            jp2: "ソウル",
            ko: "대한민국, 한국",
            ko2: "서울",
            flagname: "165.South Korea.png",
         },
         {
            id: 166,
            en: "South Sudan",
            googleMapUrl: "https://www.google.com/maps/place/South Sudan",
            en2: "Juba",
            cn: "蘇丹",
            cn_pronunciation: "[sūdān]",
            cn2: "朱巴",
            cn2_pronunciation: "[zhūbā]",
            jp: "スーダン",
            jp2: "ジュバ",
            ko: "남수단",
            ko2: "주바",
            flagname: "166.South Sudan.png",
         },
         {
            id: 167,
            en: "Spain",
            googleMapUrl: "https://www.google.com/maps/place/Spain",
            en2: "Madrid",
            cn: "西班牙",
            cn_pronunciation: "[xībānyá]",
            cn2: "马德里",
            cn2_pronunciation: "[mǎdélǐ]",
            jp: "スペイン",
            jp2: "マドリード",
            ko: "스페인",
            ko2: "마드리드",
            flagname: "167.Spain.png",
         },
         {
            id: 168,
            en: "Sri Lanka",
            googleMapUrl: "https://www.google.com/maps/place/Sri Lanka",
            en2: "Colombo",
            cn: "斯里蘭卡",
            cn_pronunciation: "[sīlǐlánkǎ]",
            cn2: "科伦坡",
            cn2_pronunciation: "[kēlúnpō]",
            jp: "スリランカ",
            jp2: "コロンボ",
            ko: "스리랑카",
            ko2: "콜롬보",
            flagname: "168.Sri Lanka.png",
         },
         {
            id: 169,
            en: "Sudan",
            googleMapUrl: "https://www.google.com/maps/place/Sudan",
            en2: "Khartoum",
            cn: "苏丹共和国",
            cn_pronunciation: "[sūdāngònghéguó]",
            cn2: "喀土穆",
            cn2_pronunciation: "[kātǔmù]",
            jp: "スーダン",
            jp2: "ハルツーム",
            ko: "수단",
            ko2: "카르툼",
            flagname: "169.Sudan.png",
         },
         {
            id: 170,
            en: "Suriname",
            googleMapUrl: "https://www.google.com/maps/place/Suriname",
            en2: "Paramaribo",
            cn: "蘇利南",
            cn_pronunciation: "[sūlìnán]",
            cn2: "帕拉马里博",
            cn2_pronunciation: "[pàlāmǎlǐbó]",
            jp: "スリナム",
            jp2: "パラマリボ",
            ko: "수리남",
            ko2: "파라마리보",
            flagname: "170.Suriname.png",
         },
         {
            id: 171,
            en: "Swaziland",
            googleMapUrl: "https://www.google.com/maps/place/Swaziland",
            en2: "Mbabane",
            cn: "斯威士兰",
            cn_pronunciation: "[sīwēishìlán]",
            cn2: "姆巴巴内",
            cn2_pronunciation: "[mǔbābānèi]",
            jp: "スワジランド",
            jp2: "ムババネ",
            ko: "스와질란드",
            ko2: "음바바네",
            flagname: "171.Swaziland.png",
         },
         {
            id: 172,
            en: "Sweden",
            googleMapUrl: "https://www.google.com/maps/place/Sweden",
            en2: "Stockholm",
            cn: "瑞典",
            cn_pronunciation: "[ruìdiǎn]",
            cn2: "斯德哥尔摩",
            cn2_pronunciation: "[sīdégēěrmó]",
            jp: "スウェーデン",
            jp2: "ストックホルム",
            ko: "스웨덴",
            ko2: "스톡홀름",
            flagname: "172.Sweden.png",
         },
         {
            id: 173,
            en: "Switzerland",
            googleMapUrl: "https://www.google.com/maps/place/Switzerland",
            en2: "Bern",
            cn: "瑞士",
            cn_pronunciation: "[ruìshì]",
            cn2: "伯尔尼",
            cn2_pronunciation: "[bóěrní]",
            jp: "スイス",
            jp2: "ベルン",
            ko: "스위스",
            ko2: "베른",
            flagname: "173.Switzerland.png",
         },
         {
            id: 174,
            en: "Syria",
            googleMapUrl: "https://www.google.com/maps/place/Syria",
            en2: "Damascus",
            cn: "叙利亚",
            cn_pronunciation: "[xùlìyà]",
            cn2: "大马士革",
            cn2_pronunciation: "[dàmǎshìgé]",
            jp: "シリア",
            jp2: "ダマスカス",
            ko: "시리아",
            ko2: "다마스쿠스",
            flagname: "174.Syria.png",
         },
         {
            id: 175,
            en: "Tajikistan",
            googleMapUrl: "https://www.google.com/maps/place/Tajikistan",
            en2: "Dushanbe",
            cn: "塔吉克斯坦",
            cn_pronunciation: "[tǎjíkèsītǎn]",
            cn2: "杜尚别",
            cn2_pronunciation: "[dùshàngbié]",
            jp: "タジキスタン",
            jp2: "ドゥシャンベ",
            ko: "타지키스탄",
            ko2: "두샨베",
            flagname: "175.Tajikistan.png",
         },
         {
            id: 176,
            en: "Tanzania",
            googleMapUrl: "https://www.google.com/maps/place/Tanzania",
            en2: "Dodoma",
            cn: "坦桑尼亚",
            cn_pronunciation: "[tǎnsāngníyà]",
            cn2: "多多马",
            cn2_pronunciation: "[duōduōmǎ]",
            jp: "タンザニア",
            jp2: "ドドマ",
            ko: "탄자니아",
            ko2: "도도마",
            flagname: "176.Tanzania.png",
         },
         {
            id: 177,
            en: "Thailand",
            googleMapUrl: "https://www.google.com/maps/place/Thailand",
            en2: "Bangkok",
            cn: "泰国",
            cn_pronunciation: "[tàiguó]",
            cn2: "曼谷",
            cn2_pronunciation: "[màngǔ]",
            jp: "タイ",
            jp2: "バンコク",
            ko: "태국",
            ko2: "방콕",
            flagname: "177.Thailand.png",
         },
         {
            id: 178,
            en: "Togo",
            googleMapUrl: "https://www.google.com/maps/place/Togo",
            en2: "Lomé",
            cn: "多哥",
            cn_pronunciation: "[duōgē]",
            cn2: "洛美",
            cn2_pronunciation: "[luòměi]",
            jp: "トーゴ",
            jp2: "ロメ",
            ko: "토고",
            ko2: "로메",
            flagname: "178.Togo.png",
         },
         {
            id: 179,
            en: "Tonga",
            googleMapUrl: "https://www.google.com/maps/place/Tonga",
            en2: "Nuku'alofa",
            cn: "東加",
            cn_pronunciation: "[dōngjiā]",
            cn2: "努库阿洛法",
            cn2_pronunciation: "[nǔkùāluòfǎ]",
            jp: "トンガ",
            jp2: "ヌクアロファ",
            ko: "통가",
            ko2: "누쿠알로파",
            flagname: "179.Tonga.png",
         },
         {
            id: 180,
            en: "Trinidad and Tobago",
            googleMapUrl: "https://www.google.com/maps/place/Trinidad and Tobago",
            en2: "Port of Spain",
            cn: "千里達及托巴哥",
            cn_pronunciation: "[qiānlǐdájítuōbāgē]",
            cn2: "西班牙港",
            cn2_pronunciation: "[xībānyágǎng]",
            jp: "トリニダード・トバゴ",
            jp2: "ポートオブスペイン",
            ko: "트리니다드 토바고",
            ko2: "포트오브스페인",
            flagname: "180.Trinidad and Tobago.png",
         },
         {
            id: 181,
            en: "Tunisia",
            googleMapUrl: "https://www.google.com/maps/place/Tunisia",
            en2: "Tunis",
            cn: "突尼西亞",
            cn_pronunciation: "[tūníxīyà]",
            cn2: "突尼斯",
            cn2_pronunciation: "[tūnísī]",
            jp: "チュニジア",
            jp2: "チュニス",
            ko: "튀니지",
            ko2: "튀니스",
            flagname: "181.Tunisia.png",
         },
         {
            id: 182,
            en: "Turkey",
            googleMapUrl: "https://www.google.com/maps/place/Turkey",
            en2: "Ankara",
            cn: "土耳其",
            cn_pronunciation: "[tǔěrqí]",
            cn2: "安卡拉",
            cn2_pronunciation: "[ānkǎlā]",
            jp: "トルコ",
            jp2: "アンカラ",
            ko: "터키",
            ko2: "앙카라",
            flagname: "182.Turkey.png",
         },
         {
            id: 183,
            en: "Turkmenistan",
            googleMapUrl: "https://www.google.com/maps/place/Turkmenistan",
            en2: "Ashgabat",
            cn: "土库曼斯坦",
            cn_pronunciation: "[tǔkùmànsītǎn]",
            cn2: "阿什哈巴德",
            cn2_pronunciation: "[āshíhābādé]",
            jp: "トルクメニスタン",
            jp2: "アシガバート",
            ko: "투르크메니스탄",
            ko2: "아스가바트",
            flagname: "183.Turkmenistan.png",
         },
         {
            id: 184,
            en: "Tuvalu",
            googleMapUrl: "https://www.google.com/maps/place/Tuvalu",
            en2: "Funafuti",
            cn: "圖瓦盧",
            cn_pronunciation: "[túwǎlú]",
            cn2: "富纳富提",
            cn2_pronunciation: "[fùnàfùtí]",
            jp: "ツバル",
            jp2: "フナフティ",
            ko: "투발루",
            ko2: "푸나푸티",
            flagname: "184.Tuvalu.png",
         },
         {
            id: 185,
            en: "Uganda",
            googleMapUrl: "https://www.google.com/maps/place/Uganda",
            en2: "Kampala",
            cn: "乌干达",
            cn_pronunciation: "[wūgàndá]",
            cn2: "坎帕拉",
            cn2_pronunciation: "[kǎnpàlā]",
            jp: "ウガンダ",
            jp2: "カンパラ",
            ko: "우간다",
            ko2: "캄팔라",
            flagname: "185.Uganda.png",
         },
         {
            id: 186,
            en: "Ukraine",
            googleMapUrl: "https://www.google.com/maps/place/Ukraine",
            en2: "Kiev (Kyiv)",
            cn: "乌克兰",
            cn_pronunciation: "[wūkèlán]",
            cn2: "基辅（基辅）",
            cn2_pronunciation: "[jīfǔ（jīfǔ）]",
            jp: "ウクライナ",
            jp2: "キエフ（キエフ）",
            ko: "우크라이나",
            ko2: "키예프",
            flagname: "186.Ukraine.png",
         },
         {
            id: 187,
            en: "The United Arab Emirates",
            googleMapUrl: "https://www.google.com/maps/place/The United Arab Emirates",
            en2: "Abu Dhabi",
            cn: "阿拉伯联合酋长国",
            cn_pronunciation: "[ālābóliánhéqiúzhǎngguó]",
            cn2: "阿布扎比",
            cn2_pronunciation: "[ābùzhābǐ]",
            jp: "アラブ首長国連邦",
            jp2: "アブダビ",
            ko: "아랍에미리트",
            ko2: "아부다비",
            flagname: "187.The United Arab Emirates.png",
         },
         {
            id: 188,
            en: "The United Kingdom",
            googleMapUrl: "https://www.google.com/maps/place/The United Kingdom",
            en2: "London",
            cn: "英国",
            cn_pronunciation: "[yīngguó]",
            cn2: "伦敦",
            cn2_pronunciation: "[lúndūn]",
            jp: "イギリス",
            jp2: "ロンドン",
            ko: "영국",
            ko2: "런던",
            flagname: "188.The United Kingdom.png",
         },
         {
            id: 189,
            en: "The United States",
            googleMapUrl: "https://www.google.com/maps/place/The United States",
            en2: "Washington, District of Columbia",
            cn: "美国",
            cn_pronunciation: "[měiguó]",
            cn2: "华盛顿，哥伦比亚特区",
            cn2_pronunciation: "[huáshèngdùn，gēlúnbǐyàtèqū]",
            jp: "アメリカ合衆国",
            jp2: "ワシントン、コロンビア特別区",
            ko: "미국",
            ko2: "워싱턴 DC",
            flagname: "189.The United States.png",
         },
         {
            id: 190,
            en: "Uruguay",
            googleMapUrl: "https://www.google.com/maps/place/Uruguay",
            en2: "Montevideo",
            cn: "乌拉圭",
            cn_pronunciation: "[wūlāguī]",
            cn2: "蒙得维的亚",
            cn2_pronunciation: "[méngdéwéideyà]",
            jp: "ウルグアイ",
            jp2: "モンテビデオ",
            ko: "우루과이",
            ko2: "몬테비데오",
            flagname: "190.Uruguay.png",
         },
         {
            id: 191,
            en: "Uzbekistan",
            googleMapUrl: "https://www.google.com/maps/place/Uzbekistan",
            en2: "Tashkent",
            cn: "乌兹别克斯坦",
            cn_pronunciation: "[wūzībiékèsītǎn]",
            cn2: "塔什干",
            cn2_pronunciation: "[tǎshígàn]",
            jp: "ウズベキスタン",
            jp2: "タシケント",
            ko: "우즈베키스탄",
            ko2: "타슈켄트",
            flagname: "191.Uzbekistan.png",
         },
         {
            id: 192,
            en: "Vanuatu",
            googleMapUrl: "https://www.google.com/maps/place/Vanuatu",
            en2: "Port Vila",
            cn: "瓦努阿图",
            cn_pronunciation: "[wǎnǔātú]",
            cn2: "维拉港",
            cn2_pronunciation: "[wéilāgǎng]",
            jp: "バヌアツ",
            jp2: "ポートビラ",
            ko: "바누아투",
            ko2: "포트빌라",
            flagname: "192.Vanuatu.png",
         },
         {
            id: 193,
            en: "The Vatican City",
            googleMapUrl: "https://www.google.com/maps/place/The Vatican City",
            en2: "Vatican City",
            cn: "梵蒂冈",
            cn_pronunciation: "[fàndìgāng]",
            cn2: "梵蒂冈城",
            cn2_pronunciation: "[fàndìgāngchéng]",
            jp: "バチカン",
            jp2: "バチカン市国",
            ko: "바티칸시국",
            ko2: "바티칸 시",
            flagname: "193.The Vatican City.png",
         },
         {
            id: 194,
            en: "Venezuela",
            googleMapUrl: "https://www.google.com/maps/place/Venezuela",
            en2: "Caracas",
            cn: "委內瑞拉",
            cn_pronunciation: "[wěinàruìlā]",
            cn2: "加拉加斯",
            cn2_pronunciation: "[jiālājiāsī]",
            jp: "ベネズエラ",
            jp2: "カラカス",
            ko: "베네수엘라",
            ko2: "카라카스",
            flagname: "194.Venezuela.png",
         },
         {
            id: 195,
            en: "Vietnam",
            googleMapUrl: "https://www.google.com/maps/place/Vietnam",
            en2: "Hanoi",
            cn: "越南",
            cn_pronunciation: "[yuènán]",
            cn2: "河内",
            cn2_pronunciation: "[hénèi]",
            jp: "ベトナム",
            jp2: "ハノイ",
            ko: "베트남",
            ko2: "하노이",
            flagname: "195.Vietnam.png",
         },
         {
            id: 196,
            en: "Western Sahara",
            googleMapUrl: "https://www.google.com/maps/place/Western Sahara",
            en2: "Laayoune",
            cn: "西撒哈拉",
            cn_pronunciation: "[xīsāhālā]",
            cn2: "阿尤恩",
            cn2_pronunciation: "[āyóuēn]",
            jp: "西サハラ",
            jp2: "Laayoune",
            ko: "서사하라",
            ko2: "엘아이운",
            flagname: "196.Western Sahara.png",
         },
         {
            id: 197,
            en: "Yemen",
            googleMapUrl: "https://www.google.com/maps/place/Yemen",
            en2: "Sanaá",
            cn: "也门",
            cn_pronunciation: "[yěmén]",
            cn2: "萨那",
            cn2_pronunciation: "[sànà]",
            jp: "イエメン",
            jp2: "サヌア",
            ko: "예멘",
            ko2: "사나",
            flagname: "197.Yemen.png",
         },
         {
            id: 198,
            en: "Zambia",
            googleMapUrl: "https://www.google.com/maps/place/Zambia",
            en2: "Lusaka",
            cn: "赞比亚",
            cn_pronunciation: "[zànbǐyà]",
            cn2: "卢萨卡",
            cn2_pronunciation: "[lúsàkǎ]",
            jp: "ザンビア",
            jp2: "ルサカ",
            ko: "잠비아",
            ko2: "루사카",
            flagname: "198.Zambia.png",
         },
         {
            id: 199,
            en: "Zimbabwe",
            googleMapUrl: "https://www.google.com/maps/place/Zimbabwe",
            en2: "Harare",
            cn: "辛巴威",
            cn_pronunciation: "[xīnbāwēi]",
            cn2: "哈拉雷",
            cn2_pronunciation: "[hālāléi]",
            jp: "ジンバブエ",
            jp2: "ハラレ",
            ko: "짐바브웨",
            ko2: "하라레",
            flagname: "199.Zimbabwe.png",
         }
      ]
   }
]

   export default LANGS_MASTER_DATA_MATERIAL; 
