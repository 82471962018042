import React from "react";

// reactstrap components
import {Container, Row, Col } from "reactstrap";

// core components

function StandardFooter01() {
  return (
    <>
      <footer className="footer footer-black footer-big">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="4" sm="4">
              <div className="logo text-center">
                <h3>삼랑</h3>
              </div>
            </Col>
            <Col className="offset-md-2" md="6" sm="8">
              <div className="links">                               
                <div className="copyright">
                  <div className="pull-left">
                    © {new Date().getFullYear()}, {" "}
                    회사명 : 삼랑스쿨  l  사업자등록번호 : 415-11-61919  l  대표 : 허준
                  </div>
                  <div className="pull-left">                    
                    TEL : 02-3332-3194  l  이메일 무단수집거부

                  </div>                  
                  <div className="pull-right">
                    <ul>
                      <li className="mr-1">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          이용약관
                        </a>
                      </li>
                      |{" "}
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          개인정보처리방침
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default StandardFooter01;
