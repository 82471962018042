import React from "react";
// import { useState } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  // CardFooter,
  CardTitle,
  // Label,
  // FormGroup,
  // Input,
  Table,
  Container,
  Row,
  Col  
} from "reactstrap";

// core components

// react-redux
import { connect } from 'react-redux'
import { selectSubcategory } from '../../../redux'
import { selectViewstyle } from '../../../redux'
import { selectEN_Voicecategory } from '../../../redux'
import { selectCN_Voicecategory } from '../../../redux'
import { selectJP_Voicecategory } from '../../../redux'
import { selectKO_Voicecategory } from '../../../redux'

// james added
import LANGS_MASTER_DATA from './langs_master.data';

const collections = LANGS_MASTER_DATA
// const myfilter = collections.filter(collection => collection.routeName === 'sharedkitchen');    



function Display04_sub_03(props) {  
  // console.log(`${category}`) 
  // console.log(`${subcategory}`) 
  const {subcategory} = props  
  const {en_voicecategory} = props  
  const {cn_voicecategory} = props  
  const {jp_voicecategory} = props  
  const {ko_voicecategory} = props  

  const myfilter = collections.filter(collection => collection.title === `${subcategory}`);      

  const { speak, voices } = useSpeechSynthesis();
  const enVoices = voices.filter(collection => collection.lang.includes('en'));
  const cnVoices = voices.filter(collection => collection.lang.includes('zh'));
  const jpVoices = voices.filter(collection => collection.lang.includes('ja'));
  const koVoices = voices.filter(collection => collection.lang.includes('ko'));  

  return (
    <>
      {/* <div className="section section-feature cd-section" id="features"> */}
      {/* cost 1 - simple table */}
      {/* <div className="features-1"> */}

      <Container>

          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">초등학교 단어</h2>
              <h5 className="description">
              {myfilter[0].title}
              </h5>
            </Col>
          </Row>
          {/* <br/> */}

          <Row>        
          <Table responsive>
          <thead>
                  <tr>
                    {/* <th className="text-center">#</th> */}
                    <th colspan="3" className="text-right">영어</th>
                    <th colspan="3" className="text-right">중국어</th>
                    <th colspan="3" className="text-right">일본어</th>
                    <th colspan="3" className="text-right">한국어</th>
                  </tr>
          </thead>
          </Table>
          {
            
            myfilter[0].items.map(
              item => (                

                <>
                  <Col md="3">
                    <Card className="card-pricing" data-color="orange">
                    <CardBody>
                        <CardTitle tag="h4">{item.en}<br/>&nbsp;</CardTitle>
                    </CardBody>
                    <Button
                      className="btn-link"
                      color="primary"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.en}`, voice: enVoices[ `${en_voicecategory.value}`]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button>                       
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card className="card-pricing" data-color="yellow">
                    <CardBody>
                        <CardTitle tag="h4">{item.cn}<br/>{item.cn_pronunciation}</CardTitle>
                    </CardBody>
                    <Button
                      className="btn-link"
                      color="danger"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.cn}`, voice: cnVoices[ `${cn_voicecategory.value}`]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button>                       
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card className="card-pricing" data-color="green">
                    <CardBody>
                        <CardTitle tag="h4">{item.jp}<br/>{item.jp_pronunciation}</CardTitle>
                    </CardBody>
                       
                    <Button
                      className="btn-link"
                      color="danger"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.jp}`, voice: jpVoices[ `${jp_voicecategory.value}`]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button>  
                    </Card>
                  </Col>

                  <Col md="3">
                    <Card className="card-pricing" data-color="blue">
                    <CardBody>
                        <CardTitle tag="h4">{item.ko}<br/>&nbsp;</CardTitle>                     
                    </CardBody>
                    <Button
                      className="btn-link"
                      color="danger"
                      // href="#pablo"

                      onClick={() => speak({ text:`${item.ko}`, voice: koVoices[ `${ko_voicecategory.value}`]})}
                      
                    >
                      <i className="nc-icon nc-button-play" />
                    </Button>                       
                    </Card>
                  </Col>            
                                       
                 </>  

              )
            )
          }
          </Row>
        </Container>
                {/* </div> */}
        {/* ********* END FEATURES 1 ********* */}
      {/* </div> */}
    </>
  );
}

// export default Display06_sub;


const mapStateToProps = state => {
  return {
      subcategory: state.elementary.subcategory,
      viewstyle: state.style.viewstyle,
      en_voicecategory : state.en_voice.en_voicecategory,
      cn_voicecategory : state.cn_voice.cn_voicecategory,
      jp_voicecategory : state.jp_voice.jp_voicecategory,
      ko_voicecategory : state.ko_voice.ko_voicecategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  selectSubcategory: (string) => dispatch(selectSubcategory(string)),
  selectViewstyle: (string) => dispatch(selectViewstyle(string)),
  selectEN_Voicecategory: (string) => dispatch(selectEN_Voicecategory(string)),
  selectCN_Voicecategory: (string) => dispatch(selectCN_Voicecategory(string)),
  selectJP_Voicecategory: (string) => dispatch(selectJP_Voicecategory(string)),
  selectKO_Voicecategory: (string) => dispatch(selectKO_Voicecategory(string)),
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Display04_sub_03)