
import { SELECT_VIEWSTYLE } from './styleTypes'

const initialState = {
    viewstyle: 'default'
}

const styleReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_VIEWSTYLE:
            return {
            ...state,
            viewstyle: action.payload
            }        
                
        default: return state
    }
}

export default styleReducer