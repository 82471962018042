import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// webSpeech api
import { useSpeechSynthesis } from 'react-speech-kit';
// reactstrap components
import {
  Button,  
  // Label,
  FormGroup,
  // Input,
  // Table,
  Container,
  Row,
  Col  
} from "reactstrap";

// react-redux
import { connect } from 'react-redux'
import { selectSubcategory } from '../../../redux'
import { selectViewstyle } from '../../../redux'
import { selectEN_Voicecategory } from '../../../redux'
import { selectCN_Voicecategory } from '../../../redux'
import { selectJP_Voicecategory } from '../../../redux'
import { selectKO_Voicecategory } from '../../../redux'

// core components

// Display
import Display04_sub_01 from "components/3langschool/Display/Display04_sub_01.js";
import Display04_sub_02 from "components/3langschool/Display/Display04_sub_02.js";
import Display04_sub_03 from "components/3langschool/Display/Display04_sub_03.js";
import Display04_sub_default from "components/3langschool/Display/Display04_sub_default.js";


// james added
import LANGS_MASTER_DATA from './langs_master.data';

const collections = LANGS_MASTER_DATA
// const myfilter = collections.filter(collection => collection.routeName === 'sharedkitchen');    

function Display04_main(props) {
  
  const { voices, supported } = useSpeechSynthesis();
  const enVoices = voices.filter(collection => collection.lang.includes('en'));
  const cnVoices = voices.filter(collection => collection.lang.includes('zh'));
  const jpVoices = voices.filter(collection => collection.lang.includes('ja'));
  const koVoices = voices.filter(collection => collection.lang.includes('ko'));

  const {subcategory, viewstyle} = props
  const {en_voicecategory} = props  
  const {cn_voicecategory} = props  
  const {jp_voicecategory} = props  
  const {ko_voicecategory} = props  
  // let { title} = useParams(); 
  // console.log('props subcategory ' +  `${subcategory}`) 
  // console.log('props viewstyle ' +  `${viewstyle}`) 
  // console.log('category is ' +  `${category}`) 
  // console.log('subcategory is ' + `${subcategory}`) 
  // const myfilter = collections.filter(collection => collection.routeName === `${category}`);        
  // const myfilter = collections.filter(collection => collection.title === `$number1`);        
  
  // console.log('en_voicecategory.value is ' + `${en_voicecategory.value}`) 
  // console.log('cn_voicecategory.value is ' + `${cn_voicecategory.value}`) 
  // console.log('cnVoices ' + cnVoices[ `${cn_voicecategory.value}`]) 
  // const cnvoice =  cnVoices[0]
  // console.log('cnVoice lang ' + cnvoice.lang) 


  return (
    
    <>    
    
    {/* <div className="section section-buttons section-gray"> */}
    <div className="section section-buttons section-gray">
      <Container>

        <Row>
        {/* <Col>
          <li> cnvoice lang: {cnvoice[0].name}, cnvoice name: {cnvoice[0].name}</li>
        </Col> */}
        {/* <Col>
                   {
                    cnVoices.map(
            (voice, index) => (
              <li>id : {index} - lang : {voice.lang} - name :  {voice.name}</li>
              
            )
          )
        } 
        </Col> */}
        {/* <Col>
          <h5>supported is {supported}</h5>
        </Col>
        <Col>
                  {
          voices.map(
            (voice, index) => (
              <li>id : {index} - lang : {voice.lang} - name :  {voice.name}</li>
              
            )
          )
        }
        </Col> */}

        <Col className="ml-auto mr-auto" md="4" sm="6" xs="12">
        <h6>음성지원 : 데스크톱 - Google Chrome, MS Edge, Firefox</h6>
        <h6>음성지원 : 모바일 - IPhone</h6>
              <FormGroup>
                <Select
                  menuPlacement="top"
                  className="react-select"
                  classNamePrefix="react-select"
                  name="en_voicecategory"
                  // value={en_voicecategory}
                  placeholder="영어 음성을 선택해주세요"
                  onChange={(e) => props.selectEN_Voicecategory(e)}

                  options={
                    enVoices.map(
                      (voice, index) => (                       
                       {"value" : index, "label": voice.name}
                      )
                    )
                  }  
                />
              </FormGroup>
              <FormGroup>
                <Select
                  menuPlacement="top"
                  className="react-select"
                  classNamePrefix="react-select"
                  name="cn_voicecategory"
                  // value={cn_voicecategory.value}
                  placeholder="중국어 음성을 선택해주세요"
                  onChange={(e) => props.selectCN_Voicecategory(e)}

                  options={
                    cnVoices.map(
                      (voice, index) => (                       
                       {"value" : index, "label": voice.name}
                      )
                    )
                  }  
                />
              </FormGroup>                 
              <FormGroup>
                <Select
                  menuPlacement="top"
                  className="react-select"
                  classNamePrefix="react-select"
                  name="jp_voicecategory"
                  // value={en_voicecategory}
                  placeholder="일본어 음성을 선택해주세요"
                  onChange={(e) => props.selectJP_Voicecategory(e)}

                  options={
                    jpVoices.map(
                      (voice, index) => (                       
                       {"value" : index, "label": voice.name}
                      )
                    )
                  }  
                />
              </FormGroup>   
              <FormGroup>
                <Select
                  menuPlacement="top"
                  className="react-select"
                  classNamePrefix="react-select"
                  name="ko_voicecategory"
                  // value={en_voicecategory}
                  placeholder="한국어 음성을 선택해주세요"
                  onChange={(e) => props.selectKO_Voicecategory(e)}

                  options={
                    koVoices.map(
                      (voice, index) => (                       
                       {"value" : index, "label": voice.name}
                      )
                    )
                  }  
                />
              </FormGroup>                 
            </Col>

          <Col className="ml-auto mr-auto text-center" md="8">
          
            <div className="title">
                    <h2>View Style</h2>
            </div>
              <Button color="info" type="button" className="mr-1"
              onClick={() => props.selectViewstyle('table')}
              >
                Table
              </Button>
              <Button color="info" type="button" className="mr-1"
              onClick={() => props.selectViewstyle('no border')}
              >
                No border
              </Button>
              <Button color="info" type="button" className="mr-1"
              onClick={() => props.selectViewstyle('card')}
              >
                Card
              </Button>  
          </Col>
            
        </Row>

      </Container>
    </div>

    
          
    
    
    
    {/* {console.log('before switch case ' +  `${viewstyle}`) }             */}
        {(() => {
  
        switch (`${viewstyle}`) {
          case 'table':
            
              return (                
                <Display04_sub_01 />
              )
          case 'no border':            
              return (
                <Display04_sub_02 />
              )
          case 'card':            
                return (                  
                  <Display04_sub_03 />
                )             
          default:
              return (
                <Display04_sub_default />
              )
              
        }

      })()}
      {/* {console.log('after switch case ' +  `${viewstyle}`) }             */}
    </>


 
    
    // <Display04_sub subcategory={subcategory}/>  
    // <Display05_sub subcategory={subcategory}/>  
    // <Display06_sub subcategory={subcategory}/>  
    // <Display04_sub subcategory={'number1'}/>  
    
    // <Display04_sub category={'number'}/>  
    // <Display04_sub category={'number'}/>  
    
    
     
  );
}

// export default Display04_main;


const mapStateToProps = state => {
  return {
      subcategory: state.elementary.subcategory,
      viewstyle: state.style.viewstyle,
      en_voicecategory : state.en_voice.en_voicecategory,
      cn_voicecategory : state.cn_voice.cn_voicecategory,
      jp_voicecategory : state.jp_voice.jp_voicecategory,
      ko_voicecategory : state.ko_voice.ko_voicecategory
  }
}

const mapDispatchToProps = dispatch => {
  return {
  selectSubcategory: (string) => dispatch(selectSubcategory(string)),
  selectViewstyle: (string) => dispatch(selectViewstyle(string)),
  selectEN_Voicecategory: (string) => dispatch(selectEN_Voicecategory(string)),
  selectCN_Voicecategory: (string) => dispatch(selectCN_Voicecategory(string)),
  selectJP_Voicecategory: (string) => dispatch(selectJP_Voicecategory(string)),
  selectKO_Voicecategory: (string) => dispatch(selectKO_Voicecategory(string))
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Display04_main)

