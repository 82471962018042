import { combineReducers } from 'redux'
// import cakeReducer from './cake/cakeReducer'
// import iceCreamReducer from './iceCream/iceCreamReducer'
// import cookiesReducer from './cookies/cookiesReducer'
// import fruitesReducer from './fruites/fruitesReducer'
// import candyReducer from './candy/candyReducer'
// import booksReducer from './books/booksReducers'
// import housesReducer from './houses/housesReducer'
// import userReducer from './user/userReducer'
import elementaryReducer from './elementary/elementaryReducer'
import styleReducer from './style/styleReducer'
import menuReducer from './menu/menuReducer'
import story_menuReducer from './story_menu/story_menuReducer'
import elementary_storyReducer from './story/elementary_storyReducer'
import en_voiceReducer from './en_voice/en_voiceReducer'
import cn_voiceReducer from './cn_voice/cn_voiceReducer'
import jp_voiceReducer from './jp_voice/jp_voiceReducer'
import ko_voiceReducer from './ko_voice/ko_voiceReducer'
import generalReducer from './general/generalReducer'
import general_menuReducer from './general_menu/general_menuReducer'


const rootReducer = combineReducers({
    elementary:  elementaryReducer,
    style:  styleReducer,
    menu: menuReducer,
    story_menu: story_menuReducer,
    story: elementary_storyReducer,
    en_voice: en_voiceReducer,
    cn_voice: cn_voiceReducer,
    jp_voice: jp_voiceReducer,
    ko_voice: ko_voiceReducer,
    general: generalReducer,
    general_menu: general_menuReducer,    
    // cake: cakeReducer,
    // iceCream: iceCreamReducer,
    // cookies: cookiesReducer,
    // fruites: fruitesReducer,
    // candy: candyReducer,
    // books: booksReducer,
    // houses: housesReducer,
    // user: userReducer
})

export default rootReducer