import { SELECT_GENERAL_SUBCATEGORY } from './generalTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

export const selectGeneral_Subcategory = (string) => {
    return {
        type: SELECT_GENERAL_SUBCATEGORY,
        payload: string
    }
}

// export const selectViewstyle = (string) => {
//     return {
//         type: SELECT_VIEWSTYLE,
//         payload: string
//     }
// }