import { SELECT_MENUCATEGORY } from './menuTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

const initialState = {
    menucategory: 'menu_elementary'
}

const menuReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_MENUCATEGORY:
            return {
             ...state,
             menucategory: action.payload
            }
        // case SELECT_VIEWSTYLE:
        //     return {
        //     ...state,
        //     viewstsyle: action.payload
        //     }        
                
        default: return state
    }
}

export default menuReducer