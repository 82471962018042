import { SELECT_STORY_MENUCATEGORY } from './story_menuTypes'
// import { SELECT_VIEWSTYLE } from './elementaryTypes'

const initialState = {
    story_menucategory: 'menu_elementary_story'
}

const story_menuReducer = (state = initialState, action) => {
    switch(action.type) {
        case SELECT_STORY_MENUCATEGORY:
            return {
             ...state,
             story_menucategory: action.payload
            }
        // case SELECT_VIEWSTYLE:
        //     return {
        //     ...state,
        //     viewstsyle: action.payload
        //     }        
                
        default: return state
    }
}

export default story_menuReducer