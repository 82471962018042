const LANGS_MASTER_DATA_STORY = [
   {
      id: 1,
      title: '12 Dancing Princess',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "12 Dancing Princess",
            en_videoUrl: "https://www.youtube.com/embed/UM0zpo0ZKIk",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=UM0zpo0ZKIk",
            cn: "十二个跳舞的公主",
            cn_pronunciation: "[shíèrgètiàowŭdegōngzhŭ]",
            cn_videoUrl: "https://www.youtube.com/embed/zaqW84X2PhA",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=zaqW84X2PhA",
            jp: "12人の踊るプリンセス",
            jp_pronunciation: "[じゅうににんのおどるプリンセス]",
            jp_videoUrl: "https://www.youtube.com/embed/_NPF2RnCcOg",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=_NPF2RnCcOg",
            ko: "춤 추는 열 두 명의 공주",
            ko_videoUrl: "https://www.youtube.com/embed/OXCjsfB8rdY",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=OXCjsfB8rdY",
         }
      ]
   },
   {
      id: 2,
      title: 'Aladdin and the Magic Lamp',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Aladdin and the Magic Lamp",
            en_videoUrl: "https://www.youtube.com/embed/jbxrzaoTCZY",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=jbxrzaoTCZY",
            cn: "阿拉丁神灯",
            cn_pronunciation: "[ālādīngshéndēng]",
            cn_videoUrl: "https://www.youtube.com/embed/k5Ak7seymXk",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=k5Ak7seymXk",
            jp: "アラジンと魔法のランプ",
            jp_pronunciation: "[アラジンとまほうのランプ]",
            jp_videoUrl: "https://www.youtube.com/embed/DNVjxTTiAhY",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=DNVjxTTiAhY",
            ko: "알라딘과 요술 램프",
            ko_videoUrl: "https://www.youtube.com/embed/rdu9uCvjIZo",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=rdu9uCvjIZo",
         }
      ]
   },
   {
      id: 3,
      title: 'Alibaba And 40 Thieves',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Alibaba And 40 Thieves",
            en_videoUrl: "https://www.youtube.com/embed/NMisWjiKQ4Y",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=NMisWjiKQ4Y",
            cn: "阿里巴巴与四十大盗",
            cn_pronunciation: "[ālĭbābāyŭsìshídàdào]",
            cn_videoUrl: "https://www.youtube.com/embed/7qsO1XDqbZw",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=7qsO1XDqbZw",
            jp: "アリババと40人の盗賊",
            jp_pronunciation: "[アリババとよんじゅうにんのとうぞく]",
            jp_videoUrl: "https://www.youtube.com/embed/RL9oJH4mwd8",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=RL9oJH4mwd8",
            ko: "알리바바와 40인의 도둑",
            ko_videoUrl: "https://www.youtube.com/embed/oSETQ4aZfwI",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=oSETQ4aZfwI",
         }
      ]
   },
   {
      id: 4,
      title: 'Alice in Wonderland',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Alice in Wonderland",
            en_videoUrl: "https://www.youtube.com/embed/ttBU-kDINz8",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=ttBU-kDINz8",
            cn: "爱丽丝梦游仙境",
            cn_pronunciation: "[àilìsīmèngyóuxiānjìng]",
            cn_videoUrl: "https://www.youtube.com/embed/wWif5WuB0to",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=wWif5WuB0to",
            jp: "不思議の国のアリス",
            jp_pronunciation: "[ふしぎのくにのアリス]",
            jp_videoUrl: "https://www.youtube.com/embed/Qv_na-bHevo",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=Qv_na-bHevo",
            ko: "이상한 나라의 앨리스",
            ko_videoUrl: "https://www.youtube.com/embed/KUkt2dP8iT4",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=KUkt2dP8iT4",
         }
      ]
   },
   {
      id: 5,
      title: 'Ant And The Grasshopper',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Ant And The Grasshopper",
            en_videoUrl: "https://www.youtube.com/embed/yq_J78PjsBQ",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=yq_J78PjsBQ",
            cn: "蚂蚁和蚂蚱",
            cn_pronunciation: "[mǎyǐhémàzhà]",
            cn_videoUrl: "https://www.youtube.com/embed/N-1DUYhRqLM",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=N-1DUYhRqLM",
            jp: "アリとキリギリス",
            jp_pronunciation: "[アリとキリギリス]",
            jp_videoUrl: "https://www.youtube.com/embed/I113PPBZpJo",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=I113PPBZpJo",
            ko: "개미와 베짱이",
            ko_videoUrl: "https://www.youtube.com/embed/g1FDhA40UCg",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=g1FDhA40UCg",
         }
      ]
   },
   {
      id: 6,
      title: 'Cinderella',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Cinderella",
            en_videoUrl: "https://www.youtube.com/embed/18l0MA4uLas",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=18l0MA4uLas",
            cn: "灰姑娘",
            cn_pronunciation: "[huīgūniáng]",
            cn_videoUrl: "https://www.youtube.com/embed/0LZfmY4IyB0",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=0LZfmY4IyB0",
            jp: "シンデレラ",
            jp_pronunciation: "[シンデレラ]",
            jp_videoUrl: "https://www.youtube.com/embed/wyBZjx2IUA4",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=wyBZjx2IUA4",
            ko: "신데렐라",
            ko_videoUrl: "https://www.youtube.com/embed/j9UY6QZgw0o",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=j9UY6QZgw0o",
         }
      ]
   },
   {
      id: 7,
      title: 'Elves And The Shoe Maker',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Elves And The Shoe Maker",
            en_videoUrl: "https://www.youtube.com/embed/WK0-x4W2Adc",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=WK0-x4W2Adc",
            cn: "鞋匠与小矮人",
            cn_pronunciation: "[xiéjiàngyǔxiǎoǎirén]",
            cn_videoUrl: "https://www.youtube.com/embed/3_AMFePF5uA",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=3_AMFePF5uA",
            jp: "エルフと靴屋さん",
            jp_pronunciation: "[エルフとくつやさん]",
            jp_videoUrl: "https://www.youtube.com/embed/ZlKHMtkvEvo",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=ZlKHMtkvEvo",
            ko: "요정과 구두장이",
            ko_videoUrl: "https://www.youtube.com/embed/ISvtROUM9l0",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=ISvtROUM9l0",
         }
      ]
   },
   {
      id: 8,
      title: 'Fisherman and His Wife',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Fisherman and His Wife",
            en_videoUrl: "https://www.youtube.com/embed/1_BS7UGKZEo",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=1_BS7UGKZEo",
            cn: "渔夫和他的妻子",
            cn_pronunciation: "[yúfūhétādeqīzi]",
            cn_videoUrl: "https://www.youtube.com/embed/HDNBdRKjt8U",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=HDNBdRKjt8U",
            jp: "漁師とその妻",
            jp_pronunciation: "[りょうしとそのつま]",
            jp_videoUrl: "https://www.youtube.com/embed/bxocRibrOh4",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=bxocRibrOh4",
            ko: "어부와 아내",
            ko_videoUrl: "https://www.youtube.com/embed/PSN12an629I",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=PSN12an629I",
         }
      ]
   },
   {
      id: 9,
      title: 'Flying Trunk',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Flying Trunk",
            en_videoUrl: "https://www.youtube.com/embed/b8UlqFjHVQo",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=b8UlqFjHVQo",
            cn: "会飞的箱子",
            cn_pronunciation: "[huìfēidexiāngzi]",
            cn_videoUrl: "https://www.youtube.com/embed/kncjrNu_MW8",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=kncjrNu_MW8",
            jp: "飛ぶトランク",
            jp_pronunciation: "[とぶトランク]",
            jp_videoUrl: "https://www.youtube.com/embed/nEjl1tb3aTU",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=nEjl1tb3aTU",
            ko: "하늘을 나는 트렁크",
            ko_videoUrl: "https://www.youtube.com/embed/Tx3X88f7XLo",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=Tx3X88f7XLo",
         }
      ]
   },
   {
      id: 10,
      title: 'Four Brahmins',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Four Brahmins",
            en_videoUrl: "https://www.youtube.com/embed/gb5Jb7brm6Y",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=gb5Jb7brm6Y",
            cn: "四个婆罗门教徒",
            cn_pronunciation: "[sìgèpóluóménjiàotú]",
            cn_videoUrl: "https://www.youtube.com/embed/DKTh-23BIn8",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=DKTh-23BIn8",
            jp: "４人のブラーミン",
            jp_pronunciation: "[よにんのブラーミン]",
            jp_videoUrl: "https://www.youtube.com/embed/5lelRgPjO-o",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=5lelRgPjO-o",
            ko: "네 명의 브라만",
            ko_videoUrl: "https://www.youtube.com/embed/QFx_lq0YyUA",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=QFx_lq0YyUA",
         }
      ]
   },
   {
      id: 11,
      title: 'Frog Prince in English',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Frog Prince in English",
            en_videoUrl: "https://www.youtube.com/embed/ycuzJ9t380E",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=ycuzJ9t380E",
            cn: "青蛙王子",
            cn_pronunciation: "[qīngwāwángzi]",
            cn_videoUrl: "https://www.youtube.com/embed/eJ0VBNYAbYE",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=eJ0VBNYAbYE",
            jp: "カエルの王子様",
            jp_pronunciation: "[かえるのおうじさま]",
            jp_videoUrl: "https://www.youtube.com/embed/Yzo7L4m38Es",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=Yzo7L4m38Es",
            ko: "개구리 왕자",
            ko_videoUrl: "https://www.youtube.com/embed/T57KkVWZGwc",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=T57KkVWZGwc",
         }
      ]
   },
   {
      id: 12,
      title: 'Golden Bird',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Golden Bird",
            en_videoUrl: "https://www.youtube.com/embed/oMiayRL7Bec",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=oMiayRL7Bec",
            cn: "金鸟",
            cn_pronunciation: "[jīnniăo]",
            cn_videoUrl: "https://www.youtube.com/embed/fBrOz2nKueg",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=fBrOz2nKueg",
            jp: "黄金の鳥",
            jp_pronunciation: "[おうごんのとり]",
            jp_videoUrl: "https://www.youtube.com/embed/a-UUZz9ed30",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=a-UUZz9ed30",
            ko: "황금새",
            ko_videoUrl: "https://www.youtube.com/embed/3qlWOBjon8U",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=3qlWOBjon8U",
         }
      ]
   },
   {
      id: 13,
      title: 'Goose Girl',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Goose Girl",
            en_videoUrl: "https://www.youtube.com/embed/JKrE-f6lrLQ",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=JKrE-f6lrLQ",
            cn: "牧鹅姑娘",
            cn_pronunciation: "[mùégūniáng]",
            cn_videoUrl: "https://www.youtube.com/embed/GRvJDg1NplU",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=GRvJDg1NplU",
            jp: "ガチョウ少女の物語",
            jp_pronunciation: "[ガチョウしょうじょのものがたり]",
            jp_videoUrl: "https://www.youtube.com/embed/WjFrwOPSiFA",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=WjFrwOPSiFA",
            ko: "거위 치는 소녀",
            ko_videoUrl: "https://www.youtube.com/embed/t_TAE688ZKY",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=t_TAE688ZKY",
         }
      ]
   },
   {
      id: 14,
      title: 'Gulliver-s Travels',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Gulliver-s Travels",
            en_videoUrl: "https://www.youtube.com/embed/P9UMGLVJuS4",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=P9UMGLVJuS4",
            cn: "格列佛游记",
            cn_pronunciation: "[gélièfóyóujì]",
            cn_videoUrl: "https://www.youtube.com/embed/1uS_lW-jbIw",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=1uS_lW-jbIw",
            jp: "ガリバー トラベル",
            jp_pronunciation: "[ガリバー トラベル]",
            jp_videoUrl: "https://www.youtube.com/embed/yGsrqPqfLOg",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=yGsrqPqfLOg",
            ko: "걸리버 여행기",
            ko_videoUrl: "https://www.youtube.com/embed/h5vN2PASeE8",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=h5vN2PASeE8",
         }
      ]
   },
   {
      id: 15,
      title: 'Jungle Book',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Jungle Book",
            en_videoUrl: "https://www.youtube.com/embed/0saNEjQECPA",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=0saNEjQECPA",
            cn: "森林王子",
            cn_pronunciation: "[sēnlín wángzǐ]",
            cn_videoUrl: "https://www.youtube.com/embed/hhgaAAZWYWg",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=hhgaAAZWYWg",
            jp: "ジャングルブック",
            jp_pronunciation: "[ジャングルブック]",
            jp_videoUrl: "https://www.youtube.com/embed/jpcP7Skj0kA",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=jpcP7Skj0kA",
            ko: "정글북",
            ko_videoUrl: "https://www.youtube.com/embed/FiTUvcTtAa8",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=FiTUvcTtAa8",
         }
      ]
   },
   {
      id: 16,
      title: 'King Grisly Beard',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "King Grisly Beard",
            en_videoUrl: "https://www.youtube.com/embed/Bc17SdVd5h0",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=Bc17SdVd5h0",
            cn: "国王贵斯里·比尔德",
            cn_pronunciation: "[guówángguìsīlǐ · bǐěrdé]",
            cn_videoUrl: "https://www.youtube.com/embed/jjgG3eykKVs",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=jjgG3eykKVs",
            jp: "ひどい王様",
            jp_pronunciation: "[ひどいおうさま]",
            jp_videoUrl: "https://www.youtube.com/embed/Uqpa1ZEFooE",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=Uqpa1ZEFooE",
            ko: "수염 난 그리즐리 왕",
            ko_videoUrl: "https://www.youtube.com/embed/12vOhhtZWDk",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=12vOhhtZWDk",
         }
      ]
   },
   {
      id: 17,
      title: 'King of Golden Mountain',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "King of Golden Mountain",
            en_videoUrl: "https://www.youtube.com/embed/psqZsoJHzmY",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=psqZsoJHzmY",
            cn: "金山国王",
            cn_pronunciation: "[jīnshānguówáng]",
            cn_videoUrl: "https://www.youtube.com/embed/Dc29FmuslUk",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=Dc29FmuslUk",
            jp: "ゴールデンマウンテンの王",
            jp_pronunciation: "[ゴールデンマウンテンのおう]",
            jp_videoUrl: "https://www.youtube.com/embed/O5bqWvEEIhU",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=O5bqWvEEIhU",
            ko: "황금산의 왕",
            ko_videoUrl: "https://www.youtube.com/embed/toITyiyclpo",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=toITyiyclpo",
         }
      ]
   },
   {
      id: 18,
      title: 'Lazy Donkey',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Lazy Donkey",
            en_videoUrl: "https://www.youtube.com/embed/pvo4nLI7GGE",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=pvo4nLI7GGE",
            cn: "懒惰的驴子",
            cn_pronunciation: "[lǎnduòdelǘzi]",
            cn_videoUrl: "https://www.youtube.com/embed/GfH3oMLaQ5o",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=GfH3oMLaQ5o",
            jp: "レイジー・ロバ",
            jp_pronunciation: "[レイジー・ロバ]",
            jp_videoUrl: "https://www.youtube.com/embed/kWWlVS0lEIg",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=kWWlVS0lEIg",
            ko: "게으른 당나귀",
            ko_videoUrl: "https://www.youtube.com/embed/nvdC1uWV7mI",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=nvdC1uWV7mI",
         }
      ]
   },
   {
      id: 19,
      title: 'Lion And The Mouse',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Lion And The Mouse",
            en_videoUrl: "https://www.youtube.com/embed/GxcGVCEEdcU",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=GxcGVCEEdcU",
            cn: "狮子与老鼠",
            cn_pronunciation: "[shīziyǔlǎoshǔ]",
            cn_videoUrl: "https://www.youtube.com/embed/LBslSKy7jdw",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=LBslSKy7jdw",
            jp: "ライオンとねずみ",
            jp_pronunciation: "[ライオンとねずみ]",
            jp_videoUrl: "https://www.youtube.com/embed/r1sUoPAGoV0",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=r1sUoPAGoV0",
            ko: "사자와 쥐",
            ko_videoUrl: "https://www.youtube.com/embed/kgjXDTeleI4",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=kgjXDTeleI4",
         }
      ]
   },
   {
      id: 20,
      title: 'Little Match Girl',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Little Match Girl",
            en_videoUrl: "https://www.youtube.com/embed/656xMkdT4DE",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=656xMkdT4DE",
            cn: "卖火柴的小女孩",
            cn_pronunciation: "[màihuŏcháidexiăonǚhái]",
            cn_videoUrl: "https://www.youtube.com/embed/t2wIIz52swc",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=t2wIIz52swc",
            jp: "マッチ売りの少女",
            jp_pronunciation: "[マッチうりのしょうじょ]",
            jp_videoUrl: "https://www.youtube.com/embed/hKT-nuGJHtw",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=hKT-nuGJHtw",
            ko: "성냥팔이 소녀",
            ko_videoUrl: "https://www.youtube.com/embed/GLLryNVH6KU",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=GLLryNVH6KU",
         }
      ]
   },
   {
      id: 21,
      title: 'Little Mermaid',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Little Mermaid",
            en_videoUrl: "https://www.youtube.com/embed/KM4sFUhHSf4",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=KM4sFUhHSf4",
            cn: "小美人鱼",
            cn_pronunciation: "[xiǎoměirényú]",
            cn_videoUrl: "https://www.youtube.com/embed/jiEBhPq_o70",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=jiEBhPq_o70",
            jp: "リトルマーメイド",
            jp_pronunciation: "[リトルマーメイド]",
            jp_videoUrl: "https://www.youtube.com/embed/lPE5ZTSUtCI",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=lPE5ZTSUtCI",
            ko: "인어공주",
            ko_videoUrl: "https://www.youtube.com/embed/BNiMMBiVVuA",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=BNiMMBiVVuA",
         }
      ]
   },
   {
      id: 22,
      title: 'Peter Pan',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Peter Pan",
            en_videoUrl: "https://www.youtube.com/embed/Dhc82aohQeU",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=Dhc82aohQeU",
            cn: "彼得潘",
            cn_pronunciation: "[bĭdépān]",
            cn_videoUrl: "https://www.youtube.com/embed/3-M9YeNS6Oc",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=3-M9YeNS6Oc",
            jp: "ピーターパン",
            jp_pronunciation: "[ピーターパン]",
            jp_videoUrl: "https://www.youtube.com/embed/bqLMyL_OI0Q",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=bqLMyL_OI0Q",
            ko: "피터팬",
            ko_videoUrl: "https://www.youtube.com/embed/-rDVOuzs_b4",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=-rDVOuzs_b4",
         }
      ]
   },
   {
      id: 23,
      title: 'Pied Piper of Hamlin',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Pied Piper of Hamlin",
            en_videoUrl: "https://www.youtube.com/embed/Rz2jYYowNd4",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=Rz2jYYowNd4",
            cn: "花衣风笛手",
            cn_pronunciation: "[huāyīfēngdíshǒu]",
            cn_videoUrl: "https://www.youtube.com/embed/CPwkJXiVKZ0",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=CPwkJXiVKZ0",
            jp: "ハーメルンの笛吹き男",
            jp_pronunciation: "[ハーメルンのふえふきおとこ]",
            jp_videoUrl: "https://www.youtube.com/embed/w256kK9oW0o",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=w256kK9oW0o",
            ko: "하멜른의 피리 부는 사나이",
            ko_videoUrl: "https://www.youtube.com/embed/fEV3r0L2jhw",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=fEV3r0L2jhw",
         }
      ]
   },
   {
      id: 24,
      title: 'Pinocchio in English',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Pinocchio in English",
            en_videoUrl: "https://www.youtube.com/embed/P5tQQaDVmeg",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=P5tQQaDVmeg",
            cn: "木偶奇遇记",
            cn_pronunciation: "[mùǒuqíyùjì]",
            cn_videoUrl: "https://www.youtube.com/embed/l8_BABoysTQ",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=l8_BABoysTQ",
            jp: "ピノキオ",
            jp_pronunciation: "[ピノキオ]",
            jp_videoUrl: "https://www.youtube.com/embed/ifFyvH0CGbA",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=ifFyvH0CGbA",
            ko: "피노키오",
            ko_videoUrl: "https://www.youtube.com/embed/7C7dMmxWQV0",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=7C7dMmxWQV0",
         }
      ]
   },
   {
      id: 25,
      title: 'Pinocchio',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Pinocchio",
            en_videoUrl: "https://www.youtube.com/embed/P5tQQaDVmeg",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=P5tQQaDVmeg",
            cn: "木偶奇遇记",
            cn_pronunciation: "[mùǒuqíyùjì]",
            cn_videoUrl: "https://www.youtube.com/embed/l8_BABoysTQ",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=l8_BABoysTQ",
            jp: "ピノキオ",
            jp_pronunciation: "[ピノキオ]",
            jp_videoUrl: "https://www.youtube.com/embed/ifFyvH0CGbA",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=ifFyvH0CGbA",
            ko: "피노키오",
            ko_videoUrl: "https://www.youtube.com/embed/7C7dMmxWQV0",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=7C7dMmxWQV0",
         }
      ]
   },
   {
      id: 26,
      title: 'Puss In Boots',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Puss In Boots",
            en_videoUrl: "https://www.youtube.com/embed/E30iZoGL7Ak",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=E30iZoGL7Ak",
            cn: "穿靴子的猫",
            cn_pronunciation: "[chuānxuēzidemāo]",
            cn_videoUrl: "https://www.youtube.com/embed/Z9erKisTPXc",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=Z9erKisTPXc",
            jp: "長ぐつをはいたネコ",
            jp_pronunciation: "[ながぐつをはいたネコ]",
            jp_videoUrl: "https://www.youtube.com/embed/LUGWQK4wZig",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=LUGWQK4wZig",
            ko: "장화 신은 고양이",
            ko_videoUrl: "https://www.youtube.com/embed/_g5Huie4CI4",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=_g5Huie4CI4",
         }
      ]
   },
   {
      id: 27,
      title: 'Red Shoes',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Red Shoes",
            en_videoUrl: "https://www.youtube.com/embed/x9Cg-NQTsag",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=x9Cg-NQTsag",
            cn: "红鞋子",
            cn_pronunciation: "[hóngxiézi]",
            cn_videoUrl: "https://www.youtube.com/embed/NK6g3g0dPGk",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=NK6g3g0dPGk",
            jp: "赤い靴",
            jp_pronunciation: "[あかいくつ]",
            jp_videoUrl: "https://www.youtube.com/embed/5B1aPgSKNTE",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=5B1aPgSKNTE",
            ko: "빨간 구두",
            ko_videoUrl: "https://www.youtube.com/embed/wG0EHA7Md78",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=wG0EHA7Md78",
         }
      ]
   },
   {
      id: 28,
      title: 'Rumpelstiltskin',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Rumpelstiltskin",
            en_videoUrl: "https://www.youtube.com/embed/sMEC4xOSQG0",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=sMEC4xOSQG0",
            cn: "侏儒怪",
            cn_pronunciation: "[zhūrúguài]",
            cn_videoUrl: "https://www.youtube.com/embed/GFODgi1_Kzg",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=GFODgi1_Kzg",
            jp: "ルンペルシュティルツキン",
            jp_pronunciation: "[ルンペルシュティルツキン]",
            jp_videoUrl: "https://www.youtube.com/embed/ee6JG8Wwgj0",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=ee6JG8Wwgj0",
            ko: "럼펠스틸트스킨",
            ko_videoUrl: "https://www.youtube.com/embed/4QGdoHA_jmc",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=4QGdoHA_jmc",
         }
      ]
   },
   {
      id: 29,
      title: 'Salty Sea',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Salty Sea",
            en_videoUrl: "https://www.youtube.com/embed/VP_Yq863USo",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=VP_Yq863USo",
            cn: "咸海",
            cn_pronunciation: "[xiánhăi]",
            cn_videoUrl: "https://www.youtube.com/embed/FVcaQvYEJWM",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=FVcaQvYEJWM",
            jp: "塩辛い海の物語",
            jp_pronunciation: "[しおからいうみのものがたり]",
            jp_videoUrl: "https://www.youtube.com/embed/fnsbJNOjJsU",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=fnsbJNOjJsU",
            ko: "소금 만드는 맷돌",
            ko_videoUrl: "https://www.youtube.com/embed/ourNwZXbtco",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=ourNwZXbtco",
         }
      ]
   },
   {
      id: 30,
      title: 'Selfish Giant',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Selfish Giant",
            en_videoUrl: "https://www.youtube.com/embed/OR24y5K9fwg",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=OR24y5K9fwg",
            cn: "自私的巨人",
            cn_pronunciation: "[zìsīdejùrén]",
            cn_videoUrl: "https://www.youtube.com/embed/793HH2sPU10",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=793HH2sPU10",
            jp: "自分勝手な巨人",
            jp_pronunciation: "[じぶんかってなきょじん]",
            jp_videoUrl: "https://www.youtube.com/embed/NeD5SE6MDOo",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=NeD5SE6MDOo",
            ko: "욕심쟁이 거인",
            ko_videoUrl: "https://www.youtube.com/embed/jzc79IBRWR0",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=jzc79IBRWR0",
         }
      ]
   },
   {
      id: 31,
      title: 'Sindbad the Sailor (Part 1)',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Sindbad the Sailor (Part 1)",
            en_videoUrl: "https://www.youtube.com/embed/ZnXgZR-8LAY",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=ZnXgZR-8LAY",
            cn: "辛巴达历险记",
            cn_pronunciation: "[xīnbādálìxiănjì]",
            cn_videoUrl: "https://www.youtube.com/embed/Zfs_tFD4_p8",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=Zfs_tFD4_p8",
            jp: "船員のシンドバッドだ",
            jp_pronunciation: "[せんいんのシンドバッドだ]",
            jp_videoUrl: "https://www.youtube.com/embed/9OZLfOS_PBM",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=9OZLfOS_PBM",
            ko: "신밧드의 모험- 파트1",
            ko_videoUrl: "https://www.youtube.com/embed/AaJU0cwLbTU",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=AaJU0cwLbTU",
         }
      ]
   },
   {
      id: 32,
      title: 'Snow Queen',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Snow Queen",
            en_videoUrl: "https://www.youtube.com/embed/FBQzt5r06Ig",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=FBQzt5r06Ig",
            cn: "冰雪女王",
            cn_pronunciation: "[bīngxuĕnǚwáng]",
            cn_videoUrl: "https://www.youtube.com/embed/37s1wPdYKPs",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=37s1wPdYKPs",
            jp: "雪の女王",
            jp_pronunciation: "[ゆきのじょうおう]",
            jp_videoUrl: "https://www.youtube.com/embed/PahUtgWHEyI",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=PahUtgWHEyI",
            ko: "눈 여왕",
            ko_videoUrl: "https://www.youtube.com/embed/ErzF_W8ON08",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=ErzF_W8ON08",
         }
      ]
   },
   {
      id: 33,
      title: 'The Lazy Brahmin Story',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "The Lazy Brahmin Story",
            en_videoUrl: "https://www.youtube.com/embed/q8NW9TiRfGA",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=q8NW9TiRfGA",
            cn: "懒惰的婆罗门",
            cn_pronunciation: "[lǎnduòdepóluómén]",
            cn_videoUrl: "https://www.youtube.com/embed/5Qsj_vTM8cw",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=5Qsj_vTM8cw",
            jp: "ナマケモノのバラモン",
            jp_pronunciation: "[ナマケモノのバラモン]",
            jp_videoUrl: "https://www.youtube.com/embed/7b02KKY1rns",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=7b02KKY1rns",
            ko: "게으른 부자",
            ko_videoUrl: "https://www.youtube.com/embed/g_-6v4B5Cb4",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=g_-6v4B5Cb4",
         }
      ]
   },
   {
      id: 34,
      title: 'Three Little Pigs',
      routeName: 'elementary_story',
      items: [
         {
            id: 1,
            en: "Three Little Pigs",
            en_videoUrl: "https://www.youtube.com/embed/Tmo4XgTHkxQ",
            en_videoOpenUrl: "https://www.youtube.com/watch?v=Tmo4XgTHkxQ",
            cn: "三只小猪",
            cn_pronunciation: "[sānzhĭxiăozhū]",
            cn_videoUrl: "https://www.youtube.com/embed/anBo8Pq_9i4",
            cn_videoOpenUrl: "https://www.youtube.com/watch?v=anBo8Pq_9i4",
            jp: "三匹の子豚",
            jp_pronunciation: "[さんびきのこぶた]",
            jp_videoUrl: "https://www.youtube.com/embed/uM9cy8FPrjg",
            jp_videoOpenUrl: "https://www.youtube.com/watch?v=uM9cy8FPrjg",
            ko: "아기돼지 삼형제",
            ko_videoUrl: "https://www.youtube.com/embed/ipxwihGf_a0",
            ko_videoOpenUrl: "https://www.youtube.com/watch?v=ipxwihGf_a0",
         }
      ]
   }
]

   export default LANGS_MASTER_DATA_STORY; 
