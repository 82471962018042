import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  // Label,
  // FormGroup,
  // Input,
  Table,
  Container,
  Row,
  Col  
} from "reactstrap";

// core components

// react-redux
import { connect } from 'react-redux'
import { selectStory_Subcategory } from '../../../redux'
import { selectViewstyle } from '../../../redux'

// james added
import LANGS_MASTER_DATA_STORY from './langs_master_story.data';

const collections = LANGS_MASTER_DATA_STORY
// const myfilter = collections.filter(collection => collection.routeName === 'sharedkitchen');    



function Display05_sub_04(props) {  
  // console.log(`${category}`) 
  // console.log(`${subcategory}`) 
  const {story_subcategory} = props  
  // console.log(`${props}`) 
  // console.log(`${story_subcategory}`) 
  const myfilter = collections.filter(collection => collection.title === `${story_subcategory}`);      
  // const myfilter = collections.filter(collection => collection.title === 'Lion And The Mouse');      

  return (
    <>
      {/* <div className="section section-feature cd-section" id="features"> */}
      {/* cost 1 - simple table */}
      {/* <div className="features-1"> */}

      <Container>

          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">초등학교 동화</h2>
              <h5 className="description">
              {myfilter[0].title}
              </h5>
            </Col>
          </Row>
          {/* <br/> */}

          <Row>        
          <Table responsive>
          <thead>
                  <tr>
                    {/* <th className="text-center">#</th> */}
                    <th colspan="3" className="text-right">영어</th>
                    <th colspan="3" className="text-right">중국어</th>
                    <th colspan="3" className="text-right">일본어</th>
                    <th colspan="3" className="text-right">한국어</th>
                  </tr>
          </thead>
          </Table>
          {

            myfilter[0].items.map(
                    item => (
                      <>
                      <Col md="3">
                        <Card className="card-blog">                    
                          <div className="card-image">                         
                            
                            <div className="iframe-container">
                                <iframe
                                    title="iframe-container"
                                    allowFullScreen="true"
                                    frameBorder="0"
                                    height="200"
                                    src={`${item.en_videoUrl}`}
                                />
                            </div>
                          </div>                          
                          <CardBody>
                            {/* <h6 className="card-category text-info">{item.en}</h6> */}
                            <CardFooter className="text-center">                  
                            <h6>{item.en}<br/><br/></h6>
                            <br/>
                            {/* <h6 className="card-category text-info">{item.en}<br/><br/></h6> */}
                            <Button
                            color="danger"
                            href={`${item.en_videoOpenUrl}`}
                            target="_blank"
                            >                            
                            PLAY
                            </Button>
                            </CardFooter>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="3">
                        <Card className="card-blog">                    
                          <div className="card-image">                         
                            
                            <div className="iframe-container">
                                <iframe
                                    title="iframe-container"
                                    allowFullScreen="true"
                                    frameBorder="0"
                                    height="200"
                                    src={`${item.cn_videoUrl}`}
                                />
                            </div>
                          </div>                          
                          <CardBody>
                            {/* <h6 className="card-category text-info">{item.cn}<br/>{item.cn_pronunciation}</h6> */}
                            <CardFooter className="text-center">    
                            <h6>{item.cn}<br/>{item.cn_pronunciation}</h6>
                            <br/>
                            {/* <h6 className="card-category text-info">{item.cn}<br/>{item.cn_pronunciation}</h6> */}
                            <Button
                            color="danger"
                            href={`${item.cn_videoOpenUrl}`}
                            target="_blank"
                            >                            
                            PLAY
                            </Button>
                            </CardFooter>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="3">
                        <Card className="card-blog">                    
                          <div className="card-image">                         
                            
                            <div className="iframe-container">
                                <iframe
                                    title="iframe-container"
                                    allowFullScreen="true"
                                    frameBorder="0"
                                    height="200"
                                    src={`${item.jp_videoUrl}`}
                                />
                            </div>
                          </div>                          
                          <CardBody>
                            {/* <h6 className="card-category text-info">{item.jp}<br/>{item.jp_pronunciation}</h6> */}
                            <CardFooter className="text-center">                       
                            <h6>{item.jp}<br/>{item.jp_pronunciation}</h6>
                            <br/>
                            {/* <h6 className="card-category text-info">{item.jp}<br/>{item.jp_pronunciation}</h6> */}
                            <Button
                            color="danger"
                            href={`${item.jp_videoOpenUrl}`}
                            target="_blank"
                            >                            
                            PLAY
                            </Button>
                            </CardFooter>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="3">
                        <Card className="card-blog">                    
                          <div className="card-image">                         
                            
                            <div className="iframe-container">
                                <iframe
                                    title="iframe-container"
                                    allowFullScreen="true"
                                    frameBorder="0"
                                    height="200"
                                    src={`${item.ko_videoUrl}`}
                                />
                            </div>
                          </div>                          
                          <CardBody>
                            {/* <h6 className="card-category text-info">{item.ko}</h6> */}                            
                            <CardFooter className="text-center">                  
                            <h6>{item.ko}<br/><br/></h6>  
                            <br/>   
                            {/* <h6 className="card-category text-info">{item.ko}<br/><br/></h6>      */}
                            <Button
                            color="danger"
                            href={`${item.ko_videoOpenUrl}`}
                            target="_blank"
                            >                            
                            PLAY
                            </Button>
                            </CardFooter>
                          </CardBody>
                        </Card>
                      </Col>                      
                      </>
                    )                    
                  )            
                  

          }
          </Row>
        </Container>
                {/* </div> */}
        {/* ********* END FEATURES 1 ********* */}
      {/* </div> */}
    </>
  );
}

// export default Display06_sub;


const mapStateToProps = state => {
  return {
      
      story_subcategory: state.story.story_subcategory,
      viewstyle: state.style.viewstyle
  }
}

const mapDispatchToProps = dispatch => {
  return {    
    selectStory_Subcategory: (string) => dispatch(selectStory_Subcategory(string)),
  selectViewstyle: (string) => dispatch(selectViewstyle(string))
  }
}

// export default StandardSmallHeader3;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Display05_sub_04)