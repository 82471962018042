const LANGS_MASTER_DATA = [
   {
      id: 1,
      title: 'adjective',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "adjective",
            cn: "形容词",
            cn_pronunciation: "[xíngróngcí]",
            jp: "形容詞",
            jp_pronunciation: "[けいようし]",
            ko: "형용사"
         },
         {
            id: 2,
            en: "beautiful",
            cn: "美丽的，漂亮的",
            cn_pronunciation: "[měilìde，piāoliàngde]",
            jp: "美しい",
            jp_pronunciation: "[うつくしい]",
            ko: "아름다운"
         },
         {
            id: 3,
            en: "big",
            cn: "大的",
            cn_pronunciation: "[dàde]",
            jp: "大きい",
            jp_pronunciation: "[おおきい]",
            ko: "큰"
         },
         {
            id: 4,
            en: "busy",
            cn: "忙碌的",
            cn_pronunciation: "[mánglùde]",
            jp: "忙しい",
            jp_pronunciation: "[いそがしい]",
            ko: "바쁜"
         },
         {
            id: 5,
            en: "cloudy",
            cn: "多云",
            cn_pronunciation: "[duōyún]",
            jp: "曇った",
            jp_pronunciation: "[くもった]",
            ko: "구름이 낀"
         },
         {
            id: 6,
            en: "cool",
            cn: "凉的",
            cn_pronunciation: "[liángde]",
            jp: "涼しい",
            jp_pronunciation: "[すずしい]",
            ko: "시원한"
         },
         {
            id: 7,
            en: "delicious",
            cn: "美味的",
            cn_pronunciation: "[měiwèide]",
            jp: "美味しい",
            jp_pronunciation: "[おいしい]",
            ko: "맛있는"
         },
         {
            id: 8,
            en: "different",
            cn: "不同的",
            cn_pronunciation: "[bútóngde]",
            jp: "異なる",
            jp_pronunciation: "[ことなる]",
            ko: "다른"
         },
         {
            id: 9,
            en: "early",
            cn: "早的",
            cn_pronunciation: "[zǎode]",
            jp: "早い",
            jp_pronunciation: "[はやい]",
            ko: "이른"
         },
         {
            id: 10,
            en: "exciting",
            cn: "令人激动的",
            cn_pronunciation: "[lìngrénjīdòngde]",
            jp: "興奮させること",
            jp_pronunciation: "[こうふんさせる]",
            ko: "흥분시키는"
         },
         {
            id: 11,
            en: "far",
            cn: "远的",
            cn_pronunciation: "[yuǎnde]",
            jp: "遠い",
            jp_pronunciation: "[とおい]",
            ko: "먼"
         },
         {
            id: 12,
            en: "fast",
            cn: "快的",
            cn_pronunciation: "[kuàide]",
            jp: "速い",
            jp_pronunciation: "[はやい]",
            ko: "빠른"
         },
         {
            id: 13,
            en: "fat",
            cn: "胖的",
            cn_pronunciation: "[pàngde]",
            jp: "太った",
            jp_pronunciation: "[ふとった]",
            ko: "뚱뚱한"
         },
         {
            id: 14,
            en: "happy",
            cn: "快乐的",
            cn_pronunciation: "[kuàilède]",
            jp: "幸せな",
            jp_pronunciation: "[しあわせな]",
            ko: "행복한"
         },
         {
            id: 15,
            en: "heavy",
            cn: "重的",
            cn_pronunciation: "[zhòngde]",
            jp: "重い",
            jp_pronunciation: "[おもい]",
            ko: "무거운"
         },
         {
            id: 16,
            en: "high",
            cn: "高的",
            cn_pronunciation: "[gāode]",
            jp: "高い",
            jp_pronunciation: "[たかい]",
            ko: "높은"
         },
         {
            id: 17,
            en: "interesting",
            cn: "兴趣的",
            cn_pronunciation: "[xìngqùde]",
            jp: "興味を起こさせる,面白い",
            jp_pronunciation: "[こうふんをおこさせる,おもしろい]",
            ko: "흥분시키는, 흥미로운,재미있는"
         },
         {
            id: 18,
            en: "large",
            cn: "大的",
            cn_pronunciation: "[dàde]",
            jp: "大きい",
            jp_pronunciation: "[おおきい]",
            ko: "큰"
         },
         {
            id: 19,
            en: "late",
            cn: "吃到的",
            cn_pronunciation: "[chīdàode]",
            jp: "遅れる",
            jp_pronunciation: "[おくれる]",
            ko: "늦은"
         },
         {
            id: 20,
            en: "light",
            cn: "轻的",
            cn_pronunciation: "[qīngde]",
            jp: "軽い",
            jp_pronunciation: "[かるい]",
            ko: "가벼운"
         },
         {
            id: 21,
            en: "little",
            cn: "年幼的",
            cn_pronunciation: "[niányòude]",
            jp: "小さい",
            jp_pronunciation: "[ちいさい]",
            ko: "작은"
         },
         {
            id: 22,
            en: "long",
            cn: "长的",
            cn_pronunciation: "[zhǎngde]",
            jp: "長い",
            jp_pronunciation: "[ながい]",
            ko: "긴"
         },
         {
            id: 23,
            en: "low",
            cn: "低的",
            cn_pronunciation: "[dīde]",
            jp: "低い",
            jp_pronunciation: "[ひくい]",
            ko: "낮은"
         },
         {
            id: 24,
            en: "old",
            cn: "老的",
            cn_pronunciation: "[lǎode]",
            jp: "年を取った",
            jp_pronunciation: "[としをとった]",
            ko: "나이 든"
         },
         {
            id: 25,
            en: "quiet",
            cn: "安静的",
            cn_pronunciation: "[ānjìngde]",
            jp: "静かな",
            jp_pronunciation: "[しずかな]",
            ko: "조용한"
         },
         {
            id: 26,
            en: "rainy",
            cn: "有雨的",
            cn_pronunciation: "[yǒuyǔde]",
            jp: "雨(降り)の",
            jp_pronunciation: "[あめ(ふり)の]",
            ko: "비가오는"
         },
         {
            id: 27,
            en: "sad",
            cn: "放心的",
            cn_pronunciation: "[fàngxīnde]",
            jp: "悲しい",
            jp_pronunciation: "[かなしい]",
            ko: "슬픈"
         },
         {
            id: 28,
            en: "short",
            cn: "矮的",
            cn_pronunciation: "[ǎide]",
            jp: "短い",
            jp_pronunciation: "[みじかい]",
            ko: "짧은"
         },
         {
            id: 29,
            en: "slow",
            cn: "曼的",
            cn_pronunciation: "[mànde]",
            jp: "遅い",
            jp_pronunciation: "[おそい]",
            ko: "느린"
         },
         {
            id: 30,
            en: "small",
            cn: "小的",
            cn_pronunciation: "[xiǎode]",
            jp: "小さい",
            jp_pronunciation: "[ちいさい]",
            ko: "작은"
         },
         {
            id: 31,
            en: "strong",
            cn: "强壮的",
            cn_pronunciation: "[qiángzhuàngde]",
            jp: "強い",
            jp_pronunciation: "[つよい]",
            ko: "강한"
         },
         {
            id: 32,
            en: "sunny",
            cn: "晴朗的",
            cn_pronunciation: "[qínglǎngde]",
            jp: "晴れる",
            jp_pronunciation: "[はれる]",
            ko: "화창한"
         },
         {
            id: 33,
            en: "tall",
            cn: "高的",
            cn_pronunciation: "[gāode]",
            jp: "高い",
            jp_pronunciation: "[たかい]",
            ko: "(키가)큰"
         },
         {
            id: 34,
            en: "thin",
            cn: "瘦的",
            cn_pronunciation: "[shòude]",
            jp: "細い",
            jp_pronunciation: "[ほそい]",
            ko: "날씬한"
         },
         {
            id: 35,
            en: "warm",
            cn: "温和的",
            cn_pronunciation: "[wēnhéde]",
            jp: "暖かい",
            jp_pronunciation: "[あたたかい]",
            ko: "따뜻한"
         },
         {
            id: 36,
            en: "well",
            cn: "好的",
            cn_pronunciation: "[hǎode]",
            jp: "よい",
            jp_pronunciation: "[よい]",
            ko: "좋은"
         },
         {
            id: 37,
            en: "windy",
            cn: "有风的",
            cn_pronunciation: "[yǒufēngde]",
            jp: "風が強い",
            jp_pronunciation: "[かぜがつよい]",
            ko: "바람이 많이 부는"
         },
         {
            id: 38,
            en: "wonderful",
            cn: "精彩的",
            cn_pronunciation: "[jīngcǎide]",
            jp: "素晴らしい",
            jp_pronunciation: "[すばらしい]",
            ko: "훌륭한"
         },
         {
            id: 39,
            en: "young",
            cn: "年轻的",
            cn_pronunciation: "[niánqīngde]",
            jp: "若い",
            jp_pronunciation: "[わかい]",
            ko: "젊은"
         }
      ]
   },
   {
      id: 2,
      title: 'animals',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "animal",
            cn: "动物",
            cn_pronunciation: "[dòngwù]",
            jp: "動物",
            jp_pronunciation: "[どうぶつ]",
            ko: "동물"
         },
         {
            id: 2,
            en: "ant",
            cn: "蚂蚁",
            cn_pronunciation: "[mǎyǐ]",
            jp: "アリ",
            jp_pronunciation: "[アリ]",
            ko: "개미"
         },
         {
            id: 3,
            en: "bear",
            cn: "熊",
            cn_pronunciation: "[xióng]",
            jp: "熊",
            jp_pronunciation: "[くま]",
            ko: "곰"
         },
         {
            id: 4,
            en: "bee",
            cn: "蜜蜂",
            cn_pronunciation: "[mìfēng]",
            jp: "ミツバチ",
            jp_pronunciation: "[ミツバチ]",
            ko: "벌"
         },
         {
            id: 5,
            en: "bird",
            cn: "鸟",
            cn_pronunciation: "[niǎo]",
            jp: "鳥",
            jp_pronunciation: "[とり]",
            ko: "새"
         },
         {
            id: 6,
            en: "butterfly",
            cn: "蝴蝶",
            cn_pronunciation: "[húdié]",
            jp: "チョウ",
            jp_pronunciation: "[チョウ]",
            ko: "나비"
         },
         {
            id: 7,
            en: "camel",
            cn: "骆驼",
            cn_pronunciation: "[luòtuó]",
            jp: "ラクダ",
            jp_pronunciation: "[ラクダ]",
            ko: "낙타"
         },
         {
            id: 8,
            en: "cat",
            cn: "猫",
            cn_pronunciation: "[māo]",
            jp: "猫",
            jp_pronunciation: "[ねこ]",
            ko: "고양이"
         },
         {
            id: 9,
            en: "cattle",
            cn: "牛；牲口",
            cn_pronunciation: "[niú；shēngkǒu]",
            jp: "牛；畜牛",
            jp_pronunciation: "[うし；ちくぎゅう]",
            ko: "소;가축"
         },
         {
            id: 10,
            en: "chameleon",
            cn: "变色龙",
            cn_pronunciation: "[biànsèlóng]",
            jp: "カメレオン",
            jp_pronunciation: "[カメレオン]",
            ko: "카멜레온"
         },
         {
            id: 11,
            en: "chick",
            cn: "小鸡",
            cn_pronunciation: "[xiǎojī]",
            jp: "ひよこ",
            jp_pronunciation: "[ひよこ]",
            ko: "병아리"
         },
         {
            id: 12,
            en: "chicken",
            cn: "鸡",
            cn_pronunciation: "[jī]",
            jp: "鶏",
            jp_pronunciation: "[にわとり]",
            ko: "닭"
         },
         {
            id: 13,
            en: "claw",
            cn: "爪子",
            cn_pronunciation: "[zhǎozǐ]",
            jp: "かぎづめ",
            jp_pronunciation: "[かぎづめ]",
            ko: "(동물의)발톱"
         },
         {
            id: 14,
            en: "cow",
            cn: "奶牛",
            cn_pronunciation: "[nǎiniú]",
            jp: "牝牛",
            jp_pronunciation: "[めうし]",
            ko: "암소"
         },
         {
            id: 15,
            en: "crocodile",
            cn: "鳄鱼",
            cn_pronunciation: "[èyú]",
            jp: "クロコダイル",
            jp_pronunciation: "[クロコダイル]",
            ko: "크로커다일"
         },
         {
            id: 16,
            en: "dog",
            cn: "狗",
            cn_pronunciation: "[gǒu]",
            jp: "犬",
            jp_pronunciation: "[いぬ]",
            ko: "개미"
         },
         {
            id: 17,
            en: "dragon",
            cn: "龙",
            cn_pronunciation: "[lóng]",
            jp: "ドラゴン、竜",
            jp_pronunciation: "[ドラゴン、りゅう]",
            ko: "드래곤, 용"
         },
         {
            id: 18,
            en: "duck",
            cn: "鸭子",
            cn_pronunciation: "[yāzǐ]",
            jp: "鴨",
            jp_pronunciation: "[カモ]",
            ko: "오리"
         },
         {
            id: 19,
            en: "elephant",
            cn: "大象",
            cn_pronunciation: "[dàxiàng]",
            jp: "象",
            jp_pronunciation: "[ぞう]",
            ko: "코끼리"
         },
         {
            id: 20,
            en: "feather",
            cn: "羽毛",
            cn_pronunciation: "[yǔmáo]",
            jp: "羽、羽毛",
            jp_pronunciation: "[はね、うもう]",
            ko: "깃털"
         },
         {
            id: 21,
            en: "firefly",
            cn: "萤火虫",
            cn_pronunciation: "[yínghuǒchóng]",
            jp: "蛍",
            jp_pronunciation: "[ホタル]",
            ko: "반딧불"
         },
         {
            id: 22,
            en: "fish",
            cn: "鱼",
            cn_pronunciation: "[yú]",
            jp: "魚",
            jp_pronunciation: "[さなか]",
            ko: "물고기"
         },
         {
            id: 23,
            en: "fox",
            cn: "狐狸",
            cn_pronunciation: "[húlí]",
            jp: "狐",
            jp_pronunciation: "[きつね]",
            ko: "여우"
         },
         {
            id: 24,
            en: "frog",
            cn: "青蛙",
            cn_pronunciation: "[qīngwā]",
            jp: "カエル",
            jp_pronunciation: "[カエル]",
            ko: "개구리"
         },
         {
            id: 25,
            en: "giraffe",
            cn: "长颈鹿",
            cn_pronunciation: "[zhǎngjǐnglù]",
            jp: "キリン",
            jp_pronunciation: "[きりん]",
            ko: "기린"
         },
         {
            id: 26,
            en: "goat",
            cn: "山羊",
            cn_pronunciation: "[shānyáng]",
            jp: "ヤギ",
            jp_pronunciation: "[ヤギ]",
            ko: "염소"
         },
         {
            id: 27,
            en: "goldfish",
            cn: "金鱼",
            cn_pronunciation: "[jīnyú]",
            jp: "金魚",
            jp_pronunciation: "[きんぎょ]",
            ko: "금붕어"
         },
         {
            id: 28,
            en: "goose",
            cn: "鹅",
            cn_pronunciation: "[é]",
            jp: "ガチョウ",
            jp_pronunciation: "[ガチョウ]",
            ko: "거위"
         },
         {
            id: 29,
            en: "hippo",
            cn: "河马",
            cn_pronunciation: "[hémǎ]",
            jp: "カバ",
            jp_pronunciation: "[カバ]",
            ko: "하마"
         },
         {
            id: 30,
            en: "horse",
            cn: "马",
            cn_pronunciation: "[mǎ]",
            jp: "馬",
            jp_pronunciation: "[うま]",
            ko: "말"
         },
         {
            id: 31,
            en: "hummingbird",
            cn: "蜂鸟",
            cn_pronunciation: "[fēngniǎo]",
            jp: "ハチドリ",
            jp_pronunciation: "[ハチドリ]",
            ko: "벌새"
         },
         {
            id: 32,
            en: "insect",
            cn: "昆虫",
            cn_pronunciation: "[kūnchóng]",
            jp: "昆虫",
            jp_pronunciation: "[こんちゅう]",
            ko: "곤충"
         },
         {
            id: 33,
            en: "kangaroo",
            cn: "袋鼠",
            cn_pronunciation: "[dàishǔ]",
            jp: "カンガルー",
            jp_pronunciation: "[カンガルー]",
            ko: "캥거루"
         },
         {
            id: 34,
            en: "kid",
            cn: "孩子；小山羊",
            cn_pronunciation: "[háizǐ；xiǎoshānyáng]",
            jp: "子ヤギ",
            jp_pronunciation: "[こヤギ]",
            ko: "새끼염소"
         },
         {
            id: 35,
            en: "koala",
            cn: "考拉熊",
            cn_pronunciation: "[kǎolāxióng]",
            jp: "コアラ",
            jp_pronunciation: "[コアラ]",
            ko: "코알라"
         },
         {
            id: 36,
            en: "lion",
            cn: "狮子",
            cn_pronunciation: "[shīzǐ]",
            jp: "ライオン、獅子",
            jp_pronunciation: "[ライオン、しし]",
            ko: "사자"
         },
         {
            id: 37,
            en: "lizard",
            cn: "蜥蜴",
            cn_pronunciation: "[xīyì]",
            jp: "トカゲ",
            jp_pronunciation: "[トカゲ]",
            ko: "도마뱀"
         },
         {
            id: 38,
            en: "mammal",
            cn: "哺乳动物",
            cn_pronunciation: "[bǔrǔdòngwù]",
            jp: "哺乳動物、哺乳類",
            jp_pronunciation: "[ほにゅうどうぶつ、ほにゅうるい]",
            ko: "포유류"
         },
         {
            id: 39,
            en: "monkey",
            cn: "猴子",
            cn_pronunciation: "[hóuzǐ]",
            jp: "猿",
            jp_pronunciation: "[サル]",
            ko: "원숭이"
         },
         {
            id: 40,
            en: "monster",
            cn: "怪兽",
            cn_pronunciation: "[guàishòu]",
            jp: "怪物",
            jp_pronunciation: "[かいぶつ]",
            ko: "괴물"
         },
         {
            id: 41,
            en: "moose",
            cn: "驼鹿",
            cn_pronunciation: "[tuólù]",
            jp: "アメリカヘラジカ",
            jp_pronunciation: "[アメリカヘラジカ]",
            ko: "무스"
         },
         {
            id: 42,
            en: "mouse",
            cn: "老鼠",
            cn_pronunciation: "[lǎoshǔ]",
            jp: "鼠",
            jp_pronunciation: "[ねずみ]",
            ko: "쥐"
         },
         {
            id: 43,
            en: "ostrich",
            cn: "鸵鸟",
            cn_pronunciation: "[tuóniǎo]",
            jp: "ダチョウ",
            jp_pronunciation: "[ダチョウ]",
            ko: "타조"
         },
         {
            id: 44,
            en: "owl",
            cn: "猫头鹰",
            cn_pronunciation: "[māotóuyīng]",
            jp: "フクロウ",
            jp_pronunciation: "[フクロウ]",
            ko: "부엉이"
         },
         {
            id: 45,
            en: "ox",
            cn: "公牛",
            cn_pronunciation: "[gōngniú]",
            jp: "雄牛",
            jp_pronunciation: "[おうし]",
            ko: "수소"
         },
         {
            id: 46,
            en: "panda",
            cn: "熊猫",
            cn_pronunciation: "[xióngmāo]",
            jp: "パンダ",
            jp_pronunciation: "[パンダ]",
            ko: "판다"
         },
         {
            id: 47,
            en: "parrot",
            cn: "鹦鹉",
            cn_pronunciation: "[yīngwǔ]",
            jp: "鸚鵡",
            jp_pronunciation: "[おうむ]",
            ko: "앵무새"
         },
         {
            id: 48,
            en: "penguin",
            cn: "企鹅",
            cn_pronunciation: "[qǐé]",
            jp: "ペンギン",
            jp_pronunciation: "[ペンギン]",
            ko: "펭귄"
         },
         {
            id: 49,
            en: "pet",
            cn: "宠物",
            cn_pronunciation: "[chǒngwù]",
            jp: "ペット",
            jp_pronunciation: "[ペット]",
            ko: "애완동물"
         },
         {
            id: 50,
            en: "pig",
            cn: "猪",
            cn_pronunciation: "[zhū]",
            jp: "豚",
            jp_pronunciation: "[ブタ]",
            ko: "돼지"
         },
         {
            id: 51,
            en: "rabbit",
            cn: "兔子",
            cn_pronunciation: "[tùzǐ]",
            jp: "ウサギ",
            jp_pronunciation: "[ウサギ]",
            ko: "토끼"
         },
         {
            id: 52,
            en: "rat",
            cn: "老鼠",
            cn_pronunciation: "[lǎoshǔ]",
            jp: "鼠",
            jp_pronunciation: "[ねずみ]",
            ko: "쥐"
         },
         {
            id: 53,
            en: "reptile",
            cn: "爬行动物",
            cn_pronunciation: "[páhángdòngwù]",
            jp: "爬虫類",
            jp_pronunciation: "[はちゅうるい]",
            ko: "파충류"
         },
         {
            id: 54,
            en: "rooster",
            cn: "公鸡",
            cn_pronunciation: "[gōngjī]",
            jp: "雄鶏",
            jp_pronunciation: "[おんどり]",
            ko: "수탉"
         },
         {
            id: 55,
            en: "shark",
            cn: "鲨鱼",
            cn_pronunciation: "[shāyú]",
            jp: "サメ",
            jp_pronunciation: "[サメ]",
            ko: "상어"
         },
         {
            id: 56,
            en: "sheep",
            cn: "绵羊",
            cn_pronunciation: "[miányáng]",
            jp: "羊、綿羊",
            jp_pronunciation: "[ひつじ、メンヨウ]",
            ko: "양, 면양"
         },
         {
            id: 57,
            en: "shrimp",
            cn: "虾",
            cn_pronunciation: "[xiā]",
            jp: "エビ",
            jp_pronunciation: "[エビ]",
            ko: "새우"
         },
         {
            id: 58,
            en: "snake",
            cn: "蛇",
            cn_pronunciation: "[shé]",
            jp: "蛇",
            jp_pronunciation: "[ヘビ]",
            ko: "뱀"
         },
         {
            id: 59,
            en: "spider",
            cn: "蜘蛛",
            cn_pronunciation: "[zhīzhū]",
            jp: "蜘蛛",
            jp_pronunciation: "[クモ]",
            ko: "거미"
         },
         {
            id: 60,
            en: "tiger",
            cn: "老虎",
            cn_pronunciation: "[lǎohǔ]",
            jp: "虎",
            jp_pronunciation: "[トラ]",
            ko: "호랑이"
         },
         {
            id: 61,
            en: "toad",
            cn: "蟾蜍",
            cn_pronunciation: "[chánchú]",
            jp: "ヒキカエル",
            jp_pronunciation: "[ヒキカエル]",
            ko: "두꺼비"
         },
         {
            id: 62,
            en: "turkey",
            cn: "火鸡",
            cn_pronunciation: "[huǒjī]",
            jp: "ターキー",
            jp_pronunciation: "[ターキー]",
            ko: "칠면조"
         },
         {
            id: 63,
            en: "turtle",
            cn: "海龟",
            cn_pronunciation: "[hǎiguī]",
            jp: "亀",
            jp_pronunciation: "[カメ]",
            ko: "거북이"
         },
         {
            id: 64,
            en: "whale",
            cn: "鲸鱼",
            cn_pronunciation: "[jīngyú]",
            jp: "鯨",
            jp_pronunciation: "[クジラ]",
            ko: "고래"
         },
         {
            id: 65,
            en: "wing",
            cn: "翼；翅膀",
            cn_pronunciation: "[yì；chìbǎng]",
            jp: "翼",
            jp_pronunciation: "[つばさ]",
            ko: "날개"
         },
         {
            id: 66,
            en: "wolf",
            cn: "狼",
            cn_pronunciation: "[láng]",
            jp: "狼",
            jp_pronunciation: "[オオカミ]",
            ko: "늑대"
         },
         {
            id: 67,
            en: "zebra",
            cn: "斑马",
            cn_pronunciation: "[bānmǎ]",
            jp: "シマウマ",
            jp_pronunciation: "[シマウマ]",
            ko: "얼룩말"
         }
      ]
   },
   {
      id: 3,
      title: 'appellation',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "appellation",
            cn: "称呼",
            cn_pronunciation: "[chēnghū]",
            jp: "呼称",
            jp_pronunciation: "[こしょう]",
            ko: "호칭"
         },
         {
            id: 2,
            en: "Dear",
            cn: "亲爱的",
            cn_pronunciation: "[qīnàide]",
            jp: "親愛な",
            jp_pronunciation: "[しんあいな]",
            ko: "(편지에서)~에게"
         },
         {
            id: 3,
            en: "gentleman",
            cn: "绅士",
            cn_pronunciation: "[shēnshì]",
            jp: "紳士",
            jp_pronunciation: "[しんし]",
            ko: "신사"
         },
         {
            id: 4,
            en: "ladies",
            cn: "女士们",
            cn_pronunciation: "[nǚshìmen]",
            jp: "女性",
            jp_pronunciation: "[じょせい]",
            ko: "숙녀"
         },
         {
            id: 5,
            en: "madam",
            cn: "女士；夫人",
            cn_pronunciation: "[nǚshì；fūrén]",
            jp: "奥様",
            jp_pronunciation: "[おくさま]",
            ko: "(업무에서 이름을 모르는 여성에게 정중하게) 부인"
         },
         {
            id: 6,
            en: "Miss",
            cn: "小姐",
            cn_pronunciation: "[xiǎojiě]",
            jp: "お嬢さん",
            jp_pronunciation: "[おじょうさん]",
            ko: "(결혼하지 않은 여자에게 정중히)…양"
         },
         {
            id: 7,
            en: "Mr",
            cn: "先生",
            cn_pronunciation: "[xiānshēng]",
            jp: "様",
            jp_pronunciation: "[さま]",
            ko: "…씨,…님"
         },
         {
            id: 8,
            en: "Mrs",
            cn: "夫人",
            cn_pronunciation: "[fūrén]",
            jp: "…夫人",
            jp_pronunciation: "[ふじん]",
            ko: "(기혼여성에)부인"
         },
         {
            id: 9,
            en: "Ms",
            cn: "女士",
            cn_pronunciation: "[nǚshì]",
            jp: "…さん",
            jp_pronunciation: "[…さん]",
            ko: "(혼인 여부에 상관없이)…씨"
         },
         {
            id: 10,
            en: "sir",
            cn: "先生；阁下",
            cn_pronunciation: "[xiānshēng；géxià]",
            jp: "先生",
            jp_pronunciation: "[せんせい]",
            ko: "(상점, 식당등에서 모르는 남자에 대한 호칭)손님"
         }
      ]
   },
   {
      id: 4,
      title: 'body',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "body",
            cn: "身体",
            cn_pronunciation: "[shēntǐ]",
            jp: "身体",
            jp_pronunciation: "[しんたい]",
            ko: "신체"
         },
         {
            id: 2,
            en: "arm",
            cn: "手臂",
            cn_pronunciation: "[shǒubì]",
            jp: "腕",
            jp_pronunciation: "[うで]",
            ko: "팔"
         },
         {
            id: 3,
            en: "ear",
            cn: "耳朵",
            cn_pronunciation: "[ěrduǒ]",
            jp: "耳",
            jp_pronunciation: "[みみ]",
            ko: "귀"
         },
         {
            id: 4,
            en: "eye",
            cn: "眼睛",
            cn_pronunciation: "[yǎnjīng]",
            jp: "目",
            jp_pronunciation: "[め]",
            ko: "눈"
         },
         {
            id: 5,
            en: "foot",
            cn: "足,脚",
            cn_pronunciation: "[zú,jiǎo]",
            jp: "足",
            jp_pronunciation: "[あし]",
            ko: "발"
         },
         {
            id: 6,
            en: "hair",
            cn: "头发",
            cn_pronunciation: "[tóufā]",
            jp: "髪",
            jp_pronunciation: "[かみ]",
            ko: "머리카락"
         },
         {
            id: 7,
            en: "hand",
            cn: "手",
            cn_pronunciation: "[shǒu]",
            jp: "手",
            jp_pronunciation: "[て]",
            ko: "손"
         },
         {
            id: 8,
            en: "head",
            cn: "头",
            cn_pronunciation: "[tóu]",
            jp: "頭",
            jp_pronunciation: "[あたま]",
            ko: "머리카락"
         },
         {
            id: 9,
            en: "knee",
            cn: "膝盖",
            cn_pronunciation: "[xīgài]",
            jp: "膝",
            jp_pronunciation: "[ひざ]",
            ko: "무릎"
         },
         {
            id: 10,
            en: "leg",
            cn: "腿",
            cn_pronunciation: "[tuǐ]",
            jp: "脚",
            jp_pronunciation: "[あし]",
            ko: "다리"
         },
         {
            id: 11,
            en: "mouth",
            cn: "嘴,嘴巴",
            cn_pronunciation: "[zuǐ,zuǐbā]",
            jp: "口",
            jp_pronunciation: "[くち]",
            ko: "입"
         },
         {
            id: 12,
            en: "neck",
            cn: "脖子",
            cn_pronunciation: "[bózǐ]",
            jp: "首",
            jp_pronunciation: "[くび]",
            ko: "목"
         },
         {
            id: 13,
            en: "nose",
            cn: "鼻子",
            cn_pronunciation: "[bízǐ]",
            jp: "鼻",
            jp_pronunciation: "[はな]",
            ko: "코"
         },
         {
            id: 14,
            en: "shoulder",
            cn: "肩,肩膀",
            cn_pronunciation: "[jiān,jiānbǎng]",
            jp: "肩",
            jp_pronunciation: "[かた]",
            ko: "어깨"
         },
         {
            id: 15,
            en: "stomach",
            cn: "胃",
            cn_pronunciation: "[wèi]",
            jp: "腹",
            jp_pronunciation: "[はら]",
            ko: "배"
         },
         {
            id: 16,
            en: "toe",
            cn: "脚趾",
            cn_pronunciation: "[jiǎozhǐ]",
            jp: "足指",
            jp_pronunciation: "[あしゆび]",
            ko: "발가락"
         },
         {
            id: 17,
            en: "tooth",
            cn: "呀",
            cn_pronunciation: "[ya]",
            jp: "歯",
            jp_pronunciation: "[は]",
            ko: "이"
         },
         {
            id: 18,
            en: "face",
            cn: "脸",
            cn_pronunciation: "[liǎn]",
            jp: "顔",
            jp_pronunciation: "[かお]",
            ko: "얼굴"
         },
         {
            id: 19,
            en: "cheek",
            cn: "脸颊；脸蛋",
            cn_pronunciation: "[liǎnjiá；liǎndàn]",
            jp: "頬",
            jp_pronunciation: "[ほお]",
            ko: "볼,  뺨"
         },
         {
            id: 20,
            en: "finger",
            cn: "手指",
            cn_pronunciation: "[shǒuzhǐ]",
            jp: "指",
            jp_pronunciation: "[ゆび]",
            ko: "손가락"
         },
         {
            id: 21,
            en: "thigh",
            cn: "大腿",
            cn_pronunciation: "[dàtuǐ]",
            jp: "太もも",
            jp_pronunciation: "[ふともも]",
            ko: "넓적다리, 허벅지"
         },
         {
            id: 22,
            en: "ankle",
            cn: "踝",
            cn_pronunciation: "[huái]",
            jp: "足首",
            jp_pronunciation: "[あしくび]",
            ko: "발목"
         },
         {
            id: 23,
            en: "shin",
            cn: "胫",
            cn_pronunciation: "[jìng]",
            jp: "むこうずね",
            jp_pronunciation: "[むこうずね]",
            ko: "정강이"
         },
         {
            id: 24,
            en: "calf",
            cn: "腓",
            cn_pronunciation: "[féi]",
            jp: "膨ら脛",
            jp_pronunciation: "[ふくらはぎ]",
            ko: "장딴지, 종아리"
         }
      ]
   },
   {
      id: 5,
      title: 'clothes & accessories',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "clothes",
            cn: "衣服",
            cn_pronunciation: "[yīfu]",
            jp: "服",
            jp_pronunciation: "[ふく]",
            ko: "옷. 의복"
         },
         {
            id: 2,
            en: "belt",
            cn: "皮带",
            cn_pronunciation: "[pídài]",
            jp: "ベルト",
            jp_pronunciation: "[ベルト]",
            ko: "가죽 혁대"
         },
         {
            id: 3,
            en: "vest",
            cn: "背心",
            cn_pronunciation: "[bèixīn]",
            jp: "ベスト",
            jp_pronunciation: "[ベスト]",
            ko: "조끼"
         },
         {
            id: 4,
            en: "blouse",
            cn: "女衬衫",
            cn_pronunciation: "[nǚchènshān]",
            jp: "ブラウス",
            jp_pronunciation: "[ブラウス]",
            ko: "블라우스"
         },
         {
            id: 5,
            en: "cap",
            cn: "帽子",
            cn_pronunciation: "[màozi]",
            jp: "縁なし帽子、野球帽",
            jp_pronunciation: "[ふちなしぼうし、やきゅうぼう]",
            ko: "테두리가 없는 모자, 야구모자"
         },
         {
            id: 6,
            en: "closet",
            cn: "壁橱；衣橱",
            cn_pronunciation: "[bìchú ； yīchú]",
            jp: "タンス、クロ（ー）ゼット",
            jp_pronunciation: "[タンス、クロ（ー）ゼット]",
            ko: "옷장"
         },
         {
            id: 7,
            en: "clothing",
            cn: "衣服；腹胀",
            cn_pronunciation: "[yīfú ； fùzhàng]",
            jp: "衣類",
            jp_pronunciation: "[いるい]",
            ko: "의복"
         },
         {
            id: 8,
            en: "coat",
            cn: "外套",
            cn_pronunciation: "[wàitào]",
            jp: "コート、外套",
            jp_pronunciation: "[コート、がいとう]",
            ko: "외투"
         },
         {
            id: 9,
            en: "dress",
            cn: "连衣裙",
            cn_pronunciation: "[liányīqún]",
            jp: "ワンピース、ドレス",
            jp_pronunciation: "[ワンピース、ドレス]",
            ko: "원피스"
         },
         {
            id: 10,
            en: "glove",
            cn: "手套",
            cn_pronunciation: "[shǒutào]",
            jp: "手袋,グローブ",
            jp_pronunciation: "[てぶくろ,グローブ]",
            ko: "장갑"
         },
         {
            id: 11,
            en: "hat",
            cn: "帽子",
            cn_pronunciation: "[màozi]",
            jp: "縁のある帽子、帽子",
            jp_pronunciation: "[ふちのあるぼうし、ぼうし]",
            ko: "테두리가 있는 모자, 모자"
         },
         {
            id: 12,
            en: "jacket",
            cn: "夹克",
            cn_pronunciation: "[jiākè]",
            jp: "ジャケット",
            jp_pronunciation: "[ジャケット]",
            ko: "재킷"
         },
         {
            id: 13,
            en: "jeans",
            cn: "牛仔裤",
            cn_pronunciation: "[niúzǎikù]",
            jp: "ジーンズ",
            jp_pronunciation: "[ジーンズ]",
            ko: "청바지"
         },
         {
            id: 14,
            en: "overcoat",
            cn: "大衣",
            cn_pronunciation: "[dàyī]",
            jp: "オーバーコート",
            jp_pronunciation: "[オーバーコート]",
            ko: "외투"
         },
         {
            id: 15,
            en: "pants",
            cn: "裤子",
            cn_pronunciation: "[kùzi]",
            jp: "ズボン",
            jp_pronunciation: "[ズボン]",
            ko: "바지"
         },
         {
            id: 16,
            en: "pocket",
            cn: "口袋",
            cn_pronunciation: "[kǒudài]",
            jp: "ポケット",
            jp_pronunciation: "[ポケット]",
            ko: "호주머니"
         },
         {
            id: 17,
            en: "raincoat",
            cn: "雨衣",
            cn_pronunciation: "[yǔyī]",
            jp: "レインコート",
            jp_pronunciation: "[レインコート]",
            ko: "우의. 비옷. 레인코트."
         },
         {
            id: 18,
            en: "scarf",
            cn: "围巾",
            cn_pronunciation: "[wéijīn]",
            jp: "スカーフ",
            jp_pronunciation: "[スカーフ]",
            ko: "스카프"
         },
         {
            id: 19,
            en: "shirt",
            cn: "衬衫",
            cn_pronunciation: "[chènshān]",
            jp: "シャツ",
            jp_pronunciation: "[シャツ]",
            ko: "셔츠"
         },
         {
            id: 20,
            en: "shoes",
            cn: "鞋子",
            cn_pronunciation: "[xiézi]",
            jp: "靴",
            jp_pronunciation: "[くつ]",
            ko: "신발"
         },
         {
            id: 21,
            en: "shorts",
            cn: "短裤",
            cn_pronunciation: "[duǎnkù]",
            jp: "ショートパンツ",
            jp_pronunciation: "[ショートパンツ]",
            ko: "반바지"
         },
         {
            id: 22,
            en: "skirt",
            cn: "短裙",
            cn_pronunciation: "[duǎnqún]",
            jp: "スカート",
            jp_pronunciation: "[スカート]",
            ko: "짧은 치마"
         },
         {
            id: 23,
            en: "sneakers",
            cn: "运动鞋",
            cn_pronunciation: "[yùndòngxié]",
            jp: "スニーカー, 運動靴",
            jp_pronunciation: "[スニーカー, うんどうぐつ]",
            ko: "운동화"
         },
         {
            id: 24,
            en: "socks",
            cn: "袜子",
            cn_pronunciation: "[wàzi]",
            jp: "靴下",
            jp_pronunciation: "[くつした]",
            ko: "양말"
         },
         {
            id: 25,
            en: "stockings",
            cn: "长筒袜",
            cn_pronunciation: "[chángtǒngwà]",
            jp: "ストッキング",
            jp_pronunciation: "[ストッキング]",
            ko: "스타킹"
         },
         {
            id: 26,
            en: "strap",
            cn: "带子",
            cn_pronunciation: "[dài‧zi]",
            jp: "革ひも,ストラップ",
            jp_pronunciation: "[かわひも,ストラップ]",
            ko: "띠·끈·밴드·리본 따위의 총칭"
         },
         {
            id: 27,
            en: "suit",
            cn: "(一套)衣服",
            cn_pronunciation: "[yītào yīfú]",
            jp: "スーツ",
            jp_pronunciation: "[スーツ]",
            ko: "옷 한 벌"
         },
         {
            id: 28,
            en: "sweater",
            cn: "毛衣",
            cn_pronunciation: "[máoyī]",
            jp: "セーター",
            jp_pronunciation: "[セーター]",
            ko: "스웨터"
         },
         {
            id: 29,
            en: "swimsuit",
            cn: "泳衣",
            cn_pronunciation: "[yǒngyī]",
            jp: "水着",
            jp_pronunciation: "[みずぎ]",
            ko: "수영복"
         },
         {
            id: 30,
            en: "necktie",
            cn: "领带",
            cn_pronunciation: "[lǐngdài]",
            jp: "ネクタイ",
            jp_pronunciation: "[ネクタイ]",
            ko: "넥타이"
         },
         {
            id: 31,
            en: "trousers",
            cn: "裙子",
            cn_pronunciation: "[qún‧zi]",
            jp: "ズボン",
            jp_pronunciation: "[ズボン]",
            ko: "치마"
         },
         {
            id: 32,
            en: "T-shirt",
            cn: "T恤衫",
            cn_pronunciation: "[T xùshān]",
            jp: "Tシャツ",
            jp_pronunciation: "[Tシャツ]",
            ko: "티셔츠"
         },
         {
            id: 33,
            en: "uniform",
            cn: "制服；军服",
            cn_pronunciation: "[zhìfú ； jūnfú]",
            jp: "ユニホーム, 制服",
            jp_pronunciation: "[ユニホーム, せいふく]",
            ko: "유니폼"
         }
      ]
   },
   {
      id: 6,
      title: 'color',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "color",
            cn: "颜色",
            cn_pronunciation: "[yánsè]",
            jp: "色",
            jp_pronunciation: "[いろ]",
            ko: "색"
         },
         {
            id: 2,
            en: "black",
            cn: "黑色",
            cn_pronunciation: "[hēisè]",
            jp: "黒",
            jp_pronunciation: "[くろ]",
            ko: "검은색"
         },
         {
            id: 3,
            en: "blue",
            cn: "蓝色",
            cn_pronunciation: "[lánsè]",
            jp: "青",
            jp_pronunciation: "[あお]",
            ko: "남색"
         },
         {
            id: 4,
            en: "brown",
            cn: "褐色",
            cn_pronunciation: "[hèsè]",
            jp: "茶色",
            jp_pronunciation: "[ちゃいろ]",
            ko: "갈색"
         },
         {
            id: 5,
            en: "gold",
            cn: "金色",
            cn_pronunciation: "[jīnsè]",
            jp: "金色",
            jp_pronunciation: "[きんいろ]",
            ko: "금색"
         },
         {
            id: 6,
            en: "green",
            cn: "绿色",
            cn_pronunciation: "[lǜsè]",
            jp: "緑色",
            jp_pronunciation: "[みどり色]",
            ko: "녹색"
         },
         {
            id: 7,
            en: "grey",
            cn: "灰色",
            cn_pronunciation: "[huīsè]",
            jp: "灰色",
            jp_pronunciation: "[はいいろ]",
            ko: "회색"
         },
         {
            id: 8,
            en: "orange",
            cn: "橙色",
            cn_pronunciation: "[chéngsè]",
            jp: "オレンジ色",
            jp_pronunciation: "[オレンジいろ]",
            ko: "주황색"
         },
         {
            id: 9,
            en: "pink",
            cn: "粉色",
            cn_pronunciation: "[fěnsè]",
            jp: "桃色",
            jp_pronunciation: "[ももいろ]",
            ko: "분홍색"
         },
         {
            id: 10,
            en: "purple",
            cn: "紫色",
            cn_pronunciation: "[zǐsè]",
            jp: "紫色",
            jp_pronunciation: "[むらさきいろ]",
            ko: "자주색"
         },
         {
            id: 11,
            en: "red",
            cn: "红色",
            cn_pronunciation: "[hóngsè]",
            jp: "赤",
            jp_pronunciation: "[あか]",
            ko: "빨강색"
         },
         {
            id: 12,
            en: "white",
            cn: "白色",
            cn_pronunciation: "[báisè]",
            jp: "白",
            jp_pronunciation: "[しろ]",
            ko: "흰색"
         },
         {
            id: 13,
            en: "yellow",
            cn: "黄色",
            cn_pronunciation: "[huángsè]",
            jp: "黄色",
            jp_pronunciation: "[きいろ]",
            ko: "노란색"
         }
      ]
   },
   {
      id: 7,
      title: 'computer',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "address",
            cn: "地址",
            cn_pronunciation: "[dìzhǐ]",
            jp: "アドレス",
            jp_pronunciation: "[アドレス]",
            ko: "주소"
         },
         {
            id: 2,
            en: "Computer",
            cn: "电脑",
            cn_pronunciation: "[diànnǎo]",
            jp: "コンピューター",
            jp_pronunciation: "[コンピューター]",
            ko: "컴퓨터"
         },
         {
            id: 3,
            en: "E-card",
            cn: "电子贺卡",
            cn_pronunciation: "[diànzǐhèkǎ]",
            jp: "イーカード",
            jp_pronunciation: "[イーカード]",
            ko: "축하 카드 메일"
         },
         {
            id: 4,
            en: "E-mail",
            cn: "电子邮件",
            cn_pronunciation: "[diànzǐyóujiàn]",
            jp: "イーメール",
            jp_pronunciation: "[イーメール]",
            ko: "전자 우편. 이메일"
         },
         {
            id: 5,
            en: "icon",
            cn: "图标",
            cn_pronunciation: "[túbiāo]",
            jp: "アイコン",
            jp_pronunciation: "[アイコン]",
            ko: "아이콘"
         },
         {
            id: 6,
            en: "information",
            cn: "信息",
            cn_pronunciation: "[xìnxī]",
            jp: "情報",
            jp_pronunciation: "[じょうほう]",
            ko: "정보"
         },
         {
            id: 7,
            en: "keyboard",
            cn: "键盘",
            cn_pronunciation: "[jiànpán]",
            jp: "キーボード",
            jp_pronunciation: "[キーボード]",
            ko: "키보드"
         },
         {
            id: 8,
            en: "laser printer",
            cn: "激光打印机",
            cn_pronunciation: "[jīguāngdǎyìnjī]",
            jp: "レーザープリンター",
            jp_pronunciation: "[レーザープリンター]",
            ko: "레이저 프린터"
         },
         {
            id: 9,
            en: "message",
            cn: "消息",
            cn_pronunciation: "[xiāoxi]",
            jp: "メッセージ",
            jp_pronunciation: "[メッセージ]",
            ko: "소식"
         },
         {
            id: 10,
            en: "monitor",
            cn: "显示器",
            cn_pronunciation: "[xiǎnshìqì]",
            jp: "モニター",
            jp_pronunciation: "[モニター]",
            ko: "모니터"
         },
         {
            id: 11,
            en: "mouse",
            cn: "鼠标",
            cn_pronunciation: "[shǔbiāo]",
            jp: "マウス",
            jp_pronunciation: "[マウス]",
            ko: "마우스"
         },
         {
            id: 12,
            en: "printer",
            cn: "打印机",
            cn_pronunciation: "[dǎyìnjī]",
            jp: "プリンター",
            jp_pronunciation: "[プリンター]",
            ko: "프린터"
         }
      ]
   },
   {
      id: 8,
      title: 'country',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "country",
            cn: "国家",
            cn_pronunciation: "[guójiā]",
            jp: "国",
            jp_pronunciation: "[くに]",
            ko: "국가"
         },
         {
            id: 2,
            en: "Korea",
            cn: "韩国",
            cn_pronunciation: "[hánguó]",
            jp: "韓国",
            jp_pronunciation: "[かんこく]",
            ko: "한국"
         },
         {
            id: 3,
            en: "Korean",
            cn: "韩国人",
            cn_pronunciation: "[hánguórén]",
            jp: "韓国人",
            jp_pronunciation: "[かんこく人]",
            ko: "한국인"
         },
         {
            id: 4,
            en: "China",
            cn: "中国",
            cn_pronunciation: "[zhōngguó]",
            jp: "中国",
            jp_pronunciation: "[ちゅうごく]",
            ko: "중국"
         },
         {
            id: 5,
            en: "Chinese",
            cn: "中国人",
            cn_pronunciation: "[zhōngguórén]",
            jp: "中国人",
            jp_pronunciation: "[ちゅうごくじん]",
            ko: "중국인"
         },
         {
            id: 6,
            en: "Japan",
            cn: "日本",
            cn_pronunciation: "[rìběn]",
            jp: "日本",
            jp_pronunciation: "[にほん]",
            ko: "일본"
         },
         {
            id: 7,
            en: "Japanese",
            cn: "日本人",
            cn_pronunciation: "[rìběnrén]",
            jp: "日本人",
            jp_pronunciation: "[にほんじん]",
            ko: "일본인"
         },
         {
            id: 8,
            en: "Thailand",
            cn: "泰国",
            cn_pronunciation: "[tàiguó]",
            jp: "タイ",
            jp_pronunciation: "[タイ]",
            ko: "태국"
         },
         {
            id: 9,
            en: "Thai",
            cn: "泰国人",
            cn_pronunciation: "[tàiguórén]",
            jp: "タイ人",
            jp_pronunciation: "[タイじん]",
            ko: "태국인"
         },
         {
            id: 10,
            en: "Vietnam",
            cn: "越南",
            cn_pronunciation: "[yuènán]",
            jp: "ベトナム",
            jp_pronunciation: "[ベトナム]",
            ko: "베트남"
         },
         {
            id: 11,
            en: "Vietnamese",
            cn: "越南人",
            cn_pronunciation: "[yuènánrén]",
            jp: "ベトナム人",
            jp_pronunciation: "[ベトナムじん]",
            ko: "베트남인"
         },
         {
            id: 12,
            en: "India",
            cn: "印度",
            cn_pronunciation: "[yìndù]",
            jp: "インド",
            jp_pronunciation: "[インド]",
            ko: "인도"
         },
         {
            id: 13,
            en: "Indian",
            cn: "印度人",
            cn_pronunciation: "[yìndùrén]",
            jp: "インド人",
            jp_pronunciation: "[インドじん]",
            ko: "인도인"
         },
         {
            id: 14,
            en: "Australia",
            cn: "澳大利亚",
            cn_pronunciation: "[àodàlìyà]",
            jp: "オーストラリア",
            jp_pronunciation: "[オーストラリア]",
            ko: "호주"
         },
         {
            id: 15,
            en: "Australian",
            cn: "澳大利亚人",
            cn_pronunciation: "[àodàlìyàrén]",
            jp: "オーストラリア人",
            jp_pronunciation: "[オーストラリアじん]",
            ko: "호주인"
         },
         {
            id: 16,
            en: "UK",
            cn: "英国",
            cn_pronunciation: "[yīngguó]",
            jp: "イギリス",
            jp_pronunciation: "[イギリス]",
            ko: "영국"
         },
         {
            id: 17,
            en: "British",
            cn: "英国人",
            cn_pronunciation: "[yīngguórén]",
            jp: "英国人",
            jp_pronunciation: "[えいこくじん]",
            ko: "영국인"
         },
         {
            id: 18,
            en: "Scotland",
            cn: "苏格兰",
            cn_pronunciation: "[sūgélán]",
            jp: "スコットランド",
            jp_pronunciation: "[スコットランド]",
            ko: "스코트랜드"
         },
         {
            id: 19,
            en: "Scottish",
            cn: "苏格兰人",
            cn_pronunciation: "[sūgélánrén]",
            jp: "イングランド人",
            jp_pronunciation: "[イングランド人]",
            ko: "스코트랜드인"
         },
         {
            id: 20,
            en: "Germany",
            cn: "德国",
            cn_pronunciation: "[déguó]",
            jp: "ドイツ",
            jp_pronunciation: "[ドイツ]",
            ko: "독일"
         },
         {
            id: 21,
            en: "German",
            cn: "的国人",
            cn_pronunciation: "[deguórén]",
            jp: "ドイツ人",
            jp_pronunciation: "[ドイツじん]",
            ko: "독일인"
         },
         {
            id: 22,
            en: "France",
            cn: "法语",
            cn_pronunciation: "[fǎyǔ]",
            jp: "フランス",
            jp_pronunciation: "[フランス]",
            ko: "프랑스"
         },
         {
            id: 23,
            en: "French",
            cn: "法国人",
            cn_pronunciation: "[fǎguórén]",
            jp: "フランス人",
            jp_pronunciation: "[フランスじん]",
            ko: "프랑스인"
         },
         {
            id: 24,
            en: "Denmark",
            cn: "丹麦",
            cn_pronunciation: "[dānmài]",
            jp: "デンマーク",
            jp_pronunciation: "[デンマーク]",
            ko: "덴마크"
         },
         {
            id: 25,
            en: "Danish",
            cn: "丹麦人",
            cn_pronunciation: "[dānmàirén]",
            jp: "デンマーク人",
            jp_pronunciation: "[デンマークじん]",
            ko: "덴마크인"
         },
         {
            id: 26,
            en: "Spain",
            cn: "西班牙",
            cn_pronunciation: "[xībānyá]",
            jp: "スペイン",
            jp_pronunciation: "[スペイン]",
            ko: "스페인"
         },
         {
            id: 27,
            en: "Spanish",
            cn: "西班牙人",
            cn_pronunciation: "[xībānyárén]",
            jp: "スペイン人",
            jp_pronunciation: "[スペインじん]",
            ko: "스페인인"
         },
         {
            id: 28,
            en: "Italy",
            cn: "意大利",
            cn_pronunciation: "[yìdàlì]",
            jp: "イタリア",
            jp_pronunciation: "[イタリア]",
            ko: "이탈리아"
         },
         {
            id: 29,
            en: "Italian",
            cn: "意大利人",
            cn_pronunciation: "[yìdàlìrén]",
            jp: "イタリア人",
            jp_pronunciation: "[イタリアじん]",
            ko: "이탈리아인"
         },
         {
            id: 30,
            en: "Russia",
            cn: "俄罗斯",
            cn_pronunciation: "[éluósī]",
            jp: "ロシア",
            jp_pronunciation: "[ロシア]",
            ko: "러시아"
         },
         {
            id: 31,
            en: "Russian",
            cn: "俄国人",
            cn_pronunciation: "[éguórén]",
            jp: "ロシア人",
            jp_pronunciation: "[ロシアじん]",
            ko: "러시아인"
         },
         {
            id: 32,
            en: "Mexico",
            cn: "墨西哥",
            cn_pronunciation: "[mòxīgē]",
            jp: "メキシコ",
            jp_pronunciation: "[メキシコ]",
            ko: "멕시코"
         },
         {
            id: 33,
            en: "Mexican",
            cn: "墨西哥人",
            cn_pronunciation: "[mòxīgērén]",
            jp: "メキシコ人",
            jp_pronunciation: "[メキシコじん]",
            ko: "멕시코인"
         },
         {
            id: 34,
            en: "America",
            cn: "美国",
            cn_pronunciation: "[měiguó]",
            jp: "アメリカ",
            jp_pronunciation: "[アメリカ]",
            ko: "미국"
         },
         {
            id: 35,
            en: "American",
            cn: "美国人",
            cn_pronunciation: "[měiguórén]",
            jp: "アメリカ人",
            jp_pronunciation: "[アメリカじん]",
            ko: "미국인"
         },
         {
            id: 36,
            en: "United Kingdom",
            cn: "英国",
            cn_pronunciation: "[yīngguó]",
            jp: "英国人",
            jp_pronunciation: "[えいこくじん]",
            ko: "영국"
         },
         {
            id: 37,
            en: "England",
            cn: "英格兰",
            cn_pronunciation: "[yīnggélán]",
            jp: "イングランド",
            jp_pronunciation: "[イングランド]",
            ko: "잉글랜드"
         },
         {
            id: 38,
            en: "English",
            cn: "英格兰人",
            cn_pronunciation: "[yīnggélánrén]",
            jp: "イングランド人",
            jp_pronunciation: "[イングランドじん]",
            ko: "잉글랜드인"
         },
         {
            id: 39,
            en: "Scotland",
            cn: "苏格兰",
            cn_pronunciation: "[sūgélán]",
            jp: "スコットランド",
            jp_pronunciation: "[スコットランド]",
            ko: "스코틀랜드"
         },
         {
            id: 40,
            en: "Scottish",
            cn: "苏格兰人",
            cn_pronunciation: "[sūgélánrén]",
            jp: "スコットランド人",
            jp_pronunciation: "[スコットランドじん]",
            ko: "스코틀랜드인"
         },
         {
            id: 41,
            en: "Wales",
            cn: "威尔士",
            cn_pronunciation: "[wēiěrshì]",
            jp: "ウェールズ",
            jp_pronunciation: "[ウェールズ]",
            ko: "웨일스"
         },
         {
            id: 42,
            en: "Welsh",
            cn: "威尔士人",
            cn_pronunciation: "[wēiěrshìrén]",
            jp: "ウェールズ人",
            jp_pronunciation: "[ウェールズじん]",
            ko: "웨일스인"
         },
         {
            id: 43,
            en: "Northern Ireland",
            cn: "北爱尔兰",
            cn_pronunciation: "[běiàiěrlán]",
            jp: "北アイルランド",
            jp_pronunciation: "[キタアイルランド]",
            ko: "북아일랜드"
         },
         {
            id: 44,
            en: "Irish",
            cn: "爱尔兰人",
            cn_pronunciation: "[àiěrlánrén]",
            jp: "アイルランド人",
            jp_pronunciation: "[アイルランドじん]",
            ko: "아일랜드인"
         },
         {
            id: 45,
            en: "Continent",
            cn: "大陆；达州（七大洲）",
            cn_pronunciation: "[dàlù；dázhōu（qīdàzhōu）]",
            jp: "大陸",
            jp_pronunciation: "[たいりく]",
            ko: "대륙"
         },
         {
            id: 46,
            en: "Africa",
            cn: "非洲",
            cn_pronunciation: "[fēizhōu]",
            jp: "アフリカ",
            jp_pronunciation: "[アフリカ]",
            ko: "아프리카"
         },
         {
            id: 47,
            en: "Antarctica",
            cn: "南极洲",
            cn_pronunciation: "[nánjízhōu]",
            jp: "南極大陸",
            jp_pronunciation: "[なんきょくたいりく]",
            ko: "남극대륙"
         },
         {
            id: 48,
            en: "Asia",
            cn: "亚洲",
            cn_pronunciation: "[yàzhōu]",
            jp: "アジア",
            jp_pronunciation: "[アジア]",
            ko: "아시아"
         },
         {
            id: 49,
            en: "Europe",
            cn: "欧洲",
            cn_pronunciation: "[ōuzhōu]",
            jp: "ヨーロッパ",
            jp_pronunciation: "[ヨーロッパ]",
            ko: "유럽"
         },
         {
            id: 50,
            en: "Oceania",
            cn: "大洋洲",
            cn_pronunciation: "[dàyángzhōu]",
            jp: "オセアニア",
            jp_pronunciation: "[オセアニア]",
            ko: "호주"
         },
         {
            id: 51,
            en: "North America",
            cn: "北美洲",
            cn_pronunciation: "[běiměizhōu]",
            jp: "北アメリカ",
            jp_pronunciation: "[キタアメリカ]",
            ko: "북아메리카"
         },
         {
            id: 52,
            en: "South America",
            cn: "南美洲",
            cn_pronunciation: "[nánměizhōu]",
            jp: "南アメリカ",
            jp_pronunciation: "[ミナミアメリカ]",
            ko: "남아메리카"
         },
         {
            id: 53,
            en: "Artic Ocean",
            cn: "北冰洋",
            cn_pronunciation: "[běibīngyáng]",
            jp: "北極海",
            jp_pronunciation: "[ほっきょくかい]",
            ko: "북극해"
         },
         {
            id: 54,
            en: "Atlantic Ocean",
            cn: "大西洋",
            cn_pronunciation: "[dàxīyáng]",
            jp: "大西洋",
            jp_pronunciation: "[たいせいよう]",
            ko: "대서양"
         },
         {
            id: 55,
            en: "Pacific Ocean",
            cn: "太平洋",
            cn_pronunciation: "[tàipíngyáng]",
            jp: "太平洋",
            jp_pronunciation: "[たいへいよう]",
            ko: "태평양"
         },
         {
            id: 56,
            en: "Indian Ocean",
            cn: "印度洋",
            cn_pronunciation: "[yìndùyáng]",
            jp: "インド洋",
            jp_pronunciation: "[インドよう]",
            ko: "인도양"
         }
      ]
   },
   {
      id: 9,
      title: 'daily necessities',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "daily necessities",
            cn: "日用品",
            cn_pronunciation: "[rìyòngpǐn]",
            jp: "日用品",
            jp_pronunciation: "[にちようひん]",
            ko: "일용품"
         },
         {
            id: 2,
            en: "armchair",
            cn: "扶手椅",
            cn_pronunciation: "[fúshouyǐ]",
            jp: "ひじかけいす",
            jp_pronunciation: "[ひじかけいす]",
            ko: "안락의자"
         },
         {
            id: 3,
            en: "article",
            cn: "物品",
            cn_pronunciation: "[wùpǐn]",
            jp: "品物",
            jp_pronunciation: "[しなもの]",
            ko: "물품"
         },
         {
            id: 4,
            en: "backpack",
            cn: "背包",
            cn_pronunciation: "[bēibāo]",
            jp: "バックパック",
            jp_pronunciation: "[バックパック]",
            ko: "배낭"
         },
         {
            id: 5,
            en: "basket",
            cn: "篮子",
            cn_pronunciation: "[lánzi]",
            jp: "バスケット",
            jp_pronunciation: "[バスケット]",
            ko: "바구니"
         },
         {
            id: 6,
            en: "bed",
            cn: "床",
            cn_pronunciation: "[chuáng]",
            jp: "ベッド",
            jp_pronunciation: "[ベッド]",
            ko: "침대"
         },
         {
            id: 7,
            en: "bell",
            cn: "钟；铃",
            cn_pronunciation: "[zhōng ; líng]",
            jp: "ベル",
            jp_pronunciation: "[ベル]",
            ko: "종 ; 벨"
         },
         {
            id: 8,
            en: "belt",
            cn: "腰带；带子",
            cn_pronunciation: "[yāodài ; dàizi]",
            jp: "ベルト",
            jp_pronunciation: "[ベルト]",
            ko: "허리띠 ; 벨트"
         },
         {
            id: 9,
            en: "bench",
            cn: "长凳，长椅",
            cn_pronunciation: "[zhǎngdèng ; zhǎngyǐ]",
            jp: "ベンチ",
            jp_pronunciation: "[ベンチ]",
            ko: "긴 의자"
         },
         {
            id: 10,
            en: "bottle",
            cn: "瓶子",
            cn_pronunciation: "[píngzi]",
            jp: "瓶",
            jp_pronunciation: "[びん]",
            ko: "병"
         },
         {
            id: 11,
            en: "bowl",
            cn: "碗",
            cn_pronunciation: "[wǎn]",
            jp: "どんぶり",
            jp_pronunciation: "[どんぶり]",
            ko: "그릇"
         },
         {
            id: 12,
            en: "box",
            cn: "箱子",
            cn_pronunciation: "[xiāngzi]",
            jp: "箱",
            jp_pronunciation: "[はこ]",
            ko: "상자"
         },
         {
            id: 13,
            en: "broom",
            cn: "扫帚",
            cn_pronunciation: "[sàozhou]",
            jp: "帚",
            jp_pronunciation: "[ほうき]",
            ko: "빗자루"
         },
         {
            id: 14,
            en: "brush",
            cn: "刷子",
            cn_pronunciation: "[shuāzi]",
            jp: "ブラシ",
            jp_pronunciation: "[ブラシ]",
            ko: "솔"
         },
         {
            id: 15,
            en: "cage",
            cn: "笼子；鸟笼",
            cn_pronunciation: "[lóngzi  ；niǎolóng]",
            jp: "鳥かご",
            jp_pronunciation: "[とりかご]",
            ko: "새장"
         },
         {
            id: 16,
            en: "camera",
            cn: "照相机",
            cn_pronunciation: "[zhàoxiàngjī]",
            jp: "カメラ",
            jp_pronunciation: "[カメラ]",
            ko: "카메라"
         },
         {
            id: 17,
            en: "candle",
            cn: "蜡烛",
            cn_pronunciation: "[làzhú]",
            jp: "ろうそく",
            jp_pronunciation: "[ろうそく]",
            ko: "양초"
         },
         {
            id: 18,
            en: "card",
            cn: "卡片",
            cn_pronunciation: "[kǎpiàn]",
            jp: "カード",
            jp_pronunciation: "[カード]",
            ko: "카드"
         },
         {
            id: 19,
            en: "cell phone",
            cn: "手机",
            cn_pronunciation: "[shǒujī]",
            jp: "携帯電話",
            jp_pronunciation: "[けいたいでんわ]",
            ko: "핸드폰"
         },
         {
            id: 20,
            en: "clock",
            cn: "时钟",
            cn_pronunciation: "[shízhōng]",
            jp: "時計",
            jp_pronunciation: "[とけい]",
            ko: "시계"
         },
         {
            id: 21,
            en: "cup",
            cn: "杯子",
            cn_pronunciation: "[bēizi]",
            jp: "カップ",
            jp_pronunciation: "[カップ]",
            ko: "컵"
         },
         {
            id: 22,
            en: "dish",
            cn: "盘子；一道菜",
            cn_pronunciation: "[pánzi；yídàocài]",
            jp: "大皿 ; 料理",
            jp_pronunciation: "[おおざら ; りょうり]",
            ko: "큰 접시；(요리)한 접시"
         },
         {
            id: 23,
            en: "disk",
            cn: "磁盘",
            cn_pronunciation: "[cípán]",
            jp: "ディスク",
            jp_pronunciation: "[ディスク]",
            ko: "디스크"
         },
         {
            id: 24,
            en: "doll",
            cn: "玩具娃娃",
            cn_pronunciation: "[wánjùwáwá]",
            jp: "人形",
            jp_pronunciation: "[にんぎょう]",
            ko: "장난감 인형"
         },
         {
            id: 25,
            en: "dollar",
            cn: "美元",
            cn_pronunciation: "[měiyuán]",
            jp: "ドル",
            jp_pronunciation: "[ドル]",
            ko: "미국 달러"
         },
         {
            id: 26,
            en: "door",
            cn: "门",
            cn_pronunciation: "[mén]",
            jp: "ドア",
            jp_pronunciation: "[ドア]",
            ko: "문"
         },
         {
            id: 27,
            en: "fan",
            cn: "扇子；风扇",
            cn_pronunciation: "[shànzi；fēngshàn]",
            jp: "扇 ; 扇風機",
            jp_pronunciation: "[おうぎ ; せんぷうき]",
            ko: "부채；선풍기"
         },
         {
            id: 28,
            en: "fire",
            cn: "火",
            cn_pronunciation: "[huǒ]",
            jp: "火",
            jp_pronunciation: "[ひ]",
            ko: "불"
         },
         {
            id: 29,
            en: "folder",
            cn: "文件夹",
            cn_pronunciation: "[wénjiànjiā]",
            jp: "フォルダー",
            jp_pronunciation: "[フォルダー]",
            ko: "서류철, 폴더"
         },
         {
            id: 30,
            en: "fork",
            cn: "叉子",
            cn_pronunciation: "[chāzi]",
            jp: "フォーク",
            jp_pronunciation: "[フォーク]",
            ko: "포크"
         },
         {
            id: 31,
            en: "fridge",
            cn: "冰箱",
            cn_pronunciation: "[bīngxiāng]",
            jp: "冷蔵庫",
            jp_pronunciation: "[れいぞうこ]",
            ko: "냉장고"
         },
         {
            id: 32,
            en: "furniture",
            cn: "家具",
            cn_pronunciation: "[jiāju]",
            jp: "家具",
            jp_pronunciation: "[かぐ]",
            ko: "가구"
         },
         {
            id: 33,
            en: "glass",
            cn: "玻璃 ; 玻璃杯",
            cn_pronunciation: "[bōli ; bólibēi]",
            jp: "ガラス ; コップ",
            jp_pronunciation: "[ガラス ; コップ]",
            ko: "유리 ; 유리잔"
         },
         {
            id: 34,
            en: "glasses",
            cn: "眼镜",
            cn_pronunciation: "[yǎnjìng]",
            jp: "眼鏡",
            jp_pronunciation: "[めがね]",
            ko: "안경"
         },
         {
            id: 35,
            en: "glue",
            cn: "胶水",
            cn_pronunciation: "[jiāoshuǐ]",
            jp: "膠",
            jp_pronunciation: "[にかわ]",
            ko: "접착제"
         },
         {
            id: 36,
            en: "invitation",
            cn: "请柬；邀请卡",
            cn_pronunciation: "[qǐngjiǎn；yāoqĭngkă]",
            jp: "招待状",
            jp_pronunciation: "[しょうたいじょう]",
            ko: "초청장"
         },
         {
            id: 37,
            en: "jungle gym",
            cn: "攀登架",
            cn_pronunciation: "[pāndēngjià]",
            jp: "ジャングルジム",
            jp_pronunciation: "[ジャングルジム]",
            ko: "정글짐"
         },
         {
            id: 38,
            en: "key",
            cn: "钥匙",
            cn_pronunciation: "[yàoshi]",
            jp: "かぎ",
            jp_pronunciation: "[かぎ]",
            ko: "열쇠"
         },
         {
            id: 39,
            en: "kite",
            cn: "风筝",
            cn_pronunciation: "[fēngzheng]",
            jp: "凧",
            jp_pronunciation: "[たこ]",
            ko: "연"
         },
         {
            id: 40,
            en: "knife",
            cn: "刀",
            cn_pronunciation: "[dāo]",
            jp: "ナイフ",
            jp_pronunciation: "[ナイフ]",
            ko: "칼"
         },
         {
            id: 41,
            en: "lamp",
            cn: "灯",
            cn_pronunciation: "[dēng]",
            jp: "ランプ",
            jp_pronunciation: "[ランプ]",
            ko: "등"
         },
         {
            id: 42,
            en: "lantern",
            cn: "灯笼",
            cn_pronunciation: "[dēnglong]",
            jp: "ランタン",
            jp_pronunciation: "[ランタン]",
            ko: "랜턴"
         },
         {
            id: 43,
            en: "light",
            cn: "电灯",
            cn_pronunciation: "[diàn dēng]",
            jp: "電灯",
            jp_pronunciation: "[でんとう]",
            ko: "전등"
         },
         {
            id: 44,
            en: "litter bin",
            cn: "垃圾箱",
            cn_pronunciation: "[lājīxiāng]",
            jp: "ごみ箱",
            jp_pronunciation: "[ごみばこ]",
            ko: "쓰레기통"
         },
         {
            id: 45,
            en: "lock",
            cn: "锁",
            cn_pronunciation: "[suǒ]",
            jp: "錠",
            jp_pronunciation: "[じょう]",
            ko: "자물쇠"
         },
         {
            id: 46,
            en: "magazine",
            cn: "杂志",
            cn_pronunciation: "[zázhì]",
            jp: "雑誌",
            jp_pronunciation: "[ざっし]",
            ko: "잡지"
         },
         {
            id: 47,
            en: "mail",
            cn: "邮件",
            cn_pronunciation: "[yóujiàn]",
            jp: "メール",
            jp_pronunciation: "[メール]",
            ko: "메일"
         },
         {
            id: 48,
            en: "mailbox",
            cn: "信箱",
            cn_pronunciation: "[xìnxiāng]",
            jp: "郵便ポスト",
            jp_pronunciation: "[ゆうびんポスト]",
            ko: "우체통"
         },
         {
            id: 49,
            en: "newspaper",
            cn: "报纸",
            cn_pronunciation: "[bàozhǐ]",
            jp: "新聞",
            jp_pronunciation: "[しんぶん]",
            ko: "신문"
         },
         {
            id: 50,
            en: "notebook",
            cn: "笔记本",
            cn_pronunciation: "[bǐjìběn]",
            jp: "ノート",
            jp_pronunciation: "[ノート]",
            ko: "노트"
         },
         {
            id: 51,
            en: "packet",
            cn: "小包",
            cn_pronunciation: "[xiǎobāo]",
            jp: "小包",
            jp_pronunciation: "[こづつみ]",
            ko: "소포"
         },
         {
            id: 52,
            en: "phone",
            cn: "电话",
            cn_pronunciation: "[diànhuà]",
            jp: "電話",
            jp_pronunciation: "[でんわ]",
            ko: "전화"
         },
         {
            id: 53,
            en: "photograph",
            cn: "照片",
            cn_pronunciation: "[zhàopiàn]",
            jp: "写真",
            jp_pronunciation: "[しゃしん]",
            ko: "사진"
         },
         {
            id: 54,
            en: "plate",
            cn: "盘子",
            cn_pronunciation: "[pánzi]",
            jp: "皿",
            jp_pronunciation: "[さら]",
            ko: "접시"
         },
         {
            id: 55,
            en: "pot",
            cn: "锅",
            cn_pronunciation: "[guō]",
            jp: "ポット",
            jp_pronunciation: "[ポット]",
            ko: "냄비"
         },
         {
            id: 56,
            en: "present",
            cn: "礼物",
            cn_pronunciation: "[lǐwù]",
            jp: "贈物",
            jp_pronunciation: "[おくりもの]",
            ko: "선물"
         },
         {
            id: 57,
            en: "radio",
            cn: "收音机",
            cn_pronunciation: "[shōuyīnjī]",
            jp: "ラジオ",
            jp_pronunciation: "[ラジオ]",
            ko: "라디오"
         },
         {
            id: 58,
            en: "rope",
            cn: "绳子",
            cn_pronunciation: "[shéngzi]",
            jp: "ロープ",
            jp_pronunciation: "[ロープ]",
            ko: "밧줄"
         },
         {
            id: 59,
            en: "scissors",
            cn: "剪刀",
            cn_pronunciation: "[jiǎndāo]",
            jp: "はさみ",
            jp_pronunciation: "[はさみ]",
            ko: "가위"
         },
         {
            id: 60,
            en: "seed",
            cn: "种子",
            cn_pronunciation: "[zhǒngzi]",
            jp: "種",
            jp_pronunciation: "[たね]",
            ko: "종자"
         },
         {
            id: 61,
            en: "seesaw",
            cn: "秋千",
            cn_pronunciation: "[qiūqiān]",
            jp: "シーソー",
            jp_pronunciation: "[シーソー]",
            ko: "그네"
         },
         {
            id: 62,
            en: "sheet",
            cn: "床单",
            cn_pronunciation: "[chuángdān]",
            jp: "シーツ",
            jp_pronunciation: "[シーツ]",
            ko: "(침대)시트"
         },
         {
            id: 63,
            en: "shelf",
            cn: "架子",
            cn_pronunciation: "[jiàzi]",
            jp: "棚",
            jp_pronunciation: "[たな]",
            ko: "선반"
         },
         {
            id: 64,
            en: "silk",
            cn: "丝",
            cn_pronunciation: "[sī]",
            jp: "シルク",
            jp_pronunciation: "[シルク]",
            ko: "실크"
         },
         {
            id: 65,
            en: "slipper",
            cn: "拖鞋",
            cn_pronunciation: "[tuōxié]",
            jp: "スリッパ",
            jp_pronunciation: "[スリッパ]",
            ko: "슬리퍼"
         },
         {
            id: 66,
            en: "soap",
            cn: "肥皂",
            cn_pronunciation: "[féizào]",
            jp: "石鹸",
            jp_pronunciation: "[せっけん]",
            ko: "비누"
         },
         {
            id: 67,
            en: "sofa",
            cn: "沙发",
            cn_pronunciation: "[shāfā]",
            jp: "ソファ",
            jp_pronunciation: "[ソファ]",
            ko: "소파"
         },
         {
            id: 68,
            en: "souvenir",
            cn: "纪念品",
            cn_pronunciation: "[jìniànpǐn]",
            jp: "記念品",
            jp_pronunciation: "[きねんひん]",
            ko: "기념품"
         },
         {
            id: 69,
            en: "spoon",
            cn: "匙",
            cn_pronunciation: "[chí]",
            jp: "スプーン",
            jp_pronunciation: "[スプーン]",
            ko: "숟가락, 스푼"
         },
         {
            id: 70,
            en: "stamp",
            cn: "邮票",
            cn_pronunciation: "[yóupiào]",
            jp: "郵便切手",
            jp_pronunciation: "[ゆうびんきって]",
            ko: "우표"
         },
         {
            id: 71,
            en: "stapler",
            cn: "订书器",
            cn_pronunciation: "[dìngshūqì]",
            jp: "ホッチキス",
            jp_pronunciation: "[ホッチキス]",
            ko: "스테이플러, 호치키스"
         },
         {
            id: 72,
            en: "stick",
            cn: "棒；棍",
            cn_pronunciation: "[bàng；gùn]",
            jp: "棒",
            jp_pronunciation: "[ぼう]",
            ko: "막대기"
         },
         {
            id: 73,
            en: "sunglasses",
            cn: "墨镜",
            cn_pronunciation: "[mòjìng]",
            jp: "サングラス",
            jp_pronunciation: "[サングラス]",
            ko: "선글라스"
         },
         {
            id: 74,
            en: "table",
            cn: "桌子",
            cn_pronunciation: "[zhuōzi]",
            jp: "テーブル",
            jp_pronunciation: "[テーブル]",
            ko: "테이블"
         },
         {
            id: 75,
            en: "telephone",
            cn: "电话",
            cn_pronunciation: "[diànhuà]",
            jp: "電話",
            jp_pronunciation: "[でんわ]",
            ko: "전화"
         },
         {
            id: 76,
            en: "television",
            cn: "电视机",
            cn_pronunciation: "[diànshìjī]",
            jp: "テレビ",
            jp_pronunciation: "[テレビ]",
            ko: "텔레비전"
         },
         {
            id: 77,
            en: "thing",
            cn: "东西；物品",
            cn_pronunciation: "[dōngxi；wùpǐn]",
            jp: "品物",
            jp_pronunciation: "[しなもの]",
            ko: "물건；물품"
         },
         {
            id: 78,
            en: "ticket",
            cn: "票",
            cn_pronunciation: "[piào]",
            jp: "きっぷ ; チケット",
            jp_pronunciation: "[きっぷ ; チケット]",
            ko: "티켓"
         },
         {
            id: 79,
            en: "tin",
            cn: "罐头盒",
            cn_pronunciation: "[guàntouhé]",
            jp: "缶",
            jp_pronunciation: "[カン]",
            ko: "캔"
         },
         {
            id: 80,
            en: "toothbrush",
            cn: "牙刷",
            cn_pronunciation: "[yáshuā]",
            jp: "歯ブラシ",
            jp_pronunciation: "[はブラシ]",
            ko: "칫솔"
         },
         {
            id: 81,
            en: "toy",
            cn: "玩具",
            cn_pronunciation: "[wánjù]",
            jp: "玩具",
            jp_pronunciation: "[おもちゃ]",
            ko: "장난감, 완구"
         },
         {
            id: 82,
            en: "umbrella",
            cn: "雨伞；阳伞",
            cn_pronunciation: "[yǔsǎn；yángsǎn]",
            jp: "傘",
            jp_pronunciation: "[かさ]",
            ko: "우산；양산"
         },
         {
            id: 83,
            en: "video",
            cn: "录像机",
            cn_pronunciation: "[lùxiàngjī]",
            jp: "ビデオ",
            jp_pronunciation: "[ビデオ]",
            ko: "비디오"
         },
         {
            id: 84,
            en: "whiteout",
            cn: "涂改液",
            cn_pronunciation: "[túgǎiyè]",
            jp: "ホワイト",
            jp_pronunciation: "[ホワイト]",
            ko: "수정액"
         }
      ]
   },
   {
      id: 10,
      title: 'demonstrative pronoun',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "demonstrative pronoun",
            cn: "指示代词",
            cn_pronunciation: "[zhǐshìdàicí]",
            jp: "指示代名詞",
            jp_pronunciation: "[しじだいめいし]",
            ko: "지시대명사"
         },
         {
            id: 2,
            en: "this",
            cn: "这个",
            cn_pronunciation: "[zhègè]",
            jp: "これ",
            jp_pronunciation: "[これ]",
            ko: "이것"
         },
         {
            id: 3,
            en: "that",
            cn: "那个",
            cn_pronunciation: "[nàgè]",
            jp: "それ",
            jp_pronunciation: "[それ]",
            ko: "저것"
         },
         {
            id: 4,
            en: "these",
            cn: "这些",
            cn_pronunciation: "[zhèxiē]",
            jp: "これら",
            jp_pronunciation: "[これら]",
            ko: "이것들"
         },
         {
            id: 5,
            en: "those",
            cn: "那些",
            cn_pronunciation: "[nàxiē]",
            jp: "それら",
            jp_pronunciation: "[それら]",
            ko: "저것들"
         },
         {
            id: 6,
            en: "interrogative",
            cn: "疑问词",
            cn_pronunciation: "[yíwèncí]",
            jp: "疑問詞",
            jp_pronunciation: "[ぎもんし]",
            ko: "의문사"
         },
         {
            id: 7,
            en: "who",
            cn: "谁",
            cn_pronunciation: "[shuí]",
            jp: "誰",
            jp_pronunciation: "[だれ]",
            ko: "누구"
         },
         {
            id: 8,
            en: "whom",
            cn: "谁",
            cn_pronunciation: "[shuí]",
            jp: "誰を",
            jp_pronunciation: "[だれを]",
            ko: "누구를"
         },
         {
            id: 9,
            en: "which",
            cn: "哪一个",
            cn_pronunciation: "[nǎyīgè]",
            jp: "どちら",
            jp_pronunciation: "[どちら]",
            ko: "어느것"
         },
         {
            id: 10,
            en: "whose",
            cn: "谁的",
            cn_pronunciation: "[shuíde]",
            jp: "誰の",
            jp_pronunciation: "[だれの]",
            ko: "누구의"
         },
         {
            id: 11,
            en: "where",
            cn: "哪里",
            cn_pronunciation: "[nǎlǐ]",
            jp: "どこで",
            jp_pronunciation: "[どこで]",
            ko: "어디에"
         },
         {
            id: 12,
            en: "when",
            cn: "什么时候",
            cn_pronunciation: "[shénme shíhòu]",
            jp: "いつ",
            jp_pronunciation: "[いつ]",
            ko: "언제"
         },
         {
            id: 13,
            en: "why",
            cn: "为什么",
            cn_pronunciation: "[wéishíme]",
            jp: "なぜ",
            jp_pronunciation: "[なぜ]",
            ko: "왜"
         },
         {
            id: 14,
            en: "what",
            cn: "什么",
            cn_pronunciation: "[shénme]",
            jp: "何",
            jp_pronunciation: "[なに]",
            ko: "무엇"
         },
         {
            id: 15,
            en: "how",
            cn: "怎么",
            cn_pronunciation: "[zěnme]",
            jp: "どのように",
            jp_pronunciation: "[どのように]",
            ko: "어떻게"
         }
      ]
   },
   {
      id: 11,
      title: 'drinks',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "drinks",
            cn: "饮料",
            cn_pronunciation: "[yǐnliào]",
            jp: "飲み物",
            jp_pronunciation: "[のみもの]",
            ko: "음료"
         },
         {
            id: 2,
            en: "beer",
            cn: "啤酒",
            cn_pronunciation: "[píjiǔ]",
            jp: "ビール",
            jp_pronunciation: "[ビール]",
            ko: "맥주"
         },
         {
            id: 3,
            en: "coffee",
            cn: "咖啡",
            cn_pronunciation: "[kāfēi]",
            jp: "コーヒー",
            jp_pronunciation: "[コーヒー]",
            ko: "커피"
         },
         {
            id: 4,
            en: "Coca-Cola",
            cn: "可口可乐",
            cn_pronunciation: "[kěkǒu kělè]",
            jp: "コカコーラ",
            jp_pronunciation: "[コカコーラ]",
            ko: "코카콜라"
         },
         {
            id: 5,
            en: "cola",
            cn: "可乐",
            cn_pronunciation: "[kělè]",
            jp: "コーラ",
            jp_pronunciation: "[コーラ]",
            ko: "콜라"
         },
         {
            id: 6,
            en: "ice cream",
            cn: "冰淇淋",
            cn_pronunciation: "[bīngqílín]",
            jp: "アイスクリーム",
            jp_pronunciation: "[アイスクリーム]",
            ko: "아이스크림"
         },
         {
            id: 7,
            en: "juice",
            cn: "果汁",
            cn_pronunciation: "[guǒzhī]",
            jp: "ジュース",
            jp_pronunciation: "[ジュース]",
            ko: "과일주스"
         },
         {
            id: 8,
            en: "milk",
            cn: "牛奶",
            cn_pronunciation: "[niúnǎi]",
            jp: "牛乳",
            jp_pronunciation: "[ぎゅうにゅう]",
            ko: "우유"
         },
         {
            id: 9,
            en: "tea",
            cn: "茶",
            cn_pronunciation: "[chá]",
            jp: "茶",
            jp_pronunciation: "[ちゃ]",
            ko: "차"
         },
         {
            id: 10,
            en: "water",
            cn: "水",
            cn_pronunciation: "[shuǐ]",
            jp: "水",
            jp_pronunciation: "[みず]",
            ko: "물"
         }
      ]
   },
   {
      id: 12,
      title: 'entertainment',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "tourist attraction",
            cn: "景点",
            cn_pronunciation: "[jǐngdiǎn]",
            jp: "観光地",
            jp_pronunciation: "[かんこうち]",
            ko: "관광지"
         },
         {
            id: 2,
            en: "Buckingham Place",
            cn: "白金汉宫",
            cn_pronunciation: "[báijīnhàngōng]",
            jp: "バッキンガム・プレイス",
            jp_pronunciation: "[バッキンガム・プレイス]",
            ko: "버킹검궁전"
         },
         {
            id: 3,
            en: "Hyde Park",
            cn: "海德公园",
            cn_pronunciation: "[hǎidégōngyuán]",
            jp: "ハイドパーク",
            jp_pronunciation: "[ハイドパーク]",
            ko: "하이드공원"
         },
         {
            id: 4,
            en: "the London Eye",
            cn: "伦敦眼",
            cn_pronunciation: "[lúndūnyǎn]",
            jp: "ロンドン・アイ",
            jp_pronunciation: "[ロンドン・アイ]",
            ko: "런던아이"
         },
         {
            id: 5,
            en: "River Thames",
            cn: "泰晤士河",
            cn_pronunciation: "[tàiwùshìhé]",
            jp: "テムズ川",
            jp_pronunciation: "[テムズがわ]",
            ko: "탬즈강"
         },
         {
            id: 6,
            en: "Tower Bridge",
            cn: "塔桥",
            cn_pronunciation: "[tǎqiáo]",
            jp: "タワーブリッジ",
            jp_pronunciation: "[タワーブリッジ]",
            ko: "타워 브리지"
         },
         {
            id: 7,
            en: "Big Ben",
            cn: "大本钟",
            cn_pronunciation: "[dàběnzhōng]",
            jp: "ビッグベン",
            jp_pronunciation: "[ビッグベン]",
            ko: "빅벤"
         },
         {
            id: 8,
            en: "N Seoul Tower",
            cn: "N首尔塔",
            cn_pronunciation: "[Nshǒuěrtǎ]",
            jp: "Nソウルタワー",
            jp_pronunciation: "[Nソウルタワー]",
            ko: "N서울타워"
         },
         {
            id: 9,
            en: "Gwanghwamun Gate",
            cn: "光化门",
            cn_pronunciation: "[guānghuàmén]",
            jp: "クァンファムン",
            jp_pronunciation: "[クァンファムン]",
            ko: "광화문"
         },
         {
            id: 10,
            en: "Gyeongbokgung Palace",
            cn: "景福宫",
            cn_pronunciation: "[jǐngfúgōng]",
            jp: "キョンボックン",
            jp_pronunciation: "[キョンボックン]",
            ko: "경복궁"
         },
         {
            id: 11,
            en: "the Great Wall",
            cn: "长城",
            cn_pronunciation: "[zhǎngchéng]",
            jp: "万里の長城",
            jp_pronunciation: "[ばんりのちょうじょう]",
            ko: "만리장성"
         },
         {
            id: 12,
            en: "Forbidden City",
            cn: "故宫",
            cn_pronunciation: "[gùgōng]",
            jp: "紫禁城",
            jp_pronunciation: "[しきんじょう]",
            ko: "자금성"
         },
         {
            id: 13,
            en: "the Summer Palace",
            cn: "颐和园",
            cn_pronunciation: "[yíhéyuán]",
            jp: "頤和園",
            jp_pronunciation: "[いわえん]",
            ko: "이화원"
         },
         {
            id: 14,
            en: "pyramid",
            cn: "金字塔",
            cn_pronunciation: "[jīnzìtǎ]",
            jp: "ピラミッド",
            jp_pronunciation: "[ピラミッド]",
            ko: "피라미드"
         },
         {
            id: 15,
            en: "Tokyo Tower",
            cn: "东京塔",
            cn_pronunciation: "[dōngjīngtǎ]",
            jp: "東京タワー",
            jp_pronunciation: "[とうきょうタワー]",
            ko: "도쿄 타워"
         },
         {
            id: 16,
            en: "Kyoto",
            cn: "京都",
            cn_pronunciation: "[jīngdōu]",
            jp: "京都",
            jp_pronunciation: "[きょうと]",
            ko: "교토"
         },
         {
            id: 17,
            en: "Statue of Liberty",
            cn: "自由女神像",
            cn_pronunciation: "[zìyóunǚshénxiàng]",
            jp: "自由の女神",
            jp_pronunciation: "[じゆうのめがみぞう]",
            ko: "자유의여신상"
         }
      ]
   },
   {
      id: 13,
      title: 'family',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "family",
            cn: "家",
            cn_pronunciation: "[jiā]",
            jp: "家族",
            jp_pronunciation: "[かぞく]",
            ko: "가족"
         },
         {
            id: 2,
            en: "aunt",
            cn: "姑姑；姨；伯母；舅母",
            cn_pronunciation: "[gūgu；yí；bómǔ；jiùmǔ]",
            jp: "叔母",
            jp_pronunciation: "[おば]",
            ko: "고모, 이모, (외)숙모"
         },
         {
            id: 3,
            en: "baby",
            cn: "幼儿",
            cn_pronunciation: "[yòu’ér]",
            jp: "赤ちゃん",
            jp_pronunciation: "[あかちゃん]",
            ko: "아기"
         },
         {
            id: 4,
            en: "brother",
            cn: "兄；弟",
            cn_pronunciation: "[xiōng；dì]",
            jp: "兄、弟",
            jp_pronunciation: "[あに；おとうと]",
            ko: "형, 오빠, 남동생"
         },
         {
            id: 5,
            en: "child",
            cn: "子女；孩子；儿童",
            cn_pronunciation: "[zǐnǚ；háizi；értóng]",
            jp: "子ども",
            jp_pronunciation: "[こども]",
            ko: "아이"
         },
         {
            id: 6,
            en: "cousin",
            cn: "堂兄弟  ,表（堂）兄（第姐妹）",
            cn_pronunciation: "[tángxiōngdì, biǎo（táng）xiōng（dì jiěmèi）]",
            jp: "いとこ",
            jp_pronunciation: "[いとこ]",
            ko: "사촌"
         },
         {
            id: 7,
            en: "dad",
            cn: "爸爸",
            cn_pronunciation: "[bàba]",
            jp: "ちち",
            jp_pronunciation: "[ちち]",
            ko: "아빠"
         },
         {
            id: 8,
            en: "daughter",
            cn: "女儿",
            cn_pronunciation: "[nǚ’ér]",
            jp: "娘",
            jp_pronunciation: "[むすめ]",
            ko: "딸"
         },
         {
            id: 9,
            en: "father",
            cn: "父亲",
            cn_pronunciation: "[fùqīn]",
            jp: "父親",
            jp_pronunciation: "[ちちおや]",
            ko: "아버지"
         },
         {
            id: 10,
            en: "granddaughter",
            cn: "(外) 孙女",
            cn_pronunciation: "[(wài) sūnnǚ]",
            jp: "孫娘",
            jp_pronunciation: "[まごむすめ]",
            ko: "손녀, 외손녀"
         },
         {
            id: 11,
            en: "grandfather",
            cn: "祖父；外祖父",
            cn_pronunciation: "[zǔfù；wàizǔfù]",
            jp: "祖父；外祖父",
            jp_pronunciation: "[そふ；がいそふ]",
            ko: "할아버지, 외할아버지"
         },
         {
            id: 12,
            en: "grandmother",
            cn: "祖母；外祖母",
            cn_pronunciation: "[zǔmǔ；wàizǔmǔ]",
            jp: "祖母；外祖母",
            jp_pronunciation: "[そぼ；がいそぼ]",
            ko: "할머니, 외할머니"
         },
         {
            id: 13,
            en: "grandparents",
            cn: "祖父母",
            cn_pronunciation: "[zǔfùmǔ]",
            jp: "祖父母",
            jp_pronunciation: "[そふぼ]",
            ko: "(외)조부[모], (외)할아버지[할머니]"
         },
         {
            id: 14,
            en: "grandson",
            cn: "孙子；外孙",
            cn_pronunciation: "[sūnzi；wàisūn]",
            jp: "孫息子",
            jp_pronunciation: "[まごむすこ]",
            ko: "손자, 외손자"
         },
         {
            id: 15,
            en: "husband",
            cn: "丈夫",
            cn_pronunciation: "[zhàngfu]",
            jp: "夫",
            jp_pronunciation: "[おっと]",
            ko: "남편"
         },
         {
            id: 16,
            en: "mother",
            cn: "母亲",
            cn_pronunciation: "[mǔqīn]",
            jp: "母親",
            jp_pronunciation: "[ははおや]",
            ko: "어머니"
         },
         {
            id: 17,
            en: "mum",
            cn: "妈妈",
            cn_pronunciation: "[māma]",
            jp: "はは",
            jp_pronunciation: "[はは]",
            ko: "엄마"
         },
         {
            id: 18,
            en: "parents",
            cn: "父母",
            cn_pronunciation: "[fùmǔ]",
            jp: "両親",
            jp_pronunciation: "[りょうしん]",
            ko: "부모"
         },
         {
            id: 19,
            en: "sister",
            cn: "姐妹 ,姐姐；妹妹",
            cn_pronunciation: "[jiĕmèi , jiĕjie；mèimei]",
            jp: "姉妹、姉、妹",
            jp_pronunciation: "[しまい ,あね；いもうと]",
            ko: "언니, 누나, 여동생"
         },
         {
            id: 20,
            en: "son",
            cn: "儿子",
            cn_pronunciation: "[érzi]",
            jp: "息子",
            jp_pronunciation: "[むすこ]",
            ko: "아들"
         },
         {
            id: 21,
            en: "twins",
            cn: "双胞胎",
            cn_pronunciation: "[shuāngbāotāi]",
            jp: "ツインズ, 双子",
            jp_pronunciation: "[ふたご]",
            ko: "쌍둥이"
         },
         {
            id: 22,
            en: "twin",
            cn: "双胞胎之一",
            cn_pronunciation: "[shuāngbāotāizhīyī]",
            jp: "双子の片方",
            jp_pronunciation: "[ふたごのかたほう]",
            ko: "쌍둥이 (중의 한 명)"
         },
         {
            id: 23,
            en: "uncle",
            cn: "伯父；叔叔；舅；姑父",
            cn_pronunciation: "[bófù；shūshū；jiù；gūfù]",
            jp: "叔父",
            jp_pronunciation: "[おじ]",
            ko: "삼촌, 외삼촌, 고모부, 이모부"
         },
         {
            id: 24,
            en: "wife",
            cn: "妻子",
            cn_pronunciation: "[qīzi]",
            jp: "妻",
            jp_pronunciation: "[つま]",
            ko: "아내"
         }
      ]
   },
   {
      id: 14,
      title: 'feeling',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "feeling",
            cn: "感觉",
            cn_pronunciation: "[gǎnjué]",
            jp: "感じ",
            jp_pronunciation: "[かんじ]",
            ko: "느낌"
         },
         {
            id: 2,
            en: "sad",
            cn: "悲伤的",
            cn_pronunciation: "[bēishāngde]",
            jp: "悲しい",
            jp_pronunciation: "[かなしい]",
            ko: "슬픈"
         },
         {
            id: 3,
            en: "hungry",
            cn: "恶的",
            cn_pronunciation: "[ède]",
            jp: "空腹な,お腹がすいている",
            jp_pronunciation: "[くうふくな、おなかがすいている]",
            ko: "배고픈"
         },
         {
            id: 4,
            en: "thirsty",
            cn: "渴的",
            cn_pronunciation: "[kěde]",
            jp: "喉が渇いた",
            jp_pronunciation: "[のどがかわいた]",
            ko: "목마른"
         },
         {
            id: 5,
            en: "tired",
            cn: "累的",
            cn_pronunciation: "[lèide]",
            jp: "疲れた",
            jp_pronunciation: "[つかれた]",
            ko: "피곤한"
         },
         {
            id: 6,
            en: "shy",
            cn: "害羞的",
            cn_pronunciation: "[hàixiūde]",
            jp: "内気な, 恥ずかしがりの",
            jp_pronunciation: "[うちきな, はずかしがりの]",
            ko: "수줍은"
         },
         {
            id: 7,
            en: "exciting",
            cn: "兴奋的",
            cn_pronunciation: "[xìngfènde]",
            jp: "(人を)興奮させる",
            jp_pronunciation: "[(ひとを)こうふんさせる]",
            ko: "흥분시키는"
         },
         {
            id: 8,
            en: "happy",
            cn: "高兴的",
            cn_pronunciation: "[gāoxìngde]",
            jp: "幸せな",
            jp_pronunciation: "[しあわせな]",
            ko: "행복한"
         },
         {
            id: 9,
            en: "angry",
            cn: "生气的",
            cn_pronunciation: "[shēngqìde]",
            jp: "怒った",
            jp_pronunciation: "[おっこた]",
            ko: "화난"
         },
         {
            id: 10,
            en: "bored",
            cn: "无聊的",
            cn_pronunciation: "[wúliáode]",
            jp: "退屈する",
            jp_pronunciation: "[たいくつした]",
            ko: "지루한"
         },
         {
            id: 11,
            en: "excited",
            cn: "兴奋的",
            cn_pronunciation: "[xìngfènde]",
            jp: "興奮した",
            jp_pronunciation: "[こうふんした]",
            ko: "흥분한"
         },
         {
            id: 12,
            en: "nervous",
            cn: "紧张的",
            cn_pronunciation: "[jǐnzhāngde]",
            jp: "神経質な",
            jp_pronunciation: "[しんけいしつな]",
            ko: "초조해하는"
         },
         {
            id: 13,
            en: "normal",
            cn: "正常的",
            cn_pronunciation: "[zhèngchángde]",
            jp: "普通の",
            jp_pronunciation: "[ふつうの]",
            ko: "보통의"
         },
         {
            id: 14,
            en: "pleased",
            cn: "高兴的",
            cn_pronunciation: "[gāoxìngde]",
            jp: "うれしい",
            jp_pronunciation: "[うれしい]",
            ko: "기쁜"
         }
      ]
   },
   {
      id: 15,
      title: 'food',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "food",
            cn: "食物",
            cn_pronunciation: "[shíwù]",
            jp: "食べ物",
            jp_pronunciation: "[たべもの]",
            ko: "음식"
         },
         {
            id: 2,
            en: "biscuit",
            cn: "饼干",
            cn_pronunciation: "[bǐnggàn]",
            jp: "ビスケット",
            jp_pronunciation: "[ビスケット]",
            ko: "비스킷"
         },
         {
            id: 3,
            en: "beef",
            cn: "牛肉",
            cn_pronunciation: "[niúròu]",
            jp: "ビーフ、牛肉",
            jp_pronunciation: "[ビーフ、ぎゅうにく]",
            ko: "소고기"
         },
         {
            id: 4,
            en: "bread",
            cn: "面包",
            cn_pronunciation: "[miànbāo]",
            jp: "パン",
            jp_pronunciation: "[パン]",
            ko: "빵"
         },
         {
            id: 5,
            en: "butter",
            cn: "黄油",
            cn_pronunciation: "[huángyóu]",
            jp: "バター",
            jp_pronunciation: "[バター]",
            ko: "버터"
         },
         {
            id: 6,
            en: "cake",
            cn: "蛋糕",
            cn_pronunciation: "[dàngāo]",
            jp: "ケーキ",
            jp_pronunciation: "[ケーキ]",
            ko: "케이크"
         },
         {
            id: 7,
            en: "candy",
            cn: "糖果（美语 sweets）",
            cn_pronunciation: "[tángguǒ（měiyǔsweets）]",
            jp: "キャンディー",
            jp_pronunciation: "[キャンディー]",
            ko: "캔디"
         },
         {
            id: 8,
            en: "cheese",
            cn: "奶酪",
            cn_pronunciation: "[nǎilào]",
            jp: "チーズ",
            jp_pronunciation: "[チーズ]",
            ko: "치즈"
         },
         {
            id: 9,
            en: "chicken",
            cn: "鸡肉",
            cn_pronunciation: "[jīròu]",
            jp: "チキン",
            jp_pronunciation: "[チキン]",
            ko: "치킨"
         },
         {
            id: 10,
            en: "chips",
            cn: "炸薯条",
            cn_pronunciation: "[zhàshǔtiáo]",
            jp: "チップス",
            jp_pronunciation: "[チップス]",
            ko: "감자튀김"
         },
         {
            id: 11,
            en: "chocolate",
            cn: "巧克力",
            cn_pronunciation: "[qiǎokèlì]",
            jp: "チョコレート",
            jp_pronunciation: "[チョコレート]",
            ko: "초콜릿"
         },
         {
            id: 12,
            en: "coffee",
            cn: "咖啡",
            cn_pronunciation: "[kāfēi]",
            jp: "コーヒー",
            jp_pronunciation: "[コーヒー]",
            ko: "커피"
         },
         {
            id: 13,
            en: "cola",
            cn: "可乐",
            cn_pronunciation: "[kělè]",
            jp: "コーラ",
            jp_pronunciation: "[コーラ]",
            ko: "콜라"
         },
         {
            id: 14,
            en: "cookie",
            cn: "饼干",
            cn_pronunciation: "[bǐnggàn]",
            jp: "クッキー",
            jp_pronunciation: "[クッキー]",
            ko: "쿠키"
         },
         {
            id: 15,
            en: "cream",
            cn: "奶油",
            cn_pronunciation: "[nǎiyóu]",
            jp: "クリーム",
            jp_pronunciation: "[クリーム]",
            ko: "크림"
         },
         {
            id: 16,
            en: "drink",
            cn: "饮料",
            cn_pronunciation: "[yǐnliào]",
            jp: "飲み物",
            jp_pronunciation: "[のみもの]",
            ko: "음료수"
         },
         {
            id: 17,
            en: "dumpling",
            cn: "饺子",
            cn_pronunciation: "[jiǎozǐ]",
            jp: "ダンプリング",
            jp_pronunciation: "[ダンプリング]",
            ko: "덤플링"
         },
         {
            id: 18,
            en: "egg",
            cn: "鸡蛋",
            cn_pronunciation: "[jīdàn]",
            jp: "卵",
            jp_pronunciation: "[たまご]",
            ko: "계란"
         },
         {
            id: 19,
            en: "fast food",
            cn: "快餐",
            cn_pronunciation: "[kuàicān]",
            jp: "ファーストフード",
            jp_pronunciation: "[ファーストフード]",
            ko: "패스트푸트"
         },
         {
            id: 20,
            en: "fish",
            cn: "鱼；鱼肉",
            cn_pronunciation: "[yú；yúròu]",
            jp: "魚",
            jp_pronunciation: "[さかな]",
            ko: "생선"
         },
         {
            id: 21,
            en: "food",
            cn: "食物；食品",
            cn_pronunciation: "[shíwù；shípǐn]",
            jp: "食べ物",
            jp_pronunciation: "[たべもの]",
            ko: "음식"
         },
         {
            id: 22,
            en: "fruitjelly",
            cn: "果冻",
            cn_pronunciation: "[guǒdòng]",
            jp: "フルーツゼリー",
            jp_pronunciation: "[フルーツゼリー]",
            ko: "프루트젤리"
         },
         {
            id: 23,
            en: "ginger",
            cn: "姜",
            cn_pronunciation: "[jiāng]",
            jp: "ショウガ",
            jp_pronunciation: "[ショウガ]",
            ko: "생강"
         },
         {
            id: 24,
            en: "hamburger",
            cn: "汉堡包",
            cn_pronunciation: "[hànbǎobāo]",
            jp: "ハンバーガー",
            jp_pronunciation: "[ハンバーガー]",
            ko: "햄버거"
         },
         {
            id: 25,
            en: "hot dog",
            cn: "热狗",
            cn_pronunciation: "[règǒu]",
            jp: "ホットドッグ",
            jp_pronunciation: "[ホットドッグ]",
            ko: "핫도그"
         },
         {
            id: 26,
            en: "ice cream",
            cn: "冰淇凌",
            cn_pronunciation: "[bīngqílíng]",
            jp: "アイスクリーム",
            jp_pronunciation: "[アイスクリーム]",
            ko: "아이스크림"
         },
         {
            id: 27,
            en: "jam",
            cn: "果酱",
            cn_pronunciation: "[guǒjiàng]",
            jp: "ジャム",
            jp_pronunciation: "[ジャム]",
            ko: "잼"
         },
         {
            id: 28,
            en: "juice",
            cn: "果汁",
            cn_pronunciation: "[guǒzhī]",
            jp: "ジュース",
            jp_pronunciation: "[ジュース]",
            ko: "주스"
         },
         {
            id: 29,
            en: "mantou",
            cn: "馒头",
            cn_pronunciation: "[mántóu]",
            jp: "饅頭",
            jp_pronunciation: "[まんじゅう]",
            ko: "만두"
         },
         {
            id: 30,
            en: "meal",
            cn: "一餐；用餐时间",
            cn_pronunciation: "[yīcān；yòngcānshíjiān]",
            jp: "食事",
            jp_pronunciation: "[しょくじ]",
            ko: "식사"
         },
         {
            id: 31,
            en: "meat",
            cn: "肉",
            cn_pronunciation: "[ròu]",
            jp: "肉",
            jp_pronunciation: "[にく]",
            ko: "고기"
         },
         {
            id: 32,
            en: "meatball",
            cn: "肉丸子",
            cn_pronunciation: "[ròuwánzǐ]",
            jp: "ミットボール",
            jp_pronunciation: "[ミットボール]",
            ko: "미트볼"
         },
         {
            id: 33,
            en: "menu",
            cn: "菜单",
            cn_pronunciation: "[càidān]",
            jp: "メニュー",
            jp_pronunciation: "[メニュー]",
            ko: "메뉴"
         },
         {
            id: 34,
            en: "milk",
            cn: "牛奶",
            cn_pronunciation: "[niúnǎi]",
            jp: "牛乳",
            jp_pronunciation: "[ぎゅうにゅう]",
            ko: "우유"
         },
         {
            id: 35,
            en: "moon cake",
            cn: "月饼",
            cn_pronunciation: "[yuèbǐng]",
            jp: "月餅",
            jp_pronunciation: "[げっぺい]",
            ko: "월병"
         },
         {
            id: 36,
            en: "mutton",
            cn: "羊肉",
            cn_pronunciation: "[yángròu]",
            jp: "羊肉、マトン",
            jp_pronunciation: "[ようにく、マトン]",
            ko: "양고기"
         },
         {
            id: 37,
            en: "noodles",
            cn: "面条",
            cn_pronunciation: "[miàntiáo]",
            jp: "麺",
            jp_pronunciation: "[めん]",
            ko: "면"
         },
         {
            id: 38,
            en: "oil",
            cn: "油",
            cn_pronunciation: "[yóu]",
            jp: "油",
            jp_pronunciation: "[あぶら]",
            ko: "기름"
         },
         {
            id: 39,
            en: "pancake",
            cn: "薄饼",
            cn_pronunciation: "[báobǐng]",
            jp: "パンケーキ",
            jp_pronunciation: "[パンケーキ]",
            ko: "팬케이크"
         },
         {
            id: 40,
            en: "peanut",
            cn: "花生",
            cn_pronunciation: "[huāshēng]",
            jp: "ピーナッツ",
            jp_pronunciation: "[ピーナッツ]",
            ko: "땅콩"
         },
         {
            id: 41,
            en: "pie",
            cn: "派,馅饼",
            cn_pronunciation: "[pài,xiànbǐng]",
            jp: "パイ",
            jp_pronunciation: "[パイ]",
            ko: "파이"
         },
         {
            id: 42,
            en: "pizza",
            cn: "比萨",
            cn_pronunciation: "[bǐsà]",
            jp: "ピザ",
            jp_pronunciation: "[ピザ]",
            ko: "피자"
         },
         {
            id: 43,
            en: "popcorn",
            cn: "爆米花",
            cn_pronunciation: "[bàomǐhuā]",
            jp: "ポップコーン",
            jp_pronunciation: "[ポップコーン]",
            ko: "팝콘"
         },
         {
            id: 44,
            en: "pork",
            cn: "猪肉",
            cn_pronunciation: "[zhūròu]",
            jp: "豚肉",
            jp_pronunciation: "[ぶたにく]",
            ko: "돼지고기"
         },
         {
            id: 45,
            en: "pumpkin",
            cn: "南瓜",
            cn_pronunciation: "[nánguā]",
            jp: "カボチャ",
            jp_pronunciation: "[カボチャ]",
            ko: "호박"
         },
         {
            id: 46,
            en: "rice",
            cn: "米饭",
            cn_pronunciation: "[mǐfàn]",
            jp: "ご飯",
            jp_pronunciation: "[ごはん]",
            ko: "밥"
         },
         {
            id: 47,
            en: "salad",
            cn: "色拉",
            cn_pronunciation: "[sèlā]",
            jp: "サラダ",
            jp_pronunciation: "[サラダ]",
            ko: "샐러드"
         },
         {
            id: 48,
            en: "salt",
            cn: "盐；咸",
            cn_pronunciation: "[yán；xián]",
            jp: "塩",
            jp_pronunciation: "[しお]",
            ko: "소금"
         },
         {
            id: 49,
            en: "sandwich",
            cn: "三明治",
            cn_pronunciation: "[sānmíngzhì]",
            jp: "サンドイッチ",
            jp_pronunciation: "[サンドイッチ]",
            ko: "샌드위치"
         },
         {
            id: 50,
            en: "sauce",
            cn: "酱油；调味品",
            cn_pronunciation: "[jiàngyóu；diàowèipǐn]",
            jp: "ソース",
            jp_pronunciation: "[ソース]",
            ko: "소스"
         },
         {
            id: 51,
            en: "sausages",
            cn: "香肠",
            cn_pronunciation: "[xiāngcháng]",
            jp: "ソーセージ",
            jp_pronunciation: "[ソーセージ]",
            ko: "소시지"
         },
         {
            id: 52,
            en: "seeds",
            cn: "瓜子",
            cn_pronunciation: "[guāzǐ]",
            jp: "種",
            jp_pronunciation: "[たね]",
            ko: "씨"
         },
         {
            id: 53,
            en: "soft drink",
            cn: "软饮料",
            cn_pronunciation: "[ruǎnyǐnliào]",
            jp: "ソフトドリンク",
            jp_pronunciation: "[ソフトドリンク]",
            ko: "소프트드링크"
         },
         {
            id: 54,
            en: "soup",
            cn: "汤",
            cn_pronunciation: "[tāng]",
            jp: "スープ",
            jp_pronunciation: "[スープ]",
            ko: "스프"
         },
         {
            id: 55,
            en: "sugar",
            cn: "糖果（美语 sweets）",
            cn_pronunciation: "[tángguǒ（měiyǔsweets）]",
            jp: "砂糖",
            jp_pronunciation: "[さとう]",
            ko: "설탕"
         },
         {
            id: 56,
            en: "sweet",
            cn: "甜的",
            cn_pronunciation: "[tiánde]",
            jp: "甘いもの",
            jp_pronunciation: "[あまいもの]",
            ko: "단 것"
         },
         {
            id: 57,
            en: "sweets",
            cn: "糖果",
            cn_pronunciation: "[tángguǒ]",
            jp: "お菓子",
            jp_pronunciation: "[おかし]",
            ko: "사탕"
         },
         {
            id: 58,
            en: "tea",
            cn: "茶",
            cn_pronunciation: "[chá]",
            jp: "紅茶",
            jp_pronunciation: "[こうちゃ]",
            ko: "차"
         },
         {
            id: 59,
            en: "tofu",
            cn: "豆腐",
            cn_pronunciation: "[dòufǔ]",
            jp: "豆腐",
            jp_pronunciation: "[とうふ]",
            ko: "두부"
         },
         {
            id: 60,
            en: "vegetables",
            cn: "蔬菜",
            cn_pronunciation: "[shūcài]",
            jp: "野菜",
            jp_pronunciation: "[やさい]",
            ko: "야채"
         },
         {
            id: 61,
            en: "vinegar",
            cn: "醋",
            cn_pronunciation: "[cù]",
            jp: "食用酢、ビネガー",
            jp_pronunciation: "[しょくようす、ビネガー]",
            ko: "식초"
         },
         {
            id: 62,
            en: "wine",
            cn: "酒",
            cn_pronunciation: "[jiǔ]",
            jp: "ワイン",
            jp_pronunciation: "[ワイン]",
            ko: "와인"
         }
      ]
   },
   {
      id: 16,
      title: 'fruits',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "fruit",
            cn: "水果",
            cn_pronunciation: "[shuǐguǒ]",
            jp: "果物",
            jp_pronunciation: "[くだもの]",
            ko: "과일"
         },
         {
            id: 2,
            en: "apple",
            cn: "苹果",
            cn_pronunciation: "[píngguǒ]",
            jp: "リンゴ",
            jp_pronunciation: "[リンゴ]",
            ko: "사과"
         },
         {
            id: 3,
            en: "banana",
            cn: "香蕉",
            cn_pronunciation: "[xiāngjiāo]",
            jp: "バナナ",
            jp_pronunciation: "[バナナ]",
            ko: "바나나"
         },
         {
            id: 4,
            en: "coconut",
            cn: "椰子",
            cn_pronunciation: "[yēzǐ]",
            jp: "ココナツ",
            jp_pronunciation: "[ココナツ]",
            ko: "코코넛"
         },
         {
            id: 5,
            en: "grape",
            cn: "葡萄",
            cn_pronunciation: "[pútáo]",
            jp: "ブドウ",
            jp_pronunciation: "[ぶどう]",
            ko: "포도"
         },
         {
            id: 6,
            en: "grapefruit",
            cn: "葡萄柚",
            cn_pronunciation: "[pútáoyòu]",
            jp: "グレープフルーツ",
            jp_pronunciation: "[グレープフルーツ]",
            ko: "그레이프프루트"
         },
         {
            id: 7,
            en: "mango",
            cn: "芒果",
            cn_pronunciation: "[mángguǒ]",
            jp: "マンゴー",
            jp_pronunciation: "[マンゴー]",
            ko: "망고"
         },
         {
            id: 8,
            en: "melon",
            cn: "甜瓜",
            cn_pronunciation: "[tiánguā]",
            jp: "メロン",
            jp_pronunciation: "[メロン]",
            ko: "멜론"
         },
         {
            id: 9,
            en: "orange",
            cn: "橙子",
            cn_pronunciation: "[chéngzǐ]",
            jp: "オレンジ",
            jp_pronunciation: "[オレンジ]",
            ko: "오렌지"
         },
         {
            id: 10,
            en: "peach",
            cn: "桃子",
            cn_pronunciation: "[táozǐ]",
            jp: "桃",
            jp_pronunciation: "[もも]",
            ko: "복숭아"
         },
         {
            id: 11,
            en: "pear",
            cn: "梨子",
            cn_pronunciation: "[lízǐ]",
            jp: "梨",
            jp_pronunciation: "[なし]",
            ko: "배"
         },
         {
            id: 12,
            en: "pineapple",
            cn: "菠萝",
            cn_pronunciation: "[bōluó]",
            jp: "パイナップル",
            jp_pronunciation: "[パイナップル]",
            ko: "파인애플"
         },
         {
            id: 13,
            en: "strawberry",
            cn: "草莓",
            cn_pronunciation: "[cǎoméi]",
            jp: "イチゴ",
            jp_pronunciation: "[イチゴ]",
            ko: "딸기"
         },
         {
            id: 14,
            en: "watermelon",
            cn: "西瓜",
            cn_pronunciation: "[xīguā]",
            jp: "スイカ",
            jp_pronunciation: "[スイカ]",
            ko: "수박"
         }
      ]
   },
   {
      id: 17,
      title: 'furniture',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "furniture",
            cn: "家具",
            cn_pronunciation: "[jiājù]",
            jp: "家具",
            jp_pronunciation: "[かぐ]",
            ko: "가구"
         },
         {
            id: 2,
            en: "armchair",
            cn: "扶手椅",
            cn_pronunciation: "[fúshǒuyǐ]",
            jp: "肘掛け椅子",
            jp_pronunciation: "[ひじかけいす]",
            ko: "안락의자"
         },
         {
            id: 3,
            en: "balcony",
            cn: "阳台",
            cn_pronunciation: "[yángtái]",
            jp: "バルコニー",
            jp_pronunciation: "[バルコニー]",
            ko: "발코니"
         },
         {
            id: 4,
            en: "basket",
            cn: "篮子",
            cn_pronunciation: "[lánzǐ]",
            jp: "手提げかご",
            jp_pronunciation: "[てさげかご]",
            ko: "손바구니"
         },
         {
            id: 5,
            en: "bathroom",
            cn: "浴室；卫生间",
            cn_pronunciation: "[yùshì；wèishēngjiān]",
            jp: "バスルーム",
            jp_pronunciation: "[バスルーム]",
            ko: "화장실"
         },
         {
            id: 6,
            en: "bed",
            cn: "床",
            cn_pronunciation: "[chuáng]",
            jp: "ベッド",
            jp_pronunciation: "[ベッド]",
            ko: "침대"
         },
         {
            id: 7,
            en: "bedrooom",
            cn: "卧室",
            cn_pronunciation: "[wòshì]",
            jp: "寝室",
            jp_pronunciation: "[しんしつ]",
            ko: "침실"
         },
         {
            id: 8,
            en: "blanket",
            cn: "毯子",
            cn_pronunciation: "[tǎnzǐ]",
            jp: "毛布",
            jp_pronunciation: "[もうふ]",
            ko: "담요"
         },
         {
            id: 9,
            en: "bookcase",
            cn: "书柜",
            cn_pronunciation: "[shūguì]",
            jp: "本棚",
            jp_pronunciation: "[ほんだな]",
            ko: "책장"
         },
         {
            id: 10,
            en: "bottle",
            cn: "瓶子",
            cn_pronunciation: "[píngzǐ]",
            jp: "ビン",
            jp_pronunciation: "[ビン]",
            ko: "병"
         },
         {
            id: 11,
            en: "bowl",
            cn: "碗",
            cn_pronunciation: "[wǎn]",
            jp: "碗",
            jp_pronunciation: "[わん]",
            ko: "그릇"
         },
         {
            id: 12,
            en: "box",
            cn: "盒子",
            cn_pronunciation: "[hézǐ]",
            jp: "箱, ボックス",
            jp_pronunciation: "[はこ, ボックス]",
            ko: "상자,박스"
         },
         {
            id: 13,
            en: "camera",
            cn: "照片机",
            cn_pronunciation: "[zhàopiànjī]",
            jp: "カメラ",
            jp_pronunciation: "[カメラ]",
            ko: "카메라"
         },
         {
            id: 14,
            en: "chair",
            cn: "椅子",
            cn_pronunciation: "[yǐzǐ]",
            jp: "椅子",
            jp_pronunciation: "[いす]",
            ko: "의자"
         },
         {
            id: 15,
            en: "chopsticks",
            cn: "筷子",
            cn_pronunciation: "[kuàizǐ]",
            jp: "はし",
            jp_pronunciation: "[はし]",
            ko: "젓가락"
         },
         {
            id: 16,
            en: "clock",
            cn: "钟",
            cn_pronunciation: "[zhōng]",
            jp: "掛(け)時計",
            jp_pronunciation: "[かけどけい]",
            ko: "괘종시계"
         },
         {
            id: 17,
            en: "closet",
            cn: "衣柜",
            cn_pronunciation: "[yīguì]",
            jp: "押(し)入れ",
            jp_pronunciation: "[おしいれ]",
            ko: "벽장"
         },
         {
            id: 18,
            en: "comb",
            cn: "梳子",
            cn_pronunciation: "[shūzǐ]",
            jp: "櫛",
            jp_pronunciation: "[くし]",
            ko: "빗"
         },
         {
            id: 19,
            en: "computer",
            cn: "电脑",
            cn_pronunciation: "[diànnǎo]",
            jp: "コンピューター",
            jp_pronunciation: "[コンピューター]",
            ko: "컴퓨터"
         },
         {
            id: 20,
            en: "cooker",
            cn: "炊具",
            cn_pronunciation: "[chuījù]",
            jp: "調理器具, レンジ",
            jp_pronunciation: "[ちょうりきぐ, レンジ]",
            ko: "레인지"
         },
         {
            id: 21,
            en: "cup",
            cn: "杯子",
            cn_pronunciation: "[bēizǐ]",
            jp: "カップ",
            jp_pronunciation: "[カップ]",
            ko: "컵"
         },
         {
            id: 22,
            en: "cupboard",
            cn: "碗柜",
            cn_pronunciation: "[wǎnguì]",
            jp: "戸棚",
            jp_pronunciation: "[とだな]",
            ko: "찬장"
         },
         {
            id: 23,
            en: "desk",
            cn: "课桌",
            cn_pronunciation: "[kèzhuō]",
            jp: "机",
            jp_pronunciation: "[つくえ]",
            ko: "책상"
         },
         {
            id: 24,
            en: "dining room",
            cn: "餐厅",
            cn_pronunciation: "[cāntīng]",
            jp: "食堂",
            jp_pronunciation: "[しょくどう]",
            ko: "식당"
         },
         {
            id: 25,
            en: "door",
            cn: "门",
            cn_pronunciation: "[mén]",
            jp: "ドア",
            jp_pronunciation: "[ドア]",
            ko: "문"
         },
         {
            id: 26,
            en: "doorbell",
            cn: "门铃",
            cn_pronunciation: "[ménlíng]",
            jp: "ドアベル",
            jp_pronunciation: "[ドアベル]",
            ko: "초인종"
         },
         {
            id: 27,
            en: "fan",
            cn: "扇子",
            cn_pronunciation: "[shànzǐ]",
            jp: "扇, うちわ",
            jp_pronunciation: "[おうぎ, うちわ]",
            ko: "부채, 선풍기"
         },
         {
            id: 28,
            en: "fence",
            cn: "篱笆",
            cn_pronunciation: "[líbā]",
            jp: "フェンス",
            jp_pronunciation: "[フェンス]",
            ko: "펜스"
         },
         {
            id: 29,
            en: "floor",
            cn: "地板",
            cn_pronunciation: "[dìbǎn]",
            jp: "床",
            jp_pronunciation: "[ゆか]",
            ko: "마루"
         },
         {
            id: 30,
            en: "fork",
            cn: "叉子",
            cn_pronunciation: "[chāzǐ]",
            jp: "フォーク",
            jp_pronunciation: "[フォーク]",
            ko: "포크"
         },
         {
            id: 31,
            en: "fridge",
            cn: "电冰箱",
            cn_pronunciation: "[diànbīngxiāng]",
            jp: "冷蔵庫",
            jp_pronunciation: "[れいぞうこ]",
            ko: "냉장고"
         },
         {
            id: 32,
            en: "garage",
            cn: "车库",
            cn_pronunciation: "[chēkù]",
            jp: "ガレージ, 車庫",
            jp_pronunciation: "[ガレージ, しゃこ]",
            ko: "차고"
         },
         {
            id: 33,
            en: "garden",
            cn: "花园",
            cn_pronunciation: "[huāyuán]",
            jp: "庭",
            jp_pronunciation: "[にわ]",
            ko: "정원"
         },
         {
            id: 34,
            en: "gate",
            cn: "大门",
            cn_pronunciation: "[dàmén]",
            jp: "門",
            jp_pronunciation: "[もん]",
            ko: "대문"
         },
         {
            id: 35,
            en: "glass",
            cn: "玻璃杯",
            cn_pronunciation: "[bōlíbēi]",
            jp: "ガラス",
            jp_pronunciation: "[ガラス]",
            ko: "유리잔"
         },
         {
            id: 36,
            en: "hairdryer",
            cn: "吹风机",
            cn_pronunciation: "[chuīfēngjī]",
            jp: "ヘアドライヤー",
            jp_pronunciation: "[ヘアドライヤー]",
            ko: "헤어드라이어"
         },
         {
            id: 37,
            en: "hammer",
            cn: "锤子",
            cn_pronunciation: "[chuízǐ]",
            jp: "ハンマー",
            jp_pronunciation: "[ハンマー]",
            ko: "해머"
         },
         {
            id: 38,
            en: "home",
            cn: "家",
            cn_pronunciation: "[jiā]",
            jp: "家",
            jp_pronunciation: "[いえ]",
            ko: "집"
         },
         {
            id: 39,
            en: "house",
            cn: "房屋",
            cn_pronunciation: "[fángwū]",
            jp: "家",
            jp_pronunciation: "[いえ]",
            ko: "집"
         },
         {
            id: 40,
            en: "key",
            cn: "钥匙",
            cn_pronunciation: "[yàoshí]",
            jp: "かぎ, キー",
            jp_pronunciation: "[かぎ, キー]",
            ko: "열쇠,키"
         },
         {
            id: 41,
            en: "kitchen",
            cn: "厨房",
            cn_pronunciation: "[chúfáng]",
            jp: "キッチン, 台所",
            jp_pronunciation: "[キッチン, だいどころ]",
            ko: "키친, 주방"
         },
         {
            id: 42,
            en: "knife",
            cn: "餐刀",
            cn_pronunciation: "[cāndāo]",
            jp: "ナイフ",
            jp_pronunciation: "[ナイフ]",
            ko: "칼"
         },
         {
            id: 43,
            en: "lamp",
            cn: "台灯",
            cn_pronunciation: "[táidēng]",
            jp: "ランプ",
            jp_pronunciation: "[ランプ]",
            ko: "램프"
         },
         {
            id: 44,
            en: "lantern",
            cn: "灯笼",
            cn_pronunciation: "[dēnglóng]",
            jp: "ランタン",
            jp_pronunciation: "[ランタン]",
            ko: "랜턴,손전등"
         },
         {
            id: 45,
            en: "light",
            cn: "灯",
            cn_pronunciation: "[dēng]",
            jp: "灯",
            jp_pronunciation: "[あかり]",
            ko: "등"
         },
         {
            id: 46,
            en: "living room",
            cn: "客厅",
            cn_pronunciation: "[kètīng]",
            jp: "リビングルームリビングルーム",
            jp_pronunciation: "[]",
            ko: "거실"
         },
         {
            id: 47,
            en: "mirror",
            cn: "镜子",
            cn_pronunciation: "[jìngzǐ]",
            jp: "鏡, ミラー",
            jp_pronunciation: "[かがみ, ミラー]",
            ko: "거울"
         },
         {
            id: 48,
            en: "newspaper",
            cn: "报纸",
            cn_pronunciation: "[bàozhǐ]",
            jp: "新聞",
            jp_pronunciation: "[しんぶん]",
            ko: "신문"
         },
         {
            id: 49,
            en: "oil lamp",
            cn: "油灯",
            cn_pronunciation: "[yóudēng]",
            jp: "石油ランプ",
            jp_pronunciation: "[せきゆランプ]",
            ko: "석유램프"
         },
         {
            id: 50,
            en: "parcel",
            cn: "包裹",
            cn_pronunciation: "[bāoguǒ]",
            jp: "包み",
            jp_pronunciation: "[くるみ]",
            ko: "소포"
         },
         {
            id: 51,
            en: "photo",
            cn: "相片",
            cn_pronunciation: "[xiàngpiàn]",
            jp: "写真",
            jp_pronunciation: "[しゃしん]",
            ko: "사진"
         },
         {
            id: 52,
            en: "picture",
            cn: "图片",
            cn_pronunciation: "[túpiàn]",
            jp: "絵",
            jp_pronunciation: "[え]",
            ko: "그림"
         },
         {
            id: 53,
            en: "plate",
            cn: "盘子",
            cn_pronunciation: "[pánzǐ]",
            jp: "皿",
            jp_pronunciation: "[サラ]",
            ko: "접시"
         },
         {
            id: 54,
            en: "pot",
            cn: "锅",
            cn_pronunciation: "[guō]",
            jp: "鍋",
            jp_pronunciation: "[なべ]",
            ko: "냄비"
         },
         {
            id: 55,
            en: "purse",
            cn: "钱包",
            cn_pronunciation: "[qiánbāo]",
            jp: "財布",
            jp_pronunciation: "[さいふ]",
            ko: "지갑"
         },
         {
            id: 56,
            en: "radio",
            cn: "录音机",
            cn_pronunciation: "[lùyīnjī]",
            jp: "ラジオ",
            jp_pronunciation: "[ラジオ]",
            ko: "라디오"
         },
         {
            id: 57,
            en: "room",
            cn: "房间",
            cn_pronunciation: "[fángjiān]",
            jp: "部屋",
            jp_pronunciation: "[へや]",
            ko: "방"
         },
         {
            id: 58,
            en: "seat",
            cn: "座位",
            cn_pronunciation: "[zuòwèi]",
            jp: "席",
            jp_pronunciation: "[せき]",
            ko: "좌석"
         },
         {
            id: 59,
            en: "shelf",
            cn: "架子",
            cn_pronunciation: "[jiàzǐ]",
            jp: "棚",
            jp_pronunciation: "[だな]",
            ko: "선반"
         },
         {
            id: 60,
            en: "sofa",
            cn: "沙发",
            cn_pronunciation: "[shāfā]",
            jp: "ソファー",
            jp_pronunciation: "[ソファー]",
            ko: "소파"
         },
         {
            id: 61,
            en: "spoon",
            cn: "勺子",
            cn_pronunciation: "[sháozǐ]",
            jp: "スプーン",
            jp_pronunciation: "[スプーン]",
            ko: "스푼"
         },
         {
            id: 62,
            en: "stairs",
            cn: "楼梯",
            cn_pronunciation: "[lóutī]",
            jp: "階段",
            jp_pronunciation: "[かいだん]",
            ko: "계단"
         },
         {
            id: 63,
            en: "stamp",
            cn: "邮票",
            cn_pronunciation: "[yóupiào]",
            jp: "切手",
            jp_pronunciation: "[きって]",
            ko: "우표"
         },
         {
            id: 64,
            en: "stove",
            cn: "炉子",
            cn_pronunciation: "[lúzǐ]",
            jp: "ストーブ",
            jp_pronunciation: "[ストーブ]",
            ko: "스토브, 난로"
         },
         {
            id: 65,
            en: "table",
            cn: "饭桌",
            cn_pronunciation: "[fànzhuō]",
            jp: "テーブル",
            jp_pronunciation: "[テーブル]",
            ko: "식탁, 테이블"
         },
         {
            id: 66,
            en: "tap",
            cn: "水龙头",
            cn_pronunciation: "[shuǐlóngtóu]",
            jp: "蛇口",
            jp_pronunciation: "[じゃぐち]",
            ko: "수도꼭지"
         },
         {
            id: 67,
            en: "teaport",
            cn: "茶壶",
            cn_pronunciation: "[cháhú]",
            jp: "急須, ティーポット",
            jp_pronunciation: "[きゅうす, ティーポット]",
            ko: "차주전자"
         },
         {
            id: 68,
            en: "telephone",
            cn: "电话",
            cn_pronunciation: "[diànhuà]",
            jp: "電話",
            jp_pronunciation: "[でんわ]",
            ko: "전화"
         },
         {
            id: 69,
            en: "ticket",
            cn: "票",
            cn_pronunciation: "[piào]",
            jp: "切符, チケット",
            jp_pronunciation: "[きっぷ, チケット]",
            ko: "표,티켓"
         },
         {
            id: 70,
            en: "tin-opener",
            cn: "开瓶器",
            cn_pronunciation: "[kāipíngqì]",
            jp: "缶切り",
            jp_pronunciation: "[かんきり]",
            ko: "깡통따개"
         },
         {
            id: 71,
            en: "toilet",
            cn: "厕所；洗手间",
            cn_pronunciation: "[cèsuǒ；xǐshǒujiān]",
            jp: "トイレ",
            jp_pronunciation: "[トイレ]",
            ko: "화장실"
         },
         {
            id: 72,
            en: "toothbrush",
            cn: "牙刷",
            cn_pronunciation: "[yáshuā]",
            jp: "歯ブラシ",
            jp_pronunciation: "[はブラシ]",
            ko: "칫솔"
         },
         {
            id: 73,
            en: "towel",
            cn: "毛巾",
            cn_pronunciation: "[máojīn]",
            jp: "タオル",
            jp_pronunciation: "[タオル]",
            ko: "타월"
         },
         {
            id: 74,
            en: "umbrella",
            cn: "伞",
            cn_pronunciation: "[sǎn]",
            jp: "傘",
            jp_pronunciation: "[かさ]",
            ko: "우산"
         },
         {
            id: 75,
            en: "vase",
            cn: "花瓶",
            cn_pronunciation: "[huāpíng]",
            jp: "花瓶",
            jp_pronunciation: "[かびん]",
            ko: "꽃병"
         },
         {
            id: 76,
            en: "wall",
            cn: "墙",
            cn_pronunciation: "[qiáng]",
            jp: "壁",
            jp_pronunciation: "[かべ]",
            ko: "벽장"
         },
         {
            id: 77,
            en: "wallet",
            cn: "钱包",
            cn_pronunciation: "[qiánbāo]",
            jp: "財布",
            jp_pronunciation: "[さいふ]",
            ko: "지갑"
         },
         {
            id: 78,
            en: "water bottle",
            cn: "水瓶",
            cn_pronunciation: "[shuǐpíng]",
            jp: "水筒",
            jp_pronunciation: "[すいとう]",
            ko: "수통"
         },
         {
            id: 79,
            en: "window",
            cn: "窗户",
            cn_pronunciation: "[chuānghù]",
            jp: "窓",
            jp_pronunciation: "[まど]",
            ko: "창문"
         },
         {
            id: 80,
            en: "yard",
            cn: "院子",
            cn_pronunciation: "[yuànzǐ]",
            jp: "庭",
            jp_pronunciation: "[にわ]",
            ko: "마당"
         }
      ]
   },
   {
      id: 18,
      title: 'home appliances',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "home appliances",
            cn: "电器",
            cn_pronunciation: "[diànqì]",
            jp: "家電製品",
            jp_pronunciation: "[かでんせいひん]",
            ko: "가전제품"
         },
         {
            id: 2,
            en: "computer",
            cn: "电脑",
            cn_pronunciation: "[diànnǎo]",
            jp: "コンピューター",
            jp_pronunciation: "[コンピューター]",
            ko: "컴퓨터"
         },
         {
            id: 3,
            en: "radio",
            cn: "收音机",
            cn_pronunciation: "[shōuyīnjī]",
            jp: "ラジオ",
            jp_pronunciation: "[ラジオ]",
            ko: "라디오"
         },
         {
            id: 4,
            en: "telephone",
            cn: "电话",
            cn_pronunciation: "[diànhuà]",
            jp: "電話",
            jp_pronunciation: "[でんわ]",
            ko: "전화"
         },
         {
            id: 5,
            en: "TV",
            cn: "电视",
            cn_pronunciation: "[diànshì]",
            jp: "テレビ",
            jp_pronunciation: "[テレビ]",
            ko: "TV"
         },
         {
            id: 6,
            en: "camera",
            cn: "相机",
            cn_pronunciation: "[xiàngjī]",
            jp: "カメラ",
            jp_pronunciation: "[カメラ]",
            ko: "카메라"
         },
         {
            id: 7,
            en: "screen",
            cn: "屏幕",
            cn_pronunciation: "[píngmù]",
            jp: "スクリーン",
            jp_pronunciation: "[スクリーン]",
            ko: "스크린"
         },
         {
            id: 8,
            en: "CD",
            cn: "激光唱盘",
            cn_pronunciation: "[jīguāngchàngpán]",
            jp: "CD",
            jp_pronunciation: "[シーディー]",
            ko: "시디"
         },
         {
            id: 9,
            en: "CD-Rom",
            cn: "只读光盘",
            cn_pronunciation: "[zhīdúguāngpán]",
            jp: "CD-Rom",
            jp_pronunciation: "[シーディーロム]",
            ko: "시디롬"
         },
         {
            id: 10,
            en: "electricity",
            cn: "电",
            cn_pronunciation: "[diàn]",
            jp: "電気",
            jp_pronunciation: "[でんき]",
            ko: "전기"
         },
         {
            id: 11,
            en: "video",
            cn: "录像带",
            cn_pronunciation: "[lùxiàngdài]",
            jp: "ビデオ",
            jp_pronunciation: "[ビデオ]",
            ko: "비디오"
         },
         {
            id: 12,
            en: "lamp",
            cn: "台灯",
            cn_pronunciation: "[táidēng]",
            jp: "ランプ",
            jp_pronunciation: "[ランプ]",
            ko: "램프"
         },
         {
            id: 13,
            en: "smart phone",
            cn: "手机",
            cn_pronunciation: "[shǒujī]",
            jp: "スマートフォン, スマホ",
            jp_pronunciation: "[スマートフォン, スマホ]",
            ko: "스마트폰"
         },
         {
            id: 14,
            en: "notebook",
            cn: "笔记本电脑",
            cn_pronunciation: "[bǐjìběndiànnǎo]",
            jp: "ノートパソコン",
            jp_pronunciation: "[ノートパソコン]",
            ko: "노트북"
         },
         {
            id: 15,
            en: "MP3 player",
            cn: "mp3播放器",
            cn_pronunciation: "[MP3bōfàngqì]",
            jp: "MP3プレーヤー",
            jp_pronunciation: "[エムピースリープレーヤー]",
            ko: "MP3 플레이어"
         },
         {
            id: 16,
            en: "DVD player",
            cn: "DVD播放器",
            cn_pronunciation: "[DVDbōfàngqì]",
            jp: "DVDプレーヤー",
            jp_pronunciation: "[ディーブイディープレーヤー]",
            ko: "DVD 플레이어"
         },
         {
            id: 17,
            en: "Bluray player",
            cn: "蓝光播放器",
            cn_pronunciation: "[lánguāngbōfàngqì]",
            jp: "ブルーレイプレーヤー",
            jp_pronunciation: "[ブルーレイプレーヤー]",
            ko: "블루레이 플레이어"
         },
         {
            id: 18,
            en: "earphone",
            cn: "耳机",
            cn_pronunciation: "[ěrjī]",
            jp: "イヤホーン",
            jp_pronunciation: "[イヤホーン]",
            ko: "이어폰"
         },
         {
            id: 19,
            en: "Bluetooth earphone",
            cn: "蓝牙耳机",
            cn_pronunciation: "[lányáěrjī]",
            jp: "ブルートゥースイヤホン",
            jp_pronunciation: "[ブルートゥースイヤホン]",
            ko: "블루투스 이어폰"
         }
      ]
   },
   {
      id: 19,
      title: 'means of transportation',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "means of transportation",
            cn: "交通工具",
            cn_pronunciation: "[jiāotōnggōngjù]",
            jp: "輸送の手段",
            jp_pronunciation: "[ゆそうのしゅだん]",
            ko: "교통수단"
         },
         {
            id: 2,
            en: "bicycle",
            cn: "自行车",
            cn_pronunciation: "[zìxíngchē]",
            jp: "自転車",
            jp_pronunciation: "[じてんしゃ]",
            ko: "자전거"
         },
         {
            id: 3,
            en: "boat",
            cn: "小船",
            cn_pronunciation: "[xiăochuán]",
            jp: "ボート",
            jp_pronunciation: "[ボート]",
            ko: "보트"
         },
         {
            id: 4,
            en: "bus",
            cn: "公共汽车，公交车",
            cn_pronunciation: "[gōnggòngqìchē , gōngjiāochē]",
            jp: "バス",
            jp_pronunciation: "[バス]",
            ko: "버스"
         },
         {
            id: 5,
            en: "car",
            cn: "汽车",
            cn_pronunciation: "[qìchē]",
            jp: "車",
            jp_pronunciation: "[くるま]",
            ko: "자동차"
         },
         {
            id: 6,
            en: "cycling",
            cn: "骑自行车",
            cn_pronunciation: "[qízìxíngchē]",
            jp: "サイクリング",
            jp_pronunciation: "[サイクリング]",
            ko: "사이클"
         },
         {
            id: 7,
            en: "minibus",
            cn: "迷你巴士",
            cn_pronunciation: "[mínǐbāshì]",
            jp: "小型バス",
            jp_pronunciation: "[こがたバス]",
            ko: "미니버스"
         },
         {
            id: 8,
            en: "motorbike",
            cn: "摩托车",
            cn_pronunciation: "[mótuōchē]",
            jp: "モーターバイク",
            jp_pronunciation: "[モーターバイク]",
            ko: "오토바이"
         },
         {
            id: 9,
            en: "plane",
            cn: "飞机",
            cn_pronunciation: "[fēijī]",
            jp: "飛行機",
            jp_pronunciation: "[ひこうき]",
            ko: "비행기"
         },
         {
            id: 10,
            en: "ship",
            cn: "轮船",
            cn_pronunciation: "[chuán]",
            jp: "船",
            jp_pronunciation: "[ふね]",
            ko: "배"
         },
         {
            id: 11,
            en: "spaceship",
            cn: "宇宙飞船",
            cn_pronunciation: "[yǔzhòufēichuán]",
            jp: "宇宙船",
            jp_pronunciation: "[うちゅうせん]",
            ko: "우주선"
         },
         {
            id: 12,
            en: "station",
            cn: "站",
            cn_pronunciation: "[zhàn]",
            jp: "駅",
            jp_pronunciation: "[えき]",
            ko: "역"
         },
         {
            id: 13,
            en: "subway",
            cn: "地铁",
            cn_pronunciation: "[dìtiě]",
            jp: "地下鉄",
            jp_pronunciation: "[ちかてつ]",
            ko: "지하철"
         },
         {
            id: 14,
            en: "subway station",
            cn: "地铁站",
            cn_pronunciation: "[dìtiězhàn]",
            jp: "地下鉄駅",
            jp_pronunciation: "[ちかてつえき]",
            ko: "지하철역"
         },
         {
            id: 15,
            en: "taxi",
            cn: "出租车",
            cn_pronunciation: "[chūzūchē]",
            jp: "タクシー",
            jp_pronunciation: "[タクシー]",
            ko: "택시"
         },
         {
            id: 16,
            en: "traffic lights",
            cn: "交通信号灯",
            cn_pronunciation: "[jiāotōngxìnhàodēng]",
            jp: "交通信号",
            jp_pronunciation: "[こうつうしんごう]",
            ko: "신호등"
         },
         {
            id: 17,
            en: "train",
            cn: "火车",
            cn_pronunciation: "[huǒchē]",
            jp: "電車",
            jp_pronunciation: "[でんしゃ]",
            ko: "기차"
         },
         {
            id: 18,
            en: "truck",
            cn: "卡车",
            cn_pronunciation: "[kǎchē]",
            jp: "トラック",
            jp_pronunciation: "[トラック]",
            ko: "트럭"
         },
         {
            id: 19,
            en: "underpass",
            cn: "地下通道",
            cn_pronunciation: "[dìxiàtōngdào]",
            jp: "地下通路",
            jp_pronunciation: "[ちかつうろ]",
            ko: "지하차도"
         },
         {
            id: 20,
            en: "van",
            cn: "厢式货车",
            cn_pronunciation: "[xiāngshìhuòchē]",
            jp: "バン",
            jp_pronunciation: "[バン]",
            ko: "밴, 화물차량"
         },
         {
            id: 21,
            en: "way",
            cn: "道路",
            cn_pronunciation: "[dàolù]",
            jp: "道",
            jp_pronunciation: "[みち]",
            ko: "길"
         }
      ]
   },
   {
      id: 20,
      title: 'measurement',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "measurement",
            cn: "测量",
            cn_pronunciation: "[cèliáng]",
            jp: "測量",
            jp_pronunciation: "[そくりょう]",
            ko: "측량"
         },
         {
            id: 2,
            en: "centimeter",
            cn: "厘米",
            cn_pronunciation: "[límǐ]",
            jp: "センチメートル",
            jp_pronunciation: "[センチメートル]",
            ko: "센티미터"
         },
         {
            id: 3,
            en: "gram",
            cn: "克",
            cn_pronunciation: "[kè]",
            jp: "グラム",
            jp_pronunciation: "[グラム]",
            ko: "그램"
         },
         {
            id: 4,
            en: "kilo",
            cn: "公斤",
            cn_pronunciation: "[gōngjīn]",
            jp: "キロ",
            jp_pronunciation: "[キロ]",
            ko: "킬로그램(kg)"
         },
         {
            id: 5,
            en: "kilometer",
            cn: "公里",
            cn_pronunciation: "[gōnglǐ]",
            jp: "キロメートル",
            jp_pronunciation: "[キロメートル]",
            ko: "킬로미터(km)"
         },
         {
            id: 6,
            en: "mass",
            cn: "团 ; 块 ; 堆",
            cn_pronunciation: "[tuán ; kuài ; duī]",
            jp: "かたまり",
            jp_pronunciation: "[かたまり]",
            ko: "덩어리"
         },
         {
            id: 7,
            en: "meter",
            cn: "米 ; 公尺",
            cn_pronunciation: "[mǐ ; gōngchǐ]",
            jp: "メートル",
            jp_pronunciation: "[メートル]",
            ko: "미터"
         },
         {
            id: 8,
            en: "mile",
            cn: "英里",
            cn_pronunciation: "[yīnglǐ]",
            jp: "マイル",
            jp_pronunciation: "[マイル]",
            ko: "마일"
         },
         {
            id: 9,
            en: "pair",
            cn: "双；对",
            cn_pronunciation: "[shuāng ; duì]",
            jp: "一組",
            jp_pronunciation: "[ひとくみ]",
            ko: "쌍 ; 짝"
         },
         {
            id: 10,
            en: "pile",
            cn: "堆；叠",
            cn_pronunciation: "[zuī ; dié]",
            jp: "積み重ね",
            jp_pronunciation: "[つみかさね]",
            ko: "더미"
         },
         {
            id: 11,
            en: "size",
            cn: "尺寸 ; 大小",
            cn_pronunciation: "[chǐcun]",
            jp: "大きさ",
            jp_pronunciation: "[おおきさ]",
            ko: "치수, 사이즈"
         },
         {
            id: 12,
            en: "ton",
            cn: "吨",
            cn_pronunciation: "[dūn]",
            jp: "トン",
            jp_pronunciation: "[トン]",
            ko: "톤"
         }
      ]
   },
   {
      id: 21,
      title: 'music',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "accordion",
            cn: "手风琴",
            cn_pronunciation: "[shǒufēngqín]",
            jp: "アコーディオン",
            jp_pronunciation: "[アコーディオン]",
            ko: "손풍금. 아코디언(accordion)."
         },
         {
            id: 2,
            en: "classical music",
            cn: "古典音乐",
            cn_pronunciation: "[gǔdiǎnyīnlè]",
            jp: "クラシック音楽",
            jp_pronunciation: "[くらしっくおんがく]",
            ko: "클래식 음악"
         },
         {
            id: 3,
            en: "concert",
            cn: "音乐会",
            cn_pronunciation: "[yīnlèhuì]",
            jp: "コンサート",
            jp_pronunciation: "[コンサート]",
            ko: "콘서트"
         },
         {
            id: 4,
            en: "dance",
            cn: "1.(动词)跳舞  2.舞蹈；舞会",
            cn_pronunciation: "[1.(dòngcí)tiàowǔ  2.wǔdǎo；wǔhuì]",
            jp: "踊る, ダンスをする",
            jp_pronunciation: "[おどる,ダンスをする]",
            ko: "춤추다"
         },
         {
            id: 5,
            en: "dancing",
            cn: "舞蹈",
            cn_pronunciation: "[wǔdǎo]",
            jp: "ダンス",
            jp_pronunciation: "[ダンス]",
            ko: "춤, 댄스"
         },
         {
            id: 6,
            en: "drum",
            cn: "鼓",
            cn_pronunciation: "[gǔ]",
            jp: "ドラム",
            jp_pronunciation: "[ドラム]",
            ko: "드럼"
         },
         {
            id: 7,
            en: "flute",
            cn: "笛子",
            cn_pronunciation: "[dízǐ]",
            jp: "フルート",
            jp_pronunciation: "[フルート]",
            ko: "플룻"
         },
         {
            id: 8,
            en: "guitar",
            cn: "吉他",
            cn_pronunciation: "[jítā]",
            jp: "ギター",
            jp_pronunciation: "[ギター]",
            ko: "기타"
         },
         {
            id: 9,
            en: "music",
            cn: "音乐",
            cn_pronunciation: "[yīnlè]",
            jp: "音楽",
            jp_pronunciation: "[おんがく]",
            ko: "음악"
         },
         {
            id: 10,
            en: "musicial",
            cn: "音乐剧",
            cn_pronunciation: "[yīnlèjù]",
            jp: "ミュージカル",
            jp_pronunciation: "[ミュージカル]",
            ko: "뮤지컬"
         },
         {
            id: 11,
            en: "piano",
            cn: "钢琴",
            cn_pronunciation: "[gāngqín]",
            jp: "ピアノ",
            jp_pronunciation: "[ピアノ]",
            ko: "피아노"
         },
         {
            id: 12,
            en: "pop music",
            cn: "流行音乐",
            cn_pronunciation: "[liúhángyīnlè]",
            jp: "ポップス, ポップ・ミュージック",
            jp_pronunciation: "[ポップス, ポップ・ミュージック]",
            ko: "팝, 팝음악"
         },
         {
            id: 13,
            en: "sing",
            cn: "（动词）唱歌",
            cn_pronunciation: "[（dòngcí）chànggē]",
            jp: "歌う",
            jp_pronunciation: "[うたう]",
            ko: "노래하다"
         },
         {
            id: 14,
            en: "song",
            cn: "歌曲",
            cn_pronunciation: "[gēqǔ]",
            jp: "歌",
            jp_pronunciation: "[うた]",
            ko: "노래"
         },
         {
            id: 15,
            en: "trumpet",
            cn: "小号",
            cn_pronunciation: "[xiǎohào]",
            jp: "トランペット",
            jp_pronunciation: "[トランペット]",
            ko: "트럼펫"
         },
         {
            id: 16,
            en: "violin",
            cn: "小提琴",
            cn_pronunciation: "[xiǎotíqín]",
            jp: "バイオリン",
            jp_pronunciation: "[バイオリン]",
            ko: "바이올린"
         },
         {
            id: 17,
            en: "cello",
            cn: "大提琴",
            cn_pronunciation: "[dàtíqín]",
            jp: "チェロ",
            jp_pronunciation: "[チェロ]",
            ko: "첼로"
         }
      ]
   },
   {
      id: 22,
      title: 'nature',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "nature",
            cn: "自然",
            cn_pronunciation: "[zìrán]",
            jp: "自然",
            jp_pronunciation: "[しぜん]",
            ko: "자연"
         },
         {
            id: 2,
            en: "air",
            cn: "空气；大气",
            cn_pronunciation: "[kōngqì；dàqì]",
            jp: "空気 ; 大気",
            jp_pronunciation: "[くうき ; たいき]",
            ko: "공기；대기"
         },
         {
            id: 3,
            en: "bamboo",
            cn: "竹子",
            cn_pronunciation: "[zhúzi]",
            jp: "竹",
            jp_pronunciation: "[たけ]",
            ko: "대나무"
         },
         {
            id: 4,
            en: "beach",
            cn: "海滩；沙滩",
            cn_pronunciation: "[hǎitān；shātān]",
            jp: "海辺",
            jp_pronunciation: "[うみべ]",
            ko: "해변 모래사장；모래사장"
         },
         {
            id: 5,
            en: "cave",
            cn: "洞穴",
            cn_pronunciation: "[dòngxué]",
            jp: "洞窟",
            jp_pronunciation: "[どうくつ]",
            ko: "동굴"
         },
         {
            id: 6,
            en: "cloud",
            cn: "云",
            cn_pronunciation: "[yún]",
            jp: "雲",
            jp_pronunciation: "[くも]",
            ko: "구름"
         },
         {
            id: 7,
            en: "desert",
            cn: "沙漠",
            cn_pronunciation: "[shāmò]",
            jp: "砂漠",
            jp_pronunciation: "[砂漠]",
            ko: "사막"
         },
         {
            id: 8,
            en: "earth",
            cn: "地球；泥土",
            cn_pronunciation: "[dìqiú；nítǔ]",
            jp: "地球 ; 地",
            jp_pronunciation: "[ちきゅう ; ち]",
            ko: "지구；흙"
         },
         {
            id: 9,
            en: "field",
            cn: "田地；领域",
            cn_pronunciation: "[tiándì；lǐngyù]",
            jp: "畑 ; 分野",
            jp_pronunciation: "[はたけ ; ぶんや]",
            ko: "논밭；분야"
         },
         {
            id: 10,
            en: "fire",
            cn: "火",
            cn_pronunciation: "[huǒ]",
            jp: "火",
            jp_pronunciation: "[ひ]",
            ko: "불"
         },
         {
            id: 11,
            en: "flower",
            cn: "花",
            cn_pronunciation: "[huā]",
            jp: "花",
            jp_pronunciation: "[はな]",
            ko: "꽃"
         },
         {
            id: 12,
            en: "gold",
            cn: "金；黄金",
            cn_pronunciation: "[jīn；huángjīn]",
            jp: "金",
            jp_pronunciation: "[きん]",
            ko: "금；황금"
         },
         {
            id: 13,
            en: "hill",
            cn: "小山",
            cn_pronunciation: "[xiǎoshān]",
            jp: "丘",
            jp_pronunciation: "[おか]",
            ko: "언덕"
         },
         {
            id: 14,
            en: "island",
            cn: "岛",
            cn_pronunciation: "[dǎo]",
            jp: "島",
            jp_pronunciation: "[しま]",
            ko: "섬"
         },
         {
            id: 15,
            en: "lake",
            cn: "湖",
            cn_pronunciation: "[hú]",
            jp: "湖",
            jp_pronunciation: "[みずうみ]",
            ko: "호수"
         },
         {
            id: 16,
            en: "land",
            cn: "陆地；土地",
            cn_pronunciation: "[lùdì；tǔdì]",
            jp: "陸",
            jp_pronunciation: "[りく ; とち]",
            ko: "육지；땅"
         },
         {
            id: 17,
            en: "moon",
            cn: "月亮",
            cn_pronunciation: "[yuèliang]",
            jp: "月",
            jp_pronunciation: "[つき]",
            ko: "달"
         },
         {
            id: 18,
            en: "mountain",
            cn: "山",
            cn_pronunciation: "[shān]",
            jp: "山",
            jp_pronunciation: "[やま]",
            ko: "산"
         },
         {
            id: 19,
            en: "ocean",
            cn: "海洋",
            cn_pronunciation: "[hǎiyáng]",
            jp: "海洋",
            jp_pronunciation: "[かいよう]",
            ko: "해양"
         },
         {
            id: 20,
            en: "planet",
            cn: "行星",
            cn_pronunciation: "[xíngxīng]",
            jp: "惑星",
            jp_pronunciation: "[わくせい]",
            ko: "행성"
         },
         {
            id: 21,
            en: "pollution",
            cn: "污染",
            cn_pronunciation: "[wūrǎn]",
            jp: "汚染",
            jp_pronunciation: "[おせん]",
            ko: "오염"
         },
         {
            id: 22,
            en: "pond",
            cn: "池塘",
            cn_pronunciation: "[chítáng]",
            jp: "池",
            jp_pronunciation: "[いけ]",
            ko: "연못"
         },
         {
            id: 23,
            en: "port",
            cn: "海港",
            cn_pronunciation: "[hǎigǎng]",
            jp: "港",
            jp_pronunciation: "[みなと]",
            ko: "항구"
         },
         {
            id: 24,
            en: "rainbow",
            cn: "彩虹",
            cn_pronunciation: "[cǎihóng]",
            jp: "にじ",
            jp_pronunciation: "[にじ]",
            ko: "무지개"
         },
         {
            id: 25,
            en: "ray",
            cn: "光线",
            cn_pronunciation: "[guāngxiàn]",
            jp: "光線",
            jp_pronunciation: "[こうせん]",
            ko: "광선"
         },
         {
            id: 26,
            en: "river",
            cn: "江",
            cn_pronunciation: "[jiāng]",
            jp: "川",
            jp_pronunciation: "[かわ]",
            ko: "강"
         },
         {
            id: 27,
            en: "sand",
            cn: "沙子",
            cn_pronunciation: "[shāzi]",
            jp: "砂",
            jp_pronunciation: "[すな]",
            ko: "모래"
         },
         {
            id: 28,
            en: "sea",
            cn: "海",
            cn_pronunciation: "[hǎi]",
            jp: "海",
            jp_pronunciation: "[うみ]",
            ko: "바다"
         },
         {
            id: 29,
            en: "shore",
            cn: "岸；滨",
            cn_pronunciation: "[àn；bīn]",
            jp: "岸",
            jp_pronunciation: "[きし]",
            ko: "(강)기슭；물가"
         },
         {
            id: 30,
            en: "silver",
            cn: "银",
            cn_pronunciation: "[yín]",
            jp: "銀",
            jp_pronunciation: "[ぎん]",
            ko: "은"
         },
         {
            id: 31,
            en: "sky",
            cn: "天空",
            cn_pronunciation: "[tiānkōng]",
            jp: "空",
            jp_pronunciation: "[そら]",
            ko: "하늘"
         },
         {
            id: 32,
            en: "space",
            cn: "空间；太空",
            cn_pronunciation: "[kōngjiān；tàikōng]",
            jp: "空間 ; 宇宙",
            jp_pronunciation: "[くうかん ; うちゅう]",
            ko: "공간；우주"
         },
         {
            id: 33,
            en: "star",
            cn: "星",
            cn_pronunciation: "[xīng]",
            jp: "星",
            jp_pronunciation: "[ほし]",
            ko: "별"
         },
         {
            id: 34,
            en: "steam",
            cn: "蒸汽",
            cn_pronunciation: "[zhēngqì]",
            jp: "蒸気",
            jp_pronunciation: "[じょうき]",
            ko: "수증기"
         },
         {
            id: 35,
            en: "steel",
            cn: "钢铁",
            cn_pronunciation: "[gāngtiě]",
            jp: "鋼",
            jp_pronunciation: "[はがね]",
            ko: "강철"
         },
         {
            id: 36,
            en: "stone",
            cn: "石头",
            cn_pronunciation: "[shítou]",
            jp: "石",
            jp_pronunciation: "[いし]",
            ko: "돌"
         },
         {
            id: 37,
            en: "stream",
            cn: "小溪",
            cn_pronunciation: "[xiǎoxī]",
            jp: "小川",
            jp_pronunciation: "[おがわ]",
            ko: "시내"
         },
         {
            id: 38,
            en: "sun",
            cn: "太阳",
            cn_pronunciation: "[tàiyáng]",
            jp: "太陽",
            jp_pronunciation: "[たいよう]",
            ko: "태양"
         },
         {
            id: 39,
            en: "sunshine",
            cn: "阳光",
            cn_pronunciation: "[yángguāng]",
            jp: "陽ざし",
            jp_pronunciation: "[ひざし]",
            ko: "햇빛"
         },
         {
            id: 40,
            en: "tree",
            cn: "树",
            cn_pronunciation: "[shù]",
            jp: "木",
            jp_pronunciation: "[き]",
            ko: "나무"
         },
         {
            id: 41,
            en: "universe",
            cn: "宇宙",
            cn_pronunciation: "[yǔzhòu]",
            jp: "宇宙",
            jp_pronunciation: "[うちゅう]",
            ko: "우주"
         },
         {
            id: 42,
            en: "village",
            cn: "村庄",
            cn_pronunciation: "[cūnzhuāng]",
            jp: "村",
            jp_pronunciation: "[むら]",
            ko: "마을"
         },
         {
            id: 43,
            en: "volcano",
            cn: "火山",
            cn_pronunciation: "[huǒshān]",
            jp: "火山",
            jp_pronunciation: "[かざん]",
            ko: "화산"
         },
         {
            id: 44,
            en: "water",
            cn: "水",
            cn_pronunciation: "[shuǐ]",
            jp: "水",
            jp_pronunciation: "[みず]",
            ko: "물"
         },
         {
            id: 45,
            en: "world",
            cn: "世界",
            cn_pronunciation: "[shìjiè]",
            jp: "世界",
            jp_pronunciation: "[せかい]",
            ko: "세계"
         }
      ]
   },
   {
      id: 23,
      title: 'number',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "one",
            cn: "一",
            cn_pronunciation: "[yī]",
            jp: "一",
            jp_pronunciation: "[いち]",
            ko: "일",
            extra1: "1"
         },
         {
            id: 2,
            en: "two",
            cn: "二",
            cn_pronunciation: "[èr]",
            jp: "二",
            jp_pronunciation: "[に]",
            ko: "이",
            extra1: "2"
         },
         {
            id: 3,
            en: "three",
            cn: "三",
            cn_pronunciation: "[sān]",
            jp: "三",
            jp_pronunciation: "[さん]",
            ko: "삼",
            extra1: "3"
         },
         {
            id: 4,
            en: "four",
            cn: "四",
            cn_pronunciation: "[sì]",
            jp: "四",
            jp_pronunciation: "[し/よん]",
            ko: "사",
            extra1: "4"
         },
         {
            id: 5,
            en: "five",
            cn: "五",
            cn_pronunciation: "[wǔ]",
            jp: "五",
            jp_pronunciation: "[ご]",
            ko: "오",
            extra1: "5"
         },
         {
            id: 6,
            en: "six",
            cn: "六",
            cn_pronunciation: "[liù]",
            jp: "六",
            jp_pronunciation: "[ろく]",
            ko: "육",
            extra1: "6"
         },
         {
            id: 7,
            en: "seven",
            cn: "七",
            cn_pronunciation: "[qī]",
            jp: "七",
            jp_pronunciation: "[しち/なな]",
            ko: "칠",
            extra1: "7"
         },
         {
            id: 8,
            en: "eight",
            cn: "八",
            cn_pronunciation: "[bā]",
            jp: "八",
            jp_pronunciation: "[はち]",
            ko: "팔",
            extra1: "8"
         },
         {
            id: 9,
            en: "nine",
            cn: "九",
            cn_pronunciation: "[jiǔ]",
            jp: "九",
            jp_pronunciation: "[きゅう/く]",
            ko: "구",
            extra1: "9"
         },
         {
            id: 10,
            en: "ten",
            cn: "十",
            cn_pronunciation: "[shí]",
            jp: "十",
            jp_pronunciation: "[じゅう]",
            ko: "십",
            extra1: "10"
         },
         {
            id: 11,
            en: "eleven",
            cn: "十一",
            cn_pronunciation: "[shíyī]",
            jp: "十一",
            jp_pronunciation: "[じゅういち]",
            ko: "십일",
            extra1: "11"
         },
         {
            id: 12,
            en: "twelve",
            cn: "十二",
            cn_pronunciation: "[shíèr]",
            jp: "十二",
            jp_pronunciation: "[じゅうに]",
            ko: "십이",
            extra1: "12"
         },
         {
            id: 13,
            en: "thirteen",
            cn: "十三",
            cn_pronunciation: "[shísān]",
            jp: "十三",
            jp_pronunciation: "[じゅうさん]",
            ko: "십삼",
            extra1: "13"
         },
         {
            id: 14,
            en: "fourteen",
            cn: "十四",
            cn_pronunciation: "[shísì]",
            jp: "十四",
            jp_pronunciation: "[じゅうし/じゅうよん]",
            ko: "십사",
            extra1: "14"
         },
         {
            id: 15,
            en: "fifteen",
            cn: "十五",
            cn_pronunciation: "[shíwǔ]",
            jp: "十五",
            jp_pronunciation: "[じゅうご]",
            ko: "십오",
            extra1: "15"
         },
         {
            id: 16,
            en: "sixteen",
            cn: "十六",
            cn_pronunciation: "[shíliù]",
            jp: "十六",
            jp_pronunciation: "[じゅうろく]",
            ko: "십육",
            extra1: "16"
         },
         {
            id: 17,
            en: "seventeen",
            cn: "十七",
            cn_pronunciation: "[shíqī]",
            jp: "十七",
            jp_pronunciation: "[じゅうしち/じゅうなな]",
            ko: "십칠",
            extra1: "17"
         },
         {
            id: 18,
            en: "eighteen",
            cn: "十八",
            cn_pronunciation: "[shíbā]",
            jp: "十八",
            jp_pronunciation: "[じゅうはち]",
            ko: "십팔",
            extra1: "18"
         },
         {
            id: 19,
            en: "nineteen",
            cn: "十九",
            cn_pronunciation: "[shíjiǔ]",
            jp: "十九",
            jp_pronunciation: "[じゅうきゅう/じゅうく]",
            ko: "십구",
            extra1: "19"
         },
         {
            id: 20,
            en: "twenty",
            cn: "二十",
            cn_pronunciation: "[èrshí]",
            jp: "二十",
            jp_pronunciation: "[にじゅう]",
            ko: "이십",
            extra1: "20"
         },
         {
            id: 21,
            en: "twenty-one",
            cn: "二十一",
            cn_pronunciation: "[èrshíyī]",
            jp: "二十一",
            jp_pronunciation: "[にじゅういち]",
            ko: "이십일",
            extra1: "21"
         },
         {
            id: 22,
            en: "twenty-two",
            cn: "二十二",
            cn_pronunciation: "[èrshíèr]",
            jp: "二十二",
            jp_pronunciation: "[にじゅうに]",
            ko: "이십이",
            extra1: "22"
         },
         {
            id: 23,
            en: "twenty-three",
            cn: "二十三",
            cn_pronunciation: "[èrshísān]",
            jp: "二十三",
            jp_pronunciation: "[にじゅうさん]",
            ko: "이십삼",
            extra1: "23"
         },
         {
            id: 24,
            en: "twenty-four",
            cn: "二十四",
            cn_pronunciation: "[èrshísì]",
            jp: "二十四",
            jp_pronunciation: "[にじゅうし/にじゅうよん]",
            ko: "이십사",
            extra1: "24"
         },
         {
            id: 25,
            en: "twenty-five",
            cn: "二十五",
            cn_pronunciation: "[èrshíwǔ]",
            jp: "二十五",
            jp_pronunciation: "[にじゅうご]",
            ko: "이십오",
            extra1: "25"
         },
         {
            id: 26,
            en: "twenty-six",
            cn: "二十六",
            cn_pronunciation: "[èrshíliù]",
            jp: "二十六",
            jp_pronunciation: "[にじゅうろく]",
            ko: "이십육",
            extra1: "26"
         },
         {
            id: 27,
            en: "twenty-seven",
            cn: "二十七",
            cn_pronunciation: "[èrshíqī]",
            jp: "二十七",
            jp_pronunciation: "[にじゅうしち/にじゅうなな]",
            ko: "이십칠",
            extra1: "27"
         },
         {
            id: 28,
            en: "twenty-eight",
            cn: "二十八",
            cn_pronunciation: "[èrshíbā]",
            jp: "二十八",
            jp_pronunciation: "[にじゅうはち]",
            ko: "이십팔",
            extra1: "28"
         },
         {
            id: 29,
            en: "twenty-nine",
            cn: "二十九",
            cn_pronunciation: "[èrshíjiǔ]",
            jp: "二十九",
            jp_pronunciation: "[にじゅうきゅう/にじゅうく]",
            ko: "이십구",
            extra1: "29"
         },
         {
            id: 30,
            en: "thirty",
            cn: "三十",
            cn_pronunciation: "[sānshí]",
            jp: "三十",
            jp_pronunciation: "[さんじゅう]",
            ko: "삼십",
            extra1: "30"
         },
         {
            id: 31,
            en: "thirty-one",
            cn: "三十一",
            cn_pronunciation: "[sānshíyī]",
            jp: "三十一",
            jp_pronunciation: "[さんじゅういち]",
            ko: "삼십일",
            extra1: "31"
         },
         {
            id: 32,
            en: "forty",
            cn: "四十",
            cn_pronunciation: "[sìshí]",
            jp: "四十",
            jp_pronunciation: "[よんじゅう/しじゅう]",
            ko: "사십",
            extra1: "40"
         },
         {
            id: 33,
            en: "fifty",
            cn: "五十",
            cn_pronunciation: "[wǔshí]",
            jp: "五十",
            jp_pronunciation: "[ごじゅう]",
            ko: "오십",
            extra1: "50"
         },
         {
            id: 34,
            en: "sixty",
            cn: "六十",
            cn_pronunciation: "[liùshí]",
            jp: "六十",
            jp_pronunciation: "[ろくじゅう]",
            ko: "육십",
            extra1: "60"
         },
         {
            id: 35,
            en: "seventy",
            cn: "七十",
            cn_pronunciation: "[qīshí]",
            jp: "七十",
            jp_pronunciation: "[しちじゅう/ななじゅう]",
            ko: "칠십",
            extra1: "70"
         },
         {
            id: 36,
            en: "eighty",
            cn: "八十",
            cn_pronunciation: "[bāshí]",
            jp: "八十",
            jp_pronunciation: "[はちじゅう]",
            ko: "팔십",
            extra1: "80"
         },
         {
            id: 37,
            en: "ninety",
            cn: "九十",
            cn_pronunciation: "[jiǔshí]",
            jp: "九十",
            jp_pronunciation: "[きゅうじゅう]",
            ko: "구십",
            extra1: "90"
         },
         {
            id: 38,
            en: "hundred",
            cn: "百",
            cn_pronunciation: "[bǎi]",
            jp: "百",
            jp_pronunciation: "[ひゃく]",
            ko: "백",
            extra1: "100"
         },
         {
            id: 39,
            en: "thousand",
            cn: "千",
            cn_pronunciation: "[qiān]",
            jp: "千",
            jp_pronunciation: "[せん]",
            ko: "천",
            extra1: "1000"
         },
         {
            id: 40,
            en: "million",
            cn: "百万",
            cn_pronunciation: "[bǎiwàn]",
            jp: "百万",
            jp_pronunciation: "[ひゃくまん]",
            ko: "백만",
            extra1: "1,000,000"
         },
         {
            id: 41,
            en: "first",
            cn: "第一",
            cn_pronunciation: "[dìyī]",
            jp: "一番目",
            jp_pronunciation: "[いちばんめ]",
            ko: "첫 번째"
         },
         {
            id: 42,
            en: "second",
            cn: "第二",
            cn_pronunciation: "[dìèr]",
            jp: "二番目",
            jp_pronunciation: "[にばんめ]",
            ko: "두 번째"
         },
         {
            id: 43,
            en: "third",
            cn: "第三",
            cn_pronunciation: "[dìsān]",
            jp: "三番目",
            jp_pronunciation: "[さんばんめ]",
            ko: "세 번째"
         },
         {
            id: 44,
            en: "fourth",
            cn: "第四",
            cn_pronunciation: "[dìsì]",
            jp: "四番目",
            jp_pronunciation: "[よんばんめ]",
            ko: "네 번째"
         },
         {
            id: 45,
            en: "fifth",
            cn: "第五",
            cn_pronunciation: "[dìwǔ]",
            jp: "五番目",
            jp_pronunciation: "[ごばんめ]",
            ko: "다섯 번째"
         },
         {
            id: 46,
            en: "sixth",
            cn: "第六",
            cn_pronunciation: "[dìliù]",
            jp: "六番目",
            jp_pronunciation: "[ろくばんめ]",
            ko: "여섯 번째"
         },
         {
            id: 47,
            en: "seventh",
            cn: "第七",
            cn_pronunciation: "[dìqī]",
            jp: "七番目",
            jp_pronunciation: "[しちばんめ/ななばんめ]",
            ko: "일곱 번째"
         },
         {
            id: 48,
            en: "eighth",
            cn: "第八",
            cn_pronunciation: "[dìbā]",
            jp: "八番目",
            jp_pronunciation: "[はちばんめ]",
            ko: "여덟 번째"
         },
         {
            id: 49,
            en: "ninth",
            cn: "第九",
            cn_pronunciation: "[dìjiǔ]",
            jp: "九番目",
            jp_pronunciation: "[きゅうばんめ]",
            ko: "아홉 번째"
         },
         {
            id: 50,
            en: "tenth",
            cn: "第十",
            cn_pronunciation: "[dìshí]",
            jp: "十番目",
            jp_pronunciation: "[じゅうばんめ]",
            ko: "열 번째"
         },
         {
            id: 51,
            en: "eleventh",
            cn: "第十一",
            cn_pronunciation: "[dìshíyī]",
            jp: "十一番目",
            jp_pronunciation: "[じゅういちばんめ]",
            ko: "열한 번째"
         },
         {
            id: 52,
            en: "twelfth",
            cn: "第十二",
            cn_pronunciation: "[dìshíèr]",
            jp: "十二番目",
            jp_pronunciation: "[じゅうにばんめ]",
            ko: "열두 번째"
         },
         {
            id: 53,
            en: "thirteenth",
            cn: "第十三",
            cn_pronunciation: "[dìshísān]",
            jp: "十三番目",
            jp_pronunciation: "[じゅうさんばんめ]",
            ko: "열세 번째"
         },
         {
            id: 54,
            en: "fourteenth",
            cn: "第十四",
            cn_pronunciation: "[dìshísì]",
            jp: "十四番目",
            jp_pronunciation: "[じゅうよんばんめ]",
            ko: "열네 번재"
         },
         {
            id: 55,
            en: "fifteenth",
            cn: "第十五",
            cn_pronunciation: "[dìshíwǔ]",
            jp: "十五番目",
            jp_pronunciation: "[じゅうごばんめ]",
            ko: "열다섯 번째"
         },
         {
            id: 56,
            en: "sixteenth",
            cn: "第十六",
            cn_pronunciation: "[dìshíliù]",
            jp: "十六番目",
            jp_pronunciation: "[じゅうろくばんめ]",
            ko: "열여섯 번째"
         },
         {
            id: 57,
            en: "seventeenth",
            cn: "第十七",
            cn_pronunciation: "[dìshíqī]",
            jp: "十七番目",
            jp_pronunciation: "[じゅうしちばんめ/じゅうななばんめ]",
            ko: "열일곱 번째"
         },
         {
            id: 58,
            en: "eighteenth",
            cn: "第十八",
            cn_pronunciation: "[dìshíbā]",
            jp: "十八番目",
            jp_pronunciation: "[じゅうはちばんめ]",
            ko: "열여덜 번째"
         },
         {
            id: 59,
            en: "nineteenth",
            cn: "第十九",
            cn_pronunciation: "[dìshíjiǔ]",
            jp: "十九番目",
            jp_pronunciation: "[じゅうきゅうばんめ]",
            ko: "열아홉 번째"
         },
         {
            id: 60,
            en: "twentieth",
            cn: "第二十",
            cn_pronunciation: "[dìèrshí]",
            jp: "二十番目",
            jp_pronunciation: "[にじゅうばんめ]",
            ko: "스무 번째"
         },
         {
            id: 61,
            en: "twenty-first",
            cn: "第二十一",
            cn_pronunciation: "[dìèrshíyī]",
            jp: "二十一番目",
            jp_pronunciation: "[にじゅういちばんめ]",
            ko: "스물한 번째"
         },
         {
            id: 62,
            en: "twenty-second",
            cn: "第二十二",
            cn_pronunciation: "[dìèrshíèr]",
            jp: "二十二番目",
            jp_pronunciation: "[にじゅうにばんめ]",
            ko: "스물두 번째"
         },
         {
            id: 63,
            en: "twenty-third",
            cn: "第二十三",
            cn_pronunciation: "[dìèrshísān]",
            jp: "二十三番目",
            jp_pronunciation: "[にじゅうさんばんめ]",
            ko: "스물세 번째"
         },
         {
            id: 64,
            en: "twenty-fourth",
            cn: "第二十四",
            cn_pronunciation: "[dìèrshísì]",
            jp: "二十四番目",
            jp_pronunciation: "[にじゅうよんばんめ]",
            ko: "스물네 번째"
         },
         {
            id: 65,
            en: "twenty-fifth",
            cn: "第二十五",
            cn_pronunciation: "[dìèrshíwǔ]",
            jp: "二十五番目",
            jp_pronunciation: "[にじゅうごばんめ]",
            ko: "스물다섯 번째"
         },
         {
            id: 66,
            en: "twenty-sixth",
            cn: "第二十六",
            cn_pronunciation: "[dìèrshíliù]",
            jp: "二十六番目",
            jp_pronunciation: "[にじゅうろくばんめ]",
            ko: "스물여섯 번째"
         },
         {
            id: 67,
            en: "twenty-seventh",
            cn: "第二十七",
            cn_pronunciation: "[dìèrshíqī]",
            jp: "二十七番目",
            jp_pronunciation: "[にじゅうしちばんめ]",
            ko: "스물일곱 번째"
         },
         {
            id: 68,
            en: "twenty-eighth",
            cn: "第二十八",
            cn_pronunciation: "[dìèrshíbā]",
            jp: "二十八番目",
            jp_pronunciation: "[にじゅうはちばんめ]",
            ko: "스물여덜 번째"
         },
         {
            id: 69,
            en: "twenty-ninth",
            cn: "第二十九",
            cn_pronunciation: "[dìèrshíjiǔ]",
            jp: "二十九番目",
            jp_pronunciation: "[にじゅうきゅうばんめ]",
            ko: "스물아홉 번째"
         },
         {
            id: 70,
            en: "thirtieth",
            cn: "第三十",
            cn_pronunciation: "[dìsānshí]",
            jp: "三十番目",
            jp_pronunciation: "[さんじゅうばんめ]",
            ko: "서른 번째"
         },
         {
            id: 71,
            en: "thirty-first",
            cn: "第三十一",
            cn_pronunciation: "[dìsānshíyī]",
            jp: "三十一番目",
            jp_pronunciation: "[さんじゅういちばんめ]",
            ko: "서른한 번째"
         },
         {
            id: 72,
            en: "fortieth",
            cn: "第四十",
            cn_pronunciation: "[dìsìshí]",
            jp: "四十番目",
            jp_pronunciation: "[よんじゅうばんめ]",
            ko: "마흔 번째"
         },
         {
            id: 73,
            en: "fiftieth",
            cn: "第五十",
            cn_pronunciation: "[dìwǔshí]",
            jp: "五十番目",
            jp_pronunciation: "[ごじゅうばんめ]",
            ko: "쉰 번째"
         },
         {
            id: 74,
            en: "sixtieth",
            cn: "第六十",
            cn_pronunciation: "[dìliùshí]",
            jp: "六十番目",
            jp_pronunciation: "[ろくじゅうばんめ]",
            ko: "예순 번재"
         },
         {
            id: 75,
            en: "seventieth",
            cn: "第七十",
            cn_pronunciation: "[dìqīshí]",
            jp: "七十番目",
            jp_pronunciation: "[しちじゅうばんめ]",
            ko: "일흔 번째"
         },
         {
            id: 76,
            en: "eightieth",
            cn: "第八十",
            cn_pronunciation: "[dìbāshí]",
            jp: "八十番目",
            jp_pronunciation: "[はちじゅうばんめ]",
            ko: "여든 번째"
         },
         {
            id: 77,
            en: "ninetieth",
            cn: "第九十",
            cn_pronunciation: "[dìjiǔshí]",
            jp: "九十番目",
            jp_pronunciation: "[きゅうじゅうばんめ]",
            ko: "아흔 번째"
         },
         {
            id: 78,
            en: "hundredth",
            cn: "第百十",
            cn_pronunciation: "[dìbǎishí]",
            jp: "百十番目",
            jp_pronunciation: "[ひゃくばんめ]",
            ko: "백 번째"
         }
      ]
   },
   {
      id: 24,
      title: 'occupation',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "occupation",
            cn: "职业",
            cn_pronunciation: "[zhíyè]",
            jp: "職業",
            jp_pronunciation: "[しょくぎょう]",
            ko: "직업"
         },
         {
            id: 2,
            en: "acrobat",
            cn: "杂技演员",
            cn_pronunciation: "[zájìyǎnyuán]",
            jp: "アクロバット",
            jp_pronunciation: "[アクロバット]",
            ko: "곡예사"
         },
         {
            id: 3,
            en: "actor",
            cn: "演员",
            cn_pronunciation: "[yǎnyuán]",
            jp: "俳優",
            jp_pronunciation: "[はいゆう]",
            ko: "배우"
         },
         {
            id: 4,
            en: "actress",
            cn: "女演员",
            cn_pronunciation: "[nǚyǎnyuán]",
            jp: "女優",
            jp_pronunciation: "[じょゆう]",
            ko: "여자 배우"
         },
         {
            id: 5,
            en: "artist",
            cn: "艺术家；画家",
            cn_pronunciation: "[yìshùjiā；huàjiā]",
            jp: "芸術家",
            jp_pronunciation: "[げいじゅつか]",
            ko: "예술가；화가"
         },
         {
            id: 6,
            en: "astronaut",
            cn: "宇航员",
            cn_pronunciation: "[yǔhángyuán]",
            jp: "宇宙飛行士",
            jp_pronunciation: "[うちゅうひこうし]",
            ko: "우주 비행사"
         },
         {
            id: 7,
            en: "bookseller",
            cn: "书商；售书员",
            cn_pronunciation: "[shūshāng；shòushū yuán]",
            jp: "書籍商",
            jp_pronunciation: "[しょせきしょう]",
            ko: "도서 판매업자；서점원"
         },
         {
            id: 8,
            en: "boss",
            cn: "老板",
            cn_pronunciation: "[lǎobǎn]",
            jp: "社長",
            jp_pronunciation: "[しゃちょう]",
            ko: "사장"
         },
         {
            id: 9,
            en: "chairman",
            cn: "主席",
            cn_pronunciation: "[zhǔxí]",
            jp: "議長",
            jp_pronunciation: "[ぎちょう]",
            ko: "의장"
         },
         {
            id: 10,
            en: "clerk",
            cn: "店员；办事员；职员",
            cn_pronunciation: "[diànyuán；bànshìyuán；zhíyuán]",
            jp: "店員",
            jp_pronunciation: "[]",
            ko: "점원；사무원；직원"
         },
         {
            id: 11,
            en: "clown",
            cn: "小丑",
            cn_pronunciation: "[xiǎochǒu]",
            jp: "道化役者",
            jp_pronunciation: "[どうけやくしゃ]",
            ko: "어릿광대"
         },
         {
            id: 12,
            en: "computer",
            cn: "计算机",
            cn_pronunciation: "[jìsuànjī]",
            jp: "コンピューター",
            jp_pronunciation: "[コンピューター]",
            ko: "계산기. 컴퓨터"
         },
         {
            id: 13,
            en: "computer programmer",
            cn: "计算机程序编制员",
            cn_pronunciation: "[jìsuànjīchéngxù biānzhì yuán]",
            jp: "コンピューター・プログラマー",
            jp_pronunciation: "[コンピューター・プログラマー]",
            ko: "컴퓨터 프로그래머"
         },
         {
            id: 14,
            en: "cook",
            cn: "厨师",
            cn_pronunciation: "[chúshī]",
            jp: "料理人",
            jp_pronunciation: "[りょうり人にん]",
            ko: "요리사"
         },
         {
            id: 15,
            en: "customer",
            cn: "顾客",
            cn_pronunciation: "[gùkè]",
            jp: "顧客",
            jp_pronunciation: "[こきゃく]",
            ko: "고객"
         },
         {
            id: 16,
            en: "dancer",
            cn: "舞蹈家",
            cn_pronunciation: "[wǔdǎojiā]",
            jp: "ダンサー",
            jp_pronunciation: "[ダンサー]",
            ko: "무용가"
         },
         {
            id: 17,
            en: "dentist",
            cn: "牙医",
            cn_pronunciation: "[yáyī]",
            jp: "歯医者",
            jp_pronunciation: "[はいしゃ]",
            ko: "치과 의사"
         },
         {
            id: 18,
            en: "detective",
            cn: "侦探",
            cn_pronunciation: "[zhēntàn]",
            jp: "探偵",
            jp_pronunciation: "[たんてい]",
            ko: "탐정"
         },
         {
            id: 19,
            en: "doctor",
            cn: "医生",
            cn_pronunciation: "[yīshēng]",
            jp: "医者",
            jp_pronunciation: "[いしゃ]",
            ko: "의사"
         },
         {
            id: 20,
            en: "driver",
            cn: "司机",
            cn_pronunciation: "[sījī]",
            jp: "運転手",
            jp_pronunciation: "[うんてんしゅ]",
            ko: "운전사"
         },
         {
            id: 21,
            en: "engineer",
            cn: "工程师；机械师",
            cn_pronunciation: "[gōngchéngshī]",
            jp: "エンジニア",
            jp_pronunciation: "[エンジニア]",
            ko: "기사"
         },
         {
            id: 22,
            en: "farmer",
            cn: "农民",
            cn_pronunciation: "[nóngmín]",
            jp: "農民",
            jp_pronunciation: "[のうみん]",
            ko: "농민"
         },
         {
            id: 23,
            en: "fireman",
            cn: "消防员",
            cn_pronunciation: "[xiāofángyuán]",
            jp: "消防士",
            jp_pronunciation: "[しょうぼうし]",
            ko: "소방대원"
         },
         {
            id: 24,
            en: "guest",
            cn: "客人；宾客",
            cn_pronunciation: "[kèrén；bīnkè]",
            jp: "客",
            jp_pronunciation: "[きゃく]",
            ko: "손님"
         },
         {
            id: 25,
            en: "hairdresser",
            cn: "理发师；美容师",
            cn_pronunciation: "[lǐfàshī；měiróngshī]",
            jp: "理髪師",
            jp_pronunciation: "[りはつし]",
            ko: "이발사；미용사"
         },
         {
            id: 26,
            en: "headmaster",
            cn: "校长",
            cn_pronunciation: "[xiàozhǎng]",
            jp: "校長",
            jp_pronunciation: "[こうちょう]",
            ko: "교장"
         },
         {
            id: 27,
            en: "hero",
            cn: "英雄",
            cn_pronunciation: "[yīngxióng]",
            jp: "英雄",
            jp_pronunciation: "[えいゆう]",
            ko: "영웅"
         },
         {
            id: 28,
            en: "inventor",
            cn: "发明家",
            cn_pronunciation: "[fāmíngjiā]",
            jp: "発明者",
            jp_pronunciation: "[はつめいしゃ]",
            ko: "발명가"
         },
         {
            id: 29,
            en: "king",
            cn: "国王 ; 王",
            cn_pronunciation: "[guówáng ; wáng]",
            jp: "国王",
            jp_pronunciation: "[こくおう]",
            ko: "국왕"
         },
         {
            id: 30,
            en: "leader",
            cn: "领导",
            cn_pronunciation: "[lǐngdǎo]",
            jp: "指導者",
            jp_pronunciation: "[しどうしゃ]",
            ko: "지도자"
         },
         {
            id: 31,
            en: "mailman",
            cn: "邮递员；邮差",
            cn_pronunciation: "[yóudìyuán；yóuchāi]",
            jp: "郵便屋",
            jp_pronunciation: "[ゆうびんや]",
            ko: "우편 집배원"
         },
         {
            id: 32,
            en: "manager",
            cn: "经理；管理人",
            cn_pronunciation: "[jīnglǐ；guǎnlǐrén]",
            jp: "マネージャー",
            jp_pronunciation: "[マネージャー]",
            ko: "매니저；관리인"
         },
         {
            id: 33,
            en: "member",
            cn: "成员；会员",
            cn_pronunciation: "[chéngyuán；huìyuán]",
            jp: "メンバー",
            jp_pronunciation: "[メンバー]",
            ko: "구성원；회원"
         },
         {
            id: 34,
            en: "minister",
            cn: "大臣；部长",
            cn_pronunciation: "[dàchén；bùzhǎng]",
            jp: "大臣",
            jp_pronunciation: "[だいじん]",
            ko: "대신；장관"
         },
         {
            id: 35,
            en: "model",
            cn: "模特",
            cn_pronunciation: "[mótè]",
            jp: "モデル",
            jp_pronunciation: "[モデル]",
            ko: "모델"
         },
         {
            id: 36,
            en: "musician",
            cn: "音乐家",
            cn_pronunciation: "[yīnyuèjiā]",
            jp: "音楽家",
            jp_pronunciation: "[おんがくか]",
            ko: "음악가"
         },
         {
            id: 37,
            en: "neighbor",
            cn: "邻居",
            cn_pronunciation: "[línjū]",
            jp: "隣人",
            jp_pronunciation: "[りんじん]",
            ko: "이웃 사람"
         },
         {
            id: 38,
            en: "nurse",
            cn: "护士",
            cn_pronunciation: "[hùshi]",
            jp: "看護師",
            jp_pronunciation: "[かんごし]",
            ko: "간호사"
         },
         {
            id: 39,
            en: "officer",
            cn: "军官；警官",
            cn_pronunciation: "[jūnguān；jǐngguān]",
            jp: "将校",
            jp_pronunciation: "[しょうこう]",
            ko: "장교；경찰관"
         },
         {
            id: 40,
            en: "official",
            cn: "行政人员；高级职员",
            cn_pronunciation: "[xíngzhèng rényuán；gāojí zhíyuán]",
            jp: "公務員",
            jp_pronunciation: "[こうむいん]",
            ko: "행정직원；고위관리"
         },
         {
            id: 41,
            en: "painter",
            cn: "画家",
            cn_pronunciation: "[huàjiā]",
            jp: "画家",
            jp_pronunciation: "[がか]",
            ko: "화가"
         },
         {
            id: 42,
            en: "passenger",
            cn: "乘客；旅客",
            cn_pronunciation: "[chéngkè；lǚkè]",
            jp: "乗客",
            jp_pronunciation: "[じょうきゃく]",
            ko: "승객"
         },
         {
            id: 43,
            en: "peasant",
            cn: "农民",
            cn_pronunciation: "[nóngmín]",
            jp: "農民",
            jp_pronunciation: "[のうみん]",
            ko: "농민"
         },
         {
            id: 44,
            en: "pilot",
            cn: "飞行员",
            cn_pronunciation: "[fēixíngyuán]",
            jp: "パイロット",
            jp_pronunciation: "[パイロット]",
            ko: "(비행기) 조종사"
         },
         {
            id: 45,
            en: "poet",
            cn: "诗人",
            cn_pronunciation: "[shīrén]",
            jp: "詩人",
            jp_pronunciation: "[しじん]",
            ko: "시인"
         },
         {
            id: 46,
            en: "police",
            cn: "警察",
            cn_pronunciation: "[jǐngchá]",
            jp: "警察",
            jp_pronunciation: "[けいさつ]",
            ko: "경찰"
         },
         {
            id: 47,
            en: "policeman",
            cn: "男警察",
            cn_pronunciation: "[nánjĭngchá]",
            jp: "警察官",
            jp_pronunciation: "[けいさつかん]",
            ko: "(남자)경찰"
         },
         {
            id: 48,
            en: "policewoman",
            cn: "女警察",
            cn_pronunciation: "[nǚjǐngchá]",
            jp: "女性警察官",
            jp_pronunciation: "[じょせいけいさつかん]",
            ko: "(여자)경찰"
         },
         {
            id: 49,
            en: "postman",
            cn: "邮递员",
            cn_pronunciation: "[yóudìyuán]",
            jp: "郵便屋",
            jp_pronunciation: "[ゆうびんや]",
            ko: "우편 집배원"
         },
         {
            id: 50,
            en: "president",
            cn: "总统",
            cn_pronunciation: "[zǒngtǒng]",
            jp: "大統領",
            jp_pronunciation: "[だいとうりょう]",
            ko: "대통령"
         },
         {
            id: 51,
            en: "pupil",
            cn: "小学生",
            cn_pronunciation: "[xiǎoxuésheng]",
            jp: "生徒",
            jp_pronunciation: "[せいと]",
            ko: "초등학생"
         },
         {
            id: 52,
            en: "sailor",
            cn: "航员",
            cn_pronunciation: "[hángyuán]",
            jp: "船員",
            jp_pronunciation: "[せんいん]",
            ko: "선원"
         },
         {
            id: 53,
            en: "scientist",
            cn: "科学家",
            cn_pronunciation: "[kēxuéjiā]",
            jp: "科学者",
            jp_pronunciation: "[科学者]",
            ko: "과학자"
         },
         {
            id: 54,
            en: "secretary",
            cn: "秘书；书记",
            cn_pronunciation: "[mìshū；shūji]",
            jp: "秘書",
            jp_pronunciation: "[ひしょ]",
            ko: "비서； 서기"
         },
         {
            id: 55,
            en: "singer",
            cn: "歌手",
            cn_pronunciation: "[gēshǒu]",
            jp: "歌手",
            jp_pronunciation: "[かしゅ]",
            ko: "가수"
         },
         {
            id: 56,
            en: "student",
            cn: "学生",
            cn_pronunciation: "[xuésheng]",
            jp: "学生",
            jp_pronunciation: "[がくせい]",
            ko: "학생"
         },
         {
            id: 57,
            en: "taxi-driver",
            cn: "出租汽车司机",
            cn_pronunciation: "[chūzūqìchēsījī]",
            jp: "タクシー運転手",
            jp_pronunciation: "[タクシー・ドライバー]",
            ko: "택시 드라이버"
         },
         {
            id: 58,
            en: "teacher",
            cn: "老师；教师",
            cn_pronunciation: "[lǎoshī；jiàoshī]",
            jp: "先生",
            jp_pronunciation: "[せんせい]",
            ko: "선생님；교사"
         },
         {
            id: 59,
            en: "thief",
            cn: "盗贼",
            cn_pronunciation: "[dàozéi]",
            jp: "泥棒",
            jp_pronunciation: "[どろぼう]",
            ko: "도적"
         },
         {
            id: 60,
            en: "traveller",
            cn: "旅行家",
            cn_pronunciation: "[lǚxíngjiā]",
            jp: "旅行者",
            jp_pronunciation: "[りょこうしゃ]",
            ko: "여행가"
         },
         {
            id: 61,
            en: "visitor",
            cn: "访问者",
            cn_pronunciation: "[fǎngwènzhě]",
            jp: "訪問者",
            jp_pronunciation: "[ほうもんしゃ]",
            ko: "방문자"
         },
         {
            id: 62,
            en: "waiter",
            cn: "男服务员",
            cn_pronunciation: "[nánfúwùyuán]",
            jp: "ウエーター",
            jp_pronunciation: "[ウエーター]",
            ko: "남성 종업원"
         },
         {
            id: 63,
            en: "waitress",
            cn: "女服务员",
            cn_pronunciation: "[nǚfúwùyuán]",
            jp: "ウエートレス",
            jp_pronunciation: "[ウエートレス]",
            ko: "여성 종업원"
         },
         {
            id: 64,
            en: "winner",
            cn: "获胜者",
            cn_pronunciation: "[huòshèngzhĕ]",
            jp: "優勝者",
            jp_pronunciation: "[ゆうしょうしゃ]",
            ko: "우승자"
         },
         {
            id: 65,
            en: "wizard",
            cn: "男巫师；行家",
            cn_pronunciation: "[nánwū；hángjia]",
            jp: "(男の)魔法使い",
            jp_pronunciation: "[(おとこの)まほうつかい]",
            ko: "남자 마법사；전문가"
         },
         {
            id: 66,
            en: "worker",
            cn: "工人",
            cn_pronunciation: "[gōngrén]",
            jp: "労働者",
            jp_pronunciation: "[ろうどうしゃ]",
            ko: "노동자"
         },
         {
            id: 67,
            en: "writer",
            cn: "作家",
            cn_pronunciation: "[zuòjiā]",
            jp: "作家",
            jp_pronunciation: "[さっか]",
            ko: "작가"
         }
      ]
   },
   {
      id: 25,
      title: 'person',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "person",
            cn: "人称",
            cn_pronunciation: "[rénchēng]",
            jp: "人称",
            jp_pronunciation: "[にんしょう]",
            ko: "인칭"
         },
         {
            id: 2,
            en: "he",
            cn: "他",
            cn_pronunciation: "[tā]",
            jp: "彼",
            jp_pronunciation: "[かれ]",
            ko: "그"
         },
         {
            id: 3,
            en: "I",
            cn: "我",
            cn_pronunciation: "[wǒ]",
            jp: "私",
            jp_pronunciation: "[わたし]",
            ko: "나"
         },
         {
            id: 4,
            en: "it",
            cn: "它",
            cn_pronunciation: "[tā]",
            jp: "それ",
            jp_pronunciation: "[それ]",
            ko: "그것"
         },
         {
            id: 5,
            en: "my",
            cn: "我的",
            cn_pronunciation: "[wǒde]",
            jp: "私の",
            jp_pronunciation: "[わたしの]",
            ko: "나의"
         },
         {
            id: 6,
            en: "she",
            cn: "她",
            cn_pronunciation: "[tā]",
            jp: "彼女",
            jp_pronunciation: "[かのじょ]",
            ko: "그녀"
         },
         {
            id: 7,
            en: "they",
            cn: "他们",
            cn_pronunciation: "[tāmen]",
            jp: "彼ら",
            jp_pronunciation: "[かれら]",
            ko: "그들"
         },
         {
            id: 8,
            en: "we",
            cn: "我们",
            cn_pronunciation: "[wǒmen]",
            jp: "私たち",
            jp_pronunciation: "[わたしたち]",
            ko: "우리들"
         },
         {
            id: 9,
            en: "you",
            cn: "你(你们)",
            cn_pronunciation: "[nǐ(nǐmen)]",
            jp: "あなた(たち)",
            jp_pronunciation: "[あなた(たち)]",
            ko: "너(너희들)"
         },
         {
            id: 10,
            en: "your",
            cn: "你(你们)的",
            cn_pronunciation: "[nǐ(nǐmen)de]",
            jp: "あなた(たち)の",
            jp_pronunciation: "[あなた(たち)の]",
            ko: "너(너희들)의"
         },
         {
            id: 11,
            en: "them",
            cn: "他们(宾格)",
            cn_pronunciation: "[tāmen(bīngé)]",
            jp: "彼らを",
            jp_pronunciation: "[彼らを]",
            ko: "그들(목적격)을"
         },
         {
            id: 12,
            en: "me",
            cn: "我",
            cn_pronunciation: "[wǒ]",
            jp: "私を",
            jp_pronunciation: "[わたしを]",
            ko: "나(를)"
         },
         {
            id: 13,
            en: "us",
            cn: "我们",
            cn_pronunciation: "[wǒmen]",
            jp: "私たちを",
            jp_pronunciation: "[わたしたちを]",
            ko: "우리(를)"
         },
         {
            id: 14,
            en: "myself",
            cn: "我自己",
            cn_pronunciation: "[wǒzìjǐ]",
            jp: "私自身",
            jp_pronunciation: "[わたしじしん]",
            ko: "나자신"
         },
         {
            id: 15,
            en: "their",
            cn: "他们的",
            cn_pronunciation: "[tāmende]",
            jp: "彼らの",
            jp_pronunciation: "[かれらの]",
            ko: "그들의"
         },
         {
            id: 16,
            en: "mine",
            cn: "我的",
            cn_pronunciation: "[wǒde]",
            jp: "私のもの",
            jp_pronunciation: "[わたしのもの]",
            ko: "나의 것"
         },
         {
            id: 17,
            en: "yours",
            cn: "你(你们)的东西",
            cn_pronunciation: "[nǐ(nǐmen)dedōng‧xi]",
            jp: "あなた(たち)のもの",
            jp_pronunciation: "[あなた(たち)のもの]",
            ko: "너(희)들 것"
         },
         {
            id: 18,
            en: "hers",
            cn: "她的东西",
            cn_pronunciation: "[tādedōngxi]",
            jp: "彼女のもの",
            jp_pronunciation: "[かのじょのもの]",
            ko: "그녀의 것"
         },
         {
            id: 19,
            en: "his",
            cn: "他的 东西",
            cn_pronunciation: "[tādedōng‧xi]",
            jp: "彼のもの",
            jp_pronunciation: "[かれのもの]",
            ko: "그의 것"
         },
         {
            id: 20,
            en: "herself",
            cn: "她自己",
            cn_pronunciation: "[tāzìjǐ]",
            jp: "彼女自身",
            jp_pronunciation: "[かのじょしじしん]",
            ko: "그녀 자신"
         },
         {
            id: 21,
            en: "ours",
            cn: "我们的",
            cn_pronunciation: "[wǒmende]",
            jp: "私たちのもの",
            jp_pronunciation: "[わたしたちのもの]",
            ko: "우리의 것"
         }
      ]
   },
   {
      id: 26,
      title: 'place',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "place",
            cn: "地点",
            cn_pronunciation: "[dìdiǎn]",
            jp: "場所",
            jp_pronunciation: "[ばしょ]",
            ko: "장소"
         },
         {
            id: 2,
            en: "airport",
            cn: "飞机场,机场",
            cn_pronunciation: "[fēi jī chǎng ; jī chǎng]",
            jp: "空港",
            jp_pronunciation: "[くうこう]",
            ko: "비행장"
         },
         {
            id: 3,
            en: "bakery",
            cn: "面包房",
            cn_pronunciation: "[miànbāofáng]",
            jp: "パン屋",
            jp_pronunciation: "[パンや]",
            ko: "제과점"
         },
         {
            id: 4,
            en: "bank",
            cn: "银行",
            cn_pronunciation: "[yínháng]",
            jp: "銀行",
            jp_pronunciation: "[ぎんこう]",
            ko: "은행"
         },
         {
            id: 5,
            en: "bar",
            cn: "酒吧",
            cn_pronunciation: "[jiǔbā]",
            jp: "飲み屋",
            jp_pronunciation: "[のみや]",
            ko: "술집"
         },
         {
            id: 6,
            en: "bathroom",
            cn: "卫生间",
            cn_pronunciation: "[wèishēngjiān]",
            jp: "トイレ",
            jp_pronunciation: "[トイレ]",
            ko: "화장실"
         },
         {
            id: 7,
            en: "beauty shop",
            cn: "美容院",
            cn_pronunciation: "[měiróngyuàn]",
            jp: "美容院",
            jp_pronunciation: "[びよういん]",
            ko: "미용실"
         },
         {
            id: 8,
            en: "bedroom",
            cn: "卧室",
            cn_pronunciation: "[wòshì]",
            jp: "ベッドルーム",
            jp_pronunciation: "[ベッドルーム]",
            ko: "침실"
         },
         {
            id: 9,
            en: "bookshop",
            cn: "书店",
            cn_pronunciation: "[shūdiàn]",
            jp: "本屋",
            jp_pronunciation: "[ほんや]",
            ko: "서점"
         },
         {
            id: 10,
            en: "building",
            cn: "大楼 ; 建筑物",
            cn_pronunciation: "[dàlóu ; jiànzhùwù]",
            jp: "ビル",
            jp_pronunciation: "[ビル]",
            ko: "빌딩 ; 건축물"
         },
         {
            id: 11,
            en: "camp",
            cn: "营地；度假营",
            cn_pronunciation: "[yíngdì ; dùjiă yíng]",
            jp: "キャンプ",
            jp_pronunciation: "[キャンプ]",
            ko: "캠프 ; 휴가 캠프"
         },
         {
            id: 12,
            en: "capital",
            cn: "首都；省会",
            cn_pronunciation: "[shǒudū ; shěnghuì]",
            jp: "首都",
            jp_pronunciation: "[しゅと]",
            ko: "수도 ; 성도"
         },
         {
            id: 13,
            en: "cinema",
            cn: "电影院,电影馆",
            cn_pronunciation: "[diànyǐngyuàn ; diànyǐngguǎn]",
            jp: "映画館",
            jp_pronunciation: "[えいがかん]",
            ko: "영화관"
         },
         {
            id: 14,
            en: "circus",
            cn: "马戏团",
            cn_pronunciation: "[mǎxìtuán]",
            jp: "サーカス",
            jp_pronunciation: "[サーカス]",
            ko: "서커스"
         },
         {
            id: 15,
            en: "city",
            cn: "城市",
            cn_pronunciation: "[chéngshì]",
            jp: "都市",
            jp_pronunciation: "[とし]",
            ko: "도시"
         },
         {
            id: 16,
            en: "clinic",
            cn: "诊所",
            cn_pronunciation: "[zhěnsuǒ]",
            jp: "診療所",
            jp_pronunciation: "[しんりょうじょ]",
            ko: "진료소"
         },
         {
            id: 17,
            en: "coffee shop",
            cn: "咖啡店",
            cn_pronunciation: "[kāfēidiàn]",
            jp: "コーヒー・ショップ",
            jp_pronunciation: "[コーヒー・ショップ]",
            ko: "카페"
         },
         {
            id: 18,
            en: "community",
            cn: "社区",
            cn_pronunciation: "[shèqū]",
            jp: "コミュニティー",
            jp_pronunciation: "[コミュニティー]",
            ko: "공동체"
         },
         {
            id: 19,
            en: "department store",
            cn: "百货商店",
            cn_pronunciation: "[bǎihuòshāngdiàn]",
            jp: "デパート",
            jp_pronunciation: "[デパート]",
            ko: "백화점"
         },
         {
            id: 20,
            en: "dining-room",
            cn: "餐厅",
            cn_pronunciation: "[cāntīng]",
            jp: "食堂",
            jp_pronunciation: "[しょくどう]",
            ko: "식당"
         },
         {
            id: 21,
            en: "drugstore",
            cn: "药店",
            cn_pronunciation: "[yàodiàn]",
            jp: "ドラッグストア",
            jp_pronunciation: "[ドラッグストア]",
            ko: "약국"
         },
         {
            id: 22,
            en: "factory",
            cn: "工厂",
            cn_pronunciation: "[gōngchǎng]",
            jp: "工場",
            jp_pronunciation: "[こうじょう]",
            ko: "공장"
         },
         {
            id: 23,
            en: "farm",
            cn: "农场",
            cn_pronunciation: "[nóngchǎng]",
            jp: "農場",
            jp_pronunciation: "[のうじょう]",
            ko: "농장"
         },
         {
            id: 24,
            en: "flat",
            cn: "公寓",
            cn_pronunciation: "[gōngyù]",
            jp: "アパート",
            jp_pronunciation: "[アパート]",
            ko: "아파트"
         },
         {
            id: 25,
            en: "gallery",
            cn: "美术馆",
            cn_pronunciation: "[měishùguǎn]",
            jp: "ギャラリー",
            jp_pronunciation: "[ギャラリー]",
            ko: "미술관"
         },
         {
            id: 26,
            en: "garden",
            cn: "花园",
            cn_pronunciation: "[huāyuán]",
            jp: "庭",
            jp_pronunciation: "[にわ]",
            ko: "화원"
         },
         {
            id: 27,
            en: "gas station",
            cn: "加油站",
            cn_pronunciation: "[jiāyóuzhàn]",
            jp: "ガソリンスタンド",
            jp_pronunciation: "[ガソリンスタンド]",
            ko: "주유소"
         },
         {
            id: 28,
            en: "grocery store",
            cn: "杂货店",
            cn_pronunciation: "[záhuòdiàn]",
            jp: "食料雑貨店",
            jp_pronunciation: "[しょくりょうざっかてん]",
            ko: "잡화점"
         },
         {
            id: 29,
            en: "hairdresser's",
            cn: "美发店",
            cn_pronunciation: "[měifàdiàn]",
            jp: "美容院",
            jp_pronunciation: "[びよういん]",
            ko: "미용실"
         },
         {
            id: 30,
            en: "history museum",
            cn: "历史博物馆",
            cn_pronunciation: "[lìshǐ bówùguǎn]",
            jp: "歴史博物館",
            jp_pronunciation: "[れきしはくぶつかん]",
            ko: "역사박물관"
         },
         {
            id: 31,
            en: "hospital",
            cn: "医院",
            cn_pronunciation: "[yīyuàn]",
            jp: "病院",
            jp_pronunciation: "[びょういん]",
            ko: "병원"
         },
         {
            id: 32,
            en: "hotel",
            cn: "酒店，饭店，旅馆",
            cn_pronunciation: "[jiǔdiàn ; fàndiàn ; lǚguǎn]",
            jp: "ホテル",
            jp_pronunciation: "[ホテル]",
            ko: "호텔"
         },
         {
            id: 33,
            en: "kitchen",
            cn: "厨房",
            cn_pronunciation: "[chúfáng]",
            jp: "台所",
            jp_pronunciation: "[だいどころ]",
            ko: "주방"
         },
         {
            id: 34,
            en: "library",
            cn: "图书馆",
            cn_pronunciation: "[túshūguǎn]",
            jp: "図書館",
            jp_pronunciation: "[としょかん]",
            ko: "도서관"
         },
         {
            id: 35,
            en: "living room",
            cn: "客厅",
            cn_pronunciation: "[kètīng]",
            jp: "リビングルーム",
            jp_pronunciation: "[リビングルーム]",
            ko: "거실"
         },
         {
            id: 36,
            en: "market",
            cn: "市场；集市",
            cn_pronunciation: "[shìchǎng ; jíshì]",
            jp: "市場",
            jp_pronunciation: "[いちば]",
            ko: "시장"
         },
         {
            id: 37,
            en: "middle school",
            cn: "中学",
            cn_pronunciation: "[zhōng xué]",
            jp: "中学校",
            jp_pronunciation: "[ちゅうがっこう]",
            ko: "중학교"
         },
         {
            id: 38,
            en: "museum",
            cn: "博物馆",
            cn_pronunciation: "[bówùguǎn]",
            jp: "博物館",
            jp_pronunciation: "[はくぶつかん]",
            ko: "박물관"
         },
         {
            id: 39,
            en: "music rooom",
            cn: "音乐室",
            cn_pronunciation: "[yīnyuèshì]",
            jp: "音楽室",
            jp_pronunciation: "[おんがくしつ]",
            ko: "음악실"
         },
         {
            id: 40,
            en: "news stand",
            cn: "报摊",
            cn_pronunciation: "[bàotān]",
            jp: "新聞の売店",
            jp_pronunciation: "[しんぶんのばいてん]",
            ko: "신문 가두 판매점"
         },
         {
            id: 41,
            en: "office",
            cn: "办公室",
            cn_pronunciation: "[bàngōngshì]",
            jp: "事務所",
            jp_pronunciation: "[じむしょ]",
            ko: "사무실"
         },
         {
            id: 42,
            en: "palace",
            cn: "宫；宫殿",
            cn_pronunciation: "[gōng ; gōngdiàn]",
            jp: "宮殿",
            jp_pronunciation: "[きゅうでん]",
            ko: "궁 ; 궁전"
         },
         {
            id: 43,
            en: "park",
            cn: "公园",
            cn_pronunciation: "[gōngyuán]",
            jp: "公園",
            jp_pronunciation: "[こうえん]",
            ko: "공원"
         },
         {
            id: 44,
            en: "passage",
            cn: "通道，过道",
            cn_pronunciation: "[tōngdào ; guòdào]",
            jp: "通路",
            jp_pronunciation: "[つうろ]",
            ko: "통로"
         },
         {
            id: 45,
            en: "path",
            cn: "路；小道",
            cn_pronunciation: "[lù ; xiǎodào]",
            jp: "小道",
            jp_pronunciation: "[こみち]",
            ko: "길 ; 작은 길"
         },
         {
            id: 46,
            en: "place",
            cn: "地方；位置",
            cn_pronunciation: "[dìfāng；wèizhì]",
            jp: "場所",
            jp_pronunciation: "[ばしょ]",
            ko: "장소；위치"
         },
         {
            id: 47,
            en: "playground",
            cn: "操场",
            cn_pronunciation: "[cāochǎng]",
            jp: "運動場",
            jp_pronunciation: "[うんどうじょう]",
            ko: "운동장"
         },
         {
            id: 48,
            en: "pond",
            cn: "池塘",
            cn_pronunciation: "[chítáng]",
            jp: "池",
            jp_pronunciation: "[いけ]",
            ko: "연못"
         },
         {
            id: 49,
            en: "pool",
            cn: "水池",
            cn_pronunciation: "[shuǐchí]",
            jp: "水たまり",
            jp_pronunciation: "[みずたまり]",
            ko: "웅덩이"
         },
         {
            id: 50,
            en: "post office",
            cn: "邮局",
            cn_pronunciation: "[yóujú]",
            jp: "郵便局",
            jp_pronunciation: "[ゆうびんきょく]",
            ko: "우체국"
         },
         {
            id: 51,
            en: "primary school",
            cn: "小学",
            cn_pronunciation: "[xiǎoxué]",
            jp: "小学校",
            jp_pronunciation: "[しょうがっこう]",
            ko: "초등학교"
         },
         {
            id: 52,
            en: "reading room",
            cn: "阅览室",
            cn_pronunciation: "[yuèlǎnshì]",
            jp: "閲覧室",
            jp_pronunciation: "[えつらんしつ]",
            ko: "열람실"
         },
         {
            id: 53,
            en: "restaurant",
            cn: "饭馆",
            cn_pronunciation: "[饭馆]",
            jp: "レストラン",
            jp_pronunciation: "[レストラン]",
            ko: "식당"
         },
         {
            id: 54,
            en: "school",
            cn: "学校",
            cn_pronunciation: "[xuéxiào]",
            jp: "学校",
            jp_pronunciation: "[がっこう]",
            ko: "학교"
         },
         {
            id: 55,
            en: "science museum",
            cn: "科学博物馆",
            cn_pronunciation: "[kēxuébówùguăn]",
            jp: "科学博物館",
            jp_pronunciation: "[かがくはくぶつかん]",
            ko: "과학박물관"
         },
         {
            id: 56,
            en: "shop",
            cn: "商店",
            cn_pronunciation: "[shāngdiàn]",
            jp: "商店",
            jp_pronunciation: "[しょうてん]",
            ko: "상점"
         },
         {
            id: 57,
            en: "shopping centre",
            cn: "购物中心",
            cn_pronunciation: "[gòuwù zhōngxīn]",
            jp: "ショッピングセンター",
            jp_pronunciation: "[ショッピングセンター]",
            ko: "쇼핑센터"
         },
         {
            id: 58,
            en: "space",
            cn: "太空",
            cn_pronunciation: "[tàikōng]",
            jp: "空間",
            jp_pronunciation: "[くうかん]",
            ko: "우주"
         },
         {
            id: 59,
            en: "sports field",
            cn: "运动场",
            cn_pronunciation: "[yùndònɡchǎnɡ]",
            jp: "競技場",
            jp_pronunciation: "[きょうぎじょう]",
            ko: "운동장"
         },
         {
            id: 60,
            en: "square",
            cn: "广场",
            cn_pronunciation: "[guǎngchǎng]",
            jp: "広場",
            jp_pronunciation: "[ひろば]",
            ko: "광장"
         },
         {
            id: 61,
            en: "stadium",
            cn: "体育场",
            cn_pronunciation: "[tǐyùchǎng]",
            jp: "競技場",
            jp_pronunciation: "[きょうぎじょう]",
            ko: "운동장"
         },
         {
            id: 62,
            en: "station",
            cn: "车站",
            cn_pronunciation: "[chēzhàn]",
            jp: "駅 ; 停留所",
            jp_pronunciation: "[えき ; バスていりゅうじょ]",
            ko: "기차역, 버스 정류장"
         },
         {
            id: 63,
            en: "street",
            cn: "街道",
            cn_pronunciation: "[jiēdào]",
            jp: "街",
            jp_pronunciation: "[まち]",
            ko: "거리"
         },
         {
            id: 64,
            en: "study",
            cn: "书房",
            cn_pronunciation: "[shūfáng]",
            jp: "書斎",
            jp_pronunciation: "[しょさい]",
            ko: "서재"
         },
         {
            id: 65,
            en: "supermarket",
            cn: "超市",
            cn_pronunciation: "[chāoshì]",
            jp: "スーパーマーケット",
            jp_pronunciation: "[スーパーマーケット]",
            ko: "슈퍼마켓"
         },
         {
            id: 66,
            en: "table tennis room",
            cn: "乒乓球室",
            cn_pronunciation: "[pīngpāngqiúshì]",
            jp: "テーブル・テニス室",
            jp_pronunciation: "[テーブル・テニスしつ]",
            ko: "탁구장"
         },
         {
            id: 67,
            en: "theatre",
            cn: "剧院",
            cn_pronunciation: "[jùyuàn]",
            jp: "劇場",
            jp_pronunciation: "[げきじょう]",
            ko: "극장"
         },
         {
            id: 68,
            en: "toilet",
            cn: "厕所",
            cn_pronunciation: "[cèsuǒ]",
            jp: "トイレ",
            jp_pronunciation: "[トイレ]",
            ko: "변소"
         },
         {
            id: 69,
            en: "tower",
            cn: "塔",
            cn_pronunciation: "[塔]",
            jp: "タワー",
            jp_pronunciation: "[タワー]",
            ko: "탑"
         },
         {
            id: 70,
            en: "town",
            cn: "城镇；镇",
            cn_pronunciation: "[chéngzhèn ；zhèn]",
            jp: "町",
            jp_pronunciation: "[まち]",
            ko: "소도시 ；읍"
         },
         {
            id: 71,
            en: "train station",
            cn: "火车站",
            cn_pronunciation: "[huǒchēzhàn]",
            jp: "鉄道駅",
            jp_pronunciation: "[てつどうえき]",
            ko: "기차역"
         },
         {
            id: 72,
            en: "zoo",
            cn: "动物园",
            cn_pronunciation: "[dòngwùyuán]",
            jp: "動物園",
            jp_pronunciation: "[どうぶつえん]",
            ko: "동물원"
         }
      ]
   },
   {
      id: 27,
      title: 'plants',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "plants",
            cn: "植物",
            cn_pronunciation: "[zhíwù]",
            jp: "植物",
            jp_pronunciation: "[しょくぶつ]",
            ko: "식물"
         },
         {
            id: 2,
            en: "bamboo",
            cn: "竹子",
            cn_pronunciation: "[zhúzi]",
            jp: "竹",
            jp_pronunciation: "[たけ]",
            ko: "대나무"
         },
         {
            id: 3,
            en: "bloom",
            cn: "化",
            cn_pronunciation: "[huà]",
            jp: "花",
            jp_pronunciation: "[はな]",
            ko: "꽃"
         },
         {
            id: 4,
            en: "branch",
            cn: "树枝；枝条",
            cn_pronunciation: "[shùzhī；zhītiáo]",
            jp: "枝",
            jp_pronunciation: "[えだ]",
            ko: "나뭇가지"
         },
         {
            id: 5,
            en: "bush",
            cn: "灌木",
            cn_pronunciation: "[guànmù]",
            jp: "灌木",
            jp_pronunciation: "[かんぼく]",
            ko: "관목"
         },
         {
            id: 6,
            en: "cherry blossom",
            cn: "樱花",
            cn_pronunciation: "[yīnghuā]",
            jp: "桜の花",
            jp_pronunciation: "[さくらのはな]",
            ko: "벚꽃"
         },
         {
            id: 7,
            en: "crop",
            cn: "作物；庄稼",
            cn_pronunciation: "[zuòwù；zhuāng‧jia]",
            jp: "作物",
            jp_pronunciation: "[さくもつ]",
            ko: "농작물"
         },
         {
            id: 8,
            en: "flower",
            cn: "花朵",
            cn_pronunciation: "[huāduǒ]",
            jp: "花",
            jp_pronunciation: "[はな]",
            ko: "꽃"
         },
         {
            id: 9,
            en: "forest",
            cn: "森林",
            cn_pronunciation: "[sēnlín]",
            jp: "森林",
            jp_pronunciation: "[しんりん]",
            ko: "삼림"
         },
         {
            id: 10,
            en: "grass",
            cn: "草",
            cn_pronunciation: "[cǎo]",
            jp: "草",
            jp_pronunciation: "[くさ]",
            ko: "풀"
         },
         {
            id: 11,
            en: "leaf",
            cn: "叶子",
            cn_pronunciation: "[yèzi]",
            jp: "葉",
            jp_pronunciation: "[は]",
            ko: "잎"
         },
         {
            id: 12,
            en: "rose",
            cn: "玫瑰；玫瑰花",
            cn_pronunciation: "[méiguī ; méiguīhuā]",
            jp: "バラ ; バラの花",
            jp_pronunciation: "[バラ ; バラのはな]",
            ko: "장미 ; 장미꽃"
         },
         {
            id: 13,
            en: "tree",
            cn: "树",
            cn_pronunciation: "[shù]",
            jp: "木",
            jp_pronunciation: "[き]",
            ko: "나무"
         },
         {
            id: 14,
            en: "wheat",
            cn: "小麦",
            cn_pronunciation: "[xiǎomài]",
            jp: "小麦",
            jp_pronunciation: "[こむぎ]",
            ko: "밀"
         },
         {
            id: 15,
            en: "wood",
            cn: "木头 ; 木材",
            cn_pronunciation: "[mùtou ; mùcái]",
            jp: "木 ; 木材",
            jp_pronunciation: "[き ; もくざい]",
            ko: "나무 ; 목재"
         }
      ]
   },
   {
      id: 28,
      title: 'public holiday',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "public holiday",
            cn: "公众假期",
            cn_pronunciation: "[gōngzhòng jiàqī]",
            jp: "祝日",
            jp_pronunciation: "[しゅくじつ]",
            ko: "한국 공휴일"
         },
         {
            id: 2,
            en: "New Year's Day",
            cn: "元旦",
            cn_pronunciation: "[[yuándàn]]",
            jp: "新正",
            jp_pronunciation: "[[シンジョン]]",
            ko: "새해"
         },
         {
            id: 3,
            en: "Seollal",
            cn: "农历新年",
            cn_pronunciation: "[[nónglìxīnnián]]",
            jp: "旧正月",
            jp_pronunciation: "[[ソルラル]]",
            ko: "설날"
         },
         {
            id: 4,
            en: "Independence Movement Day",
            cn: "三一节",
            cn_pronunciation: "[[sānyījiē]]",
            jp: "三一節",
            jp_pronunciation: "[[サミルチョル]]",
            ko: "3.1절"
         },
         {
            id: 5,
            en: "Children's Day",
            cn: "儿童节",
            cn_pronunciation: "[[értóngjiē]]",
            jp: "子どもの日",
            jp_pronunciation: "[[オリニナル]]",
            ko: "어린이날"
         },
         {
            id: 6,
            en: "Buddha's Birthday",
            cn: "释迦诞辰日",
            cn_pronunciation: "[[shìjiādànchénrì]]",
            jp: "釈迦誕生日",
            jp_pronunciation: "[[ソッカタンシンイル]]",
            ko: "부처님오신날"
         },
         {
            id: 7,
            en: "Memorial Day",
            cn: "显忠日",
            cn_pronunciation: "[[xiǎnzhōngrì]]",
            jp: "顕忠日",
            jp_pronunciation: "[[ヒョンチュンイル]]",
            ko: "현충일"
         },
         {
            id: 8,
            en: "Liberation Day",
            cn: "光复节",
            cn_pronunciation: "[[guāngfùjiē]]",
            jp: "光復節",
            jp_pronunciation: "[[クァンボッチョル]]",
            ko: "광복절"
         },
         {
            id: 9,
            en: "Chuseok",
            cn: "中秋节",
            cn_pronunciation: "[[zhōngqiūjiē]]",
            jp: "秋夕",
            jp_pronunciation: "[[チュソク]]",
            ko: "추석"
         },
         {
            id: 10,
            en: "National Foundation Day",
            cn: "开天节",
            cn_pronunciation: "[[kāitiānjiē]]",
            jp: "開天節",
            jp_pronunciation: "[[ケチョンジョル]]",
            ko: "개천절"
         },
         {
            id: 11,
            en: "Hangeul Proclamation Day",
            cn: "韩文日",
            cn_pronunciation: "[[hánwénrì]]",
            jp: "ハングルの日",
            jp_pronunciation: "[[ハングルラル]]",
            ko: "한글날"
         },
         {
            id: 12,
            en: "Christmas Day",
            cn: "圣诞节",
            cn_pronunciation: "[[shèngdànjiē ]]",
            jp: "聖誕節",
            jp_pronunciation: "[[ソンタンジョル]]",
            ko: "크리스마스"
         }
      ]
   },
   {
      id: 29,
      title: 'public signs',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "No smoking",
            cn: "禁止吸烟",
            cn_pronunciation: "[jìnzhǐxīyān]",
            jp: "禁煙",
            jp_pronunciation: "[きんえん]",
            ko: "흡연금지"
         },
         {
            id: 2,
            en: "No parking",
            cn: "静止停车",
            cn_pronunciation: "[jìnzhǐtíngchē]",
            jp: "駐車禁止",
            jp_pronunciation: "[ちゅうしゃきんし]",
            ko: "주차금지"
         },
         {
            id: 3,
            en: "Danger",
            cn: "危险",
            cn_pronunciation: "[wēixiǎn]",
            jp: "危険",
            jp_pronunciation: "[きけん]",
            ko: "위험"
         },
         {
            id: 4,
            en: "Keep off the grass",
            cn: "远离草坪",
            cn_pronunciation: "[yuǎnlícǎopíng]",
            jp: "芝生立入禁止",
            jp_pronunciation: "[しばふたちいりきんし]",
            ko: "잔디밭에서 떨어지세요"
         },
         {
            id: 5,
            en: "No littering",
            cn: "静止乱扔",
            cn_pronunciation: "[jìngzhǐluànrēng]",
            jp: "ポイ捨て禁止",
            jp_pronunciation: "[ポイすてきんし]",
            ko: "쓰레기를 버리지 마세요"
         },
         {
            id: 6,
            en: "Do not touch",
            cn: "禁止触摸",
            cn_pronunciation: "[jìnzhĭ chùmō]",
            jp: "手を触れないでください",
            jp_pronunciation: "[てをふれないでください]",
            ko: "만지지 마시오"
         },
         {
            id: 7,
            en: "Be quiet",
            cn: "安静",
            cn_pronunciation: "[ānjìng]",
            jp: "静かにしてください",
            jp_pronunciation: "[しずかにしてください]",
            ko: "조용하세요"
         }
      ]
   },
   {
      id: 30,
      title: 'regards',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "regards",
            cn: "问候",
            cn_pronunciation: "[wènhòu]",
            jp: "安否",
            jp_pronunciation: "[あんぴ]",
            ko: "안부"
         },
         {
            id: 2,
            en: "hello",
            cn: "你好",
            cn_pronunciation: "[nǐhǎo]",
            jp: "おはよう, こんにちは, こんばんは",
            jp_pronunciation: "[おはよう, こんにちは, こんばんは]",
            ko: "안녕하세요"
         },
         {
            id: 3,
            en: "thank you",
            cn: "谢谢你",
            cn_pronunciation: "[xièxiènǐ]",
            jp: "ありがとう",
            jp_pronunciation: "[ありがとう]",
            ko: "감사합니다"
         },
         {
            id: 4,
            en: "good morning",
            cn: "早上好",
            cn_pronunciation: "[zǎoshànghǎo]",
            jp: "おはよう",
            jp_pronunciation: "[おはよう]",
            ko: "안녕하세요"
         },
         {
            id: 5,
            en: "good afternoon",
            cn: "下午好",
            cn_pronunciation: "[xiàwǔhǎo]",
            jp: "こんにちは",
            jp_pronunciation: "[こんにちは]",
            ko: "안녕하세요"
         },
         {
            id: 6,
            en: "good evening",
            cn: "晚上好",
            cn_pronunciation: "[wǎnshànghǎo]",
            jp: "こんばんは",
            jp_pronunciation: "[こんばんは]",
            ko: "안녕하세요"
         },
         {
            id: 7,
            en: "good night",
            cn: "晚安",
            cn_pronunciation: "[wǎnān]",
            jp: "さようなら!, おやすみなさい",
            jp_pronunciation: "[さようなら!, おやすみなさい。]",
            ko: "안녕하세요"
         },
         {
            id: 8,
            en: "Good luck.",
            cn: "好运",
            cn_pronunciation: "[hǎoyùn]",
            jp: "幸運を祈ります",
            jp_pronunciation: "[こううんをいのります。]",
            ko: "행운을 빕니다"
         },
         {
            id: 9,
            en: "Happy birthday.",
            cn: "生日快乐",
            cn_pronunciation: "[shēngrìkuàilè]",
            jp: "誕生日おめでとう",
            jp_pronunciation: "[たんじょうびおめでとう。]",
            ko: "생일 축하합니다"
         },
         {
            id: 10,
            en: "say hello to",
            cn: "打招呼",
            cn_pronunciation: "[dǎzhāohū]",
            jp: "…によろしくと言う",
            jp_pronunciation: "[…によろしくという]",
            ko: "안부를 전해주세요"
         },
         {
            id: 11,
            en: "hi",
            cn: "嗨",
            cn_pronunciation: "[hēi]",
            jp: "おはよう, こんにちは, こんばんは",
            jp_pronunciation: "[こんにちは, こんばんは]",
            ko: "안녕"
         },
         {
            id: 12,
            en: "goodbye",
            cn: "再见",
            cn_pronunciation: "[zàijiàn]",
            jp: "さよなら",
            jp_pronunciation: "[さよなら]",
            ko: "안녕히 가세요, 안녕히 계세요"
         },
         {
            id: 13,
            en: "bye",
            cn: "再见",
            cn_pronunciation: "[zàijiàn]",
            jp: "さよなら",
            jp_pronunciation: "[さよなら]",
            ko: "안녕"
         },
         {
            id: 14,
            en: "Thank you",
            cn: "谢谢你",
            cn_pronunciation: "[xièxiènǐ]",
            jp: "ありがとうございます。",
            jp_pronunciation: "[ありがとうございます。]",
            ko: "감사합니다."
         },
         {
            id: 15,
            en: "You're welcome!",
            cn: "不客气!",
            cn_pronunciation: "[búkèqì!]",
            jp: "どういたしまして",
            jp_pronunciation: "[どういたしまして]",
            ko: "천만에요."
         },
         {
            id: 16,
            en: "How are you?",
            cn: "你好吗？",
            cn_pronunciation: "[nǐhǎoma？]",
            jp: "元気ですか？",
            jp_pronunciation: "[げんきですか。]",
            ko: "잘 지내시나요?"
         },
         {
            id: 17,
            en: "Fine",
            cn: "很好",
            cn_pronunciation: "[hěnhǎo]",
            jp: "元気です。",
            jp_pronunciation: "[げんきです。]",
            ko: "좋습니다."
         },
         {
            id: 18,
            en: "How are you?",
            cn: "你好吗？",
            cn_pronunciation: "[nǐhǎoma？]",
            jp: "あなたはどうですか？",
            jp_pronunciation: "[あなたはどうですか？]",
            ko: "어떻게 지내세요?"
         },
         {
            id: 19,
            en: "Great",
            cn: "非常好的",
            cn_pronunciation: "[fēichánghǎode]",
            jp: "すごくいいです。",
            jp_pronunciation: "[すごくいいです。]",
            ko: "아주 좋습니다."
         },
         {
            id: 20,
            en: "Not so good",
            cn: "不太好",
            cn_pronunciation: "[bútàihǎo]",
            jp: "あまりよくないです。",
            jp_pronunciation: "[あまりよくないです。]",
            ko: "별로 안 좋습니다."
         },
         {
            id: 21,
            en: "What's new?",
            cn: "有什么新鲜事吗？",
            cn_pronunciation: "[yǒushénmexīnxiānshìma？]",
            jp: "最近、どうですか？",
            jp_pronunciation: "[さいきん、どうですか？]",
            ko: "잘 지내니?(새로운 일이 있는지 없는지 관점에서)"
         },
         {
            id: 22,
            en: "Not much",
            cn: "不多",
            cn_pronunciation: "[búduō]",
            jp: "特に何もありません。",
            jp_pronunciation: "[とくになにもありません。]",
            ko: "별일 없어요"
         },
         {
            id: 23,
            en: "Busy as usual",
            cn: "像平时那么忙",
            cn_pronunciation: "[xiàngpíngshínàmemáng]",
            jp: "相変わらずいそがしいです。",
            jp_pronunciation: "[あいかわらずいそがしいです。]",
            ko: "여전히 바쁩니다"
         },
         {
            id: 24,
            en: "What's happening?",
            cn: "发生什么事了？",
            cn_pronunciation: "[fāshēngshímeshìle？]",
            jp: "最近どうですか？",
            jp_pronunciation: "[さいきん、どうですか？]",
            ko: "무슨일입니까"
         },
         {
            id: 25,
            en: "That's good for you",
            cn: "那对你的好",
            cn_pronunciation: "[nàduìnǐdehǎo]",
            jp: "よかったです。",
            jp_pronunciation: "[よかったです。]",
            ko: "잘 됐네요"
         },
         {
            id: 26,
            en: "That's wonderful.",
            cn: "那太好了",
            cn_pronunciation: "[nàtàihǎole]",
            jp: "それは素晴らしいです。",
            jp_pronunciation: "[それはすばらしいです。]",
            ko: "정말 훌륭합니다"
         }
      ]
   },
   {
      id: 31,
      title: 'school',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "school",
            cn: "学校",
            cn_pronunciation: "[xuéxiào]",
            jp: "学校",
            jp_pronunciation: "[がっこう]",
            ko: "학교"
         },
         {
            id: 2,
            en: "art room",
            cn: "美术教室",
            cn_pronunciation: "[mĕishù jiàoshì]",
            jp: "美術室",
            jp_pronunciation: "[びじゅつ しつ]",
            ko: "미술교실"
         },
         {
            id: 3,
            en: "blackboard",
            cn: "黑板",
            cn_pronunciation: "[hēibǎn]",
            jp: "黒板",
            jp_pronunciation: "[こくばん]",
            ko: "칠판"
         },
         {
            id: 4,
            en: "class",
            cn: "班级",
            cn_pronunciation: "[bānjí]",
            jp: "クラス",
            jp_pronunciation: "[クラス]",
            ko: "반"
         },
         {
            id: 5,
            en: "classroom",
            cn: "教室",
            cn_pronunciation: "[jiàoshì]",
            jp: "教室",
            jp_pronunciation: "[きょうしつ]",
            ko: "교실"
         },
         {
            id: 6,
            en: "grade",
            cn: "年级",
            cn_pronunciation: "[niánjí]",
            jp: "学年",
            jp_pronunciation: "[がくねん]",
            ko: "학년"
         },
         {
            id: 7,
            en: "gym",
            cn: "体育馆",
            cn_pronunciation: "[tǐyùguǎn]",
            jp: "体育館 ; ジム",
            jp_pronunciation: "[たいいくかん ; ジム]",
            ko: "체육관"
         },
         {
            id: 8,
            en: "laboratory",
            cn: "实验室",
            cn_pronunciation: "[shíyànshì]",
            jp: "実験室",
            jp_pronunciation: "[じっけんしつ]",
            ko: "실험실"
         },
         {
            id: 9,
            en: "lesson",
            cn: "课；功课",
            cn_pronunciation: "[kè；gōngkè]",
            jp: "課",
            jp_pronunciation: "[か]",
            ko: "수업"
         },
         {
            id: 10,
            en: "library",
            cn: "图书馆",
            cn_pronunciation: "[túshūguǎn]",
            jp: "図書館",
            jp_pronunciation: "[としょかん]",
            ko: "도서관"
         },
         {
            id: 11,
            en: "music room",
            cn: "音乐教室",
            cn_pronunciation: "[yīnyuèjiàoshì]",
            jp: "音楽室",
            jp_pronunciation: "[おんがくしつ]",
            ko: "음악교실"
         },
         {
            id: 12,
            en: "nursery school",
            cn: "幼儿园",
            cn_pronunciation: "[yòu’éryuán]",
            jp: "유아원",
            jp_pronunciation: "[保育園]",
            ko: "유아원"
         },
         {
            id: 13,
            en: "playground",
            cn: "操场",
            cn_pronunciation: "[cāochǎng]",
            jp: "運動場",
            jp_pronunciation: "[うんどうじょう]",
            ko: "운동장"
         },
         {
            id: 14,
            en: "science room",
            cn: "科学教室",
            cn_pronunciation: "[kēxuéjiàoshì]",
            jp: "科学室",
            jp_pronunciation: "[かがくしつ]",
            ko: "과학교실"
         },
         {
            id: 15,
            en: "college",
            cn: "学院",
            cn_pronunciation: "[xuéyuàn]",
            jp: "(単科)大学",
            jp_pronunciation: "[(たんか)だいがく]",
            ko: "대학"
         },
         {
            id: 16,
            en: "university",
            cn: "大学",
            cn_pronunciation: "[dàxué]",
            jp: "(総合)大学",
            jp_pronunciation: "[(そうごう)だいがく]",
            ko: "대학교"
         }
      ]
   },
   {
      id: 32,
      title: 'shapes',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "shapes",
            cn: "形状",
            cn_pronunciation: "[xíngzhuàng]",
            jp: "形",
            jp_pronunciation: "[かたち]",
            ko: "모양"
         },
         {
            id: 2,
            en: "circle",
            cn: "圆形",
            cn_pronunciation: "[yuánxíng]",
            jp: "円",
            jp_pronunciation: "[えん]",
            ko: "원형"
         },
         {
            id: 3,
            en: "cone",
            cn: "圆锥形",
            cn_pronunciation: "[yuánzhuīxíng]",
            jp: "錐面",
            jp_pronunciation: "[すいめん]",
            ko: "원추형"
         },
         {
            id: 4,
            en: "crescent",
            cn: "新月形",
            cn_pronunciation: "[xīnyuèxíng]",
            jp: "三日月形",
            jp_pronunciation: "[みかづきがた]",
            ko: "초승달 모양"
         },
         {
            id: 5,
            en: "cube",
            cn: "正方体",
            cn_pronunciation: "[zhèngfāngtǐ]",
            jp: "立方体",
            jp_pronunciation: "[りっぽうたい]",
            ko: "입방체, 큐브"
         },
         {
            id: 6,
            en: "diamond",
            cn: "菱形",
            cn_pronunciation: "[língxíng]",
            jp: "菱形",
            jp_pronunciation: "[ひしがた]",
            ko: "마름모"
         },
         {
            id: 7,
            en: "oval",
            cn: "椭圆形",
            cn_pronunciation: "[tuǒyuánxíng]",
            jp: "楕円形",
            jp_pronunciation: "[だえんけい]",
            ko: "타원형"
         },
         {
            id: 8,
            en: "rectangle",
            cn: "长方形",
            cn_pronunciation: "[chángfāngxíng]",
            jp: "長方形",
            jp_pronunciation: "[ちょうほうけい]",
            ko: "직사각형, 장방형"
         },
         {
            id: 9,
            en: "square",
            cn: "正方形",
            cn_pronunciation: "[zhèngfāngxíng]",
            jp: "正方形",
            jp_pronunciation: "[せいほうけい]",
            ko: "정사각형,정방형"
         },
         {
            id: 10,
            en: "star",
            cn: "星形",
            cn_pronunciation: "[xīng xíng]",
            jp: "星形",
            jp_pronunciation: "[ほしがた]",
            ko: "별 모양"
         },
         {
            id: 11,
            en: "triangle",
            cn: "三角形",
            cn_pronunciation: "[sānjiǎoxíng]",
            jp: "三角形",
            jp_pronunciation: "[さんかっけい]",
            ko: "삼각형"
         }
      ]
   },
   {
      id: 33,
      title: 'sports',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "sports",
            cn: "运动",
            cn_pronunciation: "[yùndòng]",
            jp: "運動",
            jp_pronunciation: "[うんどう]",
            ko: "운동"
         },
         {
            id: 2,
            en: "badminton",
            cn: "羽毛球运动",
            cn_pronunciation: "[yǔmáoqiúyùndòng]",
            jp: "バドミントン",
            jp_pronunciation: "[バドミントン]",
            ko: "배드민턴"
         },
         {
            id: 3,
            en: "shuttlecock",
            cn: "羽毛球；毛毽",
            cn_pronunciation: "[yǔmáoqiú；máojiàn]",
            jp: "シャトル",
            jp_pronunciation: "[シャトル]",
            ko: "배드민턴공"
         },
         {
            id: 4,
            en: "ball",
            cn: "球",
            cn_pronunciation: "[qiú]",
            jp: "ボール",
            jp_pronunciation: "[ボール]",
            ko: "볼"
         },
         {
            id: 5,
            en: "baseball",
            cn: "棒球",
            cn_pronunciation: "[bàngqiú]",
            jp: "野球",
            jp_pronunciation: "[やきゅう]",
            ko: "야구"
         },
         {
            id: 6,
            en: "basketball",
            cn: "篮球",
            cn_pronunciation: "[lánqiú]",
            jp: "バスケットボール",
            jp_pronunciation: "[バスケットボール]",
            ko: "농구"
         },
         {
            id: 7,
            en: "chess",
            cn: "国际象棋",
            cn_pronunciation: "[guójìxiàngqí]",
            jp: "チェス",
            jp_pronunciation: "[チェス]",
            ko: "체스"
         },
         {
            id: 8,
            en: "climb",
            cn: "爬山",
            cn_pronunciation: "[páshān]",
            jp: "クライム, 登山",
            jp_pronunciation: "[クライム, とざん]",
            ko: "등산"
         },
         {
            id: 9,
            en: "cycling",
            cn: "骑自行车",
            cn_pronunciation: "[qízìhángchē]",
            jp: "サイクリング",
            jp_pronunciation: "[サイクリング]",
            ko: "사이클"
         },
         {
            id: 10,
            en: "exercise",
            cn: "运动；锻炼",
            cn_pronunciation: "[yùndòng；duànliàn]",
            jp: "運動",
            jp_pronunciation: "[うんどう]",
            ko: "운동"
         },
         {
            id: 11,
            en: "fishing",
            cn: "钓鱼",
            cn_pronunciation: "[diàoyú]",
            jp: "釣り",
            jp_pronunciation: "[つり]",
            ko: "낚시"
         },
         {
            id: 12,
            en: "football",
            cn: "足球",
            cn_pronunciation: "[zúqiú]",
            jp: "サッカー",
            jp_pronunciation: "[サッカー]",
            ko: "축구"
         },
         {
            id: 13,
            en: "high jump",
            cn: "跳高",
            cn_pronunciation: "[tiàogāo]",
            jp: "高跳び",
            jp_pronunciation: "[たかとび]",
            ko: "높이뛰기"
         },
         {
            id: 14,
            en: "hockey",
            cn: "曲棍球",
            cn_pronunciation: "[qǔgùnqiú]",
            jp: "ホッケー",
            jp_pronunciation: "[ホッケー]",
            ko: "하키"
         },
         {
            id: 15,
            en: "jogging",
            cn: "慢跑",
            cn_pronunciation: "[mànpǎo]",
            jp: "ジョギング",
            jp_pronunciation: "[ジョギング]",
            ko: "조깅"
         },
         {
            id: 16,
            en: "long jump",
            cn: "跳远",
            cn_pronunciation: "[tiàoyuǎn]",
            jp: "幅跳び",
            jp_pronunciation: "[はばとび]",
            ko: "멀리뛰기"
         },
         {
            id: 17,
            en: "match",
            cn: "比赛",
            cn_pronunciation: "[bǐsài]",
            jp: "マッチ, 試合",
            jp_pronunciation: "[マッチ, しあい]",
            ko: "매치, 시합"
         },
         {
            id: 18,
            en: "player",
            cn: "比赛者",
            cn_pronunciation: "[bǐsàizhě]",
            jp: "プレーヤー, 選手",
            jp_pronunciation: "[プレーヤー, せんしゅ]",
            ko: "플레이어, 선수"
         },
         {
            id: 19,
            en: "race",
            cn: "比赛",
            cn_pronunciation: "[bǐsài]",
            jp: "レース, 競走",
            jp_pronunciation: "[レース, きょうそう]",
            ko: "레이스, 경주"
         },
         {
            id: 20,
            en: "row",
            cn: "划船",
            cn_pronunciation: "[huáchuán]",
            jp: "ボート遊び",
            jp_pronunciation: "[ボートあそび]",
            ko: "뱃놀이"
         },
         {
            id: 21,
            en: "rule",
            cn: "规定；规则",
            cn_pronunciation: "[guīdìng；guīzé]",
            jp: "規則, ルール",
            jp_pronunciation: "[きそく, ルール]",
            ko: "규칙, 룰"
         },
         {
            id: 22,
            en: "run",
            cn: "跑步",
            cn_pronunciation: "[pǎobù]",
            jp: "走り",
            jp_pronunciation: "[はしり]",
            ko: "달리기"
         },
         {
            id: 23,
            en: "runner",
            cn: "赛跑者",
            cn_pronunciation: "[sàipǎozhě]",
            jp: "ランナー",
            jp_pronunciation: "[ランナー]",
            ko: "러너, 주자"
         },
         {
            id: 24,
            en: "skating",
            cn: "滑冰",
            cn_pronunciation: "[huábīng]",
            jp: "スケート",
            jp_pronunciation: "[スケート]",
            ko: "스케이트"
         },
         {
            id: 25,
            en: "ski",
            cn: "滑雪",
            cn_pronunciation: "[huáxuě]",
            jp: "スキー",
            jp_pronunciation: "[スキー]",
            ko: "스키"
         },
         {
            id: 26,
            en: "skipping-rope",
            cn: "跳绳",
            cn_pronunciation: "[tiàoshéng]",
            jp: "縄飛びの縄",
            jp_pronunciation: "[なわとびのなわ]",
            ko: "줄넘기의 줄"
         },
         {
            id: 27,
            en: "slide",
            cn: "滑滑梯",
            cn_pronunciation: "[huáhuátī]",
            jp: "滑る",
            jp_pronunciation: "[すべる]",
            ko: "미끄럼을 타다"
         },
         {
            id: 28,
            en: "soccer",
            cn: "英试足球",
            cn_pronunciation: "[yīngshìzúqiú]",
            jp: "サッカー",
            jp_pronunciation: "[サッカー]",
            ko: "축구"
         },
         {
            id: 29,
            en: "swimming",
            cn: "游泳",
            cn_pronunciation: "[yóuyǒng]",
            jp: "水泳, 泳ぎ,スイミング",
            jp_pronunciation: "[すいえい,およぎ、スイミング]",
            ko: "수영"
         },
         {
            id: 30,
            en: "swing",
            cn: "秋千",
            cn_pronunciation: "[qiūqiān]",
            jp: "揺れ",
            jp_pronunciation: "[ゆれ]",
            ko: "그네."
         },
         {
            id: 31,
            en: "table tennis",
            cn: "乒乓球",
            cn_pronunciation: "[pīngpāngqiú]",
            jp: "卓球",
            jp_pronunciation: "[たっきゅう]",
            ko: "탁구"
         },
         {
            id: 32,
            en: "tennis",
            cn: "网球",
            cn_pronunciation: "[wǎngqiú]",
            jp: "テニス",
            jp_pronunciation: "[テニス]",
            ko: "테니스"
         },
         {
            id: 33,
            en: "volleyball",
            cn: "排球",
            cn_pronunciation: "[páiqiú]",
            jp: "バレーボール",
            jp_pronunciation: "[バレーボール]",
            ko: "배구"
         }
      ]
   },
   {
      id: 34,
      title: 'stationaries',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "stationeries",
            cn: "文具",
            cn_pronunciation: "[wénjù]",
            jp: "文房具",
            jp_pronunciation: "[ぶんぼうぐ]",
            ko: "문구"
         },
         {
            id: 2,
            en: "bag",
            cn: "书包",
            cn_pronunciation: "[shūbāo]",
            jp: "バッグ",
            jp_pronunciation: "[バッグ]",
            ko: "가방"
         },
         {
            id: 3,
            en: "ball",
            cn: "球",
            cn_pronunciation: "[qiú]",
            jp: "ボール",
            jp_pronunciation: "[ボール]",
            ko: "공, 볼"
         },
         {
            id: 4,
            en: "ball pen",
            cn: "圆珠笔",
            cn_pronunciation: "[yuánzhūbǐ]",
            jp: "ボールペン",
            jp_pronunciation: "[ボールペン]",
            ko: "볼펜"
         },
         {
            id: 5,
            en: "ballon",
            cn: "气球",
            cn_pronunciation: "[qìqiú]",
            jp: "風船",
            jp_pronunciation: "[ふうせん]",
            ko: "풍선"
         },
         {
            id: 6,
            en: "blackboard",
            cn: "黑板",
            cn_pronunciation: "[hēibǎn]",
            jp: "黒板",
            jp_pronunciation: "[こくばん]",
            ko: "칠판"
         },
         {
            id: 7,
            en: "book",
            cn: "书",
            cn_pronunciation: "[shū]",
            jp: "本",
            jp_pronunciation: "[ほん]",
            ko: "책"
         },
         {
            id: 8,
            en: "bookmark",
            cn: "书签",
            cn_pronunciation: "[shūqiān]",
            jp: "ブックマーク",
            jp_pronunciation: "[ブックマーク]",
            ko: "북마크"
         },
         {
            id: 9,
            en: "bookshelf",
            cn: "书架",
            cn_pronunciation: "[shūjià]",
            jp: "本棚",
            jp_pronunciation: "[ほんだな]",
            ko: "선반"
         },
         {
            id: 10,
            en: "card",
            cn: "卡片",
            cn_pronunciation: "[kǎpiàn]",
            jp: "カード",
            jp_pronunciation: "[カード]",
            ko: "카드"
         },
         {
            id: 11,
            en: "chair",
            cn: "椅子",
            cn_pronunciation: "[yǐzǐ]",
            jp: "椅子",
            jp_pronunciation: "[いす]",
            ko: "의자"
         },
         {
            id: 12,
            en: "chalk",
            cn: "粉笔",
            cn_pronunciation: "[fěnbǐ]",
            jp: "チョーク",
            jp_pronunciation: "[チョーク]",
            ko: "분필"
         },
         {
            id: 13,
            en: "computer",
            cn: "电脑",
            cn_pronunciation: "[diànnǎo]",
            jp: "コンピュータ",
            jp_pronunciation: "[コンピュータ]",
            ko: "컴퓨터"
         },
         {
            id: 14,
            en: "copybook",
            cn: "抄写本",
            cn_pronunciation: "[chāoxiěběn]",
            jp: "手本長",
            jp_pronunciation: "[てほんちょう]",
            ko: "습자책(글씨 연습용 책)"
         },
         {
            id: 15,
            en: "crayon",
            cn: "蜡笔",
            cn_pronunciation: "[làbǐ]",
            jp: "クレヨン",
            jp_pronunciation: "[クレヨン]",
            ko: "크레용"
         },
         {
            id: 16,
            en: "desk",
            cn: "书桌",
            cn_pronunciation: "[shūzhuō]",
            jp: "机",
            jp_pronunciation: "[つくえ]",
            ko: "책상"
         },
         {
            id: 17,
            en: "diary",
            cn: "日记；日记薄",
            cn_pronunciation: "[rìjì；rìjìbáo]",
            jp: "日記",
            jp_pronunciation: "[にっき]",
            ko: "일기"
         },
         {
            id: 18,
            en: "dictionary",
            cn: "词典",
            cn_pronunciation: "[cídiǎn]",
            jp: "辞書",
            jp_pronunciation: "[じしょ]",
            ko: "사전"
         },
         {
            id: 19,
            en: "drawing",
            cn: "绘画；图画",
            cn_pronunciation: "[huìhuà；túhuà]",
            jp: "線画",
            jp_pronunciation: "[せんが]",
            ko: "그림(색을 칠하지 않는), 소묘"
         },
         {
            id: 20,
            en: "e-card",
            cn: "电子贺卡",
            cn_pronunciation: "[diànzǐhèkǎ]",
            jp: "eカード",
            jp_pronunciation: "[eカード]",
            ko: "E 카드, 전자카드"
         },
         {
            id: 21,
            en: "E-mail",
            cn: "电子邮件",
            cn_pronunciation: "[diànzǐyóujiàn]",
            jp: "eメール",
            jp_pronunciation: "[eメール]",
            ko: "이메일"
         },
         {
            id: 22,
            en: "envelope",
            cn: "传真机",
            cn_pronunciation: "[chuánzhēnjī]",
            jp: "封筒",
            jp_pronunciation: "[ふうとう]",
            ko: "봉투"
         },
         {
            id: 23,
            en: "eraser",
            cn: "像被擦",
            cn_pronunciation: "[xiàngbèicā]",
            jp: "消しゴム",
            jp_pronunciation: "[消しゴム]",
            ko: "지우개"
         },
         {
            id: 24,
            en: "examination",
            cn: "考试；测试；检查",
            cn_pronunciation: "[kǎoshì；cèshì；jiǎnchá]",
            jp: "試験",
            jp_pronunciation: "[しけん]",
            ko: "시험"
         },
         {
            id: 25,
            en: "film",
            cn: "电影；影片",
            cn_pronunciation: "[diànyǐng；yǐngpiàn]",
            jp: "映画",
            jp_pronunciation: "[えいが]",
            ko: "영화"
         },
         {
            id: 26,
            en: "flag",
            cn: "旗子；旗帜",
            cn_pronunciation: "[qízǐ；qízhì]",
            jp: "旗",
            jp_pronunciation: "[き]",
            ko: "기,깃발"
         },
         {
            id: 27,
            en: "form",
            cn: "表格",
            cn_pronunciation: "[biǎogé]",
            jp: "形",
            jp_pronunciation: "[かたち]",
            ko: "형태"
         },
         {
            id: 28,
            en: "glue",
            cn: "胶水",
            cn_pronunciation: "[jiāoshuǐ]",
            jp: "接着剤",
            jp_pronunciation: "[せっちゃくざい]",
            ko: "풀. 본드. 접착제"
         },
         {
            id: 29,
            en: "homework",
            cn: "作业",
            cn_pronunciation: "[zuòyè]",
            jp: "宿題",
            jp_pronunciation: "[しゅくだい]",
            ko: "숙제"
         },
         {
            id: 30,
            en: "icon",
            cn: "图标",
            cn_pronunciation: "[túbiāo]",
            jp: "アイコン",
            jp_pronunciation: "[アイコン]",
            ko: "아이콘"
         },
         {
            id: 31,
            en: "ink",
            cn: "墨水",
            cn_pronunciation: "[mòshuǐ]",
            jp: "インク",
            jp_pronunciation: "[インク]",
            ko: "잉크"
         },
         {
            id: 32,
            en: "keyboard",
            cn: "键盘",
            cn_pronunciation: "[jiànpán]",
            jp: "キーボード",
            jp_pronunciation: "[キーボード]",
            ko: "키보드"
         },
         {
            id: 33,
            en: "knife",
            cn: "小刀",
            cn_pronunciation: "[xiǎodāo]",
            jp: "ナイフ",
            jp_pronunciation: "[ナイフ]",
            ko: "칼"
         },
         {
            id: 34,
            en: "letter",
            cn: "字母",
            cn_pronunciation: "[zìmǔ]",
            jp: "文字",
            jp_pronunciation: "[もじ]",
            ko: "글자, 문자"
         },
         {
            id: 35,
            en: "library card",
            cn: "图书卡",
            cn_pronunciation: "[túshūkǎ]",
            jp: "図書館カード",
            jp_pronunciation: "[としょかんカード]",
            ko: "도서관 카드"
         },
         {
            id: 36,
            en: "magazine",
            cn: "杂志",
            cn_pronunciation: "[zázhì]",
            jp: "雑誌",
            jp_pronunciation: "[ざっし]",
            ko: "잡지"
         },
         {
            id: 37,
            en: "map",
            cn: "地图",
            cn_pronunciation: "[dìtú]",
            jp: "地図",
            jp_pronunciation: "[ちず]",
            ko: "지도"
         },
         {
            id: 38,
            en: "note",
            cn: "便条；便笺",
            cn_pronunciation: "[biàntiáo；biànjiān]",
            jp: "覚え書き、メモ",
            jp_pronunciation: "[おぼえかき、めも]",
            ko: "메모"
         },
         {
            id: 39,
            en: "notebook",
            cn: "笔记本",
            cn_pronunciation: "[bǐjìběn]",
            jp: "ノート",
            jp_pronunciation: "[ノート]",
            ko: "노트,공책"
         },
         {
            id: 40,
            en: "page",
            cn: "页",
            cn_pronunciation: "[yè]",
            jp: "ページ",
            jp_pronunciation: "[ページ]",
            ko: "페이지, 쪽"
         },
         {
            id: 41,
            en: "paint",
            cn: "涂料；油漆",
            cn_pronunciation: "[túliào；yóuqī]",
            jp: "ペンキ",
            jp_pronunciation: "[ペンキ]",
            ko: "페인트"
         },
         {
            id: 42,
            en: "painting",
            cn: "油漆；水杉画；水彩画",
            cn_pronunciation: "[yóuqī；shuǐshānhuà；shuǐcǎihuà]",
            jp: "絵",
            jp_pronunciation: "[え]",
            ko: "그림(물감으로 그린)"
         },
         {
            id: 43,
            en: "paper",
            cn: "纸",
            cn_pronunciation: "[zhǐ]",
            jp: "紙",
            jp_pronunciation: "[かみ]",
            ko: "종이"
         },
         {
            id: 44,
            en: "pen",
            cn: "钢笔",
            cn_pronunciation: "[gāngbǐ]",
            jp: "ペン",
            jp_pronunciation: "[ぺん]",
            ko: "펜"
         },
         {
            id: 45,
            en: "pencil",
            cn: "铅笔",
            cn_pronunciation: "[qiānbǐ]",
            jp: "鉛筆",
            jp_pronunciation: "[えんぴつ]",
            ko: "연필"
         },
         {
            id: 46,
            en: "pencil box(case)",
            cn: "铅笔盒",
            cn_pronunciation: "[qiānbǐhé]",
            jp: "筆箱",
            jp_pronunciation: "[ふでばこ]",
            ko: "(문구) 주머니형 필통"
         },
         {
            id: 47,
            en: "pencil sharpener",
            cn: "刨笔刀",
            cn_pronunciation: "[páobǐdāo]",
            jp: "鉛筆削り",
            jp_pronunciation: "[えんぴつけずり]",
            ko: "연필깎이"
         },
         {
            id: 48,
            en: "picture",
            cn: "图画；胶片",
            cn_pronunciation: "[túhuà；jiāopiàn]",
            jp: "写真",
            jp_pronunciation: "[しゃしん]",
            ko: "그림"
         },
         {
            id: 49,
            en: "postcard",
            cn: "明信片",
            cn_pronunciation: "[míngxìnpiàn]",
            jp: "葉書",
            jp_pronunciation: "[はがき]",
            ko: "그림엽서, 엽서"
         },
         {
            id: 50,
            en: "rubber, eraser",
            cn: "橡皮",
            cn_pronunciation: "[xiàngpí]",
            jp: "消しゴム",
            jp_pronunciation: "[けしごむ]",
            ko: "지우개"
         },
         {
            id: 51,
            en: "ruler",
            cn: "尺子",
            cn_pronunciation: "[chǐzǐ]",
            jp: "定規",
            jp_pronunciation: "[じょうぎ]",
            ko: "자"
         },
         {
            id: 52,
            en: "school bag",
            cn: "书包",
            cn_pronunciation: "[shūbāo]",
            jp: "通学かばん、スクールバッグ",
            jp_pronunciation: "[つうがくかばん、スクールバッグ]",
            ko: "(통학용) 가방"
         },
         {
            id: 53,
            en: "stapler",
            cn: "订书机",
            cn_pronunciation: "[dìngshūjī]",
            jp: "ホッチキス",
            jp_pronunciation: "[ホッチキス]",
            ko: "스테이플러, 호치키스"
         },
         {
            id: 54,
            en: "storybook",
            cn: "故事书",
            cn_pronunciation: "[gùshìshū]",
            jp: "物語の本、おとぎ話",
            jp_pronunciation: "[ものがたりのほん、おとぎばなし]",
            ko: "이야기책, 동화책"
         },
         {
            id: 55,
            en: "correction tape",
            cn: "修正带",
            cn_pronunciation: "[xiūzhèngdài]",
            jp: "修正テープ",
            jp_pronunciation: "[しゅうせいテープ]",
            ko: "수정테이프"
         },
         {
            id: 56,
            en: "fax machine",
            cn: "转诊",
            cn_pronunciation: "[zhuǎnzhěn]",
            jp: "ファックス(機械)",
            jp_pronunciation: "[ファックス(きかい)]",
            ko: "팩스(기)"
         },
         {
            id: 57,
            en: "test",
            cn: "测验；小考",
            cn_pronunciation: "[cèyàn；xiǎokǎo]",
            jp: "試験、テスト",
            jp_pronunciation: "[しけん、テスト]",
            ko: "시험, 테스트"
         },
         {
            id: 58,
            en: "word",
            cn: "单词",
            cn_pronunciation: "[dāncí]",
            jp: "単語",
            jp_pronunciation: "[たんご]",
            ko: "단어"
         },
         {
            id: 59,
            en: "workbook",
            cn: "练习册",
            cn_pronunciation: "[liànxícè]",
            jp: "ワークブック、練習長",
            jp_pronunciation: "[ワークブック、れんしゅうちょう]",
            ko: "워크북. 연습문제집"
         },
         {
            id: 60,
            en: "writing paper",
            cn: "信纸",
            cn_pronunciation: "[xìnzhǐ]",
            jp: "筆記用紙、便箋",
            jp_pronunciation: "[ひっきようし、びんせん]",
            ko: "습자책(글씨 연습용 책)"
         }
      ]
   },
   {
      id: 35,
      title: 'subject',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "curriculum",
            cn: "课程",
            cn_pronunciation: "[kèchéng]",
            jp: "教科課程",
            jp_pronunciation: "[きょうかかてい]",
            ko: "교과과정"
         },
         {
            id: 2,
            en: "art",
            cn: "艺术",
            cn_pronunciation: "[yìshù]",
            jp: "芸術",
            jp_pronunciation: "[げいじゅつ]",
            ko: "예술"
         },
         {
            id: 3,
            en: "chemistry",
            cn: "化学",
            cn_pronunciation: "[huàxué]",
            jp: "化学",
            jp_pronunciation: "[かがく]",
            ko: "화학"
         },
         {
            id: 4,
            en: "english",
            cn: "英语",
            cn_pronunciation: "[Yīngyǔ]",
            jp: "英語",
            jp_pronunciation: "[えいご]",
            ko: "영어"
         },
         {
            id: 5,
            en: "geography",
            cn: "地理",
            cn_pronunciation: "[dìlǐ]",
            jp: "地理",
            jp_pronunciation: "[ちり]",
            ko: "지리"
         },
         {
            id: 6,
            en: "history",
            cn: "历史",
            cn_pronunciation: "[lìshǐ]",
            jp: "歴史",
            jp_pronunciation: "[れきし]",
            ko: "역사"
         },
         {
            id: 7,
            en: "math",
            cn: "数学",
            cn_pronunciation: "[shùxué]",
            jp: "数学",
            jp_pronunciation: "[すうがく]",
            ko: "수학"
         },
         {
            id: 8,
            en: "music",
            cn: "音乐",
            cn_pronunciation: "[yīnyuè]",
            jp: "音楽",
            jp_pronunciation: "[おんがく]",
            ko: "음악"
         },
         {
            id: 9,
            en: "PE",
            cn: "体育",
            cn_pronunciation: "[tǐyù]",
            jp: "体育",
            jp_pronunciation: "[たいいく]",
            ko: "체육"
         },
         {
            id: 10,
            en: "physics",
            cn: "物理",
            cn_pronunciation: "[wùlǐ]",
            jp: "物理学",
            jp_pronunciation: "[ぶつりがく]",
            ko: "물리학"
         },
         {
            id: 11,
            en: "science",
            cn: "科学",
            cn_pronunciation: "[kēxué]",
            jp: "科学",
            jp_pronunciation: "[かがく]",
            ko: "과학"
         }
      ]
   },
   {
      id: 36,
      title: 'time',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "Week",
            cn: "星期",
            cn_pronunciation: "[xīngqī]",
            jp: "曜日",
            jp_pronunciation: "[ようび]",
            ko: "요일"
         },
         {
            id: 2,
            en: "Monday",
            cn: "星期一",
            cn_pronunciation: "[xīngqīyī]",
            jp: "月曜日",
            jp_pronunciation: "[げつようび]",
            ko: "월요일"
         },
         {
            id: 3,
            en: "Tuesday",
            cn: "星期二",
            cn_pronunciation: "[xīngqīèr]",
            jp: "火曜日",
            jp_pronunciation: "[かようび]",
            ko: "화요일"
         },
         {
            id: 4,
            en: "Wednesday",
            cn: "星期三",
            cn_pronunciation: "[xīngqīsān]",
            jp: "水曜日",
            jp_pronunciation: "[すいようび]",
            ko: "수요일"
         },
         {
            id: 5,
            en: "Thursday",
            cn: "星期四",
            cn_pronunciation: "[xīngqīsì]",
            jp: "木曜日",
            jp_pronunciation: "[もくようび]",
            ko: "목요일"
         },
         {
            id: 6,
            en: "Friday",
            cn: "星期五",
            cn_pronunciation: "[xīngqīwǔ]",
            jp: "金曜日",
            jp_pronunciation: "[きんようび]",
            ko: "금요일"
         },
         {
            id: 7,
            en: "Saturday",
            cn: "星期六",
            cn_pronunciation: "[xīngqīliù]",
            jp: "土曜日",
            jp_pronunciation: "[どようび]",
            ko: "토요일"
         },
         {
            id: 8,
            en: "Sunday",
            cn: "星期日",
            cn_pronunciation: "[xīngqīrì]",
            jp: "日曜日",
            jp_pronunciation: "[にちようび]",
            ko: "일요일"
         },
         {
            id: 9,
            en: "Month",
            cn: "月份",
            cn_pronunciation: "[yuèfèn]",
            jp: "",
            jp_pronunciation: "[]",
            ko: ""
         },
         {
            id: 10,
            en: "January",
            cn: "一月",
            cn_pronunciation: "[yīyuè]",
            jp: "一月",
            jp_pronunciation: "[いちがつ]",
            ko: "1월"
         },
         {
            id: 11,
            en: "February",
            cn: "二月",
            cn_pronunciation: "[èryuè]",
            jp: "二月",
            jp_pronunciation: "[にがつ]",
            ko: "2월"
         },
         {
            id: 12,
            en: "March",
            cn: "三月",
            cn_pronunciation: "[sānyuè]",
            jp: "三月",
            jp_pronunciation: "[さんがつ]",
            ko: "3월"
         },
         {
            id: 13,
            en: "April",
            cn: "四月",
            cn_pronunciation: "[sìyuè]",
            jp: "四月",
            jp_pronunciation: "[しがつ]",
            ko: "4월"
         },
         {
            id: 14,
            en: "May",
            cn: "五月",
            cn_pronunciation: "[wǔyuè]",
            jp: "五月",
            jp_pronunciation: "[ごがつ]",
            ko: "5월"
         },
         {
            id: 15,
            en: "June",
            cn: "六月",
            cn_pronunciation: "[liùyuè]",
            jp: "六月",
            jp_pronunciation: "[ろくがつ]",
            ko: "6월"
         },
         {
            id: 16,
            en: "July",
            cn: "七月",
            cn_pronunciation: "[qīyuè]",
            jp: "七月",
            jp_pronunciation: "[しちがつ]",
            ko: "7월"
         },
         {
            id: 17,
            en: "August",
            cn: "八月",
            cn_pronunciation: "[bāyuè]",
            jp: "八月",
            jp_pronunciation: "[はちがつ]",
            ko: "8월"
         },
         {
            id: 18,
            en: "September",
            cn: "九月",
            cn_pronunciation: "[jiǔyuè]",
            jp: "九月",
            jp_pronunciation: "[くがつ]",
            ko: "9월"
         },
         {
            id: 19,
            en: "October",
            cn: "十月",
            cn_pronunciation: "[shíyuè]",
            jp: "十月",
            jp_pronunciation: "[じゅうがつ]",
            ko: "10월"
         },
         {
            id: 20,
            en: "November",
            cn: "十一月",
            cn_pronunciation: "[shíyīyuè]",
            jp: "十一月",
            jp_pronunciation: "[じゅういちがつ]",
            ko: "11월"
         },
         {
            id: 21,
            en: "December",
            cn: "十二月",
            cn_pronunciation: "[shíèryuè]",
            jp: "十二月",
            jp_pronunciation: "[じゅうにがつ]",
            ko: "12월"
         },
         {
            id: 22,
            en: "Time",
            cn: "时间",
            cn_pronunciation: "[shíjiān]",
            jp: "時間",
            jp_pronunciation: "[じかん]",
            ko: "시간"
         },
         {
            id: 23,
            en: "morning",
            cn: "早上",
            cn_pronunciation: "[zǎoshàng]",
            jp: "朝",
            jp_pronunciation: "[あさ]",
            ko: "아침"
         },
         {
            id: 24,
            en: "noon",
            cn: "正午",
            cn_pronunciation: "[zhèngwǔ]",
            jp: "正午",
            jp_pronunciation: "[しょうご]",
            ko: "정오"
         },
         {
            id: 25,
            en: "afternoon",
            cn: "下午",
            cn_pronunciation: "[xiàwǔ]",
            jp: "午後",
            jp_pronunciation: "[ごご]",
            ko: "오후"
         },
         {
            id: 26,
            en: "evening",
            cn: "晚上",
            cn_pronunciation: "[wǎnshàng]",
            jp: "夕方",
            jp_pronunciation: "[ゆうがた]",
            ko: "저녁"
         },
         {
            id: 27,
            en: "night",
            cn: "夜晚，夜间",
            cn_pronunciation: "[yèwǎn，yèjiān]",
            jp: "夜",
            jp_pronunciation: "[よる]",
            ko: "밤"
         },
         {
            id: 28,
            en: "season",
            cn: "四季",
            cn_pronunciation: "[sìjì]",
            jp: "季節",
            jp_pronunciation: "[きせつ]",
            ko: "계절"
         },
         {
            id: 29,
            en: "spring",
            cn: "春天",
            cn_pronunciation: "[chūntiān]",
            jp: "春",
            jp_pronunciation: "[はる]",
            ko: "봄"
         },
         {
            id: 30,
            en: "summer",
            cn: "夏天",
            cn_pronunciation: "[xiàtiān]",
            jp: "夏",
            jp_pronunciation: "[なつ]",
            ko: "여름"
         },
         {
            id: 31,
            en: "autumn",
            cn: "秋天",
            cn_pronunciation: "[qiūtiān]",
            jp: "秋",
            jp_pronunciation: "[あき]",
            ko: "가을"
         },
         {
            id: 32,
            en: "winter",
            cn: "冬天",
            cn_pronunciation: "[dōngtiān]",
            jp: "冬",
            jp_pronunciation: "[ふゆ]",
            ko: "겨울"
         },
         {
            id: 33,
            en: "break",
            cn: "课间休息",
            cn_pronunciation: "[kèjiānxiūxī]",
            jp: "休憩時間",
            jp_pronunciation: "[きゅうけいじかん]",
            ko: "휴식시간"
         },
         {
            id: 34,
            en: "A.M",
            cn: "上午，午前",
            cn_pronunciation: "[shàngwǔ，wǔqián]",
            jp: "午前",
            jp_pronunciation: "[ごぜん]",
            ko: "오전"
         },
         {
            id: 35,
            en: "P.M",
            cn: "下午，午后",
            cn_pronunciation: "[xiàwǔ，wǔhòu]",
            jp: "午後",
            jp_pronunciation: "[ごご]",
            ko: "오후"
         },
         {
            id: 36,
            en: "holiday",
            cn: "假日",
            cn_pronunciation: "[jiǎrì]",
            jp: "休日",
            jp_pronunciation: "[きゅうじつ]",
            ko: "휴일"
         },
         {
            id: 37,
            en: "tonight",
            cn: "今晚",
            cn_pronunciation: "[jīnwǎn]",
            jp: "今夜",
            jp_pronunciation: "[こんや]",
            ko: "오늘밤"
         },
         {
            id: 38,
            en: "today",
            cn: "今天",
            cn_pronunciation: "[jīntiān]",
            jp: "今日",
            jp_pronunciation: "[きょう]",
            ko: "오늘"
         },
         {
            id: 39,
            en: "tomorrow",
            cn: "明天",
            cn_pronunciation: "[míngtiān]",
            jp: "明日",
            jp_pronunciation: "[あした]",
            ko: "내일"
         },
         {
            id: 40,
            en: "second",
            cn: "秒",
            cn_pronunciation: "[miǎo]",
            jp: "秒",
            jp_pronunciation: "[びょう]",
            ko: "초"
         },
         {
            id: 41,
            en: "minute",
            cn: "分（钟）",
            cn_pronunciation: "[fèn（zhōng）]",
            jp: "分",
            jp_pronunciation: "[ぶん]",
            ko: "분"
         },
         {
            id: 42,
            en: "hour",
            cn: "小时",
            cn_pronunciation: "[xiǎoshí]",
            jp: "時間",
            jp_pronunciation: "[じかん]",
            ko: "시간"
         },
         {
            id: 43,
            en: "quarter",
            cn: "片刻",
            cn_pronunciation: "[piànkè]",
            jp: "十五分",
            jp_pronunciation: "[じゅうごふん]",
            ko: "15분"
         },
         {
            id: 44,
            en: "time",
            cn: "时间",
            cn_pronunciation: "[shíjiān]",
            jp: "時間",
            jp_pronunciation: "[じかん]",
            ko: "시간"
         },
         {
            id: 45,
            en: "half",
            cn: "一半",
            cn_pronunciation: "[yībàn]",
            jp: "半分",
            jp_pronunciation: "[はんふん]",
            ko: "반"
         },
         {
            id: 46,
            en: "moment",
            cn: "一会儿",
            cn_pronunciation: "[yīhuìér]",
            jp: "瞬間",
            jp_pronunciation: "[しゅんかん]",
            ko: "순간"
         },
         {
            id: 47,
            en: "o'clock",
            cn: "点钟",
            cn_pronunciation: "[diǎnzhōng]",
            jp: "(ちょうど)時",
            jp_pronunciation: "[(ちょうど)じ]",
            ko: "정각"
         },
         {
            id: 48,
            en: "sometimes",
            cn: "有时",
            cn_pronunciation: "[yǒushí]",
            jp: "時々",
            jp_pronunciation: "[ときどき]",
            ko: "때때로"
         },
         {
            id: 49,
            en: "someday",
            cn: "有一天",
            cn_pronunciation: "[yǒuyītiān]",
            jp: "いつか",
            jp_pronunciation: "[いつか]",
            ko: "언젠가"
         },
         {
            id: 50,
            en: "day",
            cn: "天；日",
            cn_pronunciation: "[tiān；rì]",
            jp: "日",
            jp_pronunciation: "[ひ]",
            ko: "날"
         },
         {
            id: 51,
            en: "date",
            cn: "日期",
            cn_pronunciation: "[rìqī]",
            jp: "日付",
            jp_pronunciation: "[ひづけ]",
            ko: "날짜"
         },
         {
            id: 52,
            en: "evening",
            cn: "傍晚",
            cn_pronunciation: "[bàngwǎn]",
            jp: "夕方",
            jp_pronunciation: "[ゆうがた]",
            ko: "저녁"
         },
         {
            id: 53,
            en: "night",
            cn: "晚上",
            cn_pronunciation: "[wǎnshàng]",
            jp: "夜",
            jp_pronunciation: "[よる]",
            ko: "밤"
         },
         {
            id: 54,
            en: "midnight",
            cn: "午夜",
            cn_pronunciation: "[wǔyè]",
            jp: "夜中",
            jp_pronunciation: "[よなか]",
            ko: "자정"
         },
         {
            id: 55,
            en: "yesterday",
            cn: "昨天",
            cn_pronunciation: "[zuótiān]",
            jp: "昨日",
            jp_pronunciation: "[きのう]",
            ko: "어제"
         },
         {
            id: 56,
            en: "weekend",
            cn: "周末",
            cn_pronunciation: "[zhōumò]",
            jp: "週末",
            jp_pronunciation: "[しゅうまつ]",
            ko: "주말"
         },
         {
            id: 57,
            en: "year",
            cn: "年",
            cn_pronunciation: "[nián]",
            jp: "年",
            jp_pronunciation: "[ねん]",
            ko: "년"
         },
         {
            id: 58,
            en: "century",
            cn: "世纪；百年",
            cn_pronunciation: "[shìjì；bǎinián]",
            jp: "世紀",
            jp_pronunciation: "[せいき]",
            ko: "세기"
         },
         {
            id: 59,
            en: "daytime",
            cn: "白天",
            cn_pronunciation: "[báitiān]",
            jp: "昼間",
            jp_pronunciation: "[ひるま]",
            ko: "낮"
         },
         {
            id: 60,
            en: "minute",
            cn: "分（钟）",
            cn_pronunciation: "[fèn（zhōng）]",
            jp: "分",
            jp_pronunciation: "[ふん]",
            ko: "분"
         },
         {
            id: 61,
            en: "twent to one",
            cn: "12:40",
            cn_pronunciation: "[十二点四十分]",
            jp: "12時40分",
            jp_pronunciation: "[じゅうにじよんじゅっぷん]",
            ko: "12시40분"
         },
         {
            id: 62,
            en: "a quarter to two",
            cn: "13:45",
            cn_pronunciation: "[一点四十五分]",
            jp: "1時45分",
            jp_pronunciation: "[いちじよんじゅうごふん]",
            ko: "1시45분"
         },
         {
            id: 63,
            en: "ten to three",
            cn: "14:50",
            cn_pronunciation: "[两点五十分]",
            jp: "2時50分",
            jp_pronunciation: "[にじごじゅっぷん]",
            ko: "2시50분"
         },
         {
            id: 64,
            en: "ten past three",
            cn: "15:10",
            cn_pronunciation: "[三点十分]",
            jp: "3時10分",
            jp_pronunciation: "[さんじじゅっぷん]",
            ko: "3시10분"
         },
         {
            id: 65,
            en: "a quarter past four",
            cn: "16:15",
            cn_pronunciation: "[四点十五分]",
            jp: "4時15分",
            jp_pronunciation: "[よじじゅうごふん]",
            ko: "4시15분"
         },
         {
            id: 66,
            en: "twenty past five",
            cn: "17:20",
            cn_pronunciation: "[五点二十分]",
            jp: "5時20分",
            jp_pronunciation: "[ごじにじゅっぷん]",
            ko: "5시20분"
         },
         {
            id: 67,
            en: "half past six",
            cn: "18:30",
            cn_pronunciation: "[六点半]",
            jp: "6時半、6時30分",
            jp_pronunciation: "[ろくじはん、ろくじさんじゅっぷん]",
            ko: "6시 반, 6시30분"
         }
      ]
   },
   {
      id: 37,
      title: 'tourist attraction',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "tourist attraction",
            cn: "景点",
            cn_pronunciation: "[jǐngdiǎn]",
            jp: "観光地",
            jp_pronunciation: "[かんこうち]",
            ko: "관광지"
         },
         {
            id: 2,
            en: "Buckingham Place",
            cn: "白金汉宫",
            cn_pronunciation: "[báijīnhàngōng]",
            jp: "バッキンガム・プレイス",
            jp_pronunciation: "[バッキンガム・プレイス]",
            ko: "버킹검궁전"
         },
         {
            id: 3,
            en: "Hyde Park",
            cn: "海德公园",
            cn_pronunciation: "[hǎidégōngyuán]",
            jp: "ハイドパーク",
            jp_pronunciation: "[ハイドパーク]",
            ko: "하이드공원"
         },
         {
            id: 4,
            en: "the London Eye",
            cn: "伦敦眼",
            cn_pronunciation: "[lúndūnyǎn]",
            jp: "ロンドン・アイ",
            jp_pronunciation: "[ロンドン・アイ]",
            ko: "런던아이"
         },
         {
            id: 5,
            en: "River Thames",
            cn: "泰晤士河",
            cn_pronunciation: "[tàiwùshìhé]",
            jp: "テムズ川",
            jp_pronunciation: "[テムズがわ]",
            ko: "탬즈강"
         },
         {
            id: 6,
            en: "Tower Bridge",
            cn: "塔桥",
            cn_pronunciation: "[tǎqiáo]",
            jp: "タワーブリッジ",
            jp_pronunciation: "[タワーブリッジ]",
            ko: "타워 브리지"
         },
         {
            id: 7,
            en: "Big Ben",
            cn: "大本钟",
            cn_pronunciation: "[dàběnzhōng]",
            jp: "ビッグベン",
            jp_pronunciation: "[ビッグベン]",
            ko: "빅벤"
         },
         {
            id: 8,
            en: "N Seoul Tower",
            cn: "N首尔塔",
            cn_pronunciation: "[Nshǒuěrtǎ]",
            jp: "Nソウルタワー",
            jp_pronunciation: "[Nソウルタワー]",
            ko: "N서울타워"
         },
         {
            id: 9,
            en: "Gwanghwamun Gate",
            cn: "光化门",
            cn_pronunciation: "[guānghuàmén]",
            jp: "クァンファムン",
            jp_pronunciation: "[クァンファムン]",
            ko: "광화문"
         },
         {
            id: 10,
            en: "Gyeongbokgung Palace",
            cn: "景福宫",
            cn_pronunciation: "[jǐngfúgōng]",
            jp: "キョンボックン",
            jp_pronunciation: "[キョンボックン]",
            ko: "경복궁"
         },
         {
            id: 11,
            en: "the Great Wall",
            cn: "长城",
            cn_pronunciation: "[zhǎngchéng]",
            jp: "万里の長城",
            jp_pronunciation: "[ばんりのちょうじょう]",
            ko: "만리장성"
         },
         {
            id: 12,
            en: "Forbidden City",
            cn: "故宫",
            cn_pronunciation: "[gùgōng]",
            jp: "紫禁城",
            jp_pronunciation: "[しきんじょう]",
            ko: "자금성"
         },
         {
            id: 13,
            en: "the Summer Palace",
            cn: "颐和园",
            cn_pronunciation: "[yíhéyuán]",
            jp: "頤和園",
            jp_pronunciation: "[いわえん]",
            ko: "이화원"
         },
         {
            id: 14,
            en: "pyramid",
            cn: "金字塔",
            cn_pronunciation: "[jīnzìtǎ]",
            jp: "ピラミッド",
            jp_pronunciation: "[ピラミッド]",
            ko: "피라미드"
         },
         {
            id: 15,
            en: "Tokyo Tower",
            cn: "东京塔",
            cn_pronunciation: "[dōngjīngtǎ]",
            jp: "東京タワー",
            jp_pronunciation: "[とうきょうタワー]",
            ko: "도쿄 타워"
         },
         {
            id: 16,
            en: "Kyoto",
            cn: "京都",
            cn_pronunciation: "[jīngdōu]",
            jp: "京都",
            jp_pronunciation: "[きょうと]",
            ko: "교토"
         },
         {
            id: 17,
            en: "Statue of Liberty",
            cn: "自由女神像",
            cn_pronunciation: "[zìyóunǚshénxiàng]",
            jp: "自由の女神",
            jp_pronunciation: "[じゆうのめがみぞう]",
            ko: "자유의여신상"
         }
      ]
   },
   {
      id: 38,
      title: 'toy',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "toy",
            cn: "玩具",
            cn_pronunciation: "[wánjù]",
            jp: "おもちゃ",
            jp_pronunciation: "[おもちゃ]",
            ko: "장난감"
         },
         {
            id: 2,
            en: "toy train",
            cn: "玩具火车",
            cn_pronunciation: "[wánjùhuǒchē]",
            jp: "おもちゃ電車",
            jp_pronunciation: "[おもちゃでんしゃ]",
            ko: "장난감 기차"
         },
         {
            id: 3,
            en: "ballon",
            cn: "气球",
            cn_pronunciation: "[qìqiú]",
            jp: "バロン",
            jp_pronunciation: "[バロン]",
            ko: "기구"
         },
         {
            id: 4,
            en: "diary",
            cn: "日记本",
            cn_pronunciation: "[rìjìběn]",
            jp: "日記帳",
            jp_pronunciation: "[にっきちょう]",
            ko: "일기장"
         },
         {
            id: 5,
            en: "doll",
            cn: "洋娃娃",
            cn_pronunciation: "[yángwáwa]",
            jp: "人形",
            jp_pronunciation: "[にんぎょう]",
            ko: "서양 인형"
         },
         {
            id: 6,
            en: "earphones",
            cn: "耳机",
            cn_pronunciation: "[ěrjī]",
            jp: "イヤホン",
            jp_pronunciation: "[イヤホン]",
            ko: "이어폰"
         },
         {
            id: 7,
            en: "film",
            cn: "胶卷",
            cn_pronunciation: "[jiāojuǎn]",
            jp: "フィルム",
            jp_pronunciation: "[フィルム]",
            ko: "필름"
         },
         {
            id: 8,
            en: "glasses",
            cn: "眼镜",
            cn_pronunciation: "[yǎnjìng]",
            jp: "眼鏡",
            jp_pronunciation: "[めがね]",
            ko: "안경"
         },
         {
            id: 9,
            en: "kite",
            cn: "风筝",
            cn_pronunciation: "[fēng‧zheng]",
            jp: "凧",
            jp_pronunciation: "[たこ]",
            ko: "연"
         },
         {
            id: 10,
            en: "mask",
            cn: "面具",
            cn_pronunciation: "[miànjù]",
            jp: "仮面 ; マスク",
            jp_pronunciation: "[かめん ; マスク]",
            ko: "마스크"
         },
         {
            id: 11,
            en: "mobile phone",
            cn: "手机",
            cn_pronunciation: "[shǒujī]",
            jp: "携帯電話",
            jp_pronunciation: "[けいたいでんわ]",
            ko: "핸드폰"
         },
         {
            id: 12,
            en: "smart phone",
            cn: "智能手机",
            cn_pronunciation: "[zhìnéngshǒujī]",
            jp: "スマホ",
            jp_pronunciation: "[スマホ]",
            ko: "스마트폰"
         },
         {
            id: 13,
            en: "puzzle",
            cn: "拼图",
            cn_pronunciation: "[pīntú]",
            jp: "パズル",
            jp_pronunciation: "[パズル]",
            ko: "퍼즐"
         },
         {
            id: 14,
            en: "skateboard",
            cn: "滑板",
            cn_pronunciation: "[huábǎn]",
            jp: "スケートボード",
            jp_pronunciation: "[スケートボード]",
            ko: "스케이트보드"
         },
         {
            id: 15,
            en: "telescope",
            cn: "望远镜",
            cn_pronunciation: "[wàngyuǎnjìng]",
            jp: "望遠鏡",
            jp_pronunciation: "[ぼうえんきょう]",
            ko: "망원경"
         },
         {
            id: 16,
            en: "watch",
            cn: "手表",
            cn_pronunciation: "[shǒubiǎo]",
            jp: "腕時計",
            jp_pronunciation: "[うでどけい]",
            ko: "손목시계"
         }
      ]
   },
   {
      id: 39,
      title: 'vegetable',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "vegetable",
            cn: "蔬菜",
            cn_pronunciation: "[shūcài]",
            jp: "野菜",
            jp_pronunciation: "[やさい]",
            ko: "채소, 야채"
         },
         {
            id: 2,
            en: "bean",
            cn: "豆",
            cn_pronunciation: "[dòu]",
            jp: "豆",
            jp_pronunciation: "[まめ]",
            ko: "콩"
         },
         {
            id: 3,
            en: "cabbage",
            cn: "卷心菜",
            cn_pronunciation: "[juànxīncài]",
            jp: "キャベツ",
            jp_pronunciation: "[キャベツ]",
            ko: "양배추"
         },
         {
            id: 4,
            en: "carrot",
            cn: "胡萝卜",
            cn_pronunciation: "[húluóbo]",
            jp: "ニンジン",
            jp_pronunciation: "[ニンジン]",
            ko: "당근"
         },
         {
            id: 5,
            en: "cucumber",
            cn: "黄瓜",
            cn_pronunciation: "[huángguā]",
            jp: "キュウリ",
            jp_pronunciation: "[キュウリ]",
            ko: "오이"
         },
         {
            id: 6,
            en: "eggplant",
            cn: "茄子",
            cn_pronunciation: "[qiézǐ]",
            jp: "ナス",
            jp_pronunciation: "[ナス]",
            ko: "가지"
         },
         {
            id: 7,
            en: "nut",
            cn: "坚果",
            cn_pronunciation: "[jiānguǒ]",
            jp: "ナッツ",
            jp_pronunciation: "[ナッツ]",
            ko: "견과"
         },
         {
            id: 8,
            en: "onion",
            cn: "洋葱",
            cn_pronunciation: "[yángcōng]",
            jp: "タマネギ",
            jp_pronunciation: "[タマネギ]",
            ko: "양파"
         },
         {
            id: 9,
            en: "pea",
            cn: "豌豆",
            cn_pronunciation: "[wāndòu]",
            jp: "エンドウ",
            jp_pronunciation: "[エンドウ]",
            ko: "완두콩"
         },
         {
            id: 10,
            en: "peanut",
            cn: "花生",
            cn_pronunciation: "[huāshēng]",
            jp: "ピーナッツ",
            jp_pronunciation: "[ピーナッツ]",
            ko: "땅콩"
         },
         {
            id: 11,
            en: "potato",
            cn: "土豆；马铃薯",
            cn_pronunciation: "[tǔdòu；mǎlíngshǔ]",
            jp: "ポテト",
            jp_pronunciation: "[ポテト]",
            ko: "감자"
         },
         {
            id: 12,
            en: "tomato",
            cn: "西红柿",
            cn_pronunciation: "[xīhóngshì]",
            jp: "トマト",
            jp_pronunciation: "[トマト]",
            ko: "토마토"
         }
      ]
   },
   {
      id: 40,
      title: 'verb',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "clean the windows",
            cn: "擦窗户",
            cn_pronunciation: "[cāchuānghù]",
            jp: "窓を掃除する",
            jp_pronunciation: "[まどをそうじする]",
            ko: "창문을 청소하다"
         },
         {
            id: 2,
            en: "close",
            cn: "关",
            cn_pronunciation: "[guān]",
            jp: "閉じる",
            jp_pronunciation: "[とじる]",
            ko: "닫다"
         },
         {
            id: 3,
            en: "collect stamps",
            cn: "集邮",
            cn_pronunciation: "[jíyóu]",
            jp: "切手を集める",
            jp_pronunciation: "[きってをあつめる]",
            ko: "우표를 수집하다"
         },
         {
            id: 4,
            en: "come",
            cn: "来",
            cn_pronunciation: "[lái]",
            jp: "来る",
            jp_pronunciation: "[くる]",
            ko: "오다"
         },
         {
            id: 5,
            en: "cook",
            cn: "做饭",
            cn_pronunciation: "[zuòfàn]",
            jp: "～を料理する",
            jp_pronunciation: "[～をりょうりする]",
            ko: "요리하다"
         },
         {
            id: 6,
            en: "copy",
            cn: "抄写",
            cn_pronunciation: "[chāoxiě]",
            jp: "コピーする",
            jp_pronunciation: "[コピーする]",
            ko: "카피하다"
         },
         {
            id: 7,
            en: "dance",
            cn: "跳舞",
            cn_pronunciation: "[tiàowǔ]",
            jp: "踊る",
            jp_pronunciation: "[おどる]",
            ko: "춤추다"
         },
         {
            id: 8,
            en: "draw",
            cn: "画",
            cn_pronunciation: "[huà]",
            jp: "描く",
            jp_pronunciation: "[かく]",
            ko: "그리다"
         },
         {
            id: 9,
            en: "drink",
            cn: "喝",
            cn_pronunciation: "[hē]",
            jp: "飲む",
            jp_pronunciation: "[のむ]",
            ko: "마시다"
         },
         {
            id: 10,
            en: "eat",
            cn: "吃",
            cn_pronunciation: "[chī]",
            jp: "食べる",
            jp_pronunciation: "[たべる]",
            ko: "먹다"
         },
         {
            id: 11,
            en: "go",
            cn: "去",
            cn_pronunciation: "[qù]",
            jp: "行く",
            jp_pronunciation: "[いく]",
            ko: "가다"
         },
         {
            id: 12,
            en: "go shopping",
            cn: "购物",
            cn_pronunciation: "[gòuwù]",
            jp: "買い物に行く",
            jp_pronunciation: "[かいものにいく]",
            ko: "쇼핑하러가다"
         },
         {
            id: 13,
            en: "grow flowers",
            cn: "种花",
            cn_pronunciation: "[zhònghuā]",
            jp: "花を育てる",
            jp_pronunciation: "[はなをそだてる]",
            ko: "꽃을 가꾸다"
         },
         {
            id: 14,
            en: "have a picnic",
            cn: "去野餐",
            cn_pronunciation: "[qùyěcān]",
            jp: "ピクニックをします",
            jp_pronunciation: "[ピクニックをします]",
            ko: "피크닉가다"
         },
         {
            id: 15,
            en: "jump",
            cn: "跳",
            cn_pronunciation: "[tiào]",
            jp: "跳ぶ",
            jp_pronunciation: "[とぶ]",
            ko: "뛰다"
         },
         {
            id: 16,
            en: "look",
            cn: "看",
            cn_pronunciation: "[kàn]",
            jp: "見る",
            jp_pronunciation: "[みる]",
            ko: "보다"
         },
         {
            id: 17,
            en: "make clothes",
            cn: "做衣服",
            cn_pronunciation: "[zuòyīfú]",
            jp: "服を作る",
            jp_pronunciation: "[ふくをつくる]",
            ko: "옷을 만들다"
         },
         {
            id: 18,
            en: "milk",
            cn: "挤奶",
            cn_pronunciation: "[jǐnǎi]",
            jp: "乳を搾る",
            jp_pronunciation: "[ちちをしぼる]",
            ko: "젖을 짜다"
         },
         {
            id: 19,
            en: "open",
            cn: "打开",
            cn_pronunciation: "[dǎkāi]",
            jp: "開ける",
            jp_pronunciation: "[あける]",
            ko: "열다"
         },
         {
            id: 20,
            en: "opener",
            cn: "开瓶器",
            cn_pronunciation: "[kāipíngqì]",
            jp: "オープナー ; 缶切り",
            jp_pronunciation: "[オープナー ; かんきり]",
            ko: "오프너, 병따개"
         },
         {
            id: 21,
            en: "pick",
            cn: "摘",
            cn_pronunciation: "[zhāi]",
            jp: "選ぶ",
            jp_pronunciation: "[えらぶ]",
            ko: "고르다"
         },
         {
            id: 22,
            en: "play cards",
            cn: "打牌",
            cn_pronunciation: "[dǎpái]",
            jp: "トランプをする",
            jp_pronunciation: "[トランプをする]",
            ko: "카드놀이를 하다"
         },
         {
            id: 23,
            en: "play chess",
            cn: "下棋",
            cn_pronunciation: "[xiàqí]",
            jp: "チェスをする",
            jp_pronunciation: "[チェスをする]",
            ko: "체스를 두다"
         },
         {
            id: 24,
            en: "play the guitar",
            cn: "弹吉他",
            cn_pronunciation: "[tánjítā]",
            jp: "ギターを弾く",
            jp_pronunciation: "[ギターをひく]",
            ko: "기타를 치다"
         },
         {
            id: 25,
            en: "play the violin",
            cn: "拉小提琴",
            cn_pronunciation: "[lāxiǎotíqín]",
            jp: "バイオリンを弾く",
            jp_pronunciation: "[バイオリンをひく]",
            ko: "바이올린을 켜다"
         },
         {
            id: 26,
            en: "point",
            cn: "指，指向",
            cn_pronunciation: "[zhǐ ; zhǐxiàng]",
            jp: "指す",
            jp_pronunciation: "[さす]",
            ko: "가리키다"
         },
         {
            id: 27,
            en: "pull",
            cn: "拔",
            cn_pronunciation: "[bá]",
            jp: "引く",
            jp_pronunciation: "[ひく]",
            ko: "끌다"
         },
         {
            id: 28,
            en: "read",
            cn: "读",
            cn_pronunciation: "[dú]",
            jp: "読む",
            jp_pronunciation: "[よむ]",
            ko: "읽다"
         },
         {
            id: 29,
            en: "read a magazine",
            cn: "看杂志",
            cn_pronunciation: "[kànzázhì]",
            jp: "雑誌を読む",
            jp_pronunciation: "[ざっしをよむ]",
            ko: "잡지를 읽다"
         },
         {
            id: 30,
            en: "read a picture book",
            cn: "看图画书",
            cn_pronunciation: "[kàntúhuà shū]",
            jp: "絵本を読む",
            jp_pronunciation: "[えほんをよむ]",
            ko: "그림책을 읽다"
         },
         {
            id: 31,
            en: "run",
            cn: "跑",
            cn_pronunciation: "[păo]",
            jp: "走る",
            jp_pronunciation: "[はしる]",
            ko: "달리다"
         },
         {
            id: 32,
            en: "say",
            cn: "说",
            cn_pronunciation: "[shuō]",
            jp: "話す",
            jp_pronunciation: "[はなす]",
            ko: "말하다"
         },
         {
            id: 33,
            en: "see a play",
            cn: "看话剧",
            cn_pronunciation: "[kànhuàjù]",
            jp: "芝居を見る",
            jp_pronunciation: "[しばいをみる]",
            ko: "연극을 보다"
         },
         {
            id: 34,
            en: "sing",
            cn: "唱",
            cn_pronunciation: "[chàng]",
            jp: "歌う",
            jp_pronunciation: "[うたう]",
            ko: "노래하다"
         },
         {
            id: 35,
            en: "sit",
            cn: "坐",
            cn_pronunciation: "[zuò]",
            jp: "座る",
            jp_pronunciation: "[すわる]",
            ko: "앉다"
         },
         {
            id: 36,
            en: "sleep",
            cn: "睡觉",
            cn_pronunciation: "[shuìjiào]",
            jp: "眠る",
            jp_pronunciation: "[ねむる]",
            ko: "자다"
         },
         {
            id: 37,
            en: "speak",
            cn: "说 ; 讲",
            cn_pronunciation: "[shuō ; jiǎng]",
            jp: "話す",
            jp_pronunciation: "[はなす]",
            ko: "말하다"
         },
         {
            id: 38,
            en: "stand",
            cn: "站",
            cn_pronunciation: "[zhàn]",
            jp: "立つ",
            jp_pronunciation: "[たつ]",
            ko: "서다"
         },
         {
            id: 39,
            en: "stand up",
            cn: "起立",
            cn_pronunciation: "[qǐlì]",
            jp: "立ち上がる",
            jp_pronunciation: "[たちあがる]",
            ko: "일어서다"
         },
         {
            id: 40,
            en: "sweep the floor",
            cn: "扫地",
            cn_pronunciation: "[sǎodì]",
            jp: "床を掃除する",
            jp_pronunciation: "[ゆかをそうじする]",
            ko: "마루를 청소하다"
         },
         {
            id: 41,
            en: "swim",
            cn: "游泳",
            cn_pronunciation: "[yóuyǒng]",
            jp: "泳ぐ",
            jp_pronunciation: "[およぐ]",
            ko: "수영하다"
         },
         {
            id: 42,
            en: "take",
            cn: "取",
            cn_pronunciation: "[qǔ]",
            jp: "取る",
            jp_pronunciation: "[とる]",
            ko: "잡다"
         },
         {
            id: 43,
            en: "take part in",
            cn: "参加",
            cn_pronunciation: "[cānjiā]",
            jp: "参加する",
            jp_pronunciation: "[さんかする]",
            ko: "참가하다"
         },
         {
            id: 44,
            en: "take photos",
            cn: "拍照",
            cn_pronunciation: "[pāizhào]",
            jp: "写真を撮る",
            jp_pronunciation: "[しゃしんをとる]",
            ko: "사진을 찍다"
         },
         {
            id: 45,
            en: "taste",
            cn: "品尝",
            cn_pronunciation: "[pǐncháng]",
            jp: "味わう",
            jp_pronunciation: "[あじわう]",
            ko: "맛보다"
         },
         {
            id: 46,
            en: "walk",
            cn: "走路",
            cn_pronunciation: "[zǒulù]",
            jp: "歩く",
            jp_pronunciation: "[あるく]",
            ko: "걷다"
         },
         {
            id: 47,
            en: "wash",
            cn: "洗",
            cn_pronunciation: "[xǐ]",
            jp: "洗う",
            jp_pronunciation: "[あらう]",
            ko: "씻다"
         },
         {
            id: 48,
            en: "water",
            cn: "浇水",
            cn_pronunciation: "[jiāoshuǐ]",
            jp: "水を掛ける",
            jp_pronunciation: "[みずをかける]",
            ko: "물을 뿌리다."
         },
         {
            id: 49,
            en: "write",
            cn: "写",
            cn_pronunciation: "[xiě]",
            jp: "書く",
            jp_pronunciation: "[かく]",
            ko: "글씨를 쓰다"
         }
      ]
   },
   {
      id: 41,
      title: 'weather',
      routeName: 'elementary',
      items: [
         {
            id: 1,
            en: "weather",
            cn: "天气",
            cn_pronunciation: "[tiān qì]",
            jp: "天気",
            jp_pronunciation: "[てんき]",
            ko: "날씨"
         },
         {
            id: 2,
            en: "breeze",
            cn: "微风",
            cn_pronunciation: "[wēi fēng]",
            jp: "微風",
            jp_pronunciation: "[そよかぜ]",
            ko: "미풍"
         },
         {
            id: 3,
            en: "cloudy",
            cn: "多云的；阴天的",
            cn_pronunciation: "[duō yún de ; yīn tiān de]",
            jp: "曇った",
            jp_pronunciation: "[くもった]",
            ko: "흐린"
         },
         {
            id: 4,
            en: "cold",
            cn: "寒冷的",
            cn_pronunciation: "[hán lěng de]",
            jp: "寒い",
            jp_pronunciation: "[さむい]",
            ko: "추운"
         },
         {
            id: 5,
            en: "cool",
            cn: "凉快的,凉爽的",
            cn_pronunciation: "[liáng kuài de ; liáng shuǎng de]",
            jp: "涼しい",
            jp_pronunciation: "[すずしい]",
            ko: "시원한"
         },
         {
            id: 6,
            en: "hot",
            cn: "热的",
            cn_pronunciation: "[rè de]",
            jp: "熱い",
            jp_pronunciation: "[あつい]",
            ko: "더운"
         },
         {
            id: 7,
            en: "ice",
            cn: "冰",
            cn_pronunciation: "[bīng]",
            jp: "氷",
            jp_pronunciation: "[こおり]",
            ko: "얼음"
         },
         {
            id: 8,
            en: "rain",
            cn: "雨",
            cn_pronunciation: "[yǔ]",
            jp: "雨",
            jp_pronunciation: "[あめ]",
            ko: "비"
         },
         {
            id: 9,
            en: "rainy",
            cn: "下雨的",
            cn_pronunciation: "[xià yǔ de]",
            jp: "雨の",
            jp_pronunciation: "[あめの]",
            ko: "비가 많이 오는"
         },
         {
            id: 10,
            en: "shower",
            cn: "阵雨",
            cn_pronunciation: "[zhèn yǔ]",
            jp: "にわか雨",
            jp_pronunciation: "[にわかあめ]",
            ko: "소나기"
         },
         {
            id: 11,
            en: "snow",
            cn: "雪",
            cn_pronunciation: "[xuě]",
            jp: "雪",
            jp_pronunciation: "[ゆき]",
            ko: "눈"
         },
         {
            id: 12,
            en: "snowy",
            cn: "下雪多的",
            cn_pronunciation: "[xià xuě duō de]",
            jp: "雪の降る",
            jp_pronunciation: "[ゆきのふる]",
            ko: "눈이 많이 내리는"
         },
         {
            id: 13,
            en: "sunny",
            cn: "阳光充足的",
            cn_pronunciation: "[yánɡ ɡuānɡ chōnɡ zú de]",
            jp: "日当たりの良い",
            jp_pronunciation: "[ひあたりのよい]",
            ko: "화창한"
         },
         {
            id: 14,
            en: "temperature",
            cn: "温度",
            cn_pronunciation: "[wēn dù]",
            jp: "温度",
            jp_pronunciation: "[おんど]",
            ko: "온도"
         },
         {
            id: 15,
            en: "warm",
            cn: "温暖",
            cn_pronunciation: "[wēn nuǎn]",
            jp: "暖かい",
            jp_pronunciation: "[あたたかい]",
            ko: "따뜻한"
         },
         {
            id: 16,
            en: "wet",
            cn: "湿的",
            cn_pronunciation: "[shī de]",
            jp: "ぬれた",
            jp_pronunciation: "[ぬれた]",
            ko: "젖은"
         },
         {
            id: 17,
            en: "wind",
            cn: "风",
            cn_pronunciation: "[fēng]",
            jp: "風",
            jp_pronunciation: "[かぜ]",
            ko: "바람"
         },
         {
            id: 18,
            en: "windy",
            cn: "刮风的",
            cn_pronunciation: "[guā fēng de]",
            jp: "風の吹く",
            jp_pronunciation: "[かぜのふく]",
            ko: "바람이 부는"
         }
      ]
   }
]

   export default LANGS_MASTER_DATA; 
